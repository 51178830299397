import { AxiosError } from 'axios'
import { UseMutateFunction, useMutation, useQueryClient } from 'react-query'

import { queryKeys } from 'shared/constants'
import { ExtendedAxiosError } from 'shared/models'
import { ReferenceBookService } from 'shared/services'
import { toasts } from 'shared/utils'

interface DeleteActivityArea {
  gs_activity_areas_id: string
  successAction: () => void
}
export function useDeleteActivityArea(): {
  mutate: UseMutateFunction<void, AxiosError, DeleteActivityArea, unknown>
  isLoading: boolean
} {
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation<
    void,
    AxiosError,
    DeleteActivityArea
  >(
    ({ gs_activity_areas_id }: DeleteActivityArea) =>
      ReferenceBookService.deleteActivityArea({
        gs_activity_areas_id,
      }).then(() => {}),
    {
      onSuccess: (_, variables: DeleteActivityArea) => {
        if (variables.successAction) variables.successAction()

        toasts.success({
          title: 'Зона дейтиельности   ГП удалена',
          description: `Зону дейтиельности ГП успешно удалена`,
        })

        queryClient.invalidateQueries([
          queryKeys.referenceBooks.activityAreas.allActivityAreas,
        ])
      },
      onError: (error: ExtendedAxiosError) => {
        toasts.error({
          title: 'Произошла ошибка',
          description:
            error.response.data.detail ||
            'Зону дейтиельности ГП не была удалена',
        })
      },
    },
  )

  return { mutate, isLoading }
}
