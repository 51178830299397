import { useQuery } from 'react-query'

import { CalculationSchemesResponse } from 'shared/models'
import { queryKeys } from 'shared/constants'
import { CounteragentsContractsService } from 'shared/services'

interface CalculationSchemesData {
  value: string
  label: string
}

interface UseGetDayZonesResult {
  data: CalculationSchemesResponse[] | undefined
  isError: boolean
  isFetching: boolean
  isLoading: boolean
  dayZonesData: CalculationSchemesData[] | undefined
}

interface UseAllCalculationSchemes {
  enabled: boolean
}

export function useGetAllCalculationSchemes({
  enabled,
}: UseAllCalculationSchemes): UseGetDayZonesResult {
  const { data, isError, isFetching, isLoading } = useQuery<
    CalculationSchemesResponse[]
  >({
    queryKey: [
      queryKeys.counteragentsContracts.calculationSchemes.allCalculationSchemes,
    ],
    queryFn: () =>
      CounteragentsContractsService.getAllCalculationSchemes().then(
        (response) => response.data,
      ),
    enabled,
    retry: false,
  })

  const dayZonesData = data?.map((schemes) => ({
    value: schemes.calc_schemes_id,
    label: schemes.schema_name,
  }))

  return { data, isError, isFetching, isLoading, dayZonesData }
}
