import React, { useMemo } from 'react'
import { uniqueId } from 'lodash'
import { Box } from '@chakra-ui/react'
import { DeliveryPoint } from 'shared/models'

import { EditGroupDelivery } from './EditGroupDelivery'
import { useGetAllGroupDelivery } from 'entities/referenceBooks'

export interface GroupDeliveryProps {
  groupDelivery: DeliveryPoint[]
  gs_activity_areas_id: string
}

export const GroupDelivery: React.FC<GroupDeliveryProps> = React.memo(
  (props) => {
    const { groupDelivery = [], gs_activity_areas_id } = props

    const { groupDeliveryData } = useGetAllGroupDelivery({
      enabled: true,
    })

    const groupDeliveryPeriods = useMemo(
      () =>
        groupDelivery.map((item) => ({
          date_from: item.date_from,
          date_to: item.date_to,
          id: item.id,
        })),
      [groupDelivery],
    )

    return (
      <Box>
        {groupDelivery.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <EditGroupDelivery
                groupDeliveryPeriods={groupDeliveryPeriods}
                id={item.id}
                {...item}
                groupDeliveryData={groupDeliveryData}
                gs_activity_areas_id={gs_activity_areas_id}
              />
              <Box
                borderBottom={
                  index + 1 === groupDelivery.length
                    ? 'none '
                    : '1px solid #e2e8f0'
                }
                p={index + 1 === groupDelivery.length ? 'none ' : '5px 20px'}
              />
            </React.Fragment>
          )
        })}
      </Box>
    )
  },
)
