import { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { PathMatch } from 'react-router-dom'

import { AuthStage } from 'widgets/authentication'

interface RegistrationFormData {
  password?: string
  confirmPassword?: string
}

const SCHEMA_VALIDATION = yup.object().shape({
  password: yup
    .string()
    .min(6, 'Пароль должен содержать минимум 6 символов')
    .required('Пароль обязательное поле'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Пароли должны совпадать')
    .required('Подтверждение пароля обязательное поле'),
})

export const useRegistrationForm = (
  isRegistrationUrl: PathMatch<string>,
  setAuthStage: React.Dispatch<React.SetStateAction<AuthStage>>,
) => {
  const [registrationToken, setRegistrationToken] = useState<string | null>(
    null,
  )

  const { register, handleSubmit, formState } = useForm<RegistrationFormData>({
    resolver: yupResolver(SCHEMA_VALIDATION),
  })

  useEffect(() => {
    setRegistrationToken(isRegistrationUrl?.params?.registrationToken || null)
  }, [isRegistrationUrl])

  return {
    register,
    handleSubmit,
    errors: formState.errors,
    isDirty: formState.isDirty,
    isValid: formState.isValid,
    isSubmitting: formState.isSubmitting,
    registrationToken,
    setAuthStage,
  }
}
