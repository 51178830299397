import React, { forwardRef, ReactNode, useCallback } from 'react'
import Calendar, { ReactDatePickerProps } from 'react-datepicker'
import ru from 'date-fns/locale/ru'
import moment from 'moment'
import clsx from 'clsx'
import { Portal } from 'react-overlays'

import 'react-datepicker/dist/react-datepicker.css'
import { FormInputControl } from '../FormInputControl'

interface Holiday {
  date: string
}

interface IDataPicker extends ReactDatePickerProps {
  disabledKeyboardNavigation: boolean
  onChange: (
    date: Date | null,
    event: React.SyntheticEvent<any> | undefined,
  ) => void
  format: string
  theme?: 'holydays' | 'dates'
  placeholder?: string
  isClearable?: boolean
  value?: Date
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void
  id?: string
  holidays?: Holiday[]
  closeBtn?: boolean
  showTimeSelect?: boolean
  showMonthYearPicker?: boolean
  selected?: Date
  minDate?: Date
  maxDate?: Date
  startDate?: Date
  name?: string
  inline?: boolean
  customInput?: 'default' | 'hidden'
}

interface CustomInputProps {
  value?: string
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const CustomInput = forwardRef<HTMLInputElement, any>(({ ...rest }, ref) => (
  <FormInputControl ref={ref} {...rest} />
))

const CalendarContainer = ({ children }) => {
  const el = document.getElementById('calendar-portal')

  return <Portal container={el}>{children}</Portal>
}

const classes = {
  holydays: 'holydaysWrapper',
  dates: 'datesWrapper',
}

export const DatePicker = React.memo<IDataPicker>((props) => {
  const {
    format,
    onChange,
    placeholder,
    theme = classes.holydays,
    value,
    onBlur,
    id,
    startDate = null,
    closeBtn = true,
    isClearable = false,
    showMonthYearPicker = false,
    disabledKeyboardNavigation = true,
    showTimeSelect = false,
    inline = false,
    customInput = 'default',
    selected,
    ...rest
  } = props

  const className = classes[theme] || classes.holydays

  const inputs = {
    default: null,
    chakra: FormInputControl,
  }

  const handleClearInput = useCallback((): void => {
    onChange(null, undefined)
  }, [onChange])

  return (
    <div className={clsx(className, { value: value })}>
      <Calendar
        customInput={inputs[customInput]}
        // startDate={moment().toDate()}
        // startDate={startDate}
        disabledKeyboardNavigation={disabledKeyboardNavigation}
        // open
        selected={selected}
        onChange={onChange}
        // id={id}
        // onBlur={onBlur}
        dateFormat={format}
        showMonthYearPicker={showMonthYearPicker}
        showTimeSelect={showTimeSelect}
        showPopperArrow={false}
        locale={ru}
        inline={inline}
        shouldCloseOnSelect
        isClearable={isClearable}
        // popperContainer={CalendarContainer}

        {...rest}
      />
      <div className={'buttonClose'} onClick={handleClearInput} />
      {closeBtn && value && (
        <div className={'buttonClose'} onClick={handleClearInput} />
      )}
      {placeholder && <span className={'placeholder'}>{placeholder}</span>}
    </div>
  )
})
