import { Box, Flex } from '@chakra-ui/react'

import { TypesCounteragentDetailResponse } from 'shared/models'
import { EditButton, TextLabelTitle, TextTitle } from 'shared/ui'
import { TableEntity } from './TableEntity'
import { useCallback, useState } from 'react'
import { accessDict, modulesDict } from 'shared/dictionary'
import { Access } from 'app/providers'

interface RelatedTypeContractProps {
  type_counteragent_id: string
  typesContracts: TypesCounteragentDetailResponse[]
}

const hiddenColumns = ['action']

export const RelatedTypeContractList: React.FC<RelatedTypeContractProps> = (
  props,
) => {
  const { typesContracts, type_counteragent_id } = props

  const [isEdit, setIsEdit] = useState<boolean>(false)

  const handleEditClick = useCallback(() => {
    setIsEdit(!isEdit)
  }, [isEdit])

  return (
    <Box position={'relative'} w="800px">
      <TextTitle size="extraSmall">Связанные виды договоров:</TextTitle>
      {typesContracts && typesContracts.length > 0 && (
        <Access
          permissions={[accessDict.update_read]}
          module={modulesDict.reference_books}
        >
          <Flex
            w="100%"
            justifyContent="flex-end"
            position={'absolute'}
            right={'0px'}
            top={'-5px'}
          >
            <EditButton
              handleEditClick={handleEditClick}
              isEdit={isEdit}
              size={'xs'}
            />
          </Flex>
        </Access>
      )}

      <Box mt={'10px'} mb={'10px'}>
        {typesContracts && typesContracts.length > 0 ? (
          <Box maxWidth={'1000px'}>
            <TableEntity
              type_counteragent_id={type_counteragent_id}
              typesContracts={typesContracts}
              hiddenColumns={isEdit ? [''] : hiddenColumns}
              isEdit={isEdit}
            />
          </Box>
        ) : (
          <Box p={'5px'}>
            <TextLabelTitle>Отсутствуют</TextLabelTitle>
          </Box>
        )}
      </Box>
    </Box>
  )
}
