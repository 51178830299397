import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Path } from 'react-hook-form'
import { omitBy, isEmpty, isObject, isEqual } from 'lodash'

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Switch,
  Text,
  Collapse,
  Grid,
} from '@chakra-ui/react'

import {
  FormInputControl,
  MaskedInput,
  TextTitle,
  DatePickerInput,
} from 'shared/ui'
import { COMPANIES_ROUTE } from 'shared/constants'

import { formSections } from './data'

import {
  CreateCompanyFormData,
  useCreateCompanyForm,
} from './CreateCompanyForm'

import { useCreateCompany } from '../models/services/createCompanyService'
import { CompanyCreateProps } from '../models/services/createCompanyService'

// misc
import { HiArrowLeftCircle, HiXMark } from 'react-icons/hi2'

type AddressMatchState = {
  legal_address: boolean
  mailing_address: boolean
}

type SectionId = 'legal_address' | 'mailing_address'

export const CreateCompany = () => {
  const navigate = useNavigate()

  const [theSameAddresses, setTheSameAddresses] = useState<AddressMatchState>({
    legal_address: false,
    mailing_address: false,
  })

  const { mutate: createCompany, isLoading } = useCreateCompany()

  const {
    register,
    handleSubmit,
    errors,
    reset,
    control,
    watchedFields,
    watch,
    setValue,
    getValues,
  } = useCreateCompanyForm()

  const updateAddressMatchState = (actualAddress, otherAddress, key) => {
    setTheSameAddresses((prev) => ({
      ...prev,
      [key]: isEqual(actualAddress, otherAddress),
    }))
  }

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      const actualAddress = value.actual_address

      if (name?.includes('actual_address')) {
        if (theSameAddresses.legal_address)
          setValue('legal_address', actualAddress, { shouldDirty: true })

        if (theSameAddresses.mailing_address)
          setValue('mailing_address', actualAddress, { shouldDirty: true })
      }
    })

    return () => subscription.unsubscribe()
  }, [watch, theSameAddresses, setValue])

  const handleResetForm = () => reset()

  const onSubmit = ({
    general_info,
    bank_details,
    contacts,
    director,
    legal_address,
    actual_address,
    mailing_address,
  }) => {
    const updatedLegalAddress = {
      ...legal_address,
      is_same: theSameAddresses.legal_address,
    }
    const updatedMailingAddress = {
      ...mailing_address,
      is_same: theSameAddresses.mailing_address,
    }
    const updatedActualAddress = {
      ...actual_address,
      is_same: true,
    }

    const allData = {
      director_position: director.director_position,
      director_last_name: director.director_last_name,
      director_first_name: director.director_first_name,
      director_middle_name: director.director_middle_name,
      basis_of_action: director.basis_of_action,
      legal_address: updatedLegalAddress,
      actual_address: updatedActualAddress,
      mailing_address: updatedMailingAddress,
      short_name: general_info.short_name,
      full_name: general_info.full_name,
      inn: general_info.inn,
      kpp: general_info.kpp,
      ogrn: general_info.ogrn,
      account_number: bank_details.account_number,
      bic: bank_details.bic,
      bank_name: bank_details.bank_name,
      correspondent_account: bank_details.correspondent_account,
      email: contacts.email,
      phone_number: `+${contacts.phone_number}`,
    }

    const cleanedData = omitBy(
      allData,
      (value) =>
        isEmpty(value) || (isObject(value) && isEmpty(omitBy(value, isEmpty))),
    )

    createCompany({
      ...(cleanedData as CompanyCreateProps),
      successAction: () => navigate(COMPANIES_ROUTE),
    })
  }

  const handleReturn = () => {
    navigate(-1)
  }

  const handleSwitchChange = useCallback(
    (sectionId: SectionId, isChecked: boolean) => {
      setTheSameAddresses((prev) => ({
        ...prev,
        [sectionId]: isChecked,
      }))

      if (isChecked) {
        const actualAddress = getValues('actual_address')
        Object.keys(actualAddress).forEach((key) => {
          setValue(
            `${sectionId}.${key}` as keyof CreateCompanyFormData,
            actualAddress[key],
            { shouldDirty: true },
          )
        })
      }
    },
    [getValues, setValue, setTheSameAddresses],
  )

  const ActionBlock: React.FC = () => {
    return (
      <Flex
        justifyContent={'flex-start'}
        mt="8px"
        pb="8px"
        // borderBottom={'1px solid #ededed'}
      >
        <Button mr="10px" onClick={handleReturn} size={'sm'}>
          <Flex alignItems={'center'}>
            <Box color={'gray.400'} mr={'3px'}>
              <HiArrowLeftCircle fontSize={'18px'} />
            </Box>
            Отмена
          </Flex>
        </Button>
        <Button mr="10px" onClick={handleResetForm} size={'sm'}>
          <Flex alignItems={'center'}>
            <Box color={'gray.500'} mr={'3px'}>
              <HiXMark fontSize={'18px'} />
            </Box>
            Очистить
          </Flex>
        </Button>
        <Button
          size={'sm'}
          colorScheme="blue"
          type="submit"
          isDisabled={isLoading}
          isLoading={isLoading}
        >
          Добавить
        </Button>
      </Flex>
    )
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextTitle>Добавление новой компании</TextTitle>
      <Box borderTop={'1px solid #ededed'} mt="15px" />
      <ActionBlock />
      <Box mb={'10px'} />
      {formSections.map((section, sectionIndex) => {
        const isAddressSection = ['legal_address', 'mailing_address'].includes(
          section.id,
        )
        const isOpen = isAddressSection ? !theSameAddresses[section.id] : true

        return (
          <React.Fragment key={sectionIndex}>
            <Box w="fit-content">
              <Box position={'relative'}>
                <TextTitle size="extraSmall" mt="12px">
                  {section.title}
                </TextTitle>

                {isAddressSection && (
                  <Flex
                    w={'200px'}
                    align="center"
                    position={'absolute'}
                    top={'3px'}
                    left={'180px'}
                    alignItems={'center'}
                  >
                    <Text
                      // htmlFor={`${section.id}-same-as`}
                      mr="10px"
                      fontSize={'12px'}
                      color={'gray.500'}
                      fontWeight={'500'}
                    >
                      Совпадает с фактическим?
                    </Text>
                    <Switch
                      size={'sm'}
                      id={`${section.id}-same-as`}
                      isChecked={theSameAddresses[section.id]}
                      onChange={(e) =>
                        handleSwitchChange(
                          section.id as SectionId,
                          e.target.checked,
                        )
                      }
                    />
                  </Flex>
                )}
              </Box>

              <Collapse in={isOpen} style={{ overflow: 'visible' }}>
                <Grid templateColumns="repeat(2, 1fr)" columnGap={'30px'}>
                  {section.fields.map((field, fieldIndex) => {
                    const isError =
                      !!errors?.[field.name.split('.')[0]]?.[
                        field.name.split('.')[1]
                      ]
                    const errorMessage =
                      errors?.[field.name.split('.')[0]]?.[
                        field.name.split('.')[1]
                      ]?.message

                    return (
                      <Flex
                        alignItems={'center'}
                        mt="10px"
                        ml={'10px'}
                        key={fieldIndex}
                        w={'fit-content'}
                      >
                        <Box w="130px" mr="10px">
                          <FormLabel
                            m="0"
                            fontSize={'12px'}
                            fontWeight={'500'}
                            color={'gray.500'}
                          >
                            {field.label}
                          </FormLabel>
                        </Box>
                        <Box w="280px">
                          <FormControl
                            id={field.name}
                            isInvalid={!!errors[field.name]}
                          >
                            {field.variant === 'masked' && (
                              <MaskedInput
                                control={control}
                                register={register}
                                errors={errors}
                                name={field.name as Path<CreateCompanyFormData>}
                                mask={field.mask}
                                placeholder={field.placeholder}
                                watchedFields={watchedFields}
                                isRequired={field?.isRequired}
                                type={field.type}
                                size={'sm'}
                                smallErrorTextInside
                                onChangeFormatter={(inputValue: string) =>
                                  inputValue.replace(/\D/g, '')
                                }
                              />
                            )}

                            {field.variant === 'default' && (
                              <FormInputControl
                                name={field.name as Path<CreateCompanyFormData>}
                                register={register}
                                errors={errors}
                                watchedFields={watchedFields}
                                isRequired={field?.isRequired}
                                placeholder={field.placeholder}
                                type={field.type}
                                size={'sm'}
                                smallErrorTextInside
                              />
                            )}

                            {field.variant === 'datePicker' && (
                              <DatePickerInput
                                control={control}
                                name={field.name as Path<CreateCompanyFormData>}
                                register={register}
                                errors={errors}
                                watchedFields={watchedFields}
                                isRequired={field?.isRequired}
                                placeholder={field.placeholder}
                                type={field.type}
                                size={'sm'}
                                smallErrorTextInside
                              />
                            )}
                            {/* {isError && (
                            <Text color="red.600">{errorMessage}</Text>
                          )} */}
                          </FormControl>
                        </Box>
                      </Flex>
                    )
                  })}
                </Grid>
              </Collapse>
            </Box>
            <Box borderTop={'1px solid #ededed'} mt="10px" />
          </React.Fragment>
        )
      })}
      <ActionBlock />
    </form>
  )
}
