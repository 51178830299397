import { AxiosError, AxiosResponse } from 'axios'
import { UseMutateFunction, useMutation, useQueryClient } from 'react-query'
import { queryKeys } from 'shared/constants'

import { RegisterNewUserRequest, RegistrationResponse } from 'shared/models'
import { UserService } from 'shared/services'
import { toasts } from 'shared/utils'

interface RegisterNewUserWithActions extends RegisterNewUserRequest {
  successAction: () => void
  errorAction: () => void
}

export function registerAddUser(): {
  mutate: UseMutateFunction<
    AxiosResponse<RegistrationResponse>,
    AxiosError,
    RegisterNewUserWithActions,
    unknown
  >
  isLoading: boolean
} {
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation<
    AxiosResponse<RegistrationResponse>,
    AxiosError,
    RegisterNewUserWithActions
  >(
    ({ token, password }: RegisterNewUserWithActions) =>
      UserService.fetchRegistration({
        token,
        password,
      }),
    {
      onSuccess: (
        data: AxiosResponse<RegistrationResponse>,
        variables: RegisterNewUserWithActions,
      ) => {
        variables.successAction()
        toasts.success({
          title: 'Пароль успешно установлен!',
          description: 'Вы переадресованы на форму с вводом логина и пароля',
        })
        queryClient.setQueryData([queryKeys.user.registration], data)
      },
      onError: (error: AxiosError, variables: RegisterNewUserWithActions) => {
        variables.errorAction()
        toasts.error({
          title: 'Произошла ошибка',
          description: `${error.message} Пароль не был установлен`,
        })
      },
    },
  )

  return { mutate, isLoading }
}
