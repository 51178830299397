import { IconButton } from '@chakra-ui/react'

//  misc
import { HiMiniXMark, HiOutlinePencilSquare } from 'react-icons/hi2'

interface EditButtonProps {
  isEdit: boolean
  handleEditClick: () => void
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
}

export const EditButton: React.FC<EditButtonProps> = (props) => {
  const { isEdit, handleEditClick, size = 'sm' } = props

  return (
    <IconButton
      size={size}
      aria-label="Edit Activity"
      icon={isEdit ? <HiMiniXMark /> : <HiOutlinePencilSquare />}
      onClick={handleEditClick}
      fontSize="22px"
      {...(isEdit ? { colorScheme: 'red' } : {})}
    />
  )
}
