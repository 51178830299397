import React, { useState } from 'react'
import { useAsyncDebounce } from 'react-table'
import { Box, Flex, Input } from '@chakra-ui/react'

import { HiXMark } from 'react-icons/hi2'

interface GlobalFilterProps {
  filter: string
  setFilter: (value: string | undefined) => void
}

export const GlobalFilter: React.FC<GlobalFilterProps> = ({
  filter,
  setFilter,
}) => {
  const [value, setValue] = useState<string>(filter)

  const onChange = useAsyncDebounce((val: string): void => {
    setFilter(val || undefined)
  }, 500)

  const options = [
    { value: 'option1', label: 'Option 1' },
    { value: 'option2', label: 'Option 2' },
  ]

  return (
    <>
      <Flex alignItems={'center'}>
        <Input
          placeholder="Поиск"
          size="xs"
          ml="10px"
          value={value || ''}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setValue(e.target.value)
            onChange(e.target.value)
          }}
        />

        {value && (
          <Box
            fontSize={'20px'}
            ml="5px"
            color={'#c2c2c2'}
            cursor={'pointer'}
            _hover={{ cursor: 'pointer', color: '#3435bc' }}
            onClick={() => {
              setValue('')
              onChange('')
            }}
          >
            <HiXMark />
          </Box>
        )}
      </Flex>
    </>
  )
}
