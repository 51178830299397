import { AxiosError } from 'axios'
import { UseMutateFunction, useMutation, useQueryClient } from 'react-query'

import { queryKeys } from 'shared/constants'
import { ExtendedAxiosError } from 'shared/models'
import { ReferenceBookService } from 'shared/services'
import { toasts } from 'shared/utils'

interface DeletePriceZoneMutation {
  price_zone_id: string
  zone_name: string
  successAction: () => void
}
export function useDeletePriceZone(): {
  mutate: UseMutateFunction<void, AxiosError, DeletePriceZoneMutation, unknown>
  isLoading: boolean
} {
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation<
    void,
    AxiosError,
    DeletePriceZoneMutation
  >(
    ({ price_zone_id }: DeletePriceZoneMutation) =>
      ReferenceBookService.deletePriceZone({ price_zone_id }).then(() => {}),
    {
      onSuccess: (_, variables: DeletePriceZoneMutation) => {
        if (variables.successAction) variables.successAction()

        toasts.success({
          title: 'Ценовая зона удалена',
          description: `Ценовая зона ${variables.zone_name}  успешно удалена`,
        })
        queryClient.invalidateQueries([
          queryKeys.referenceBooks.priceZones.allPriceZones,
        ])
      },
      onError: (error: ExtendedAxiosError) => {
        toasts.error({
          title: 'Произошла ошибка',
          description:
            error.response.data.detail || 'Ценовая зона не был удалена',
        })
      },
    },
  )

  return { mutate, isLoading }
}
