import { AxiosError, AxiosResponse } from 'axios'
import { UseMutateFunction, useMutation, useQueryClient } from 'react-query'
import { queryKeys } from 'shared/constants'

import { DeleteHolydayRequest, ExtendedAxiosError } from 'shared/models'
import { ReferenceBookService } from 'shared/services'
import { toasts } from 'shared/utils'

interface ClearHolydayWithSuccessAction {
  successAction: () => void
  year: string
}

export function useClearHolyday(): {
  mutate: UseMutateFunction<
    AxiosResponse<undefined>,
    AxiosError,
    ClearHolydayWithSuccessAction,
    unknown
  >
  isLoading: boolean
} {
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation(
    ({ year }: ClearHolydayWithSuccessAction) =>
      ReferenceBookService.clearHolyday({
        year,
      }),
    {
      onSuccess: (
        _: AxiosResponse<undefined>,
        variables: ClearHolydayWithSuccessAction,
      ) => {
        if (variables.successAction) {
          variables.successAction()
        }

        queryClient.invalidateQueries([queryKeys.holydays.allHolydays])
      },
      onError: (error: ExtendedAxiosError) =>
        toasts.error({
          title: 'Произошла ошибка',
          description: error.response.data.detail || 'Год не был очишен',
        }),
    },
  )
  return { mutate, isLoading }
}
