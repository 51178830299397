import { useCallback, useMemo } from 'react'
import { Collapse, Box, Flex, Text, Button, Checkbox } from '@chakra-ui/react'

import {
  FormInputBlock,
  FormInputControl,
  SelectInputForm,
  TextLabelTitle,
  TextTitle,
} from 'shared/ui'

import { useGetTypesContracts } from 'entities/counteragentsContracts'
import { AddCounteragentsRequest } from 'shared/models'

import {
  CreateTypeCounteragentFormData,
  useCreateTypeCounteragentForm,
} from './UseCreateTypeCounteragentForm'

import { useCreateTypeCounteragent } from '../models/services/createTypeCounteragentService'

//  misc
import { HiMiniPlusCircle, HiMiniXMark } from 'react-icons/hi2'

interface AddNewActivityAreaProps {
  isOpen: boolean
  onToggle: () => void
}

interface PayloadType {
  full_name: string
  short_name: string
  successAction: () => void
  related_type_contract?: AddCounteragentsRequest
}

export const CreateTypeCounteragent: React.FC<AddNewActivityAreaProps> = (
  props,
) => {
  const { isOpen, onToggle } = props

  const {
    mutate: createTypeCounteragent,
    isLoading: isCreateTypeCounteragentLoading,
  } = useCreateTypeCounteragent()

  const { typesContractsData } = useGetTypesContracts({
    enabled: true,
  })

  const {
    register,
    handleSubmit,
    errors,
    reset,
    isDirty,
    watchedFields,
    control,
    setValue,
    setError,
    watch,
  } = useCreateTypeCounteragentForm()

  const buyerValue = watch('related_type_contract.buyer')
  const supplierValue = watch('related_type_contract.supplier')

  const onSubmit = ({
    full_name,
    short_name,
    related_type_contract,
  }: CreateTypeCounteragentFormData) => {
    const payload: PayloadType = {
      full_name,
      short_name,
      successAction: () => {
        onToggle()
        reset()
      },
      ...(related_type_contract && {
        related_type_contract,
      }),
    }
    createTypeCounteragent(payload)
  }

  const handleResetForm = useCallback(() => {
    reset()
  }, [reset])

  const handleAddRelatedTypeContact = useCallback(() => {
    setValue('related_type_contract', {
      type_contract_id: '',
      supplier: false,
      buyer: false,
    })
  }, [setValue])

  const handleRemoveRelatedTypeContact = useCallback(() => {
    setValue('related_type_contract', undefined)
  }, [setValue])

  const commonInputProps = useMemo(
    () => ({
      control,
      register,
      errors,
      watchedFields,
      size: 'sm' as 'sm',
      smallErrorTextInside: true,
      isRequired: true,
    }),
    [register, errors, watchedFields, control],
  )

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Collapse in={isOpen} animateOpacity style={{ overflow: 'inherit' }}>
        <Flex borderBottom={'1px solid #dfe0eb'} flexDir={'column'}>
          <Box p={'10px'}>
            <TextTitle size={'small'}>Добавление вида контрагента</TextTitle>
          </Box>
          <Flex>
            <Box p="10px 15px 20px 15px" w={'500px'}>
              <FormInputBlock
                titleWidth={'200px'}
                allowEdit={true}
                edit
                title={'Сокращение:'}
              >
                <FormInputControl
                  name="short_name"
                  placeholder="Сокращение"
                  type="text"
                  {...commonInputProps}
                />
              </FormInputBlock>

              <Box m={'10px'} />

              <FormInputBlock
                titleWidth={'200px'}
                allowEdit={true}
                edit
                title={'Наименование:'}
              >
                <FormInputControl
                  name="full_name"
                  placeholder="Наименование"
                  type="text"
                  {...commonInputProps}
                />
              </FormInputBlock>
            </Box>
            <Flex flexDir={'column'} ml={'8px'} w={'500px'}>
              <Box mt={'10px'}>
                {!!watchedFields.related_type_contract ? (
                  <Button
                    position={'relative'}
                    size={'xs'}
                    display={'flex'}
                    alignItems={'center'}
                    onClick={handleRemoveRelatedTypeContact}
                  >
                    <Box fontSize={'18px'}>
                      <HiMiniXMark />
                    </Box>
                    <Text position={'relative'}>Отмена</Text>
                  </Button>
                ) : (
                  <Button
                    position={'relative'}
                    size={'xs'}
                    bg="blue.700"
                    color="white"
                    display={'flex'}
                    alignItems={'center'}
                    onClick={handleAddRelatedTypeContact}
                    _hover={{
                      bg: 'blue.600',
                    }}
                  >
                    <Box fontSize={'18px'} mr={'5px'}>
                      <HiMiniPlusCircle />
                    </Box>
                    <Text position={'relative'}>Вид договора</Text>
                  </Button>
                )}
              </Box>
              {!!watchedFields.related_type_contract && (
                <Box mt={'5px'}>
                  <TextLabelTitle mb={'8px'}>
                    Добавление договора
                  </TextLabelTitle>

                  <Box maxW={'800px'}>
                    <Box m={'8px 0'}>
                      {(errors?.related_type_contract?.supplier ||
                        errors?.related_type_contract?.buyer) && (
                        <Text color={'red'} fontSize={'12px'}>
                          Необходимо выбрать поставщика или покупателя
                        </Text>
                      )}
                    </Box>

                    <Box mb={'8px'}>
                      <FormInputBlock
                        titleWidth={'90PX'}
                        allowEdit={true}
                        title={'Вид договора'}
                        edit={true}
                        value={''}
                      >
                        <SelectInputForm
                          placeholder="Выберите вид договора"
                          data={typesContractsData || []}
                          isCreatable={false}
                          isClearable={true}
                          getOptionValue={(option) =>
                            option ? option.value : ''
                          }
                          name="related_type_contract.type_contract_id"
                          {...commonInputProps}
                        />
                      </FormInputBlock>
                    </Box>

                    <Flex mb={'10px'}>
                      <Box mr={'50px'}>
                        <FormInputBlock
                          titleWidth={'90px'}
                          allowEdit={true}
                          title={'Поставщик:'}
                          edit={true}
                          value={''}
                        >
                          <Checkbox
                            size={'lg'}
                            isChecked={supplierValue}
                            onChange={() =>
                              setValue(
                                'related_type_contract.supplier',
                                !supplierValue,
                              )
                            }
                            {...register('related_type_contract.supplier')}
                          />
                        </FormInputBlock>
                      </Box>
                      <Box>
                        <FormInputBlock
                          titleWidth={'90px'}
                          allowEdit={true}
                          title={'Покупатель:'}
                          edit={true}
                          value={''}
                        >
                          <Checkbox
                            size={'lg'}
                            isChecked={buyerValue}
                            onChange={() =>
                              setValue(
                                'related_type_contract.buyer',
                                !buyerValue,
                              )
                            }
                            {...register('related_type_contract.buyer')}
                          />
                        </FormInputBlock>
                      </Box>
                    </Flex>
                  </Box>
                </Box>
              )}
            </Flex>
          </Flex>

          {isDirty && (
            <Box p="0 0 25px 15px">
              <Flex justifyContent={'flex-start'}>
                <Button mr="5px" onClick={handleResetForm} size={'xs'}>
                  <Flex alignItems={'center'}>Очистить</Flex>
                </Button>
                <Button
                  mr="5px"
                  size={'xs'}
                  colorScheme="blue"
                  type="submit"
                  isDisabled={isCreateTypeCounteragentLoading}
                  isLoading={isCreateTypeCounteragentLoading}
                >
                  Сохранить
                </Button>
              </Flex>
            </Box>
          )}
        </Flex>
      </Collapse>
    </form>
  )
}
