import React from 'react'
import { Button, Text } from '@chakra-ui/react'

// misc
import { HiArrowsUpDown, HiBarsArrowDown, HiBarsArrowUp } from 'react-icons/hi2'

export enum SortStage {
  none,
  asc,
  desc,
}

interface SortingElementsProps {
  setSortOrder: React.Dispatch<React.SetStateAction<SortStage>>
  sortOrder: SortStage
  title?: string
}

export const SortingElements: React.FC<SortingElementsProps> = ({
  setSortOrder,
  sortOrder,
  title = '',
}) => {
  let sortIcon

  const style = {
    fontSize: '14px',
  }

  switch (sortOrder) {
    case SortStage.asc:
      sortIcon = <HiBarsArrowDown {...style} />
      break
    case SortStage.desc:
      sortIcon = <HiBarsArrowUp {...style} />
      break
    default:
      sortIcon = <HiArrowsUpDown {...style} />
  }

  const toggleSortOrder = () => {
    setSortOrder((currentSortOrder) => {
      switch (currentSortOrder) {
        case SortStage.none:
          return SortStage.asc
        case SortStage.asc:
          return SortStage.desc
        default:
          return SortStage.none
      }
    })
  }

  return (
    <Button onClick={toggleSortOrder} size={'xs'} h={'20px'}>
      {sortIcon}{' '}
      {title && (
        <Text ml={'3px'} fontSize={'10px'} color={'#707070'}>
          {title}
        </Text>
      )}
    </Button>
  )
}
