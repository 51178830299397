import { UseFormRegister, Path, FieldErrors } from 'react-hook-form'
import { Flex, Text, Input as ChakraInput, Box } from '@chakra-ui/react'

import { Tooltip } from 'shared/ui'
import { IoIosWarning } from 'react-icons/io'

// misc

import { AiOutlineCheck } from 'react-icons/ai'
import warningLogo from 'assets/svg/warning_logo.svg'
import { forwardRef, useEffect } from 'react'

interface InputProps<T> {
  name: Path<T>
  register: UseFormRegister<T>
  errors: FieldErrors<T>
  watchedFields: T
  placeholder?: string
  type?: string
  isRequired: boolean
  smallErrorTextInside?: boolean
  isReadOnly?: boolean
  size?: 'sm' | 'md' | 'xs'
}

const getNestedValue = (object, path) =>
  path.split('.').reduce((o, p) => o?.[p], object)

export const FormInputControl = forwardRef<HTMLInputElement, InputProps<any>>(
  (
    {
      name,
      register,
      errors,
      watchedFields,
      placeholder = '',
      type = 'text',
      isRequired = false,
      smallErrorTextInside = false,
      isReadOnly,
      size = 'md',
      ...rest
    },
    ref,
  ) => {
    const fieldValue = getNestedValue(watchedFields, name)
    const fieldError = getNestedValue(errors, name)
    const isTouched = fieldValue !== undefined && fieldValue !== ''
    const isValid = isTouched && !fieldError

    const { ref: formRef, onChange, onBlur } = register(name)

    const combinedRef = (instance: HTMLInputElement) => {
      if (formRef) formRef(instance)
      if (typeof ref === 'function') ref(instance)
      else if (ref) ref.current = instance
    }

    const getStyles = {
      md: {
        fontSize: '22px',
        errorFontSize: '12px',
        successIconTop: '3px',
        successBoxShadow: '0 0 0 1px #04bcb0b6',
        errorBoxShadow: '0 0 0 1px #04bcb0b6',
        errorIconTop: '9px',
        errorRight: '10px',
      },
      sm: {
        fontSize: '20px',
        errorFontSize: '10px',
        successIconTop: '10px',
        successBoxShadow: '0 0 0 1px #04bcb0b6',
        errorBoxShadow: '0 0 0 1px #04bcb0b6',
        errorIconTop: '6px',
        errorRight: '10px',
      },
      xs: {
        fontSize: '18px',
        errorFontSize: '10px',
        successIconTop: '5px',
        successBoxShadow: 'none',
        errorBoxShadow: 'none',
        errorIconTop: '3px',
        errorRight: '7px',
      },
    }

    return (
      <Flex flexDirection={'column'} position="relative" w={'100%'}>
        <ChakraInput
          ref={combinedRef}
          size={size}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={(e) => {
            onChange(e)
          }}
          {...(register && name ? register(name) : {})}
          isReadOnly={isReadOnly}
          isInvalid={!!fieldError}
          color="black"
          type={type}
          placeholder={placeholder}
          mr="5px"
          w="100%"
          borderColor={isValid ? '#16b164' : '#e2e8f0'}
          boxShadow={isValid ? getStyles[size].successBoxShadow : 'none'}
          textOverflow={'ellipsis'}
          pr={'20px'}
          // boxShadow={
          //   fieldError && smallErrorTextInside
          //     ? 'none!important'
          //     : isValid
          //     ? getStyles[size].successBoxShadow
          //     : 'none'
          // }
          borderRadius={'6px'}
          {...rest}
        />
        {isRequired && !isTouched && (
          <Box
            position="absolute"
            // right="10px"
            right={getStyles[size].errorRight}
            // top={size === 'sm' ? '6px' : '9px'}
            top={getStyles[size].errorIconTop}
            color="gray.400"
            fontSize={getStyles[size].fontSize}
          >
            <Tooltip
              id={`${name}-${placeholder}-warning`}
              content="Данное поле является обязательным для заполнения"
              place="top"
            >
              <Box
                w={size === 'sm' ? '14px' : '18px'}
                color={fieldError ? 'red.500' : '#e5e6e5'}
              >
                {/* <img src={warningLogo} alt="Warning" /> */}
                <IoIosWarning />
              </Box>
            </Tooltip>
          </Box>
        )}
        {fieldError && smallErrorTextInside && (
          <Flex
            alignItems="center"
            color="red.600"
            position={'absolute'}
            top={'-6px'}
            left={'5px'}
            background={'#fff'}
            borderRadius={'20px'}
            paddingRight={'5px'}
            zIndex={'9'}
          >
            <Text
              fontSize={getStyles[size].errorFontSize}
              fontWeight={'700'}
              ml="5px"
              lineHeight={'11px'}
              whiteSpace={'nowrap'}
            >
              {fieldError.message}
            </Text>
          </Flex>
        )}
        {isValid && isRequired && (
          <Flex
            alignItems="center"
            color="green.600"
            position={'absolute'}
            right={'6px'}
            top={getStyles[size].successIconTop}
          >
            <Tooltip
              id={`${name}-${placeholder}-success`}
              content="Данное поле успешно прошло валидацию"
              place="top"
            >
              <AiOutlineCheck />
            </Tooltip>
          </Flex>
        )}
      </Flex>
    )
  },
)
