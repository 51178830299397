import React, { useCallback, useEffect, useMemo } from 'react'
import { Box, Checkbox, Flex } from '@chakra-ui/react'

import { useGetTypesContracts } from 'entities/counteragentsContracts'

import {
  BaseModal,
  FormInputBlock,
  SelectInputForm,
  SubmitCancelButtons,
} from 'shared/ui'
import { TypesCounteragentDetailResponse } from 'shared/models'

import {
  useEditRelatedContractForm,
  EditRelatedContractFormData,
} from './EditRelatedContractForm'
import { useUpdateRelatedContract } from '../models/services/useUpdateRelatedContractService'

interface IDeleteUser {
  onClose: () => void
  isOpen: boolean
  actionData: TypesCounteragentDetailResponse
}

export const EditRelatedContractModal: React.FC<IDeleteUser> = (props) => {
  const { onClose, isOpen, actionData } = props

  const {
    register,
    handleSubmit,
    errors,
    reset,
    control,
    isDirty,
    watchedFields,
    setValue,
    watch,
  } = useEditRelatedContractForm()

  const {
    mutate: updateRelatedContract,
    isLoading: isUpdatingRelatedContract,
  } = useUpdateRelatedContract()

  const { typesContractsData } = useGetTypesContracts({
    enabled: true,
  })

  const buyerValue = watch('buyer')
  const supplierValue = watch('supplier')

  useEffect(() => {
    if (actionData) {
      reset({
        buyer: actionData?.buyer,
        supplier: actionData?.supplier,
        type_contract_id: actionData?.type_contract_id,
      })
    }
  }, [reset, actionData])

  const onSubmit = ({
    buyer,
    supplier,
    type_contract_id,
  }: EditRelatedContractFormData) => {
    updateRelatedContract({
      successAction: () => onClose(),
      type_counteragent_id: actionData?.type_counteragent_id,
      related_tc_id: actionData?.related_tc_id,
      type_contract_id,
      supplier,
      buyer,
    })
  }

  const handleCloseModal = () => onClose()

  const commonInputProps = useMemo(
    () => ({
      control,
      register,
      errors,
      watchedFields,
      size: 'sm' as 'sm',
      smallErrorTextInside: true,
      isRequired: true,
    }),
    [register, errors, watchedFields, control],
  )

  const handleResetForm = useCallback(() => {
    reset()
  }, [reset])

  return (
    <BaseModal
      header="Редактирование связанного контрагента"
      id="EditRelatedContractModal"
      isOpen={isOpen}
      onClose={handleCloseModal}
      isCentered
    >
      <Box mb={'15px'}>
        {(errors.supplier || errors.buyer) && (
          <p style={{ color: 'red' }}>
            Необходимо выбрать поставщика или покупателя
          </p>
        )}
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box mb={'15px'}>
          <FormInputBlock
            titleWidth={'90PX'}
            allowEdit={true}
            title={'Вид договора'}
            edit={true}
            value={''}
          >
            <SelectInputForm
              placeholder="Выберите вид договора"
              data={typesContractsData || []}
              isCreatable={false}
              isClearable={true}
              getOptionValue={(option) => (option ? option.value : '')}
              name="type_contract_id"
              {...commonInputProps}
            />
          </FormInputBlock>
        </Box>

        <Flex>
          <Box mr={'50px'}>
            <FormInputBlock
              titleWidth={'90px'}
              allowEdit={true}
              title={'Поставщик:'}
              edit={true}
              value={''}
            >
              <Checkbox
                size={'lg'}
                isChecked={supplierValue}
                onChange={() => setValue('supplier', !supplierValue)}
                {...register('supplier')}
              />
            </FormInputBlock>
          </Box>
          <Box>
            <FormInputBlock
              titleWidth={'90px'}
              allowEdit={true}
              title={'Покупатель:'}
              edit={true}
              value={''}
            >
              <Checkbox
                size={'lg'}
                isChecked={buyerValue}
                onChange={() => setValue('buyer', !buyerValue)}
                {...register('buyer')}
              />
            </FormInputBlock>
          </Box>
        </Flex>

        <Flex justifyContent={'flex-start'} mt="25px">
          {isDirty && (
            <Box>
              <SubmitCancelButtons
                isDirty={isDirty}
                isUpdating={isUpdatingRelatedContract}
                handleCancel={handleResetForm}
              />
            </Box>
          )}
        </Flex>
      </form>
    </BaseModal>
  )
}
