import { AxiosError, AxiosPromise } from 'axios'
import { UseMutateFunction, useMutation, useQueryClient } from 'react-query'
import { queryKeys } from 'shared/constants'

import { UserService } from 'shared/services'
import { toasts } from 'shared/utils'

interface DeleteUserWithSuccessAction {
  userId: string
  userName: string
  successAction: () => void
}
export function useDeleteUser(): {
  mutate: UseMutateFunction<
    void,
    AxiosError,
    DeleteUserWithSuccessAction,
    unknown
  >
  isLoading: boolean
} {
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation<
    void,
    AxiosError,
    DeleteUserWithSuccessAction
  >(
    ({ userId }: DeleteUserWithSuccessAction) =>
      UserService.deleteUser(userId).then(() => {}),
    {
      onSuccess: (_, variables: DeleteUserWithSuccessAction) => {
        if (variables.successAction) variables.successAction()

        toasts.success({
          title: 'Пользователь удален',
          description: `Пользователь ${variables.userName} с ID ${variables.userId} успешно удален`,
        })
        queryClient.invalidateQueries([queryKeys.users.allUsers])
        queryClient.invalidateQueries([
          queryKeys.dictionaries.employeesPositions,
        ])
      },
      onError: (error: AxiosError) => {
        toasts.error({
          title: 'Произошла ошибка',
          description: error.message || 'Пользователь не был удален',
        })
      },
    },
  )

  return { mutate, isLoading }
}
