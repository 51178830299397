import { BaseSettingLayout, SettingSidebar } from 'shared/ui'

interface SettingLayoutPropsType {
  type: string
}

export const SettingLayout: React.FC<SettingLayoutPropsType> = (props) => {
  const { type } = props

  return (
    <BaseSettingLayout
      headerSlot={<p></p>}
      sidebarSlot={<SettingSidebar type={type} />}
    />

  )
}
