import { AxiosError } from 'axios'
import { UseMutateFunction, useMutation, useQueryClient } from 'react-query'

import { queryKeys } from 'shared/constants'
import { ExtendedAxiosError } from 'shared/models'
import { CounteragentsContractsService } from 'shared/services'
import { toasts } from 'shared/utils'

interface DeleteTypeCounteragents {
  type_counteragent_id: string
  successAction: () => void
}
export function useDeleteTypeCounteragents(): {
  mutate: UseMutateFunction<void, AxiosError, DeleteTypeCounteragents, unknown>
  isLoading: boolean
} {
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation<
    void,
    AxiosError,
    DeleteTypeCounteragents
  >(
    ({ type_counteragent_id }: DeleteTypeCounteragents) =>
      CounteragentsContractsService.deleteTypesCounteragent({
        type_counteragent_id,
      }).then(() => {}),
    {
      onSuccess: (_, variables: DeleteTypeCounteragents) => {
        if (variables.successAction) variables.successAction()

        toasts.success({
          title: 'Успех',
          description: `Вид контрагента успешно удален`,
        })

        queryClient.invalidateQueries([
          queryKeys.counteragentsContracts.typeCounteragents
            .allTypesCounteragents,
        ])
      },
      onError: (error: ExtendedAxiosError) => {
        toasts.error({
          title: 'Произошла ошибка',
          description:
            error.response.data.detail || 'Вид контрагента не был удален',
        })
      },
    },
  )

  return { mutate, isLoading }
}
