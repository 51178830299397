import React from 'react'
import { Box, BoxProps } from '@chakra-ui/react'
import { IconType } from 'react-icons'

interface ButtonIconProps extends Omit<BoxProps, 'onClick'> {
  icon: IconType
  bg?: string
  color?: string
  hoverBg?: string
  hoverColor?: string
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}

export const ButtonIcon: React.FC<ButtonIconProps> = ({
  icon: IconComponent,
  bg = '#edf2f7',
  color = 'teal.600',
  hoverBg = '#dfe5eb',
  hoverColor = '#2d2d2d',
  onClick,
  ...rest
}) => {
  return (
    <Box
      as="button"
      bg={bg}
      color={color}
      py={1.5}
      px={2}
      ml={2}
      rounded="md"
      fontWeight="semibold"
      _hover={{ bg: hoverBg, color: hoverColor }}
      transition="background-color 200ms linear"
      transitionProperty="background-color"
      transitionDuration="normal"
      onClick={onClick}
      {...rest}
    >
      <IconComponent style={{ width: '25px', height: '25px' }} />
    </Box>
  )
}
