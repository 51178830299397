import { ReactNode } from 'react'
import { Box } from '@chakra-ui/react'

import { ROLES, roleColorScheme } from 'shared/constants'

type RoleValues = (typeof ROLES)[keyof typeof ROLES] | string

interface RoleTagProps {
  children: ReactNode
  role: RoleValues
  size?: 'small' | 'medium' | 'large'
  padding?: string
}

export const RoleTag: React.FC<RoleTagProps> = (props) => {
  const { children, role, size = 'medium', padding } = props

  const styles = {
    small: {
      borderRadius: '4px',
      fontSize: '12px',
      padding: '3px 5px',
    },
    medium: {
      borderRadius: '4px',
      fontSize: '16px',
      padding: '3px 5px',
    },
    large: {},
  }

  const restStyles = {
    padding,
  }

  return (
    <Box
      background={roleColorScheme[role]}
      color={'white'}
      {...styles[size]}
      // {...restStyles}
      height={'fit-content'}
    >
      {children}
    </Box>
  )
}
