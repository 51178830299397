import { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'

import { UserInfoResponse } from 'shared/models'
import { LOCAL_STORAGE_ENTITY, queryKeys } from 'shared/constants'
import { UserService } from 'shared/services'
import { localStorageHandler, toasts } from 'shared/utils'

interface UseGetUserInfoResult {
  data: AxiosResponse<UserInfoResponse> | undefined
  userInfo: UserInfoResponse | null
  isError: boolean
  isFetching: boolean
  isLoading: boolean
  currentUserRole: string
  currentUserId: string
}

interface UseGetUserInfo {
  enabled: boolean
  successAction?: () => void
  errorAction?: () => void
}

export function useGetUserInfo({
  enabled,
  successAction = () => {},
  errorAction = () => {},
}: UseGetUserInfo): UseGetUserInfoResult {
  const { data, isError, isFetching, isLoading } = useQuery<
    AxiosResponse<UserInfoResponse>
  >({
    queryKey: [queryKeys.user.info],
    queryFn: () => UserService.getUserInfo(),
    enabled,
    retry: false,
    onSuccess: (responseData) => {
      if (responseData.data.role_name)
        localStorageHandler.saveToLocalStorage<string>(
          LOCAL_STORAGE_ENTITY.USER_ROLE,
          responseData?.data?.role_name,
        )

      successAction()
    },
    onError: () => {
      toasts.error({
        title: 'Данные пользователя не загружены',
        description: 'Работа в системе прервана',
      })
      errorAction()
    },
  })

  const userInfo = data ? data.data : null

  const currentUserRole = data ? data?.data?.role_name : null

  const currentUserId = data ? data?.data?.user_id : null

  return {
    data,
    userInfo,
    isError,
    isFetching,
    currentUserRole,
    currentUserId,
    isLoading,
  }
}
