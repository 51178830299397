import { Box, Button, Flex, useDisclosure, Text } from '@chakra-ui/react'

import { BaseModal } from 'shared/ui'
import { useDeleteAdditionalRequisite } from '../models/services/deleteAdditionalRequisiteService'

// misc
import warningLogo from 'assets/svg/warning_logo.svg'
import { HiOutlineArchiveBoxXMark } from 'react-icons/hi2'

interface DeleteAdditionalRequisiteProps {
  add_requisite_id: string
  additional_requisite: string
}

export const DeleteAdditionalRequisite: React.FC<
  DeleteAdditionalRequisiteProps
> = (props) => {
  const { add_requisite_id, additional_requisite } = props

  const { isOpen, onOpen, onClose } = useDisclosure()

  const { mutate: deleteAdditionalRequisite, isLoading } =
    useDeleteAdditionalRequisite()

  const handleCloseModal = () => onClose()

  const handleDeleteAdditionalRequisite = () => {
    deleteAdditionalRequisite({
      additional_requisite: additional_requisite,
      add_requisite_id: add_requisite_id,
      successAction: () => onClose(),
    })
  }

  return (
    <div>
      <Button
        isDisabled={isLoading}
        isLoading={isLoading}
        colorScheme={'red'}
        size={'xs'}
        color="white"
        display={'flex'}
        alignItems={'center'}
        onClick={() => onOpen()}
      >
        Удалить
        <Box fontSize={'18px'} ml={'7px'}>
          <HiOutlineArchiveBoxXMark />
        </Box>
      </Button>
      <BaseModal
        header="Удаление дополнительного реквизита"
        id="delete user"
        isOpen={isOpen}
        onClose={handleCloseModal}
      >
        <Box>
          <Flex justifyContent={'center'}>
            <Box w={'50px'} mb={'15px'}>
              <img src={warningLogo} alt="Warning" />
            </Box>
          </Flex>
          <Text
            fontWeight={'800'}
            mb={'5px'}
            color={'red.500'}
            textAlign={'center'}
          >
            Отмеченный дополнительный реквизит будет удален
          </Text>
          <Text fontWeight={'600'} mb={'5px'} textAlign={'center'}>
            Вы действительно хотите удалить следующий дополнительный реквизит{' '}
            <b>{additional_requisite}</b>?
          </Text>
        </Box>
        <Flex justifyContent={'flex-end'} mt="35px">
          <Button fontWeight={'500'} mr="15px" onClick={onClose}>
            Отмена
          </Button>
          <Button
            fontWeight={'500'}
            onClick={handleDeleteAdditionalRequisite}
            colorScheme={'red'}
            isDisabled={isLoading}
            isLoading={isLoading}
          >
            Удалить
          </Button>
        </Flex>
      </BaseModal>
    </div>
  )
}
