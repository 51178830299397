import { Collapse, Box, Flex, Text, Button } from '@chakra-ui/react'

import {
  AddNewPriceZoneFormData,
  AddEnergySystemForm,
} from './UseAddNewPriceZoneForm'
import { useAddNewPriceZone } from '../models/services/addPriceZone'

//  misc
import { FormInputControl, TextTitle } from 'shared/ui'

interface AddNewPriceZoneProps {
  isOpen: boolean
  onToggle: () => void
}

export const AddNewPriceZone: React.FC<AddNewPriceZoneProps> = (props) => {
  const { isOpen, onToggle } = props

  const { mutate: addNewPriceZone, isLoading: isAddNewPriceZoneLoading } =
    useAddNewPriceZone()

  const { register, handleSubmit, errors, reset, isDirty, watchedFields } =
    AddEnergySystemForm()

  const onSubmit = ({ zone_name }: AddNewPriceZoneFormData) => {
    addNewPriceZone({
      zone_name,
      successAction: () => {
        onToggle()
        reset()
      },
    })
  }

  const handleResetForm = () => reset()

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Collapse in={isOpen} animateOpacity>
        <Box borderBottom={'1px solid #dfe0eb'}>
          <Box p={'10px'}>
            <TextTitle size={'small'}>Добавление новой ценовой зоны</TextTitle>
          </Box>
          <Flex p="10px 15px 20px 15px">
            <Flex mr={'5px'} w={'350px'} alignItems={'center'}>
              <Text
                mr={'5px'}
                color={'#718096'}
                fontSize={'12px'}
                fontWeight={'500'}
              >
                Имя:
              </Text>
              <FormInputControl
                smallErrorTextInside
                size="xs"
                name="zone_name"
                register={register}
                errors={errors}
                watchedFields={watchedFields}
                isRequired
                placeholder="Имя зоны"
                type="text"
              />
            </Flex>
          </Flex>
          {isDirty && (
            <Box p="0 0 25px 15px">
              <Flex justifyContent={'flex-start'}>
                <Button mr="5px" onClick={handleResetForm} size={'xs'}>
                  <Flex alignItems={'center'}>Очистить</Flex>
                </Button>
                <Button
                  mr="5px"
                  size={'xs'}
                  colorScheme="blue"
                  type="submit"
                  isDisabled={isAddNewPriceZoneLoading}
                  isLoading={isAddNewPriceZoneLoading}
                >
                  Сохранить
                </Button>
              </Flex>
            </Box>
          )}
        </Box>
      </Collapse>
    </form>
  )
}
