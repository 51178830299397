import { useState } from 'react'
import {
  parseISO,
  startOfMonth,
  endOfMonth,
  isBefore,
  isAfter,
  isEqual,
  addMonths,
  subMonths,
  subDays,
  addDays,
  format,
} from 'date-fns'

import './TestPage.scss'
import { DatePicker, Tooltip } from 'shared/ui'
import { Access } from 'app/providers'
import { ROLES } from 'shared/constants'
import { Box, Flex } from '@chakra-ui/react'

type Period = {
  date_from: string
  date_to: string
  id?: string
}

function checkPeriodsOverlap(
  existingPeriods: Period[],
  newPeriod: Period,
): boolean {
  let absorbedPeriods: string[] = []
  let absorbedIds: string[] = []

  for (const period of existingPeriods) {
    const existingFrom = new Date(period.date_from)
    const existingTo = new Date(period.date_to)
    const newFrom = new Date(newPeriod.date_from)
    const newTo = new Date(newPeriod.date_to)

    // Проверяем, поглощает ли новый период текущий старый период полностью
    if (newFrom <= existingFrom && newTo >= existingTo) {
      absorbedPeriods.push(`с ${period.date_from} по ${period.date_to}`)
      if (period.id) absorbedIds.push(period.id)
    }
  }

  if (absorbedPeriods.length > 0) {
    console.log(
      `Новый период с ${newPeriod.date_from} по ${
        newPeriod.date_to
      } полностью поглощает периоды: ${absorbedPeriods.join(', ')}`,
    )
    console.log(`ID поглощенных периодов: ${absorbedIds.join(', ')}`)
    return false
  } else return true
}

// Пример использования функции
const periods: Period[] = [
  {
    date_from: '2024-01-01',
    date_to: '2024-03-01',
    id: 'groupDelivery_9',
  },
  {
    date_from: '2024-04-01',
    date_to: '2024-06-01',
    id: 'groupDelivery_11',
  },
  {
    date_from: '2024-07-01',
    date_to: '2024-12-01',
    id: 'groupDelivery_10',
  },
]

const newPeriod: Period = {
  date_from: '2010-03-01',
  date_to: '2034-08-01',
}

const result = checkPeriodsOverlap(periods, newPeriod)
console.log(`The result of the period overlap check is: ${result}`)

export const Posts = () => {
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h5>Posts</h5>
        <div></div>
      </div>
      <table>
        <thead>
          <tr>
            <th>Title</th>
            <th>Published</th>
            <th>#</th>
          </tr>
        </thead>
        <tbody></tbody>
      </table>
    </>
  )
}

export const Post = ({ post }) => {
  return (
    <tr>
      <td>{post.title}</td>
      <td>{post.published ? '✅' : '❌'}</td>
      <td>
        <div style={{ display: 'flex' }}>
          <Access roles={[ROLES.SUPERADMIN, ROLES.ADMIN]}>
            <button className="small-button">Edit</button>
            <button className="secondary small-button">Delete</button>
          </Access>
        </div>
      </td>
    </tr>
  )
}

export const TestPage = () => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false)

  const [startDate, setStartDate] = useState(new Date())

  return (
    <div>
      'TestPage'
      <Box>
        <Flex
          w={'800px'}
          justifyContent={'space-between'}
          border={'1px solid red'}
        >
          <DatePicker
            disabledKeyboardNavigation
            // showMonthYearPicker
            theme={'dates'}
            selected={startDate}
            onChange={(date) => {
              setStartDate(date)
            }}
            // isClearable={true}
            // showMonthYearPicker
            format="dd.MM.yyyy"
            // showTimeSelect
            // startDate={startDate}
          />
        </Flex>
      </Box>
      <Box>
        <Flex
          w={'800px'}
          justifyContent={'space-between'}
          border={'1px solid red'}
        >
          <DatePicker
            disabledKeyboardNavigation
            // showMonthYearPicker
            theme={'dates'}
            selected={startDate}
            onChange={(date) => {
              setStartDate(date)
            }}
            // isClearable={true}
            // showMonthYearPicker
            format="dd.MM.yyyy"
            // showTimeSelect
            // startDate={startDate}
          />
        </Flex>
      </Box>
      <Posts />
      <div style={{ marginLeft: '300px', marginTop: '300px' }}>
        <div>
          <h1>Пример использования CustomTooltip</h1>
          <Tooltip
            id="unique-tooltip-1"
            content="Это подсказка для текстового элемента"
            place="top-start"
          >
            <p>Наведите на меня</p>
          </Tooltip>

          <Tooltip
            data-tooltip-offset={50}
            id="unique-tooltip-2"
            content={<strong>Это подсказка для кнопки</strong>} // JSX как контент
            place="left"
          >
            <button>Наведите на кнопку</button>
          </Tooltip>
        </div>
      </div>
      <h1>This is the testing page </h1>
    </div>
  )
}
