import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

export interface SetNewPasswordFormData {
  password?: string
  password_confirmation?: string
}

const SCHEMA_VALIDATION = yup.object().shape({
  password: yup
    .string()
    .required('Пароль обязателен')
    .min(6, 'Пароль должен содержать не менее 6 символов'),
  password_confirmation: yup
    .string()
    .required('Подтверждение пароля обязательно')
    .oneOf([yup.ref('password'), null], 'Пароли должны совпадать'),
})

export const useSetNewPasswordForm = () => {
  const {
    setValue,
    register,
    handleSubmit,
    formState,
    resetField,
    reset,
    control,
    watch,
  } = useForm<SetNewPasswordFormData>({
    mode: 'all',
    resolver: yupResolver(SCHEMA_VALIDATION),
  })

  const watchedFields = watch()

  return {
    reset,
    register,
    handleSubmit,
    errors: formState.errors,
    isDirty: formState.isDirty,
    isValid: formState.isValid,
    isSubmitting: formState.isSubmitting,
    touchedFields: formState.touchedFields,
    control,
    resetField,
    setValue,
    watchedFields,
  }
}
