import React, { useCallback } from 'react'

import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  useColorModeValue,
  Text,
  IconButton,
  Heading,
} from '@chakra-ui/react'

import { AuthStage } from 'widgets/authentication'
import { CompanyLogo } from 'shared/ui'

import { useResetForm } from './UseResetForm'
import { useResetPassword } from '../../models/services/resetService'

// assets
import { ArrowBackIcon } from '@chakra-ui/icons'

interface ResetFormPropsType {
  setAuthStage: React.Dispatch<React.SetStateAction<AuthStage>>
}

export const ResetForm: React.FC<ResetFormPropsType> = ({ setAuthStage }) => {
  const { register, handleSubmit, errors } = useResetForm()

  const { mutate: resetPassword, isLoading } = useResetPassword()

  const onSubmit = (data: { email: string }) => {
    resetPassword({
      email: data.email,
      successAction: () => setAuthStage(AuthStage.login),
      errorAction: () => {},
    })
  }

  const handleBack = useCallback(() => {
    setAuthStage(AuthStage.login)
  }, [AuthStage])

  return (
    <Flex
      minH="100vh"
      align="center"
      justify="center"
      bg={useColorModeValue('gray.50', 'gray.800')}
      data-testid="reset-form"
    >
      <Stack spacing={8} mx="auto" py={12} px={6} alignItems={'center'}>
        <CompanyLogo />
        <Box
          rounded="lg"
          bg={useColorModeValue('white', 'gray.700')}
          boxShadow="lg"
          p={8}
          width={'450px'}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={4}>
              <Heading
                color="#3435bc"
                fontSize={{ base: '2xl' }}
                fontWeight={'medium'}
                mb={5}
                textAlign={'center'}
              >
                Восстановление пароля
              </Heading>
              <Flex alignItems={'center'}>
                <IconButton
                  isRound={true}
                  w={'fit-content'}
                  variant="solid"
                  colorScheme="teal"
                  aria-label="Done"
                  fontSize="20px"
                  icon={<ArrowBackIcon />}
                  onClick={handleBack}
                />

                <Text fontSize="md" color={'blackAlpha.700'} ml={'15px'}>
                  Введите электронную почту, на которую вам будет отправленa
                  ссылка для восстановления пароля
                </Text>
              </Flex>

              <FormControl id="email" data-testid="email-control">
                <FormLabel>Email</FormLabel>
                <Input
                  type="text"
                  placeholder="Введите email"
                  {...register('email')}
                  data-testid="email-input"
                />
                <Text color="red.600" data-testid="email-error">
                  {errors.email?.message}
                </Text>
              </FormControl>
              <Stack spacing={10}>
                <Button
                  type="submit"
                  isDisabled={isLoading}
                  isLoading={isLoading}
                  bg="#3451bc"
                  color="white"
                  _hover={{
                    // bg: 'teal.500',
                    bg: '#3435bc',
                  }}
                  data-testid="submit-button"
                >
                  Отправить
                </Button>
              </Stack>
            </Stack>
          </form>
        </Box>
      </Stack>
    </Flex>
  )
}
