import { AxiosError, AxiosResponse } from 'axios'
import { UseMutateFunction, useMutation, useQueryClient } from 'react-query'
import { queryKeys } from 'shared/constants'

import { UpdateUserInfoResponse } from 'shared/models'
import { UserService } from 'shared/services'
import { toasts } from 'shared/utils'

interface UpdateProfileMutation {
  isSuperAdmin: boolean
  successAction: () => void
  email: string
  username: string
  last_name: string
  first_name: string
  position_name?: string
  middle_name?: string
}

interface UserData {
  data: {
    user_id: string
  }
}

export function useUpdateUserPersonalProfile(): {
  mutate: UseMutateFunction<
    AxiosResponse<UpdateUserInfoResponse>,
    AxiosError,
    UpdateProfileMutation,
    unknown
  >
  isLoading: boolean
} {
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation(
    ({
      email,
      username,
      last_name,
      first_name,
      middle_name,
      position_name,
      isSuperAdmin,
    }: UpdateProfileMutation) =>
      UserService.updateUserPersonalProfile({
        isSuperAdmin,
        email,
        username,
        last_name,
        first_name,
        middle_name,
        position_name,
      }),
    {
      onSuccess: (
        data: AxiosResponse<UpdateUserInfoResponse>,
        variables: UpdateProfileMutation,
      ) => {
        if (variables.successAction) {
          variables.successAction()
          toasts.success({
            title: 'Ваши данные  обновлены',
            description: `Ваши персональные данные  успешно обновлены`,
          })
        }

        queryClient.invalidateQueries([queryKeys.users.allUsers])
        queryClient.invalidateQueries([queryKeys.user.info])
      },
      onError: () =>
        toasts.error({
          title: 'Произошла ошибка',
          description: 'Персональные данные не были обновлены',
        }),
    },
  )
  return { mutate, isLoading }
}
