import React, { useEffect, useMemo, useState } from 'react'
import { Box, Flex, Spinner, Text } from '@chakra-ui/react'

import {
  CalculationSchemesDataList,
  useGetAllCalculationSchemes,
} from 'entities/counteragentsContracts'

import { CalculationSchemesResponse } from 'shared/models'
import { FilterElements, SortingElements, SortStage, Tooltip } from 'shared/ui'

export const CalculationSchemesList: React.FC = () => {
  const [calculationSchemes, setCalculationSchemes] = useState<
    CalculationSchemesResponse[]
  >([])
  const [filter, setFilter] = useState<string>('')
  const [sortOrderByName, setSortOrderByName] = useState<SortStage>(
    SortStage.none,
  )
  const [sortByCode, setSortOrderByCode] = useState<SortStage>(SortStage.none)

  const { data, isLoading } = useGetAllCalculationSchemes({
    enabled: true,
  })

  useEffect(() => {
    if (data) setCalculationSchemes(data)
  }, [data])

  const handleSortByName = () => {
    setSortOrderByName((currentSortOrder) =>
      currentSortOrder === SortStage.none
        ? SortStage.asc
        : currentSortOrder === SortStage.asc
        ? SortStage.desc
        : SortStage.none,
    )
    setSortOrderByCode(SortStage.none)
  }

  const handleSortByCode = () => {
    setSortOrderByCode((currentSortOrder) =>
      currentSortOrder === SortStage.none
        ? SortStage.asc
        : currentSortOrder === SortStage.asc
        ? SortStage.desc
        : SortStage.none,
    )
    setSortOrderByName(SortStage.none)
  }

  const sortedAndFilteredCalculationSchemes = useMemo(() => {
    const sortedCalculationSchemes = calculationSchemes.sort((a, b) => {
      if (sortOrderByName !== SortStage.none) {
        const zoneA = a.schema_name?.toLowerCase() ?? ''
        const zoneB = b.schema_name?.toLowerCase() ?? ''
        if (sortOrderByName === SortStage.asc) return zoneA.localeCompare(zoneB)
        if (sortOrderByName === SortStage.desc)
          return zoneB.localeCompare(zoneA)
      } else if (sortByCode !== SortStage.none) {
        const codeA = a.code_id ?? 0
        const codeB = b.code_id ?? 0
        if (sortByCode === SortStage.asc) return codeA - codeB
        if (sortByCode === SortStage.desc) return codeB - codeA
      }
      return 0
    })

    return sortedCalculationSchemes.filter((dayZone) => {
      const energyName = dayZone.schema_name?.toLowerCase() ?? ''
      const regionCode = dayZone.code_id?.toString() ?? ''
      const filterLower = filter?.toLowerCase() ?? ''
      return (
        energyName.includes(filterLower) || regionCode.includes(filterLower)
      )
    })
  }, [filter, calculationSchemes, sortOrderByName, sortByCode])

  const calculationSchemesFilterProps = {
    filter,
    setFilter,
    delay: 0,
  }

  if (isLoading)
    return (
      <>
        <Flex align="center" justify="center" height="100vh">
          <Spinner
            thickness="2px"
            speed="0.35s"
            emptyColor="gray.200"
            color="blue.500"
            size="md"
          />
        </Flex>
      </>
    )

  return (
    <Box maxH="90vh" overflowY="hidden">
      <Flex py="8px" alignItems={'center'}>
        <Box ml={'8px'}>
          <Tooltip
            delayShow={500}
            id={`subject-sort-by-id`}
            content="Сортировка по ID схемы"
            place="top"
          >
            <SortingElements
              setSortOrder={handleSortByCode}
              sortOrder={sortByCode}
              title="По ID"
            />
          </Tooltip>
        </Box>
        <Box ml={'8px'}>
          <Tooltip
            delayShow={500}
            id={`subject-sort-by-name`}
            content="Сортировка по имени схемы"
            place="top"
          >
            <SortingElements
              setSortOrder={handleSortByName}
              sortOrder={sortOrderByName}
              title="По имени"
            />
          </Tooltip>
        </Box>
        <Box ml="5px">
          <FilterElements {...calculationSchemesFilterProps} />
        </Box>
      </Flex>

      <Box h={'100vh'} overflowY="scroll">
        <Flex
          borderBottom={'1px solid #eeeeee'}
          alignItems={'center'}
          p={'3px 0'}
        >
          <Text ml={'25px'} fontSize={'sm'}>
            ID
          </Text>
          <Text ml={'22px'} fontSize={'sm'}>
            Наименование
          </Text>
        </Flex>
        <CalculationSchemesDataList
          calculationSchemes={sortedAndFilteredCalculationSchemes}
        />
      </Box>
    </Box>
  )
}
