import React, { useMemo, useState } from 'react'

import {
  Box,
  Button,
  Flex,
  Spinner,
  Text,
  useDisclosure,
} from '@chakra-ui/react'

import { Access } from 'app/providers'
import { useGetAllActivityAreas } from 'entities/referenceBooks'

import {
  AddNewActivityArea,
  AddNewPriceZone,
  EditActivity,
} from 'features/referenceBooks'

import { ActivityAreasResponse } from 'shared/models'
import { FilterElements } from 'shared/ui'
import { accessDict, modulesDict } from 'shared/dictionary'

//  misc
import { HiMiniPlusCircle, HiMiniXMark } from 'react-icons/hi2'

export const ActivityAreas: React.FC = () => {
  const { isOpen, onToggle } = useDisclosure()

  const [filter, setFilter] = useState<string>('')
  // const [activityAreas, setActivityAreas] = useState<ActivityAreasResponse[]>(
  //   [],
  // )

  const { data, isLoading } = useGetAllActivityAreas({
    enabled: true,
  })

  const filteredActivityAreas = useMemo(() => {
    const currentFilter = filter ? filter.toLowerCase() : ''
    return data
      ? data.filter(
          (area) =>
            currentFilter === '' ||
            area.area_name.toLowerCase().includes(currentFilter),
        )
      : []
  }, [data, filter])

  const addActivityAreaProps = {
    isOpen,
    onToggle,
  }

  const subjectFilterProps = {
    filter,
    setFilter,
    delay: 0,
  }

  if (isLoading)
    return (
      <>
        <Flex align="center" justify="center" height="100vh">
          <Spinner
            thickness="2px"
            speed="0.35s"
            emptyColor="gray.200"
            color="blue.500"
            size="md"
          />
        </Flex>
      </>
    )

  return (
    <Box maxH="90vh" overflowY="hidden">
      <Flex py="8px" alignItems={'center'}>
        <Box ml="5px">
          <FilterElements {...subjectFilterProps} />
        </Box>

        <Access
          permissions={[accessDict.update_read]}
          module={modulesDict.reference_books}
        >
          <Box ml={'8px'}>
            {isOpen ? (
              <Button
                position={'relative'}
                size={'xs'}
                display={'flex'}
                alignItems={'center'}
                onClick={() => onToggle()}
              >
                <Box fontSize={'18px'}>
                  <HiMiniXMark />
                </Box>
                <Text position={'relative'}>Отмена</Text>
              </Button>
            ) : (
              <Button
                position={'relative'}
                size={'xs'}
                bg="blue.700"
                color="white"
                display={'flex'}
                alignItems={'center'}
                onClick={() => onToggle()}
                _hover={{
                  bg: 'blue.600',
                }}
              >
                <Box fontSize={'18px'} mr={'5px'}>
                  <HiMiniPlusCircle />
                </Box>
                <Text position={'relative'}>Добавить зону ГП</Text>
              </Button>
            )}
          </Box>
        </Access>
      </Flex>
      <Access
        permissions={[accessDict.update_read]}
        module={modulesDict.reference_books}
      >
        <Box
          position="relative"
          zIndex="1"
          boxShadow="0px 4px  10px rgba(0, 0, 0, 0.12)"
          borderBottom={'1px solid #eeeeee'}
          borderTop={'1px solid #319795'}
        >
          <AddNewActivityArea {...addActivityAreaProps} />
        </Box>
      </Access>

      <Box h={'100vh'} overflowY="scroll">
        <EditActivity activityAries={filteredActivityAreas} />
      </Box>
    </Box>
  )
}
