import { useGetUsers } from 'entities/Users'

import { TableEntity } from './TableEntity'

export const SettingsUsersTable = () => {
  const { data: users, isFetching } = useGetUsers({ enabled: false })

  const tableEntityProps = {
    loading: isFetching,
    users: users || [],
  }

  return (
    <div>
      <TableEntity {...tableEntityProps} />
    </div>
  )
}
