import { useMemo, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Flex, Grid, GridItem } from '@chakra-ui/react'

import { Access } from 'app/providers'

import {
  EditUserPermission,
  EditUserProfile,
  DeleteUserSettings,
  EditUserInheritance,
  EditUserCompanies,
} from 'features/users'

import { useGetUsersInfoById } from 'entities/usersInfo'
import { useGetUserInfo } from 'entities/User'

import { ROLES, USERS_ROUTE } from 'shared/constants'
import { Role } from 'shared/models'

import {
  canEditProfile,
  canEditAccess,
  canEditCompanies,
} from './utils/canEdit'

// misc
import { HiArrowLeftCircle } from 'react-icons/hi2'
import { NavigateBackButton, ScrollToTop } from 'shared/ui'

export const UserEditPage = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const scrollContainerRef = useRef(null)

  const { usersInfo, isLoading } = useGetUsersInfoById({
    userId: id,
    enabled: !!id,
  })

  const { currentUserRole, currentUserId } = useGetUserInfo({
    enabled: false,
  })

  const editableUserRole = usersInfo?.role_name

  const editParams = useMemo(
    () => ({
      currentUserRole: currentUserRole as Role,
      editableUserRole: editableUserRole as Role,
      currentUserId: currentUserId,
      editableUserId: id,
    }),
    [currentUserRole, editableUserRole, currentUserId, id],
  )

  // FIX: Change to useAccess
  const isCurrentUserAbleToEditProfile = useMemo(
    () => canEditProfile(editParams),
    [editParams],
  )
  // FIX: Change to useAccess
  const isCurrentUserAbleToEditAccess = useMemo(
    () => canEditAccess(editParams),
    [editParams],
  )
  // FIX: Change to useAccess
  const isCurrentUserAbleToEditCompanies = useMemo(
    () => canEditCompanies(editParams),
    [editParams],
  )

  const moduleProps = {
    usersInfo,
    isLoading,
    userId: id,
    isCurrentUserAbleToEditProfile,
    isCurrentUserAbleToEditAccess,
    currentUserRole,
    isCurrentUserAbleToEditCompanies,
  }

  return (
    <Grid
      templateAreas={`"header" "main"`}
      gridTemplateRows={'35px 1fr'}
      gridTemplateColumns={'1fr'}
      height="91vh"
      overflow={'100'}
      color="blackAlpha.700"
      fontWeight="bold"
    >
      <ScrollToTop scrollContainerRef={scrollContainerRef} />
      <GridItem pl="2" area={'header'} borderBottom={'1px solid #dfe0eb'}>
        <NavigateBackButton onClick={() => navigate(USERS_ROUTE)} />
      </GridItem>
      <GridItem area={'main'} height={'87vh'}>
        <Flex height={'100%'}>
          <Box
            id="nav-content"
            ref={scrollContainerRef}
            style={{
              width: '100%',
              overflowY: 'scroll',
              overflowX: 'hidden',
              height: 'calc(100vh - 90px)',
              paddingBottom: '50px',
            }}
          >
            <Box id="personal" className="personal">
              <EditUserProfile {...moduleProps} />
            </Box>
            <Box id="inheritance" className="inheritance">
              <Box p={'15px'}>
                <EditUserInheritance {...moduleProps} />
              </Box>
            </Box>
            <Box borderBottom={'1px solid #dfe0eb'} />
            <Box id="companies" className="inheritance" p={'15px'}>
              <EditUserCompanies {...moduleProps} />
            </Box>
            <Box borderBottom={'1px solid #dfe0eb'} />
            <Box
              id="permission"
              style={{ width: '100%' }}
              className="personal"
              p={'15px'}
            >
              <EditUserPermission {...moduleProps} />
            </Box>

            <>
              {isCurrentUserAbleToEditAccess && (
                <Access roles={[ROLES.SUPERADMIN]}>
                  <Box
                    id="permission"
                    style={{ width: '100%' }}
                    className="deleteUser"
                  >
                    <DeleteUserSettings {...moduleProps} />
                  </Box>
                </Access>
              )}
            </>
          </Box>
        </Flex>
      </GridItem>
    </Grid>
  )
}
