import { useQuery } from 'react-query'

import { AdditionalRequisitesResponse } from 'shared/models'
import { queryKeys } from 'shared/constants'
import { CounteragentsContractsService } from 'shared/services'

interface AdditionalRequisitesData {
  value: string
  label: string
}

interface UseGetAdditionalRequisitesResult {
  data: AdditionalRequisitesResponse[] | undefined
  isError: boolean
  isFetching: boolean
  isLoading: boolean
  additionalRequisitesData: AdditionalRequisitesData[] | undefined
}

interface UseAllCalculationSchemes {
  enabled: boolean
}

export function useGetAllAdditionalRequisite({
  enabled,
}: UseAllCalculationSchemes): UseGetAdditionalRequisitesResult {
  const { data, isError, isFetching, isLoading } = useQuery<
    AdditionalRequisitesResponse[]
  >({
    queryKey: [
      queryKeys.counteragentsContracts.additionalRequisite
        .allAdditionalRequisite,
    ],
    queryFn: () =>
      CounteragentsContractsService.getAllAdditionalRequisites().then(
        (response) => response.data,
      ),
    enabled,
    retry: false,
  })

  const additionalRequisitesData = data?.map((requisite) => ({
    value: requisite.add_requisite_id,
    label: requisite.additional_requisite,
  }))

  return { data, isError, isFetching, isLoading, additionalRequisitesData }
}
