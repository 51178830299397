import { ReactNode, ComponentProps } from 'react'
import { Text } from '@chakra-ui/react'

interface TextValueTitleProps
  extends Omit<ComponentProps<typeof Text>, 'size'> {
  size?: 'small' | 'medium' | 'large' | 'smallSource'
}

export const TextValueTitle: React.FC<TextValueTitleProps> = (props) => {
  const { size = 'small', children, ...rest } = props

  const styles = {
    small: {
      position: 'relative',
      top: '-5px',
      fontSize: '14px',
      fontWeight: '600',
      color: '#000000a3',
    },
    smallSource: {
      fontSize: '14px',
      fontWeight: '600',
      color: '#000000a3',
    },
  }

  return (
    <Text {...styles[size]} {...rest}>
      {children}
    </Text>
  )
}
