import React from 'react'
import { Box } from '@chakra-ui/react'

import { TypesContractsResponse } from 'shared/models'
import { ListView } from 'shared/ui'

import { TypesContractsItem } from './TypesContractsItem'

interface EditTypesContractsProps {
  contracts: TypesContractsResponse[]
}

export const EditTypesContracts: React.FC<EditTypesContractsProps> = (
  props,
) => {
  const { contracts } = props

  return (
    <ListView.Lists>
      {contracts.map((contract) => (
        <TypesContractsItem
          key={contract.type_contract_id}
          full_name={contract.full_name}
          short_name={contract.short_name}
          type_contract_id={contract.type_contract_id}
          code_id={contract.code_id}
        />
      ))}
    </ListView.Lists>
  )
}
