import React, { useEffect, useMemo, useState } from 'react'

import { Box, Flex, Spinner, Text } from '@chakra-ui/react'

import {
  SalesAllowancesDataList,
  useGetAllSalesAllowances,
} from 'entities/priceCategories'

import { SalesAllowancesResponse } from 'shared/models'
import { FilterElements, SortingElements, SortStage, Tooltip } from 'shared/ui'

export const SalesAllowancesList: React.FC = () => {
  const [salesAllowances, setSalesAllowances] = useState<
    SalesAllowancesResponse[]
  >([])
  const [filter, setFilter] = useState<string>('')
  const [sortOrderByName, setSortOrderByName] = useState<SortStage>(
    SortStage.none,
  )
  const [sortByCode, setSortOrderByCode] = useState<SortStage>(SortStage.none)

  const { data, isLoading } = useGetAllSalesAllowances({
    enabled: true,
  })

  useEffect(() => {
    if (data) setSalesAllowances(data)
  }, [data])

  const handleSortByName = () => {
    setSortOrderByName((currentSortOrder) =>
      currentSortOrder === SortStage.none
        ? SortStage.asc
        : currentSortOrder === SortStage.asc
        ? SortStage.desc
        : SortStage.none,
    )
    setSortOrderByCode(SortStage.none)
  }

  const handleSortByCode = () => {
    setSortOrderByCode((currentSortOrder) =>
      currentSortOrder === SortStage.none
        ? SortStage.asc
        : currentSortOrder === SortStage.asc
        ? SortStage.desc
        : SortStage.none,
    )
    setSortOrderByName(SortStage.none)
  }

  const sortedAndFilteredSalesAllowances = useMemo(() => {
    const sortedSalesAllowances = salesAllowances.sort((a, b) => {
      if (sortOrderByName !== SortStage.none) {
        const systemA = a.allowance?.toLowerCase() ?? ''
        const systemB = b.allowance?.toLowerCase() ?? ''
        if (sortOrderByName === SortStage.asc)
          return systemA.localeCompare(systemB)
        if (sortOrderByName === SortStage.desc)
          return systemB.localeCompare(systemA)
      } else if (sortByCode !== SortStage.none) {
        const codeA = a.code_id ?? 0
        const codeB = b.code_id ?? 0
        if (sortByCode === SortStage.asc) return codeA - codeB
        if (sortByCode === SortStage.desc) return codeB - codeA
      }
      return 0
    })

    return sortedSalesAllowances.filter((subject) => {
      const allowanceName = subject.allowance?.toLowerCase() ?? ''
      const allowanceCode = subject.code_id?.toString() ?? ''
      const filterLower = filter?.toLowerCase() ?? ''
      return (
        allowanceName.includes(filterLower) ||
        allowanceCode.includes(filterLower)
      )
    })
  }, [filter, salesAllowances, sortOrderByName, sortByCode])

  const subjectFilterProps = {
    filter,
    setFilter,
    delay: 0,
  }

  if (isLoading)
    return (
      <>
        <Flex align="center" justify="center" height="100vh">
          <Spinner
            thickness="4px"
            speed="0.35s"
            emptyColor="gray.200"
            color="blue.500"
            size="sm"
          />
        </Flex>
      </>
    )

  return (
    <Box maxH="90vh" overflowY="hidden">
      <Flex py="8px" alignItems={'center'}>
        <Box ml={'8px'}>
          <Tooltip
            delayShow={500}
            id={`subject-sort-by-id`}
            content="Сортировка по ID надбавки"
            place="top"
          >
            <SortingElements
              setSortOrder={handleSortByCode}
              sortOrder={sortByCode}
              title="По ID"
            />
          </Tooltip>
        </Box>
        <Box ml={'8px'}>
          <Tooltip
            delayShow={500}
            id={`subject-sort-by-name`}
            content="Сортировка по имени надбавки"
            place="top"
          >
            <SortingElements
              setSortOrder={handleSortByName}
              sortOrder={sortOrderByName}
              title="По имени"
            />
          </Tooltip>
        </Box>
        <Box ml="5px">
          <FilterElements {...subjectFilterProps} />
        </Box>
      </Flex>

      <Box h={'100vh'} overflowY="scroll">
        <Flex
          borderBottom={'1px solid #eeeeee'}
          alignItems={'center'}
          p={'3px 0'}
        >
          <Text ml={'25px'} fontSize={'sm'}>
            ID
          </Text>
          <Text ml={'22px'} fontSize={'sm'}>
            Наименование
          </Text>
        </Flex>
        <SalesAllowancesDataList
          salesAllowances={sortedAndFilteredSalesAllowances}
        />
      </Box>
    </Box>
  )
}
