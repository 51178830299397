import { AxiosError, AxiosResponse } from 'axios'
import { UseMutateFunction, useMutation, useQueryClient } from 'react-query'

import { queryKeys } from 'shared/constants'
import { ExtendedAxiosError } from 'shared/models'
import { CounteragentsContractsService } from 'shared/services'
import { toasts } from 'shared/utils'

interface UpdateCounteragentItemMutation {
  successAction: () => void
  type_counteragent_id: string
  full_name: string
  short_name: string
}

export function useUpdateCounteragent(): {
  mutate: UseMutateFunction<
    AxiosResponse<undefined>,
    AxiosError,
    UpdateCounteragentItemMutation,
    unknown
  >
  isLoading: boolean
} {
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation(
    ({
      type_counteragent_id,
      full_name,
      short_name,
    }: UpdateCounteragentItemMutation) =>
      CounteragentsContractsService.updateTypesCounteragent({
        type_counteragent_id,
        full_name,
        short_name,
      }),
    {
      onSuccess: (
        data: AxiosResponse<undefined>,
        variables: UpdateCounteragentItemMutation,
      ) => {
        if (variables.successAction) {
          variables.successAction()
          toasts.success({
            title: 'Успех',
            description: `Вид контрагента ${variables.short_name} успешно обновлен`,
          })
        }
        queryClient.invalidateQueries([
          queryKeys.counteragentsContracts.typeCounteragents
            .allTypesCounteragents,
        ])
      },
      onError: (error: ExtendedAxiosError) =>
        toasts.error({
          title: 'Произошла ошибка',
          description:
            error.response.data.detail || 'Вид контрагента не обновлен',
        }),
    },
  )
  return { mutate, isLoading }
}
