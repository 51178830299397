import { useContext } from 'react'
import { AccessContext } from './AccessProvider'

interface AccessProps {
  permissions?: string[]
  module?: string
  roles?: string[]
}

export const useAccess = () => {
  const { roles, permissions } = useContext(AccessContext)

  const hasAccess = ({
    permissions: requiredPermissions = [],
    module = '',
    roles: requiredRoles = [],
  }: AccessProps): boolean => {
    const hasRoleAccess = requiredRoles.length
      ? requiredRoles.some((role) => roles.includes(role))
      : true

    const hasModuleAccess =
      requiredPermissions.length && module
        ? permissions.some(
            (p) =>
              p.module === module &&
              requiredPermissions.some((access) => p.access.includes(access)),
          )
        : true

    return hasRoleAccess && hasModuleAccess
  }

  return { hasAccess }
}
