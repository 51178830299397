import { useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import { Box, Grid, GridItem } from '@chakra-ui/react'

import { useAccess } from 'app/providers'
import { useGetUserInfo } from 'entities/User'

import {
  EditUserInheritance,
  EditUserCompanies,
  EditUserPersonalProfile,
  EditUserPermission,
} from 'features/users'

import { accessDict, modulesDict } from 'shared/dictionary'
import { SetNewPassword } from 'features/authentication'
import { NavigateBackButton, ScrollToTop } from 'shared/ui'

export const UserProfilePage: React.FC = () => {
  const navigate = useNavigate()

  const scrollContainerRef = useRef(null)

  const { hasAccess } = useAccess()

  const { userInfo } = useGetUserInfo({
    enabled: false,
  })

  const isUserAbleToEditCompanies: boolean = hasAccess({
    permissions: [accessDict.full_access],
    module: modulesDict.companies,
  })

  return (
    <Grid
      templateAreas={`"header" "main"`}
      gridTemplateRows={'35px 1fr'}
      gridTemplateColumns={'1fr'}
      height="91vh"
      overflow={'100'}
      color="blackAlpha.700"
      fontWeight="bold"
    >
      <ScrollToTop scrollContainerRef={scrollContainerRef} />
      <GridItem pl="2" area={'header'} borderBottom={'1px solid #dfe0eb'}>
        <NavigateBackButton ml="13px" onClick={() => navigate(-1)} />
      </GridItem>
      <GridItem area={'main'}>
        <Box
          p={'10px 25px'}
          ref={scrollContainerRef}
          style={{
            width: '100%',
            overflowY: 'scroll',
            overflowX: 'hidden',
            height: 'calc(100vh - 90px)',
          }}
        >
          <EditUserPersonalProfile />
          <Box borderBottom={'1px solid #dfe0eb'} mt={'10px'} mb={'10px'} />
          <EditUserInheritance
            userId={userInfo?.user_id}
            isCurrentUserAbleToEditAccess={false}
          />
          <Box borderBottom={'1px solid #dfe0eb'} mt={'10px'} mb={'10px'} />
          <Box mt={'10px'} mb={'10px'} />
          <EditUserCompanies
            userId={userInfo?.user_id}
            isCurrentUserAbleToEditCompanies={isUserAbleToEditCompanies}
          />
          <Box
           borderBottom={'1px solid #dfe0eb'}
            mt={'10px'} mb={'10px'} />
          <EditUserPermission
            userId={userInfo?.user_id}
            isCurrentUserAbleToEditAccess={false}
            title={'Доступы'}
          />
          <Box
           borderBottom={'1px solid #dfe0eb'}
           mt={'10px'} mb={'10px'} />
          <SetNewPassword />
        </Box>
      </GridItem>
    </Grid>
  )
}
