import { AxiosPromise } from 'axios'

import { ServiceBase } from './serviceBase'
import { UserPermissionsResponse } from 'shared/models'

export class AbilityService extends ServiceBase {
  protected static BASE_PATH = 'ability'

  public static getAbilityModules(): AxiosPromise<string[]> {
    return this.get<string[]>(`${this.BASE_PATH}/modules`)
  }

  public static getAbilityActions(): AxiosPromise<string[]> {
    return this.get<string[]>(`${this.BASE_PATH}/actions`)
  }

  public static getAbilityRole(userRole: string): AxiosPromise<string[]> {
    return this.get<string[]>(`${this.BASE_PATH}/role/${userRole}`)
  }

  public static getAbilityAssigned(userId: string): AxiosPromise<string[]> {
    return this.get<string[]>(`${this.BASE_PATH}/assigned/${userId}`)
  }

  public static getAbilityInherited(id: string): AxiosPromise<string[]> {
    return this.get<string[]>(`${this.BASE_PATH}/inherited/${id}`)
  }

  public static getAbilityUserAll(userId: string): AxiosPromise<string[]> {
    return this.get<string[]>(`${this.BASE_PATH}/allUser/${userId}`)
  }

  public static getAbilityFull(
    userId: string,
  ): AxiosPromise<UserPermissionsResponse[]> {
    return this.get<UserPermissionsResponse[]>(
      `${this.BASE_PATH}/full/${userId}`,
    )
  }

  public static getAbilityTest(): AxiosPromise<string[]> {
    return this.get<string[]>(`${this.BASE_PATH}/test_ability`)
  }

  public static getAbilityPermissions(): AxiosPromise<string[]> {
    return this.get<string[]>(`${this.BASE_PATH}/permissions`)
  }
}
