import { AxiosPromise } from 'axios'
import { ServiceBase } from './serviceBase'

import { AuthenticationProps } from 'features/authentication'

import { UserResponse, AuthResponse } from 'shared/models'

export class AuthService extends ServiceBase {
  protected static BASE_PATH = 'users'

  public static login(data: FormData): AxiosPromise<UserResponse> {
    return this.post(`${this.BASE_PATH}/token`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  }

  public static fetchRefresh(): AxiosPromise<AuthResponse> {
    return this.post(`${this.BASE_PATH}/token/refresh`)
  }

  public static fetchLogout(): AxiosPromise<unknown> {
    return this.post(`${this.BASE_PATH}/logout`, {
      exitAll: false,
    })
  }
}
