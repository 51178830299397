import { JwtPayload } from 'jwt-decode'
import { UserResponse } from 'shared/models'

import { StoreSchema } from 'app/providers'

import {
  fakePermissions,
  getPermissions,
} from 'entities/User/utils/getPermissions'

import { decodeJwt } from '../../utils/jwtDecode'

interface UserInfoJwtPayload extends JwtPayload {
  login: string
  userId: string
}

export interface UserState {
  userInfo: { login: string } | null
  userId: string | null
  userPermissions: any | null
  setAuthData: (authData: UserResponse) => void
  setUserInfo: (accessToken: string) => void
  getUserId: () => string | null
  setUserId: (userId: string) => void
  resetUser: () => void
}

const initialState = {
  userInfo: null,
  userId: null,
  userPermissions: null,
}

export const userStore = (
  set: (fn: (state: StoreSchema) => void) => void,
  get: () => StoreSchema,
): UserState => ({
  ...initialState,

  setAuthData: (authData: UserResponse) => {
    const decodedData = decodeJwt<UserInfoJwtPayload>(authData.access_token)
    const permissionsData = getPermissions(fakePermissions)

    set((state: StoreSchema) => {
      state.user.userInfo = { login: decodedData?.login as string }
      state.user.userPermissions = permissionsData
    })
  },

  setUserInfo: (accessToken: string) => {
    const decodedData = decodeJwt<UserInfoJwtPayload>(accessToken)

    set((state: StoreSchema) => {
      state.user.userInfo = { login: decodedData?.login as string }
      state.user.userId = decodedData?.userId
    })
  },
  setUserId: (userId: string) => {
    set((state: StoreSchema) => {
      state.user.userId = userId
    })
  },
  resetUser: () => {
    set((state: StoreSchema) => {
      // state.user.userInfo = null
      // state.user.userId = null
      // state.user.userPermissions = null
      state.user = {
        ...state.user,
        ...initialState,
      }
    })
  },
  getUserId: () => get().user.userId,
})
