/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useMemo, useState } from 'react'
import { useDisclosure } from '@chakra-ui/react'

import { DeleteRelatedTypeContract } from 'features/counteragentsContracts'
import { BasicTable } from 'shared/ui'
import { TypesCounteragentDetailResponse } from 'shared/models'

import {
  initialNotificationState,
  ModalAction,
  NotificationState,
  TableStructure,
} from './TableStructure'
import { EditRelatedContractModal } from './EditRelatedContractModal'
import { useUpdateRelatedContract } from '../models/services/useUpdateRelatedContractService'

interface ITableEntityProps {
  type_counteragent_id: string
  typesContracts: TypesCounteragentDetailResponse[]
  hiddenColumns?: string[]
  isEdit?: boolean
}

export const TableEntity: React.FC<ITableEntityProps> = (props) => {
  const { typesContracts, hiddenColumns, isEdit } = props

  const [actionData, setActionData] =
    useState<TypesCounteragentDetailResponse | null>(null)

  const [currentModal, setCurrentModal] = useState<ModalAction | null>(null)

  const [openNotification, setOpenNotification] = useState<NotificationState>(
    initialNotificationState,
  )

  const { isOpen, onOpen, onClose } = useDisclosure()

  const {
    mutate: updateRelatedContract,
    isLoading: isUpdatingRelatedContract,
  } = useUpdateRelatedContract()

  const handleOpenModal = useCallback(
    (data: TypesCounteragentDetailResponse, action: ModalAction) => {
      setActionData(data)
      setCurrentModal(action)
      onOpen()
    },
    [onOpen],
  )

  const handleCloseModal = useCallback(() => {
    onClose()
    setCurrentModal(null)
  }, [onClose])

  const handleClickCheckbox = useCallback(
    ({ related_tc_id, part }: { related_tc_id: string; part: string }) => {
      const typeContract = typesContracts.find(
        (item) => item.related_tc_id === related_tc_id,
      )

      const changedTypeContract = {
        ...typeContract,
        [part]: !typeContract[part],
      }

      if (!changedTypeContract.supplier && !changedTypeContract.buyer) {
        setOpenNotification({
          isOpen: true,
          message: 'Поставщик или покупатель должен быть выбран',
          title: 'Операция невозможна',
          id: related_tc_id,
          part,
        })
        return
      }

      updateRelatedContract({
        successAction: () => onClose(),
        type_counteragent_id: changedTypeContract?.type_counteragent_id,
        related_tc_id: changedTypeContract?.related_tc_id,
        type_contract_id: changedTypeContract?.type_contract_id,
        supplier: changedTypeContract?.supplier,
        buyer: changedTypeContract.buyer,
      })
    },
    [typesContracts],
  )

  const columns = useMemo(
    () =>
      TableStructure({
        openNotification,
        setOpenNotification,
        handleOpenModal: ({ data, action }) => handleOpenModal(data, action),
        handleClickCheckbox: ({ related_tc_id, part }) =>
          handleClickCheckbox({ related_tc_id, part }),
        isEdit,
      }),
    [
      handleOpenModal,
      isEdit,
      handleClickCheckbox,
      openNotification,
      setOpenNotification,
    ],
  )

  const data = useMemo(() => typesContracts, [typesContracts])

  const tableProps = useMemo(
    () => ({
      tableStyles: {
        tableMaxHeight: '80vh',
        tableWidth: '100%',
      },
      data,
      columns,
      emptyText: 'Связанныq контракт: не найден, добавьте новый контракт.',
      loading: false,
      showTableMenu: false,
      smallShadow: true,
      lightHeader: true,
      hiddenColumns,
    }),
    [data, columns, hiddenColumns],
  )

  const modalsProps = {
    isOpen,
    onClose: handleCloseModal,
    actionData,
  }

  return (
    <>
      {currentModal === ModalAction.delete && (
        <DeleteRelatedTypeContract {...modalsProps} />
      )}
      {currentModal === ModalAction.edit && (
        <EditRelatedContractModal {...modalsProps} />
      )}
      <BasicTable {...tableProps} />
    </>
  )
}
