import { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'

import { UserModuleRightItem } from 'shared/models'
import { queryKeys } from 'shared/constants'
import { ModulesService } from 'shared/services'

interface TransformedData {
  [key: string]: {
    access: string
    inheritance: string
    originalData: UserModuleRightItem
  }
}

interface UseGetUserPermissionsResult {
  data: AxiosResponse<UserModuleRightItem[]> | undefined
  userModuleRights: UserModuleRightItem[] | null
  transformedData: TransformedData
  isError: boolean
  isFetching: boolean
  isLoading: boolean
}

interface UseGetPermissions {
  userId: string
  enabled?: boolean
  successAction?: () => void
  errorAction?: () => void
}

function transformModuleRightsData(
  data: UserModuleRightItem[],
): TransformedData {
  return data.reduce((acc, item) => {
    const { module, access, inheritance, ...originalData } = item
    acc[module] = {
      access,
      inheritance,
      originalData: { ...item },
    }
    return acc
  }, {} as TransformedData)
}

export function useGetUsersPermissions({
  userId,
  enabled = false,
  successAction = () => {},
  errorAction = () => {},
}: UseGetPermissions): UseGetUserPermissionsResult {
  const { data, isError, isFetching, isLoading } = useQuery<
    AxiosResponse<UserModuleRightItem[]>
  >({
    queryKey: [queryKeys.modules.userRights, userId],
    queryFn: () => ModulesService.getModuleRights(userId),
    enabled,
    retry: false,
    onSuccess: (responseData) => successAction(),
    onError: () => errorAction(),
  })

  const userModuleRights = data ? data.data : null
  const transformedData = userModuleRights
    ? transformModuleRightsData(userModuleRights)
    : {}

  return {
    data,
    userModuleRights,
    transformedData,
    isError,
    isFetching,
    isLoading,
  }
}
