import { AxiosError, AxiosResponse } from 'axios'
import { UseMutateFunction, useMutation, useQueryClient } from 'react-query'

import { queryKeys } from 'shared/constants'
import { AddGroupDeliveryRequest, DeliveryGroupAxiosError } from 'shared/models'
import { ReferenceBookService } from 'shared/services'
import { toasts } from 'shared/utils'

interface AddNewActivityAreaMutation {
  successAction: () => void
  errorAction: (gd_point_names: string[]) => void
  area_name: string
  price_zone_id: string
  ue_system_id: string
  subject_id: string
  time_zone: string
  related_group_delivery_points?: AddGroupDeliveryRequest
}

export function useAddNewActivityArea(): {
  mutate: UseMutateFunction<
    AxiosResponse<undefined>,
    AxiosError,
    AddNewActivityAreaMutation,
    unknown
  >
  isLoading: boolean
} {
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation(
    ({
      area_name,
      price_zone_id,
      ue_system_id,
      subject_id,
      time_zone,
      related_group_delivery_points,
    }: AddNewActivityAreaMutation) =>
      ReferenceBookService.addNewActivityArea({
        area_name,
        price_zone_id,
        ue_system_id,
        subject_id,
        time_zone,
        ...(related_group_delivery_points && {
          related_group_delivery_points,
        }),
      }),
    {
      onSuccess: (
        data: AxiosResponse<undefined>,
        variables: AddNewActivityAreaMutation,
      ) => {
        if (variables.successAction) {
          variables.successAction()
          toasts.success({
            title: 'Успех',
            description: `Зона дейтельности добавлена`,
          })
        }
        queryClient.invalidateQueries([
          queryKeys.referenceBooks.activityAreas.allActivityAreas,
        ])
      },
      onError: (
        error: DeliveryGroupAxiosError,
        variables: AddNewActivityAreaMutation,
      ) => {
        variables.errorAction(error.response.data.gd_point_names)
        toasts.error({
          title: 'Произошла ошибка',
          description:
            error.response.data.detail || 'Зона дейтельности не добавлена',
        })
      },
    },
  )
  return { mutate, isLoading }
}
