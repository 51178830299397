import { AxiosError, AxiosResponse } from 'axios'
import { UseMutateFunction, useMutation, useQueryClient } from 'react-query'

import { queryKeys } from 'shared/constants'
import { ExtendedAxiosError } from 'shared/models'
import { ReferenceBookService } from 'shared/services'
import { toasts } from 'shared/utils'

interface AddNewEnergySystemMutation {
  successAction: () => void
  system_name: string
}

export function useAddNewEnergySystem(): {
  mutate: UseMutateFunction<
    AxiosResponse<undefined>,
    AxiosError,
    AddNewEnergySystemMutation,
    unknown
  >
  isLoading: boolean
} {
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation(
    ({ system_name }: AddNewEnergySystemMutation) =>
      ReferenceBookService.addNewEnergySystem({
        system_name,
      }),
    {
      onSuccess: (
        data: AxiosResponse<undefined>,
        variables: AddNewEnergySystemMutation,
      ) => {
        if (variables.successAction) {
          variables.successAction()
          toasts.success({
            title: 'Успех',
            description: `Новыая ОЭС успешно добвлена`,
          })
        }
        queryClient.invalidateQueries([
          queryKeys.referenceBooks.energySystems.allEnergySystems,
        ])
      },
      onError: (error: ExtendedAxiosError) =>
        toasts.error({
          title: 'Произошла ошибка',
          description:
            error.response.data.detail || 'Новыая ОЭС не была добвлена',
        }),
    },
  )
  return { mutate, isLoading }
}
