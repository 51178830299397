// hooks/useLoginForm.ts
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

interface LoginData {
  username?: string
  password?: string
}

const SCHEMA_VALIDATION = yup.object({
  password: yup.string().required('Введите пароль'),
  username: yup.string().required('Введите логин'),
})

export const useLoginForm = () => {
  const [showPassword, setShowPassword] = useState<boolean>(false)

  const { register, handleSubmit, formState } = useForm<LoginData>({
    resolver: yupResolver(SCHEMA_VALIDATION),
  })

  const { errors } = formState

  return {
    register,
    handleSubmit,
    errors,
    showPassword,
    setShowPassword,
  }
}
