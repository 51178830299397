import { AxiosPromise } from 'axios'
import { ServiceBase } from './serviceBase'

import {
  UpdateUserInheritancesRequest,
  UpdateUserInheritancesResponse,
  UpdateUserPermissionsRequest,
  UpdateUserPermissionsResponse,
  UserGetInheritancesResponse,
  UserModuleRightItem,
} from 'shared/models'

export class ModulesService extends ServiceBase {
  protected static BASE_PATH = 'modules'

  public static getModuleRights(
    userId: string,
  ): AxiosPromise<UserModuleRightItem[]> {
    return this.get<UserModuleRightItem[]>(
      `${this.BASE_PATH}/list_module_rights/${userId}`,
    )
  }

  public static updateUserModulePermissions(
    data: UpdateUserPermissionsRequest,
  ): AxiosPromise<UpdateUserPermissionsResponse> {
    const { accessId, moduleRightsId, inheritTypeId } = data
    return this.patch(`${this.BASE_PATH}/module_rights/${moduleRightsId}`, {
      access_id: accessId,
      inherit_type_id: inheritTypeId,
    })
  }

  public static getInheritance(
    userId: string,
  ): AxiosPromise<UserGetInheritancesResponse[]> {
    return this.get<UserGetInheritancesResponse[]>(
      `${this.BASE_PATH}/list_user_inheritance/${userId}`,
    )
  }

  public static updateUserInheritance(
    data: UpdateUserInheritancesRequest,
  ): AxiosPromise<UpdateUserInheritancesResponse> {
    const { userId, fromUsers } = data
    return this.patch(`${this.BASE_PATH}/update_user_inheritance`, {
      to_user: userId,
      from_users: fromUsers,
    })
  }
}
