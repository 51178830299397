import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

export interface RegistrationFormData {
  email?: string
  login?: string
  role?: string
  last_name?: string
  first_name?: string
  middle_name?: string
  position_name?: string
  users_companies?: {
    label: string
    value: string
  }[]
}

const SCHEMA_VALIDATION = yup.object().shape({
  email: yup
    .string()
    .required('email обязательное поле')
    .email('email должен быть валидный')
    .matches(
      /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      'email должен содержать точку и доменное имя',
    ),
  login: yup.string().required('Лгогин обязательное поле'),
  role: yup.string().required('Тип  обязательное поле'),
  first_name: yup.string().required('Имя  обязательное поле'),
  last_name: yup.string().required('Фамилия  обязательное поле'),
  position_name: yup.string().required('Должность  обязательное поле'),
})

export const useAddUserForm = () => {
  const { register, handleSubmit, formState, resetField, reset, control } =
    useForm<RegistrationFormData>({
      resolver: yupResolver(SCHEMA_VALIDATION),
    })

  return {
    reset,
    register,
    handleSubmit,
    errors: formState.errors,
    isDirty: formState.isDirty,
    isValid: formState.isValid,
    isSubmitting: formState.isSubmitting,
    control,
  }
}
