import { AxiosError, AxiosResponse } from 'axios'
import { UseMutateFunction, useMutation, useQueryClient } from 'react-query'

import { queryKeys } from 'shared/constants'
import { AddCounteragentsRequest, DeliveryGroupAxiosError } from 'shared/models'
import { CounteragentsContractsService } from 'shared/services'
import { toasts } from 'shared/utils'

interface CreateTypeCounteragentMutation {
  successAction: () => void
  full_name: string
  short_name: string
  related_type_contract?: AddCounteragentsRequest
}

export function useCreateTypeCounteragent(): {
  mutate: UseMutateFunction<
    AxiosResponse<undefined>,
    AxiosError,
    CreateTypeCounteragentMutation,
    unknown
  >
  isLoading: boolean
} {
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation(
    ({
      full_name,
      short_name,
      related_type_contract,
    }: CreateTypeCounteragentMutation) =>
      CounteragentsContractsService.createTypesCounteragent({
        full_name,
        short_name,
        ...(related_type_contract && {
          related_type_contract,
        }),
      }),
    {
      onSuccess: (
        data: AxiosResponse<undefined>,
        variables: CreateTypeCounteragentMutation,
      ) => {
        if (variables.successAction) {
          variables.successAction()
          toasts.success({
            title: 'Успех',
            description: `Вид контрагента ${variables.short_name} успешно добавлен`,
          })
        }
        queryClient.invalidateQueries([
          queryKeys.counteragentsContracts.typeCounteragents
            .allTypesCounteragents,
        ])
      },
      onError: (
        error: DeliveryGroupAxiosError,
        variables: CreateTypeCounteragentMutation,
      ) => {
        toasts.error({
          title: 'Произошла ошибка',
          description:
            error.response.data.detail || 'Вид контрагента не добавлен',
        })
      },
    },
  )
  return { mutate, isLoading }
}
