import React from 'react'
import { PathMatch, useNavigate } from 'react-router-dom'

import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  useColorModeValue,
  Text,
  Heading,
} from '@chakra-ui/react'

import { AuthStage } from 'widgets/authentication'

import { HOME_ROUTE } from 'shared/constants'
import { CompanyLogo } from 'shared/ui'

import { useRegistrationForm } from './useRegistrationForm'
import { registerAddUser } from '../../models/services/registrationServiceHooks'

interface RegistrationFormProps {
  setAuthStage: React.Dispatch<React.SetStateAction<AuthStage>>
  isRegistrationUrl: PathMatch<string>
}

export const RegistrationForm: React.FC<RegistrationFormProps> = ({
  setAuthStage,
  isRegistrationUrl,
}) => {
  const navigate = useNavigate()
  const { mutate, isLoading } = registerAddUser()

  const { register, handleSubmit, errors, registrationToken } =
    useRegistrationForm(isRegistrationUrl, setAuthStage)

  const onSubmit = (data: { password: string; confirmPassword: string }) => {
    if (data?.password && registrationToken) {
      mutate({
        token: registrationToken,
        password: data?.password,
        successAction: () => {
          setAuthStage(AuthStage.login)
          navigate(HOME_ROUTE)
        },
        errorAction: () => {},
      })
    }
  }

  return (
    <Flex
      minH="100vh"
      align="center"
      justify="center"
      bg={useColorModeValue('gray.50', 'gray.800')}
      data-testid="login-form"
    >
      <Stack spacing={8} mx="auto" alignItems={'center'} py={12} px={6}>
        <CompanyLogo />
        <Box
          rounded="lg"
          bg={useColorModeValue('white', 'gray.700')}
          boxShadow="lg"
          p={8}
          width={'450px'}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={4}>
              <FormControl
                id="password"
                isInvalid={!!errors.password}
                data-testid="password-control"
              >
                <Heading
                  color="#3435bc"
                  fontSize={{ base: '2xl' }}
                  fontWeight={'medium'}
                  mb={5}
                  textAlign={'center'}
                >
                  Придумайте пароль
                </Heading>
                <FormLabel>Пароль</FormLabel>
                <Input
                  type="text"
                  placeholder="Введите пароль"
                  {...register('password')}
                  data-testid="password-input"
                />
                <Text color="red.600" data-testid="password-error">
                  {errors.password?.message}
                </Text>
              </FormControl>
              <FormControl
                id="confirmPassword"
                isInvalid={!!errors.confirmPassword}
                data-testid="confirmPassword-control"
              >
                <FormLabel>Подтверждение пароля</FormLabel>
                <Input
                  type="text"
                  placeholder="Введите подтверждение пароля"
                  {...register('confirmPassword')}
                  data-testid="confirmPassword-input"
                />
                <Text color="red.600" data-testid="confirmPassword-error">
                  {errors.confirmPassword?.message}
                </Text>
              </FormControl>
              <Stack spacing={10}>
                <Button
                  type="submit"
                  isDisabled={isLoading}
                  isLoading={isLoading}
                  bg="#3451bc"
                  color="white"
                  _hover={{
                    // bg: 'teal.500',
                    bg: '#3435bc',
                  }}
                  data-testid="submit-button"
                >
                  Отправить
                </Button>
              </Stack>
            </Stack>
          </form>
        </Box>
      </Stack>
    </Flex>
  )
}
