import React, { ReactNode } from 'react'
import { IconType } from 'react-icons'

import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Box,
  Flex,
} from '@chakra-ui/react'

interface PopoverMessageProps {
  isOpen: boolean
  onClick: () => void
  showIcon?: boolean
  triggerElement?: JSX.Element
  message?: string | null
  title?: string | null
  icon?: IconType
}

export const PopoverMessage: React.FC<PopoverMessageProps> = (props) => {
  const {
    showIcon = false,
    isOpen = false,
    message = '',
    title = '',
    onClick,
    icon: IconComponent,
    triggerElement,
  } = props

  if (showIcon) {
    return (
      <Popover placement="top" isOpen={isOpen} closeOnBlur={true}>
        <PopoverTrigger>{triggerElement}</PopoverTrigger>
        <PopoverContent
          boxShadow="md"
          w={'fit-content'}
          bg={'red'}
          border={'none'}
          p={0}
          borderRadius={
            '12px                                                                                                                                '
          }
        >
          <PopoverArrow bg={'red'} />
          <PopoverBody color={'white'} p={'5px'} borderRadius={'50%'}>
            <Flex flexDirection={'column'} alignItems={'center'}>
              <Box fontSize={'22px'}>
                {showIcon && IconComponent && <IconComponent />}
              </Box>
              <Box fontSize={'10px'}>{message}</Box>
            </Flex>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    )
  }

  return (
    <Popover placement="top" isOpen={isOpen} closeOnBlur={true}>
      <PopoverTrigger>{triggerElement}</PopoverTrigger>
      <PopoverContent boxShadow="lg">
        <PopoverHeader fontWeight="semibold" fontSize={'12px'} color={'red'}>
          {title}
        </PopoverHeader>

        <PopoverArrow />
        <PopoverCloseButton onClick={onClick} />
        <PopoverBody fontSize={'12px'}>{message}</PopoverBody>
      </PopoverContent>
    </Popover>
  )
}
