import React, { useState, useEffect, ReactNode } from 'react'
import { Flex, Spinner } from '@chakra-ui/react'
import { Loader } from '../Loader/Loader'

interface LoadingOverlayProps {
  children: ReactNode
}

export const LoadingOverlay: React.FC<LoadingOverlayProps> = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false)
    }, 500)
    return () => clearTimeout(timer)
  }, [])

  return (
    <Flex position="relative" width="full" height="full">
      {loading && (
        <Flex
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          align="center"
          justify="center"
          backgroundColor="#fff"
          zIndex="overlay"
        >
          <Loader size="md" />
        </Flex>
      )}
      {children}
    </Flex>
  )
}
