import { Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react'

import {
  PriceCategoriesList,
  DayZonesList,
  SalesAllowancesList,
  TransmissionServiceList,
  ProductsList,
  DetailsCalculationPriceList,
  PriceATCList,
} from 'widgets'

export const PriceCategoriesPage: React.FC = () => {
  return (
    <Tabs>
      <TabList p={'10px'} pb={0}>
        <Tab>
          <Text fontSize={'18px'}>Ценовые категории</Text>
        </Tab>
        <Tab>
          <Text fontSize={'18px'}>Сбытовые надбавки</Text>
        </Tab>
        <Tab>
          <Text fontSize={'18px'}>Тарифы на услуги по передаче</Text>
        </Tab>
        <Tab>
          <Text fontSize={'18px'}>Зоны суток</Text>
        </Tab>
        <Tab>
          <Text fontSize={'18px'}>Товары</Text>
        </Tab>
        <Tab>
          <Text fontSize={'18px'}>Детализации расчета ЦК</Text>
        </Tab>
        <Tab>
          <Text fontSize={'18px'}>Цены АТС</Text>
        </Tab>
      </TabList>

      <TabPanels>
        <TabPanel padding={0}>
          <PriceCategoriesList />
        </TabPanel>
        <TabPanel padding={0}>
          <SalesAllowancesList />
        </TabPanel>
        <TabPanel padding={0}>
          <TransmissionServiceList />
        </TabPanel>
        <TabPanel padding={0}>
          <DayZonesList />
        </TabPanel>
        <TabPanel padding={0}>
          <ProductsList />
        </TabPanel>
        <TabPanel padding={0}>
          <DetailsCalculationPriceList />
        </TabPanel>
        <TabPanel padding={0}>
          <PriceATCList />
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}
