import React, { useEffect, useMemo, useState } from 'react'
import { Box, Center, Flex, SimpleGrid, Spinner } from '@chakra-ui/react'
import moment from 'moment'

import { useAccess } from 'app/providers'
import { useGetAllHolydays } from 'entities/holydays'
import { useAddHolyday, useDeleteHolyday } from 'features/holydays'
import { DatePicker, Loader } from 'shared/ui'
import { scrollBarStyles } from 'shared/styles'
import { accessDict, modulesDict } from 'shared/dictionary'

interface HolydaysCalendarProps {
  selectedYear: number
}

interface Holiday {
  date: string
  weekend_id: string
}

interface HolidaysByYear {
  [year: number]: Holiday[]
}

interface MonthCalendarProps {
  year: number
  month: number
  onChange: (date: Date, month: number, year: number) => void
  holidays: Holiday[]
}

export const HolydaysCalendar: React.FC<HolydaysCalendarProps> = (props) => {
  const { selectedYear } = props

  const { hasAccess } = useAccess()

  const accessAllowedEdit = hasAccess({
    permissions: [accessDict.update_read],
    module: modulesDict.reference_books,
  })

  const { data: holidaysData, isLoading } = useGetAllHolydays({
    enabled: true,
  })

  const { mutate: addHolyday } = useAddHolyday()
  const { mutate: deleteHolyday } = useDeleteHolyday()

  const [holidaysDate, setHolidaysDate] = useState<HolidaysByYear>({})

  useEffect(() => {
    if (holidaysData) {
      const organizedHolidays = holidaysData.reduce((acc, holiday) => {
        const year = moment(holiday.weekend_date).year()
        const date = moment(holiday.weekend_date).format('YYYY-MM-DD')

        if (!acc[year]) acc[year] = []
        acc[year].push({ date, weekend_id: holiday.weekend_id })

        return acc
      }, {} as HolidaysByYear)

      setHolidaysDate(organizedHolidays)
    }
  }, [holidaysData])

  const MonthCalendar: React.FC<MonthCalendarProps> = ({
    year,
    month,
    onChange,
    holidays,
  }) => {
    const handleChange = (
      date: Date | null,
      event: React.SyntheticEvent<any, Event> | undefined,
    ) => {
      if (date) onChange(date, month, year)
    }

    return (
      <DatePicker
        theme={'holydays'}
        name={`month-${month}`}
        onChange={handleChange}
        format="MM.dd.yyyy"
        disabledKeyboardNavigation
        holidays={holidays}
        inline
        minDate={new Date(year, month, 1)}
        maxDate={new Date(year, month + 1, 0)}
      />
    )
  }

  const handleDateChange = (
    selectedDate: Date,
    month: number,
    year: number,
  ) => {
    const formattedDate = moment(selectedDate).format('YYYY-MM-DD')
    const yearHolidays = holidaysDate[year] || []
    const index = yearHolidays.findIndex(
      (holiday) => holiday.date === formattedDate,
    )

    if (index > -1) {
      const { weekend_id } = yearHolidays[index]

      deleteHolyday({
        weekend_id,
        weekend_date: formattedDate,
        successAction: () => null,
      })
      setHolidaysDate({
        ...holidaysDate,
        [year]: yearHolidays.filter((_, i) => i !== index),
      })
    } else {
      setHolidaysDate({
        ...holidaysDate,
        [year]: [...yearHolidays, { date: formattedDate, weekend_id: '' }],
      })

      addHolyday({
        weekend_date: formattedDate,
        successAction: () => null,
      })
    }
  }

  const renderCalendars: JSX.Element[] = useMemo(() => {
    return Array.from({ length: 12 }).map((_, month) => (
      <Box key={month} mb="10px">
        <MonthCalendar
          year={selectedYear}
          month={month}
          onChange={(date) =>
            accessAllowedEdit && handleDateChange(date, month, selectedYear)
          }
          holidays={holidaysDate[selectedYear] || []}
        />
      </Box>
    ))
  }, [selectedYear, holidaysDate, holidaysData])

  if (isLoading) return <Loader size={'md'} />

  return (
    <Center>
      <SimpleGrid
        pt="10px"
        columns={4}
        maxW="890px"
        w="100%"
        maxH="90vh"
        overflowY="auto"
        p="20px"
        pb="50px"
        css={scrollBarStyles}
      >
        {renderCalendars}
      </SimpleGrid>
    </Center>
  )
}
