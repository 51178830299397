import { AuthenticationProps } from 'features/authentication'
import React from 'react'

interface PageScopeContext {
  handleLogout: () => void
  handleSignIn: (data: AuthenticationProps) => void
  isLoading: boolean
}

export const PageScope = React.createContext<PageScopeContext | null>(null)
