import axios, { AxiosPromise } from 'axios'
import MockAdapter from 'axios-mock-adapter'

import { ServiceBase } from '../serviceBase'

const axiosInstance = axios.create()

const mock = new MockAdapter(axiosInstance)

const fakeUsers = [
  {
    userId: '1',
    login: 'user1',
    email: 'user1@example.com',
    position: 'Developer',
    group: 'Group1',
    inheritance: 'None',
  },
  {
    userId: '2',
    login: 'user2',
    email: 'user2@example.com',
    position: 'Manager',
    group: 'Group2',
    inheritance: 'None',
  },
  {
    userId: '3',
    login: 'user3',
    email: 'user3@example.com',
    position: 'Tester',
    group: 'Group3',
    inheritance: 'None',
  },
  {
    userId: '4',
    login: 'user4',
    email: 'user4@example.com',
    position: 'Designer',
    group: 'Group4',
    inheritance: 'None',
  },
  {
    userId: '5',
    login: 'user5',
    email: 'user5@example.com',
    position: 'DevOps',
    group: 'Group5',
    inheritance: 'None',
  },
] as const

mock.onGet('/users').reply((config) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve([200, fakeUsers])
    }, 3000)
  })
})

export class FakeUserService extends ServiceBase {
  protected static BASE_PATH = 'users'

  public static getUsers(): AxiosPromise<typeof fakeUsers> {
    return axiosInstance.get<typeof fakeUsers>(`${this.BASE_PATH}`)
  }
}
