import { useQuery } from 'react-query'

import { SubjectsResponse } from 'shared/models'
import { queryKeys } from 'shared/constants'
import { ReferenceBookService } from 'shared/services'

interface SubjectsData {
  value: string
  label: string
}

interface UseGetSubjectsResult {
  data: SubjectsResponse[] | undefined
  isError: boolean
  isFetching: boolean
  isLoading: boolean
  subjectsData: SubjectsData[] | undefined
}

interface UseGetSubjects {
  enabled: boolean
}

export function useGetAllSubjects({
  enabled,
}: UseGetSubjects): UseGetSubjectsResult {
  const { data, isError, isFetching, isLoading } = useQuery<SubjectsResponse[]>(
    {
      queryKey: [queryKeys.referenceBooks.subjects.allSubjects],
      queryFn: () =>
        ReferenceBookService.getAllSubjects().then((response) => response.data),
      enabled,
      retry: false,
    },
  )

  const subjectsData = data?.map((subject) => ({
    value: subject.subject_id,
    label: subject.region_name,
  }))

  return { data, isError, isFetching, isLoading, subjectsData }
}
