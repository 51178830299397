import React from 'react'

import { DayZonesResponse } from 'shared/models'
import { ListView } from 'shared/ui'
import { DayZonesItem } from './DayZonesItem'

interface PriceDayZonesProps {
  dayZones: DayZonesResponse[]
}

export const DayZonesDataList: React.FC<PriceDayZonesProps> = (props) => {
  const { dayZones } = props

  return (
    <ListView.Lists>
      {dayZones.map((datZone) => (
        <DayZonesItem
          key={datZone.day_zone_id}
          day_zone={datZone.day_zone}
          price_category_id={datZone.day_zone_id}
          code_id={datZone.code_id}
        />
      ))}
    </ListView.Lists>
  )
}
