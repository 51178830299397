// export const formSections = [
//   {
//     title: 'Общая информация',
//     id: 'general_info',
//     fields: [
//       {
//         label: 'Дата регистрации',
//         name: 'general_info.date_registered',
//         type: 'text',
//         variant: 'datePicker',
//         placeholder: 'Дата регистрации',
//         isRequired: true,
//       },
//     ],
//   },
// ]

import { uniqueId } from 'lodash'

export const formSections = [
  {
    title: 'Общая информация',
    id: 'general_info',
    fields: [
      {
        label: 'Краткое наименование',
        name: 'general_info.short_name',
        type: 'text',
        variant: 'default',
        placeholder: 'Краткое название',
        isRequired: true,
      },
      {
        label: 'Полное наименование',
        name: 'general_info.full_name',
        type: 'text',
        variant: 'default',
        placeholder: 'Полное наименование',
        isRequired: true,
      },
      {
        label: 'ИНН',
        name: 'general_info.inn',
        type: 'text',
        mask: '999999999999',
        variant: 'masked',
        placeholder: 'ИНН',
        isRequired: true,
        onChangeFormatter: (inputValue) => inputValue.replace(/\D/g, ''),
      },
      {
        label: 'КПП',
        name: 'general_info.kpp',
        type: 'text',
        mask: '999999999',
        variant: 'masked',
        placeholder: 'КПП',
        isRequired: true,
        onChangeFormatter: (inputValue) => inputValue.replace(/\D/g, ''),
      },
      {
        label: 'ОГРН',
        name: 'general_info.ogrn',
        type: 'text',
        mask: '9999999999999',
        variant: 'masked',
        placeholder: 'ОГРН',
        isRequired: true,
        onChangeFormatter: (inputValue) => inputValue.replace(/\D/g, ''),
      },
    ],
  },
  {
    title: 'Фактический адрес',
    id: 'actual_address',
    fields: [
      {
        label: 'Почтовый индекс',
        name: 'actual_address.postal_code',
        type: 'text',
        mask: '999999',
        variant: 'masked',
        placeholder: 'Почтовый индекс',
        isRequired: true,
        onChangeFormatter: (inputValue) => inputValue.replace(/\D/g, ''),
      },
      {
        label: 'Регион',
        name: 'actual_address.region',
        type: 'text',
        placeholder: 'Регион',
        isRequired: true,
        variant: 'default',
      },
      {
        label: 'Район',
        name: 'actual_address.district',
        type: 'text',
        placeholder: 'Район',
        isRequired: false,
        variant: 'default',
      },
      {
        label: 'Город',
        name: 'actual_address.city',
        type: 'text',
        placeholder: 'Город',
        isRequired: true,
        variant: 'default',
      },
      {
        label: 'Улица',
        name: 'actual_address.street',
        type: 'text',
        placeholder: 'Улица',
        isRequired: true,
        variant: 'default',
      },
      {
        label: 'Номер дома',
        name: 'actual_address.house_number',
        type: 'text',
        placeholder: 'Номер дома',
        isRequired: true,
        variant: 'default',
      },
      {
        label: 'Корпус',
        name: 'actual_address.block',
        type: 'text',
        placeholder: 'Корпус',
        isRequired: false,
        variant: 'default',
      },
      {
        label: 'Строение',
        name: 'actual_address.building',
        type: 'text',
        placeholder: 'Строение',
        isRequired: false,
        variant: 'default',
      },
      {
        label: 'Квартира/офис',
        name: 'actual_address.apartment',
        type: 'text',
        placeholder: 'Квартира/офис',
        isRequired: false,
        variant: 'default',
      },
    ],
  },
  {
    title: 'Юридический адрес',
    id: 'legal_address',
    fields: [
      {
        label: 'Почтовый индекс',
        name: 'legal_address.postal_code',
        type: 'text',
        mask: '999999',
        placeholder: 'Почтовый индекс',
        isRequired: true,
        onChangeFormatter: (inputValue) => inputValue.replace(/\D/g, ''),
        variant: 'masked',
      },
      {
        label: 'Регион',
        name: 'legal_address.region',
        type: 'text',
        placeholder: 'Регион',
        isRequired: true,
        variant: 'default',
      },
      {
        label: 'Район',
        name: 'legal_address.district',
        type: 'text',
        placeholder: 'Район',
        isRequired: false,
        variant: 'default',
      },
      {
        label: 'Город',
        name: 'legal_address.city',
        type: 'text',
        placeholder: 'Город',
        isRequired: true,
        variant: 'default',
      },
      {
        label: 'Улица',
        name: 'legal_address.street',
        type: 'text',
        placeholder: 'Улица',
        isRequired: true,
        variant: 'default',
      },
      {
        label: 'Номер дома',
        name: 'legal_address.house_number',
        type: 'text',
        placeholder: 'Номер дома',
        isRequired: true,
        variant: 'default',
      },
      {
        label: 'Корпус',
        name: 'legal_address.block',
        type: 'text',
        placeholder: 'Корпус',
        isRequired: false,
        variant: 'default',
      },
      {
        label: 'Строение',
        name: 'legal_address.building',
        type: 'text',
        placeholder: 'Строение',
        isRequired: false,
        variant: 'default',
      },
      {
        label: 'Квартира/офис',
        name: 'legal_address.apartment',
        type: 'text',
        placeholder: 'Квартира/офис',
        isRequired: false,
        variant: 'default',
      },
    ],
  },
  {
    title: 'Почтовый адрес',
    id: 'mailing_address',
    fields: [
      {
        label: 'Почтовый индекс',
        name: 'mailing_address.postal_code',
        type: 'text',
        mask: '999999',
        variant: 'masked',
        placeholder: 'Почтовый индекс',
        isRequired: true,
        onChangeFormatter: (inputValue) => inputValue.replace(/\D/g, ''),
      },
      {
        label: 'Регион',
        name: 'mailing_address.region',
        type: 'text',
        placeholder: 'Регион',
        isRequired: true,
        variant: 'default',
      },
      {
        label: 'Район',
        name: 'mailing_address.district',
        type: 'text',
        placeholder: 'Район',
        isRequired: false,
        variant: 'default',
      },
      {
        label: 'Город',
        name: 'mailing_address.city',
        type: 'text',
        placeholder: 'Город',
        isRequired: true,
        variant: 'default',
      },
      {
        label: 'Улица',
        name: 'mailing_address.street',
        type: 'text',
        placeholder: 'Улица',
        isRequired: true,
        variant: 'default',
      },
      {
        label: 'Номер дома',
        name: 'mailing_address.house_number',
        type: 'text',
        placeholder: 'Номер дома',
        isRequired: true,
        variant: 'default',
      },
      {
        label: 'Корпус',
        name: 'mailing_address.block',
        type: 'text',
        placeholder: 'Корпус',
        isRequired: false,
        variant: 'default',
      },
      {
        label: 'Строение',
        name: 'mailing_address.building',
        type: 'text',
        placeholder: 'Строение',
        isRequired: false,
        variant: 'default',
      },
      {
        label: 'Квартира/офис',
        name: 'mailing_address.apartment',
        type: 'text',
        placeholder: 'Квартира/офис',
        isRequired: false,
        variant: 'default',
      },
    ],
  },
  {
    title: 'Банковские реквизиты',
    id: 'bank_details',
    fields: [
      {
        label: 'Расчетный счет',
        name: 'bank_details.account_number',
        type: 'text',
        mask: '99999999999999999999',
        placeholder: 'Расчетный счет',
        isRequired: true,
        onChangeFormatter: (inputValue) => inputValue.replace(/\D/g, ''),
        variant: 'masked',
      },
      {
        empty: true,
        name: uniqueId(),
      },
      {
        label: 'Название банка',
        name: 'bank_details.bank_name',
        type: 'text',
        placeholder: 'Название банка',
        isRequired: true,
        variant: 'default',
      },
      {
        empty: true,
        name: uniqueId(),
      },
      {
        label: 'БИК',
        name: 'bank_details.bic',
        type: 'text',
        placeholder: 'БИК',
        isRequired: true,
        variant: 'default',
      },
      {
        label: 'Корреспондентский счет',
        name: 'bank_details.correspondent_account',
        type: 'text',
        mask: '99999999999999999999',
        placeholder: 'Корреспондентский счет',
        isRequired: true,
        onChangeFormatter: (inputValue) => inputValue.replace(/\D/g, ''),
        variant: 'masked',
      },
    ],
  },
  {
    title: 'Контакты',
    id: 'contacts',
    fields: [
      {
        label: 'Электронная почта',
        name: 'contacts.email',
        type: 'email',
        placeholder: 'email',
        isRequired: true,
        variant: 'default',
      },
      {
        label: 'Телефон',
        name: 'contacts.phone_number',
        type: 'text',
        mask: '+7 (999) 999-99-99',
        placeholder: 'Телефон',
        isRequired: true,
        onChangeFormatter: (inputValue) => {
          const processedPhoneNumber = inputValue.replace(/\D/g, '')
          return `+${processedPhoneNumber}`
        },
        variant: 'masked',
      },
    ],
  },
  {
    title: 'Руководитель',
    id: 'director',
    fields: [
      {
        label: 'Должность',
        name: 'director.director_position',
        type: 'text',
        placeholder: 'Должность',
        isRequired: true,
        variant: 'default',
      },
      {
        label: 'Действует на основании',
        name: 'director.basis_of_action',
        type: 'text',
        placeholder: 'Основание',
        isRequired: true,
        variant: 'default',
      },
      {
        label: 'Фамилия',
        name: 'director.director_last_name',
        type: 'text',
        placeholder: 'Фамилия',
        isRequired: true,
        variant: 'default',
      },
      {
        empty: true,
        name: uniqueId(),
      },
      {
        label: 'Имя',
        name: 'director.director_first_name',
        type: 'text',
        placeholder: 'Имя',
        isRequired: true,
        variant: 'default',
      },
      {
        empty: true,
        name: uniqueId(),
      },
      {
        label: 'Отчество',
        name: 'director.director_middle_name',
        type: 'text',
        placeholder: 'Отчество',
        isRequired: true,
        variant: 'default',
      },
    ],
  },
]
