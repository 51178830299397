import { useQuery } from 'react-query'

import { CompaniesResponse } from 'shared/models'
import { queryKeys } from 'shared/constants'
import { CompaniesService } from 'shared/services'

interface UseGetCompaniesResult {
  data: CompaniesResponse[] | undefined
  isError: boolean
  isFetching: boolean
  isLoading: boolean
  editableCompaniesData: CompaniesSelectOption[] | undefined
}

interface CompaniesSelectOption {
  value: string
  label: string
}

interface UseGetEditableCompanies {
  enabled: boolean
}

export function useGetEditableCompanies({
  enabled,
}: UseGetEditableCompanies): UseGetCompaniesResult {
  const { data, isError, isFetching, isLoading } = useQuery<
    CompaniesResponse[]
  >({
    queryKey: [queryKeys.companies.editableCompanies],
    queryFn: () =>
      CompaniesService.getUserEditableCompanies().then(
        (response) => response.data,
      ),
    enabled,
    retry: false,
  })

  const editableCompaniesData = data
    ? data.map((company) => ({
        value: company.company_id,
        label: company.short_name,
      }))
    : []

  return { data, isError, isFetching, editableCompaniesData, isLoading }
}
