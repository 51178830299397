import { useQuery } from 'react-query'

import { DayZonesResponse } from 'shared/models'
import { queryKeys } from 'shared/constants'
import { PriceCategoriesService } from 'shared/services'

interface DayZonesData {
  value: string
  label: string
}

interface UseGetDayZonesResult {
  data: DayZonesResponse[] | undefined
  isError: boolean
  isFetching: boolean
  isLoading: boolean
  dayZonesData: DayZonesData[] | undefined
}

interface UseAllDayZones {
  enabled: boolean
}

export function useGetAllDayZones({
  enabled,
}: UseAllDayZones): UseGetDayZonesResult {
  const { data, isError, isFetching, isLoading } = useQuery<DayZonesResponse[]>(
    {
      queryKey: [queryKeys.priceCategories.dayZones.allDayZones],
      queryFn: () =>
        PriceCategoriesService.getDayZones().then((response) => response.data),
      enabled,
      retry: false,
    },
  )

  const dayZonesData = data?.map((zone) => ({
    value: zone.day_zone_id,
    label: zone.day_zone,
  }))

  return { data, isError, isFetching, isLoading, dayZonesData }
}
