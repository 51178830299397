import React, { useState, useRef, useEffect } from 'react'

import moment from 'moment'
import Calendar from 'react-datepicker'
import ru from 'date-fns/locale/ru'
import { format } from 'date-fns'
import { getYear, getMonth, Locale } from 'date-fns'
import { Portal } from 'react-overlays'
import MaskedTextInput from 'react-text-mask'
import clsx from 'clsx'

import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Button,
  SimpleGrid,
  Box,
  Flex,
  Text,
  Input,
  IconButton,
} from '@chakra-ui/react'

import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  Path,
  UseFormRegister,
} from 'react-hook-form'

import { Tooltip } from 'shared/ui'

// misc
import { AiOutlineCheck } from 'react-icons/ai'
import { IoIosWarning } from 'react-icons/io'
import { CloseIcon } from '@chakra-ui/icons'
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi2'
import { FaChevronLeft } from 'react-icons/fa6'
import { FaChevronRight } from 'react-icons/fa6'

type MaskArray = (RegExp | string)[]

interface YearGridProps {
  selectedYear: number
  onSelect: (year: number) => void
}

interface MonthGridProps {
  selectedMonth: number
  onSelect: (monthIndex: number) => void
}

const classes = {
  holydays: 'holydaysWrapper',
  dates: 'datesWrapper',
  monthPicker: 'monthPicker',
}

interface DatePickerInputProps<T> {
  control: Control<T>
  register: UseFormRegister<T>
  errors: FieldErrors<T>
  watchedFields: T
  name: Path<T>
  placeholder: string
  type: string
  isRequired: boolean
  onChangeFormatter?: (inputValue: string) => string
  showMonthYearPicker?: boolean
  showFullMonthYearPicker?: boolean
  showTimeSelect?: boolean
  inline?: boolean
  isClearable?: boolean
  format?: string
  theme?: 'holydays' | 'dates' | 'monthPicker'
  minDate?: Date
  maxDate?: Date
  isReadOnly?: boolean
  size?: 'sm' | 'md' | 'xs'
  mask?: string | MaskArray
  smallErrorTextInside?: boolean
  years?: number[]
  months?: string[]
}

interface ICalendarContainer {
  children: React.ReactNode
  theme?: 'holydays' | 'dates' | 'monthPicker'
}

const CalendarContainer: React.FC<ICalendarContainer> = ({
  children,
  theme,
}) => {
  const className = classes[theme] || classes.holydays
  const el = document.getElementById('calendar-portal')

  return (
    <Portal container={el}>
      <div className={clsx(className)}>{children}</div>
    </Portal>
  )
}

const getStyles = {
  md: {
    fontSize: '22px',
    errorFontSize: '12px',
    successIconTop: '3px',
    successBoxShadow: '0 0 0 1px #04bcb0b6',
    errorBoxShadow: '0 0 0 1px #04bcb0b6',
    errorIconTop: '9px',
    errorRight: '10px',
  },
  sm: {
    fontSize: '20px',
    errorFontSize: '10px',
    successIconTop: '10px',
    successBoxShadow: '0 0 0 1px #04bcb0b6',
    errorBoxShadow: '0 0 0 1px #04bcb0b6',
    errorIconTop: '6px',
    errorRight: '10px',
  },
  xs: {
    fontSize: '18px',
    errorFontSize: '10px',
    successIconTop: '5px',
    successBoxShadow: 'none',
    errorBoxShadow: 'none',
    errorIconTop: '3px',
    errorRight: '7px',
  },
}

const getNestedValue = (object, path) =>
  path.split('.').reduce((o, p) => o?.[p], object)

const generateYearRange = (startYear: number, endYear: number): number[] => {
  const years: number[] = []
  for (let year = startYear; year <= endYear; year++) {
    years.push(year)
  }
  return years
}
const yearsRange: number[] = generateYearRange(2005, 2050)

const capitalizeFirstLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1)

const getMonthsArray = (locale: Locale): string[] => {
  let months: string[] = []
  for (let month = 0; month < 12; month++) {
    const monthName = format(new Date(2020, month), 'LLLL', { locale })
    months.push(capitalizeFirstLetter(monthName))
  }
  return months
}

const russianMonths = getMonthsArray(ru)

const months = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
]

const YearGrid: React.FC<YearGridProps> = ({ selectedYear, onSelect }) => {
  const yearRefs = useRef([])
  yearRefs.current = []
  const currentYear = new Date().getFullYear()
  const addToRefs = (el) => {
    if (el && !yearRefs.current.includes(el)) {
      yearRefs.current.push(el)
    }
  }

  useEffect(() => {
    const selectedYearIndex = yearsRange.indexOf(selectedYear)
    const selectedYearRef = yearRefs.current[selectedYearIndex]
    if (selectedYearRef) {
      selectedYearRef.scrollIntoView({
        // behavior: 'smooth',
        block: 'center',
      })
    }
  }, [selectedYear])

  return (
    <SimpleGrid
      columns={4}
      spacing={1}
      overflowY="auto"
      maxH="220px"
      p={2}
      className="custom-scrollbar"
    >
      {yearsRange.map((year) => (
        <Box
          ref={addToRefs}
          key={year}
          p={1}
          bg={year === selectedYear ? '#2c5282' : 'gray.50'}
          color={year === selectedYear ? 'white' : 'black'}
          textAlign="center"
          borderRadius="md"
          cursor="pointer"
          position="relative"
          border="2px solid transparent"
          _hover={{
            border: '2px solid #2c5282',
          }}
          onClick={() => {
            onSelect(year)
          }}
        >
          {year}
          {year === currentYear && (
            <Box
              position="absolute"
              top="1"
              right="1"
              bg="green.500"
              borderRadius="full"
              w="8px"
              h="8px"
            />
          )}
        </Box>
      ))}
    </SimpleGrid>
  )
}

const MonthGrid: React.FC<MonthGridProps> = ({ selectedMonth, onSelect }) => {
  const currentMonth = new Date().getMonth()

  return (
    <SimpleGrid
      columns={3}
      spacing={1}
      overflowY="auto"
      maxH="220px"
      p={2}
      className="custom-scrollbar"
    >
      {months.map((month, index) => (
        <Box
          key={month}
          p={1}
          bg={index === selectedMonth ? '#2c5282' : 'gray.50'}
          color={index === selectedMonth ? 'white' : 'black'}
          textAlign="center"
          borderRadius="md"
          cursor="pointer"
          border="2px solid transparent"
          position="relative"
          _hover={{
            border: '2px solid #2c5282',
          }}
          onClick={() => {
            onSelect(index)
          }}
        >
          {month}
        </Box>
      ))}
    </SimpleGrid>
  )
}

export const DatePickerField = <TFieldValues extends FieldValues>(
  props: DatePickerInputProps<TFieldValues>,
) => {
  const {
    control,
    name,
    placeholder = '',
    register,
    errors,
    watchedFields,
    isRequired,
    theme,
    format = 'dd.MM.yyyy',
    mask = [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/],
    smallErrorTextInside = false,
    showMonthYearPicker = false,
    showFullMonthYearPicker = false,
    showTimeSelect = false,
    isClearable = false,
    inline = false,
    isReadOnly = false,
    minDate = null,
    maxDate = null,
    size = 'xs',
    type = 'text',
    years = yearsRange,
    months = russianMonths,
    ...rest
  } = props

  const ref = useRef(null)

  const [yearPopoverOpen, setYearPopoverOpen] = useState<boolean>(false)
  const [monthPopoverOpen, setMonthPopoverOpen] = useState<boolean>(false)

  const className = classes[theme] || classes.holydays

  const handleCalendarClose = () => {
    setYearPopoverOpen(false)
    setMonthPopoverOpen(false)
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const { onChange, value } = field
        const { ref: formRef, onBlur } = register(name)
        const fieldValue = getNestedValue(watchedFields, name)
        const fieldError = getNestedValue(errors, name)
        const isTouched =
          fieldValue !== undefined && fieldValue !== '' && fieldValue !== null
        const isValid = isTouched && !fieldError

        return (
          <div className={clsx(className)}>
            <Calendar
              onCalendarClose={handleCalendarClose}
              disabledKeyboardNavigation
              showMonthYearPicker={showMonthYearPicker}
              showFullMonthYearPicker={showFullMonthYearPicker}
              selected={(value && new Date(value)) || null}
              startDate={moment().toDate()}
              onChange={onChange}
              renderCustomHeader={({
                date,
                changeYear,
                changeMonth,
                decreaseMonth,
                increaseMonth,
                increaseYear,
                decreaseYear,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
                prevYearButtonDisabled,
                nextYearButtonDisabled,
              }) => (
                <Flex justifyContent="center" h={'100%'} alignItems={'center'}>
                  <Flex
                    w={'100%'}
                    justifyContent={'space-between'}
                    p={'0 10px'}
                  >
                    <Flex>
                      <Flex
                        position={'relative'}
                        alignItems={'center'}
                        mr={'10px'}
                      >
                        {/* <Box
                      onClick={() => {
                        const newDate = new Date(date)
                        newDate.setFullYear(newDate.getFullYear() - 1)
                        changeYear(newDate.getFullYear())
                        onChange(newDate)
                      }}
                      color="#b6b8b9"
                      _hover={{ color: '#2c5282' }}
                      transition={'color 0.2s'}
                      padding={'5px'}
                      cursor="pointer"
                      fontSize={'18px'}
                    >
                      <FaChevronLeft />
                    </Box> */}
                        <Popover
                          isOpen={yearPopoverOpen}
                          onClose={() => setYearPopoverOpen(false)}
                        >
                          <PopoverTrigger>
                            <Button
                              w="70px"
                              size="sm"
                              onClick={() => setYearPopoverOpen(true)}
                            >
                              {getYear(date)}
                            </Button>
                          </PopoverTrigger>
                          <PopoverContent
                            shadow={'lg'}
                            style={{
                              position: 'absolute',
                              top: '-130px',
                              left: '-90px',
                            }}
                          >
                            <PopoverBody p={'0'} m={'0'}>
                              <IconButton
                                zIndex={10}
                                rounded={'full'}
                                colorScheme="red"
                                fontSize="8px"
                                aria-label="Close popover"
                                icon={<CloseIcon />}
                                size="xs"
                                position="absolute"
                                right="-2"
                                top="-2"
                                onClick={() => setYearPopoverOpen(false)}
                              />
                              <YearGrid
                                selectedYear={getYear(date)}
                                onSelect={(year) => {
                                  const newDate = new Date(
                                    date.setFullYear(year),
                                  )
                                  changeYear(year)
                                  onChange(newDate)
                                  setYearPopoverOpen(false)
                                }}
                              />
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>

                        {/* <Box
                      onClick={() => {
                        const newDate = new Date(date)
                        newDate.setFullYear(newDate.getFullYear() + 1)
                        changeYear(newDate.getFullYear())
                        onChange(newDate)
                      }}
                      color="#b6b8b9"
                      _hover={{ color: '#2c5282' }}
                      transition={'color 0.2s'}
                      padding={'5px'}
                      cursor="pointer"
                      fontSize={'18px'}
                    >
                      <FaChevronRight />
                    </Box> */}
                      </Flex>
                      <Flex position={'relative'} alignItems={'center'}>
                        <Popover
                          isOpen={monthPopoverOpen}
                          onClose={() => setMonthPopoverOpen(false)}
                        >
                          <PopoverTrigger>
                            <Button
                              w="70px"
                              size="sm"
                              onClick={() => setMonthPopoverOpen(true)}
                            >
                              {months[getMonth(date)]}
                            </Button>
                          </PopoverTrigger>
                          <PopoverContent
                            shadow={'lg'}
                            style={{
                              position: 'absolute',
                              top: '-130px',
                              left: '-157px',
                            }}
                          >
                            <PopoverBody p={'0'} m={'0'}>
                              <IconButton
                                zIndex={10}
                                rounded={'full'}
                                fontSize="8px"
                                colorScheme="red"
                                aria-label="Close popover"
                                icon={<CloseIcon />}
                                size="xs"
                                position="absolute"
                                right="-2"
                                top="-2"
                                onClick={() => setMonthPopoverOpen(false)}
                              />
                              <MonthGrid
                                selectedMonth={getMonth(date)}
                                onSelect={(monthIndex) => {
                                  const newDate = new Date(
                                    date.setMonth(monthIndex),
                                  )
                                  changeMonth(monthIndex)
                                  onChange(newDate)
                                  setMonthPopoverOpen(false)
                                }}
                              />
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>
                      </Flex>
                    </Flex>

                    <Flex alignItems={'center'}>
                      <Box
                        onClick={() => {
                          const newDate = new Date(date)
                          newDate.setMonth(newDate.getMonth() - 1)
                          changeMonth(newDate.getMonth())
                          onChange(newDate)
                        }}
                        color="#b6b8b9"
                        _hover={{ color: '#2c5282' }}
                        transition={'color 0.2s'}
                        padding={'7px'}
                        cursor="pointer"
                        fontSize={'14px'}
                      >
                        <FaChevronLeft />
                      </Box>

                      <Box
                        onClick={() => {
                          const newDate = new Date(date)
                          newDate.setMonth(newDate.getMonth() + 1)
                          changeMonth(newDate.getMonth())
                          onChange(newDate)
                        }}
                        color="#b6b8b9"
                        _hover={{ color: '#2c5282' }}
                        transition={'color 0.2s'}
                        padding={'7px'}
                        cursor="pointer"
                        fontSize={'14px'}
                      >
                        <FaChevronRight />
                      </Box>
                    </Flex>
                  </Flex>
                </Flex>
              )}
              customInput={
                <MaskedTextInput
                  placeholder={placeholder}
                  type="text"
                  mask={mask}
                  render={(ref, props) => {
                    return (
                      <Flex
                        flexDirection={'column'}
                        position="relative"
                        w={'100%'}
                      >
                        <Input
                          isReadOnly={isReadOnly}
                          isInvalid={!!fieldError}
                          size={size}
                          borderRadius={'6px'}
                          onBlur={onBlur}
                          mr="5px"
                          w="100%"
                          textOverflow={'ellipsis'}
                          ref={ref}
                          borderColor={isValid ? '#16b164' : '#e2e8f0'}
                          boxShadow={
                            isValid ? getStyles[size].successBoxShadow : 'none'
                          }
                          {...props}
                          placeholder={placeholder}
                        />
                        {isRequired && !isTouched && (
                          <Box
                            position="absolute"
                            right={getStyles[size].errorRight}
                            top={getStyles[size].errorIconTop}
                            color="gray.400"
                            fontSize={getStyles[size].fontSize}
                          >
                            <Tooltip
                              id={`${name}-${placeholder}-warning`}
                              content="Данное поле является обязательным для заполнения"
                              place="top"
                            >
                              <Box
                                w={size === 'sm' ? '14px' : '18px'}
                                color={fieldError ? 'red.500' : '#e5e6e5'}
                              >
                                <IoIosWarning />
                              </Box>
                            </Tooltip>
                          </Box>
                        )}
                        {fieldError && smallErrorTextInside && (
                          <Flex
                            alignItems="center"
                            color="red.600"
                            position={'absolute'}
                            top={'-6px'}
                            left={'5px'}
                            background={'#fff'}
                            borderRadius={'20px'}
                            paddingRight={'5px'}
                            zIndex={'9'}
                          >
                            <Text
                              fontSize={getStyles[size].errorFontSize}
                              fontWeight={'700'}
                              ml="5px"
                              lineHeight={'11px'}
                              whiteSpace={'nowrap'}
                            >
                              {fieldError.message}
                            </Text>
                          </Flex>
                        )}
                        {isValid && isRequired && (
                          <Flex
                            alignItems="center"
                            color="green.600"
                            position={'absolute'}
                            right={'6px'}
                            top={getStyles[size].successIconTop}
                          >
                            <Tooltip
                              id={`${name}-${placeholder}-success`}
                              content="Данное поле успешно прошло валидацию"
                              place="top"
                            >
                              <AiOutlineCheck />
                            </Tooltip>
                          </Flex>
                        )}
                      </Flex>
                    )
                  }}
                />
              }
              dateFormat={format}
              {...rest}
              {...field}
              showPopperArrow={false}
              locale={ru}
              shouldCloseOnSelect
              isClearable
              popperContainer={({ children }) => (
                <CalendarContainer theme={theme} children={children} />
              )}
            />
          </div>
        )
      }}
    />
  )
}
