import React from 'react'
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  Path,
  UseFormRegister,
} from 'react-hook-form'
import InputMask from 'react-input-mask'
import { FormInputControl } from '../FormInputControl'

interface MaskedInputProps<T> {
  control: Control<T>
  register: UseFormRegister<T>
  errors: FieldErrors<T>
  watchedFields: T
  mask: string
  name: Path<T>
  placeholder: string
  type: string
  isRequired: boolean
  onChangeFormatter?: (inputValue: string) => string
  isReadOnly?: boolean
  size?: 'sm' | 'md'
  smallErrorTextInside?: boolean
}

const defaultFormatter = (inputValue: string) => inputValue

export const MaskedInput = <TFieldValues extends FieldValues>({
  control,
  name,
  mask,
  placeholder,
  register,
  errors,
  watchedFields,
  isRequired,
  smallErrorTextInside = false,
  isReadOnly = false,
  size = 'md',
  type = 'text',
  onChangeFormatter = defaultFormatter,
}: MaskedInputProps<TFieldValues>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, value } }) => (
        <InputMask
          mask={mask}
          value={value || ''}
          isReadOnly={isReadOnly}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            onChange(onChangeFormatter(e.target.value))
          }}
          onBlur={onBlur}
        >
          {(inputProps) => (
            <FormInputControl
              {...inputProps}
              name={name}
              register={register}
              errors={errors}
              watchedFields={watchedFields}
              type={type}
              smallErrorTextInside={smallErrorTextInside}
              size={size}
              isReadOnly={isReadOnly}
              placeholder={placeholder}
              isRequired={isRequired}
            />
          )}
        </InputMask>
      )}
    />
  )
}
