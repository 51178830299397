import { Box, Button, Flex, useDisclosure, Text } from '@chakra-ui/react'

import { BaseModal } from 'shared/ui'
import { useDeleteEnergySystem } from '../models/services/deleteEnergySystemService'

// misc
import warningLogo from 'assets/svg/warning_logo.svg'
import { HiOutlineArchiveBoxXMark } from 'react-icons/hi2'

interface DeleteSubjectProps {
  ue_system_id: string
  system_name: string
}

export const DeleteEnergySystem: React.FC<DeleteSubjectProps> = (props) => {
  const { ue_system_id, system_name } = props

  const { isOpen, onOpen, onClose } = useDisclosure()

  const { mutate: deleteEnergySystem, isLoading } = useDeleteEnergySystem()

  const handleCloseModal = () => onClose()

  const handleDeleteEnergySystem = () => {
    deleteEnergySystem({
      ue_system_id,
      system_name,
      successAction: () => onClose(),
    })
  }

  return (
    <div>
      <Button
        isDisabled={isLoading}
        isLoading={isLoading}
        colorScheme={'red'}
        size={'xs'}
        color="white"
        display={'flex'}
        alignItems={'center'}
        onClick={() => onOpen()}
      >
        Удалить
        <Box fontSize={'18px'} ml={'7px'}>
          <HiOutlineArchiveBoxXMark />
        </Box>
      </Button>
      <BaseModal
        header="Удаление ОЭС"
        id="delete user"
        isOpen={isOpen}
        onClose={handleCloseModal}
      >
        <Box>
          <Flex justifyContent={'center'}>
            <Box w={'50px'} mb={'15px'}>
              <img src={warningLogo} alt="Warning" />
            </Box>
          </Flex>
          <Text
            fontWeight={'800'}
            mb={'5px'}
            color={'red.500'}
            textAlign={'center'}
          >
            Отмеченная ОЭС будет удалена
          </Text>
          <Text fontWeight={'600'} mb={'5px'} textAlign={'center'}>
            Вы действительно хотите удалить следующую ОЭС <b>{system_name}</b>?
          </Text>
        </Box>
        <Flex justifyContent={'flex-end'} mt="35px">
          <Button fontWeight={'500'} mr="15px" onClick={onClose}>
            Отмена
          </Button>
          <Button
            fontWeight={'500'}
            onClick={handleDeleteEnergySystem}
            colorScheme={'red'}
            isDisabled={isLoading}
            isLoading={isLoading}
          >
            Удалить
          </Button>
        </Flex>
      </BaseModal>
    </div>
  )
}
