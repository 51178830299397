import ReactDOM from 'react-dom/client'
import 'regenerator-runtime/runtime'

import App from 'app/AppEntry'

import 'shared/styles/global.scss'

ReactDOM.createRoot(document.getElementById('root')!).render(
  <>
    <App />
  </>,
)
