import { AxiosError } from 'axios'
import { UseMutateFunction, useMutation, useQueryClient } from 'react-query'

import { queryKeys } from 'shared/constants'
import { ExtendedAxiosError } from 'shared/models'
import { ReferenceBookService } from 'shared/services'
import { toasts } from 'shared/utils'

interface DeleteEnergySystem {
  ue_system_id: string
  system_name: string
  successAction: () => void
}
export function useDeleteEnergySystem(): {
  mutate: UseMutateFunction<void, AxiosError, DeleteEnergySystem, unknown>
  isLoading: boolean
} {
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation<
    void,
    AxiosError,
    DeleteEnergySystem
  >(
    ({ ue_system_id }: DeleteEnergySystem) =>
      ReferenceBookService.deleteEnergySystem({ ue_system_id }).then(() => {}),
    {
      onSuccess: (_, variables: DeleteEnergySystem) => {
        if (variables.successAction) variables.successAction()

        toasts.success({
          title: 'ОЭС удалена',
          description: `ОЭС ${variables.system_name}  успешно удалена`,
        })
        queryClient.invalidateQueries([
          queryKeys.referenceBooks.energySystems.allEnergySystems,
        ])
      },
      onError: (error: ExtendedAxiosError) => {
        toasts.error({
          title: 'Произошла ошибка',
          description: error.response.data.detail || 'ОЭС не был удалена',
        })
      },
    },
  )

  return { mutate, isLoading }
}
