import { useQuery } from 'react-query'

import { DetailsCalculationPriceResponse } from 'shared/models'
import { queryKeys } from 'shared/constants'
import { PriceCategoriesService } from 'shared/services'

interface DetailsCalculationData {
  value: string
  label: string
}

interface UseGetDetailsCalculationPriceResult {
  data: DetailsCalculationPriceResponse[] | undefined
  isError: boolean
  isFetching: boolean
  isLoading: boolean
  detailsCalculationData: DetailsCalculationData[] | undefined
}

interface UseAllDetailsCalculation {
  enabled: boolean
}

export function useGetAllDetailsCalculation({
  enabled,
}: UseAllDetailsCalculation): UseGetDetailsCalculationPriceResult {
  const { data, isError, isFetching, isLoading } = useQuery<
    DetailsCalculationPriceResponse[]
  >({
    queryKey: [
      queryKeys.priceCategories.detailsCalculationPrice
        .allDetailsCalculationPrice,
    ],
    queryFn: () =>
      PriceCategoriesService.getDetailsCalculationPrice().then(
        (response) => response.data,
      ),
    enabled,
    retry: false,
  })

  const detailsCalculationData = data?.map((details) => ({
    value: details.details_calculation_pc_id,
    label: details.details_calculation,
  }))

  return { data, isError, isFetching, isLoading, detailsCalculationData }
}
