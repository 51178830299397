import { useQuery } from 'react-query'

import { PriceCategoriesResponse } from 'shared/models'
import { queryKeys } from 'shared/constants'
import { PriceCategoriesService } from 'shared/services'

interface PriceCategoriesData {
  value: string
  label: string
}

interface UseGetPriceCategoriesResult {
  data: PriceCategoriesResponse[] | undefined
  isError: boolean
  isFetching: boolean
  isLoading: boolean
  priceCategoriesData: PriceCategoriesData[] | undefined
}

interface UseAllPriceCategories {
  enabled: boolean
}

export function useGetAllPriceCategories({
  enabled,
}: UseAllPriceCategories): UseGetPriceCategoriesResult {
  const { data, isError, isFetching, isLoading } = useQuery<
    PriceCategoriesResponse[]
  >({
    queryKey: [queryKeys.priceCategories.priceCategories.allPriceCategories],
    queryFn: () =>
      PriceCategoriesService.getAllPriceCategories().then(
        (response) => response.data,
      ),
    enabled,
    retry: false,
  })

  const priceCategoriesData = data?.map((zone) => ({
    value: zone.price_category_id,
    label: zone.category,
  }))

  return { data, isError, isFetching, isLoading, priceCategoriesData }
}
