import { Box, Button, Flex, useDisclosure, Text } from '@chakra-ui/react'

import { BaseModal } from 'shared/ui'
import { useDeletePriceZone } from '../models/services/deletePriceZoneService'

// misc
import warningLogo from 'assets/svg/warning_logo.svg'
import { HiOutlineArchiveBoxXMark } from 'react-icons/hi2'

interface DeletePriceZoneProps {
  price_zone_id: string
  zone_name: string
}

export const DeletePriceZone: React.FC<DeletePriceZoneProps> = (props) => {
  const { price_zone_id, zone_name } = props

  const { isOpen, onOpen, onClose } = useDisclosure()

  const { mutate: deleteEnergySystem, isLoading } = useDeletePriceZone()

  const handleCloseModal = () => onClose()

  const handleDeletePriceZone = () => {
    deleteEnergySystem({
      price_zone_id,
      zone_name,
      successAction: () => onClose(),
    })
  }

  return (
    <div>
      <Button
        isDisabled={isLoading}
        isLoading={isLoading}
        colorScheme={'red'}
        size={'xs'}
        color="white"
        display={'flex'}
        alignItems={'center'}
        onClick={() => onOpen()}
      >
        Удалить
        <Box fontSize={'18px'} ml={'7px'}>
          <HiOutlineArchiveBoxXMark />
        </Box>
      </Button>
      <BaseModal
        header="Удаление ценовой зоны"
        id="delete user"
        isOpen={isOpen}
        onClose={handleCloseModal}
      >
        <Box>
          <Flex justifyContent={'center'}>
            <Box w={'50px'} mb={'15px'}>
              <img src={warningLogo} alt="Warning" />
            </Box>
          </Flex>
          <Text
            fontWeight={'800'}
            mb={'5px'}
            color={'red.500'}
            textAlign={'center'}
          >
            Отмеченная ценовая зона будет удалена
          </Text>
          <Text fontWeight={'600'} mb={'5px'} textAlign={'center'}>
            Вы действительно хотите удалить следующую ценовую зону{' '}
            <b>{zone_name}</b>?
          </Text>
        </Box>
        <Flex justifyContent={'flex-end'} mt="35px">
          <Button fontWeight={'500'} mr="15px" onClick={onClose}>
            Отмена
          </Button>
          <Button
            fontWeight={'500'}
            onClick={handleDeletePriceZone}
            colorScheme={'red'}
            isDisabled={isLoading}
            isLoading={isLoading}
          >
            Удалить
          </Button>
        </Flex>
      </BaseModal>
    </div>
  )
}
