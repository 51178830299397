import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

export interface EditActivityFormData {
  area_name?: string
  price_zone_id?: string
  ue_system_id?: string
  subject_id?: string
  time_zone?: number
}

const SCHEMA_VALIDATION = yup.object().shape({
  area_name: yup.string().required('Имя ГП обязательное поле'),
  price_zone_id: yup.string().required('Ценовая зона обязательное поле'),
  ue_system_id: yup.string().required('ОЭС  обязательное поле'),
  subject_id: yup.string().required('Субьет РФ обязательное поле'),
  // time_zone: yup.string().required('Часовой пояс обязательное поле'),
  time_zone: yup
    .number()
    .typeError('Часовой пояс обязательное поле')
    .integer('Часовой пояс должен быть целым числом')
    .max(14, 'Часовой сдвиг не может быть больше 14'),
})

export const useEditActivityForm = () => {
  const {
    setValue,
    register,
    handleSubmit,
    formState,
    resetField,
    reset,
    control,
    watch,
  } = useForm<EditActivityFormData>({
    resolver: yupResolver(SCHEMA_VALIDATION),
    mode: 'onChange',
  })

  const watchedFields = watch()

  return {
    reset,
    register,
    handleSubmit,
    errors: formState.errors,
    isDirty: formState.isDirty,
    isValid: formState.isValid,
    isSubmitting: formState.isSubmitting,
    control,
    resetField,
    setValue,
    watchedFields,
  }
}
