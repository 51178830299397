import { AxiosError } from 'axios'
import { UseMutateFunction, useMutation } from 'react-query'

import { UserService } from 'shared/services'
import { toasts } from 'shared/utils'

interface SetNewPassword {
  password: string
  successAction?: () => void
}
export function useSetNewPassword(): {
  mutate: UseMutateFunction<void, AxiosError, SetNewPassword, unknown>
  isLoading: boolean
} {
  const { mutate, isLoading } = useMutation<void, AxiosError, SetNewPassword>(
    ({ password }: SetNewPassword) =>
      UserService.fetchSetNewPassword(password).then(() => {}),
    {
      onSuccess: (_, variables: SetNewPassword) => {
        if (variables.successAction) variables.successAction()

        toasts.success({
          title: 'Проль изменен',
          description: `Ваш пароль успешно изменен`,
        })
      },
      onError: (error: AxiosError) => {
        toasts.error({
          title: 'Произошла ошибка',
          description: error.message || 'Пароль не был изменен',
        })
      },
    },
  )

  return { mutate, isLoading }
}
