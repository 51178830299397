import { Resolver, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

export interface EditGroupDeliveryFormData {
  date_from?: string
  date_to?: string
  gd_point_names?: { value: string; label: string }[]
}

const SCHEMA_VALIDATION = yup.object().shape({
  date_from: yup.string().required('Дата обязательное поле'),
  gd_point_names: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string().required('Value is required'),
        label: yup.string().required('Label is required'),
      }),
    )
    .required('Добавте хотя бы один ГТП')
    .min(1, 'Добавте хотя бы один ГТП'),
})

export const useEditGroupDeliveryForm = () => {
  const {
    setValue,
    register,
    handleSubmit,
    formState,
    resetField,
    reset,
    control,
    watch,
    getValues,
    setError,
  } = useForm<EditGroupDeliveryFormData>({
    resolver: yupResolver(
      SCHEMA_VALIDATION,
    ) as Resolver<EditGroupDeliveryFormData>,
    mode: 'onChange',
    defaultValues: {
      date_from: '',
      date_to: '',
      gd_point_names: [],
    },
  })

  const watchedFields = watch()

  return {
    reset,
    register,
    handleSubmit,
    errors: formState.errors,
    isDirty: formState.isDirty,
    isValid: formState.isValid,
    isSubmitting: formState.isSubmitting,
    control,
    resetField,
    setValue,
    watchedFields,
    getValues,
    watch,
    setError,
  }
}
