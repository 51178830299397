import React from 'react'
import { Text, Flex } from '@chakra-ui/react'
import { ListView } from 'shared/ui'

interface DetailsCalculationPriceProps {
  details_calculation: string
  details_calculation_pc_id: string
  code_id: number
}

export const DetailsCalculationPriceItem: React.FC<
  DetailsCalculationPriceProps
> = (props) => {
  const { code_id, details_calculation } = props

  return (
    <ListView.Stacks>
      <Flex alignItems={'center'} ml={'5px'}>
        <Text w={'35px'} fontSize={'sm'}>
          {code_id}
        </Text>

        <Text fontSize={'sm'} textAlign={'left'} maxW={'4xl'}>
          {details_calculation}
        </Text>
      </Flex>
    </ListView.Stacks>
  )
}
