import React, { useCallback, useEffect, useState } from 'react'
import { Stack, Text, Button, Flex, IconButton, Box } from '@chakra-ui/react'

import { Access } from 'app/providers'
import { FormInputControl, ListView } from 'shared/ui'
import { accessDict, modulesDict } from 'shared/dictionary'

import {
  EditAdditionalRequisiteFormData,
  useEditAdditionalRequisiteForm,
} from './UseEditAdditionalRequisiteForm'

import { DeleteAdditionalRequisite } from '../../deleteAdditionalRequisite'
import { useUpdateAdditionalRequisite } from '../models/services/useUpdateAdditionalRequisite'

//  misc
import { HiMiniXMark, HiOutlinePencilSquare } from 'react-icons/hi2'

interface PriceZoneItemProps {
  additional_requisite: string
  add_requisite_id: string
  code_id: number
}

export const AdditionalRequisiteItem: React.FC<PriceZoneItemProps> = (
  props,
) => {
  const { code_id, additional_requisite, add_requisite_id } = props
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const {
    mutate: AdditionalRequisite,
    isLoading: isUpdateAdditionalRequisiteLoading,
  } = useUpdateAdditionalRequisite()

  const { register, handleSubmit, errors, reset, isDirty, watchedFields } =
    useEditAdditionalRequisiteForm()

  useEffect(() => {
    if (additional_requisite)
      reset({
        additional_requisite,
      })
  }, [code_id, additional_requisite, additional_requisite, reset])

  const handleEditClick = useCallback(() => {
    if (isEdit) {
      reset()
      setIsEdit(false)
    } else setIsEdit(!isEdit)
  }, [isEdit])

  const onSubmit = ({
    additional_requisite,
  }: EditAdditionalRequisiteFormData) => {
    AdditionalRequisite({
      additional_requisite,
      add_requisite_id,
      successAction: () => setIsEdit(false),
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ListView.Stacks>
        <Flex alignItems={'center'} ml={'5px'}>
          <Text w={'35px'} fontSize={'sm'}>
            {code_id}
          </Text>

          {isEdit ? (
            <Flex mr={'5px'} w={'350px'} alignItems={'center'}>
              <Text
                mr={'5px'}
                color={'#718096'}
                fontSize={'12px'}
                fontWeight={'500'}
              >
                Имя:
              </Text>
              <FormInputControl
                smallErrorTextInside
                size="xs"
                name="additional_requisite"
                register={register}
                errors={errors}
                watchedFields={watchedFields}
                isRequired
                placeholder="Имя реквизита"
                type="text"
              />
            </Flex>
          ) : (
            <Text fontSize={'sm'} textAlign={'left'} maxW={'4xl'}>
              {additional_requisite}
            </Text>
          )}
        </Flex>

        <Flex alignItems={'center'}>
          {isEdit && isDirty && (
            <Box>
              <Flex justifyContent={'flex-start'}>
                <Button
                  mr="5px"
                  size={'xs'}
                  colorScheme="blue"
                  type="submit"
                  isDisabled={isUpdateAdditionalRequisiteLoading}
                  isLoading={isUpdateAdditionalRequisiteLoading}
                >
                  Сохранить
                </Button>
              </Flex>
            </Box>
          )}
          {isEdit && (
            <Box mr={'15px'}>
              <DeleteAdditionalRequisite
                add_requisite_id={add_requisite_id}
                additional_requisite={additional_requisite}
              />
            </Box>
          )}

          <Access
            permissions={[accessDict.update_read]}
            module={modulesDict.reference_books}
          >
            <IconButton
              size="xs"
              aria-label="Edit Activity"
              icon={isEdit ? <HiMiniXMark /> : <HiOutlinePencilSquare />}
              onClick={handleEditClick}
              fontSize="20px"
            />
          </Access>
        </Flex>
      </ListView.Stacks>
    </form>
  )
}
