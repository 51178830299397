import { Box, Text } from '@chakra-ui/react'

//assets
import logoPNG from 'assets/logo/png/OESK-logo.png'

export const CompanyLogo = () => {
  return (
    <Box
      py={2}
      px={6}
      display={'flex'}
      flexDir={'column'}
      alignItems={'center'}
      justifyContent={'center'}
      alignContent={'center'}
    >
      <Box w={'250px'}>
        <img src={logoPNG} alt="Logo" />
      </Box>

      <Text
        fontSize={'36px'}
        fontWeight={'700'}
        mt="30px"
        color="#3435bc"
        textAlign={'center'}
      >
        <span
          style={{ fontSize: '50px', fontWeight: '800', letterSpacing: '5px' }}
        >
          АСПЭ
        </span>
        <br />
        Аналитическая Система Продажи Электроэнергии
      </Text>
    </Box>
  )
}
