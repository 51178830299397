/* eslint-disable react/no-array-index-key */
import { Ref, forwardRef } from 'react'
import { Events, Link } from 'react-scroll'

import './NavigationSidebar.scss'

export interface INavigationProps {
  list: INavigationItem[]
  containerId: string
  onChange?: (to: string) => void
}
export interface INavigationItem {
  title: string
  anchor: string
}

export const NavigationSidebar: React.FC<INavigationProps> = forwardRef<
  HTMLDivElement,
  INavigationProps
>((props: INavigationProps & any, ref) => {
  const { list, containerId, ...rest } = props

  Events.scrollEvent.register('begin', (to, element: Element | null) => {
    rest.onChange(to)
  })

  Events.scrollEvent.register('end', function (to, element: Element | null) {})

  return (
    <div className="navigation-sidebar-wrapper">
      {list.map((item, i) => {
        const { anchor, title } = item

        return (
          <Link
            className="navigation-sidebar-link"
            key={i}
            activeClass="navigation-sidebar-active-link"
            containerId={containerId}
            to={anchor.replace('#', '')}
            spy={true}
            smooth
            duration={300}
            isDynamic
            ref={ref as Ref<HTMLDivElement>}
            {...(rest || {})}
          >
            {title}
          </Link>
        )
      })}
    </div>
  )
})
