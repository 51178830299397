import { Collapse, Box, Flex, Text, Button } from '@chakra-ui/react'

import {
  AddNewTypesContractFormFormData,
  useAddNewTypesContractForm,
} from './UseAddNewTypesContractForm'

import { useAddNewTypesContract } from '../models/services/addAdditionalRequisite'

//  misc
import { FormInputControl, TextTitle } from 'shared/ui'

interface AddNewTypesContractProps {
  isOpen: boolean
  onToggle: () => void
}

export const AddNewTypesContract: React.FC<AddNewTypesContractProps> = (
  props,
) => {
  const { isOpen, onToggle } = props

  const {
    mutate: addNewTypesContract,
    isLoading: isAddNewTypesContractLoading,
  } = useAddNewTypesContract()

  const { register, handleSubmit, errors, reset, isDirty, watchedFields } =
    useAddNewTypesContractForm()

  const onSubmit = ({
    full_name,
    short_name,
  }: AddNewTypesContractFormFormData) => {
    addNewTypesContract({
      full_name,
      short_name,
      successAction: () => {
        onToggle()
        reset()
      },
    })
  }

  const handleResetForm = () => reset()

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Collapse in={isOpen} animateOpacity>
        <Box borderBottom={'1px solid #dfe0eb'}>
          <Box p={'10px'}>
            <TextTitle size={'small'}>
              Добавление нового вида договора
            </TextTitle>
          </Box>
          <Flex p="10px 15px 10px 15px">
            <Flex mr={'5px'} w={'500px'} alignItems={'center'}>
              <Text
                mr={'5px'}
                color={'#718096'}
                fontSize={'12px'}
                fontWeight={'500'}
                w={'150px'}
              >
                Наименование:
              </Text>
              <FormInputControl
                smallErrorTextInside
                size="xs"
                name="full_name"
                register={register}
                errors={errors}
                watchedFields={watchedFields}
                isRequired
                placeholder="Наименование"
                type="text"
              />
            </Flex>
          </Flex>
          <Flex p="0 15px 20px 15px">
            <Flex mr={'5px'} w={'500px'} alignItems={'center'}>
              <Text
                mr={'5px'}
                color={'#718096'}
                fontSize={'12px'}
                fontWeight={'500'}
                w={'150px'}
              >
                Сокращение:
              </Text>
              <FormInputControl
                smallErrorTextInside
                size="xs"
                name="short_name"
                register={register}
                errors={errors}
                watchedFields={watchedFields}
                isRequired
                placeholder="Сокращение"
                type="text"
              />
            </Flex>
          </Flex>
          {isDirty && (
            <Box p="0 0 25px 15px">
              <Flex justifyContent={'flex-start'}>
                <Button mr="5px" onClick={handleResetForm} size={'xs'}>
                  <Flex alignItems={'center'}>Очистить</Flex>
                </Button>
                <Button
                  mr="5px"
                  size={'xs'}
                  colorScheme="blue"
                  type="submit"
                  isDisabled={isAddNewTypesContractLoading}
                  isLoading={isAddNewTypesContractLoading}
                >
                  Сохранить
                </Button>
              </Flex>
            </Box>
          )}
        </Box>
      </Collapse>
    </form>
  )
}
