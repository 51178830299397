import { format } from 'date-fns'
import { ru } from 'date-fns/locale'

interface MonthMap {
  [key: string]: string
}

export function formatDateWithNominativeMonth(date: Date): string {
  const formatted = format(date, 'MMMM yyyy', { locale: ru })
  const monthGenitive = formatted.split(' ')[0]
  const year = formatted.split(' ')[1]

  const nominativeMonths: MonthMap = {
    января: 'Январь',
    февраля: 'Февраль',
    марта: 'Март',
    апреля: 'Апрель',
    мая: 'Май',
    июня: 'Июнь',
    июля: 'Июль',
    августа: 'Aвгуст',
    сентября: 'Сентябрь',
    октября: 'Октябрь',
    ноября: 'Ноябрь',
    декабря: 'Декабрь',
  }

  const monthNominative = nominativeMonths[monthGenitive]
  return `${monthNominative} ${year}`
}
