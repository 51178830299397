import { useQuery } from 'react-query'

import { TypesCounteragentDetailResponse } from 'shared/models'
import { queryKeys } from 'shared/constants'
import { CounteragentsContractsService } from 'shared/services'

interface UseGetDetailTypesContractResult {
  data: TypesCounteragentDetailResponse[] | undefined
  isError: boolean
  isFetching: boolean
  isLoading: boolean
}

interface UseDetailTypesContract {
  enabled: boolean
  type_counteragent_id: string
}

export function useGetDetailTypesContract({
  enabled,
  type_counteragent_id,
}: UseDetailTypesContract): UseGetDetailTypesContractResult {
  const { data, isError, isFetching, isLoading } = useQuery<
    TypesCounteragentDetailResponse[]
  >({
    queryKey: [
      queryKeys.counteragentsContracts.typeCounteragents
        .detailTypesCounteragents,
      type_counteragent_id,
    ],
    queryFn: () =>
      CounteragentsContractsService.getTypesCounteragentDetails({
        type_counteragent_id,
      }).then((response) => response.data),
    enabled,
    retry: false,
  })

  return { data, isError, isFetching, isLoading }
}
