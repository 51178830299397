import { AxiosError, AxiosResponse } from 'axios'
import { UseMutateFunction, useMutation, useQueryClient } from 'react-query'
import { queryKeys } from 'shared/constants'

import { DeleteHolydayRequest } from 'shared/models'
import { ReferenceBookService } from 'shared/services'
import { toasts } from 'shared/utils'

interface DeleteHolydayWithSuccessAction extends DeleteHolydayRequest {
  successAction: () => void
  weekend_date: Date | string
}

export function useDeleteHolyday(): {
  mutate: UseMutateFunction<
    AxiosResponse<undefined>,
    AxiosError,
    DeleteHolydayWithSuccessAction,
    unknown
  >
  isLoading: boolean
} {
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation(
    ({ weekend_id }: DeleteHolydayWithSuccessAction) =>
      ReferenceBookService.deleteHolyday({
        weekend_id,
      }),
    {
      onSuccess: (
        _: AxiosResponse<undefined>,
        variables: DeleteHolydayWithSuccessAction,
      ) => {
        if (variables.successAction) {
          variables.successAction()
          // toasts.success({
          //   title: 'Выходной день удален',
          //   description: `Дата ${variables.weekend_date} успешно установлена как рабочий день`,
          // })
        }

        queryClient.invalidateQueries([queryKeys.holydays.allHolydays])
      },
      onError: () =>
        toasts.error({
          title: 'Произошла ошибка',
          description: 'Выходной день не был удален',
        }),
    },
  )
  return { mutate, isLoading }
}
