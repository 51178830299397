import { AxiosError, AxiosResponse } from 'axios'
import { UseMutateFunction, useMutation } from 'react-query'

import { ResetPasswordRequest, ResetPasswordResponse } from 'shared/models'
import { UserService } from 'shared/services'
import { toasts } from 'shared/utils'

interface UseResetPasswordMutation extends ResetPasswordRequest {
  successAction?: () => void
  errorAction?: () => void
  email: string
}
export function useResetPassword(): {
  mutate: UseMutateFunction<
    AxiosResponse<ResetPasswordResponse>,
    AxiosError,
    UseResetPasswordMutation,
    unknown
  >
  isLoading: boolean
} {
  const { mutate, isLoading } = useMutation(
    ({ email }: UseResetPasswordMutation) =>
      UserService.fetchResetPassword({
        email,
      }),
    {
      onSuccess: (
        data: AxiosResponse<ResetPasswordResponse>,
        { successAction, email }: UseResetPasswordMutation,
      ) => {
        if (successAction && email) {
          successAction()
          toasts.success({
            title: 'Успешно',
            description: `Письмо с инструкциями по восстановлению пароля отправлено на ${email}`,
          })
        }
      },
      onError: (
        error: AxiosError,
        { errorAction, email }: UseResetPasswordMutation,
      ) => {
        errorAction()
        toasts.error({
          title: 'Ошибка',
          description: `Пользователь с e-mail ${email} в системе не зарегистрирован.`,
        })
      },
    },
  )
  return { mutate, isLoading }
}
