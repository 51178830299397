import React, { useEffect } from 'react'

import { Flex, IconButton, Text } from '@chakra-ui/react'

import { localStorageHandler } from 'shared/utils'
import { LOCAL_STORAGE_ENTITY } from 'shared/constants'

//  misc
import { ArrowLeftIcon, ArrowRightIcon } from '@chakra-ui/icons'

interface SelectYearProps {
  selectedYear: number
  maxYear: number
  minYear: number
  setSelectedYear: React.Dispatch<React.SetStateAction<number>>
}

export const SelectYear: React.FC<SelectYearProps> = (props) => {
  const { selectedYear, setSelectedYear, maxYear, minYear } = props

  useEffect(() => {
    const storedYear = localStorageHandler.getFromLocalStorage<number>(
      LOCAL_STORAGE_ENTITY.SELECTED_YEAR,
      selectedYear,
    )
    setSelectedYear(storedYear)
  }, [])

  const handleSetYear = (newYear: number) => {
    setSelectedYear(newYear)
    localStorageHandler.saveToLocalStorage(
      LOCAL_STORAGE_ENTITY.SELECTED_YEAR,
      newYear,
    )
  }

  return (
    <Flex justifyContent={'center'} w={'100%'} alignItems={'center'}>
      <IconButton
        size={'xs'}
        aria-label="left"
        icon={<ArrowLeftIcon />}
        onClick={() => {
          const newYear = selectedYear <= minYear ? minYear : selectedYear - 1
          handleSetYear(newYear)
        }}
      />
      <Text fontSize={'20px'} fontWeight={'800'} ml={'15px'} mr={'15px'}>
        {selectedYear}
      </Text>
      <IconButton
        size={'xs'}
        aria-label="right"
        icon={<ArrowRightIcon />}
        onClick={() => {
          const newYear = selectedYear >= maxYear ? maxYear : selectedYear + 1
          handleSetYear(newYear)
        }}
      />
    </Flex>
  )
}
