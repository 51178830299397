import { useQuery } from 'react-query'

import { PriceATCResponse } from 'shared/models'
import { queryKeys } from 'shared/constants'
import { PriceCategoriesService } from 'shared/services'

interface PriceATCData {
  value: string
  label: string
}

interface UseGetPriceATCResult {
  data: PriceATCResponse[] | undefined
  isError: boolean
  isFetching: boolean
  isLoading: boolean
  priceATCData: PriceATCData[] | undefined
}

interface UseAllTariffTransmission {
  enabled: boolean
}

export function useGetAllPriceATC({
  enabled,
}: UseAllTariffTransmission): UseGetPriceATCResult {
  const { data, isError, isFetching, isLoading } = useQuery<PriceATCResponse[]>(
    {
      queryKey: [queryKeys.priceCategories.priceATC.allPriceATC],
      queryFn: () =>
        PriceCategoriesService.getPriceATC().then((response) => response.data),
      enabled,
      retry: false,
    },
  )

  const priceATCData = data?.map((tariff) => ({
    value: tariff.type_price_id,
    label: tariff.price_full_name,
  }))

  return { data, isError, isFetching, isLoading, priceATCData }
}
