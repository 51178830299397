import { AxiosError, AxiosResponse } from 'axios'
import { UseMutateFunction, useMutation, useQueryClient } from 'react-query'
import { queryKeys } from 'shared/constants'

import { UpdateCompanyResponse } from 'shared/models'
import { CompaniesService, UserService } from 'shared/services'
import { toasts } from 'shared/utils'

export interface Address {
  postal_code: string
  region: string
  city: string
  street: string
  house_number: string
  district?: string
  building?: string
  block?: string
  apartment?: string
}

interface UpdateCompany {
  legal_address: Address
  actual_address: Address
  mailing_address: Address
  short_name: string
  full_name: string
  inn: string
  kpp: string
  ogrn: string
  account_number: string
  bic: string
  bank_name: string
  correspondent_account: string
  email: string
  phone_number: string
  director_position?: string
  director_last_name?: string
  director_first_name?: string
  director_middle_name?: string
  basis_of_action?: string
}

export interface CompanyUpdateProps extends UpdateCompany {
  successAction: () => void
  companyId: string
  companyName: string
}

export function useUpdateCompany(): {
  mutate: UseMutateFunction<
    AxiosResponse<UpdateCompanyResponse>,
    AxiosError,
    CompanyUpdateProps,
    unknown
  >
  isLoading: boolean
} {
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation(
    ({
      director_position,
      director_last_name,
      director_first_name,
      director_middle_name,
      basis_of_action,
      legal_address,
      actual_address,
      mailing_address,
      short_name,
      full_name,
      inn,
      kpp,
      ogrn,
      account_number,
      bic,
      bank_name,
      correspondent_account,
      email,
      phone_number,
      companyId,
      companyName,
    }: CompanyUpdateProps) =>
      CompaniesService.updateCompany({
        companyId,
        data: {
          director_position,
          director_last_name,
          director_first_name,
          director_middle_name,
          basis_of_action,
          legal_address,
          actual_address,
          mailing_address,
          short_name,
          full_name,
          inn,
          kpp,
          ogrn,
          account_number,
          bic,
          bank_name,
          correspondent_account,
          email,
          phone_number,
        },
      }),
    {
      onSuccess: (
        data: AxiosResponse<UpdateCompanyResponse>,
        variables: CompanyUpdateProps,
      ) => {
        if (variables.successAction) {
          variables.successAction()
          toasts.success({
            title: `Данные компании  обновлены`,
            description: `Данные компаии ${variables.companyName} успешно обновлены`,
          })
        }

        queryClient.invalidateQueries([
          queryKeys.companies.companyDetail,
          variables.companyId,
        ])
        // queryClient.invalidateQueries([queryKeys.companies.allCompanies])
        queryClient.invalidateQueries([queryKeys.companies.editableCompanies])
      },
      onError: () =>
        toasts.error({
          title: 'Произошла ошибка',
          description: 'Данные компании не были обновлены',
        }),
    },
  )
  return { mutate, isLoading }
}
