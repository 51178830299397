import { useQuery } from 'react-query'

import { ProductsResponse } from 'shared/models'
import { queryKeys } from 'shared/constants'
import { PriceCategoriesService } from 'shared/services'

interface PriceProductsData {
  value: string
  label: string
}

interface UseGetProductsResult {
  data: ProductsResponse[] | undefined
  isError: boolean
  isFetching: boolean
  isLoading: boolean
  productsData: PriceProductsData[] | undefined
}

interface UseAllProducts {
  enabled: boolean
}

export function useGetAllProducts({
  enabled,
}: UseAllProducts): UseGetProductsResult {
  const { data, isError, isFetching, isLoading } = useQuery<ProductsResponse[]>(
    {
      queryKey: [queryKeys.priceCategories.products.allProducts],
      queryFn: () =>
        PriceCategoriesService.getProducts().then((response) => response.data),
      enabled,
      retry: false,
    },
  )

  const productsData = data?.map((product) => ({
    value: product.product_id,
    label: product.product,
  }))

  return { data, isError, isFetching, isLoading, productsData }
}
