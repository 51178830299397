import { Stack, Box } from '@chakra-ui/react'

interface ListViewOwnPropsType {
  children: React.ReactNode
}

export const Lists: React.FC<ListViewOwnPropsType> = ({ children }) => {
  return (
    <Box border="1px solid #eeeeee" w="1200px">
      {children}
    </Box>
  )
}

export const Stacks: React.FC<ListViewOwnPropsType> = ({ children }) => {
  return (
    <Stack
      p="2px"
      boxShadow="sm"
      m="1"
      borderRadius="sm"
      borderLeft={'3px solid #319795'}
      transition={'all 0.2s'}
      _hover={{
        background: '#ebeeff3c',
        boxShadow: 'md',
      }}
    >
      <Stack direction={'row'} justifyContent="space-between">
        {children}
      </Stack>
    </Stack>
  )
}
