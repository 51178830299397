import { ReactNode } from 'react'
import { Outlet } from 'react-router-dom'

import { Grid, GridItem } from '@chakra-ui/react'

import './BaseSettingLayout.scss'

interface SettingLayoutPropsType {
  headerSlot?: ReactNode
  sidebarSlot?: ReactNode
}

export const BaseSettingLayout: React.FC<SettingLayoutPropsType> = (props) => {
  const { headerSlot, sidebarSlot } = props

  return (
    <Grid
      templateAreas={`"header header""nav main"`}
      gridTemplateRows={'3px 1fr'}
      gridTemplateColumns={'200px 1fr'}
      h="100%"
    >
      <GridItem className="setting-header" area={'header'}>
        {headerSlot && headerSlot}
      </GridItem>
      <GridItem area={'nav'} className="setting-sidebar">
        {sidebarSlot && sidebarSlot}
      </GridItem>
      <GridItem area={'main'}>
        <Outlet />
      </GridItem>
    </Grid>
  )
}
