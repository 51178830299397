import { Box, Button, Flex, useDisclosure, Text } from '@chakra-ui/react'

import { BaseModal } from 'shared/ui'
import { useClearHolyday } from '../models/services/clearHolydayService'

// misc

import { HiCalendarDays, HiOutlineArchiveBoxXMark } from 'react-icons/hi2'

interface ClearYearHolydaysProps {
  year: string
}

export const ClearYearHolydays: React.FC<ClearYearHolydaysProps> = (props) => {
  const { year } = props

  const { isOpen, onOpen, onClose } = useDisclosure()

  const { mutate: clearYearHolydays, isLoading } = useClearHolyday()

  const handleCloseModal = () => onClose()

  const handleDeleteUser = () => {
    clearYearHolydays({
      year,
      successAction: () => onClose(),
    })
  }

  return (
    <div>
      <Flex justifyContent={'center'} w={'100%'}>
        <Button
          isDisabled={null}
          isLoading={null}
          size={'xs'}
          display={'flex'}
          alignItems={'center'}
          onClick={() => onOpen()}
        >
          Очистить данные за год
          <Box fontSize={'18px'} ml={'7px'}>
            <HiCalendarDays />
          </Box>
        </Button>
      </Flex>
      <BaseModal
        header=" Очистить данные за год"
        id="delete user"
        isOpen={isOpen}
        onClose={handleCloseModal}
      >
        <Box>
          <Text
            fontWeight={'800'}
            mb={'5px'}
            color={'red.500'}
            textAlign={'center'}
          >
            Отмеченные выходные дни будут удалены
          </Text>
          <Text fontWeight={'600'} mb={'5px'} textAlign={'center'}>
            Вы действительно хотите очистить данные за <b>{year}</b> год?
          </Text>
        </Box>
        <Flex justifyContent={'flex-end'} mt="35px">
          <Button fontWeight={'500'} mr="15px" onClick={onClose}>
            Отмена
          </Button>
          <Button
            fontWeight={'500'}
            onClick={handleDeleteUser}
            colorScheme={'red'}
            isDisabled={isLoading}
            isLoading={isLoading}
          >
            Очистить данные за год
          </Button>
        </Flex>
      </BaseModal>
    </div>
  )
}
