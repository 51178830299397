import { AxiosError, AxiosResponse } from 'axios'
import { UseMutateFunction, useMutation, useQueryClient } from 'react-query'
import { queryKeys } from 'shared/constants'

import { AddHolydayRequest } from 'shared/models'
import { ReferenceBookService } from 'shared/services'
import { toasts } from 'shared/utils'

interface AddHolydayWithSuccessAction extends AddHolydayRequest {
  successAction: () => void
}

export function useAddHolyday(): {
  mutate: UseMutateFunction<
    AxiosResponse<undefined>,
    AxiosError,
    AddHolydayWithSuccessAction,
    unknown
  >
  isLoading: boolean
} {
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation(
    ({ weekend_date }: AddHolydayWithSuccessAction) =>
      ReferenceBookService.addHolyday({
        weekend_date,
      }),
    {
      onSuccess: (
        _: AxiosResponse<undefined>,
        variables: AddHolydayWithSuccessAction,
      ) => {
        if (variables.successAction) {
          variables.successAction()
          // toasts.success({
          //   title: 'Выходной день добавлен',
          //   description: `Дата ${variables.weekend_date} успешно установлена как выходной день`,
          // })
        }

        queryClient.invalidateQueries([queryKeys.holydays.allHolydays])
      },
      onError: () =>
        toasts.error({
          title: 'Произошла ошибка',
          description: 'Выходной день не был добавлен',
        }),
    },
  )
  return { mutate, isLoading }
}
