import { forwardRef } from 'react'
import { Flex, FlexProps } from '@chakra-ui/react'

//  misc
import { HiArrowLeftCircle } from 'react-icons/hi2'

interface NavigateBackButtonProps extends FlexProps {
  onClick?: () => void
}

export const NavigateBackButton = forwardRef<
  HTMLDivElement,
  NavigateBackButtonProps
>((props, ref) => {
  const { onClick, ...rest } = props
  return (
    <Flex
      alignItems={'center'}
      h={'100%'}
      w={'30px'}
      p="0 2"
      color="#28aa72"
      cursor={'pointer'}
      _hover={{ cursor: 'pointer', color: '#24c665' }}
      transition={'0.2s'}
      onClick={onClick}
      ref={ref}
      {...rest}
    >
      <HiArrowLeftCircle fontSize={'25px'} />
    </Flex>
  )
})
