import { useQuery } from 'react-query'

import { TariffTransmissionServiceResponse } from 'shared/models'
import { queryKeys } from 'shared/constants'
import { PriceCategoriesService } from 'shared/services'

interface TariffTransmissionData {
  value: string
  label: string
}

interface UseGetTariffTransmissionResult {
  data: TariffTransmissionServiceResponse[] | undefined
  isError: boolean
  isFetching: boolean
  isLoading: boolean
  tariffTransmissionData: TariffTransmissionData[] | undefined
}

interface UseAllTariffTransmission {
  enabled: boolean
}

export function useGetAllTariffTransmission({
  enabled,
}: UseAllTariffTransmission): UseGetTariffTransmissionResult {
  const { data, isError, isFetching, isLoading } = useQuery<
    TariffTransmissionServiceResponse[]
  >({
    queryKey: [
      queryKeys.priceCategories.tariffTransmissionService
        .allTariffTransmissionService,
    ],
    queryFn: () =>
      PriceCategoriesService.getTariffTransmission().then(
        (response) => response.data,
      ),
    enabled,
    retry: false,
  })

  const tariffTransmissionData = data?.map((tariff) => ({
    value: tariff.tariff_service_id,
    label: tariff.tariff,
  }))

  return { data, isError, isFetching, isLoading, tariffTransmissionData }
}
