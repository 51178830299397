import { AxiosError } from 'axios'
import { UseMutateFunction, useMutation, useQueryClient } from 'react-query'

import { queryKeys } from 'shared/constants'
import { ExtendedAxiosError } from 'shared/models'
import { ReferenceBookService } from 'shared/services'
import { toasts } from 'shared/utils'

interface DeleteGroupDelivery {
  gs_activity_areas_id: string
  date_from: string
  date_to?: string
  successAction: () => void
}
export function useDeleteGroupDelivery(): {
  mutate: UseMutateFunction<void, AxiosError, DeleteGroupDelivery, unknown>
  isLoading: boolean
} {
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation<
    void,
    AxiosError,
    DeleteGroupDelivery
  >(
    ({ gs_activity_areas_id, date_from, date_to }: DeleteGroupDelivery) =>
      ReferenceBookService.deleteGroupDelivery({
        gs_activity_areas_id,
        date_from,
        ...(date_to && { date_to }),
      }).then(() => {}),
    {
      onSuccess: (_, variables: DeleteGroupDelivery) => {
        if (variables.successAction) variables.successAction()

        toasts.success({
          title: 'ГПТ удалена',
          description: `ГПТ  успешно удаленa`,
        })
        queryClient.invalidateQueries([
          queryKeys.referenceBooks.activityAreas.detailActivityAreas,
          variables.gs_activity_areas_id,
        ])
        queryClient.invalidateQueries([
          queryKeys.referenceBooks.activityAreas.allGroupDelivery,
        ])
      },
      onError: (error: ExtendedAxiosError) => {
        toasts.error({
          title: 'Произошла ошибка',
          description:
            error.response.data.detail || 'Пользователь не был удален',
        })
      },
    },
  )

  return { mutate, isLoading }
}
