import { AxiosError, AxiosResponse } from 'axios'
import { UseMutateFunction, useMutation, useQueryClient } from 'react-query'
import { queryKeys } from 'shared/constants'
import { DeliveryGroupAxiosError, ExtendedAxiosError } from 'shared/models'

import { ReferenceBookService } from 'shared/services'
import { toasts } from 'shared/utils'

interface UpdateGroupDeliveryMutation {
  successAction: () => void
  errorAction: (gd_point_names: string[]) => void
  date_from: string
  date_to?: string
  new_date_to?: string
  new_date_from: string
  gs_activity_areas_id: string
  gd_point_names: string[]
}

export function useUpdateGroupDelivery(): {
  mutate: UseMutateFunction<
    AxiosResponse<undefined>,
    AxiosError,
    UpdateGroupDeliveryMutation,
    unknown
  >
  isLoading: boolean
} {
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation(
    ({
      date_from,
      date_to,
      gd_point_names,
      gs_activity_areas_id,
      new_date_to,
      new_date_from,
    }: UpdateGroupDeliveryMutation) =>
      ReferenceBookService.updateGroupDelivery({
        date_from,
        gd_point_names,
        gs_activity_areas_id,
        new_date_from,
        ...(new_date_to && { new_date_to }),
        ...(date_to && { date_to }),
      }),
    {
      onSuccess: (
        data: AxiosResponse<undefined>,
        variables: UpdateGroupDeliveryMutation,
      ) => {
        if (variables.successAction) {
          variables.successAction()
          toasts.success({
            title: 'Успех',
            description: `ГТП успешно обновлена`,
          })
        }
        queryClient.invalidateQueries([
          queryKeys.referenceBooks.activityAreas.detailActivityAreas,
          variables.gs_activity_areas_id,
        ])
        queryClient.invalidateQueries([
          queryKeys.referenceBooks.activityAreas.allGroupDelivery,
        ])
      },
      onError: (error: DeliveryGroupAxiosError, variables) => {
        variables.errorAction(error.response.data.gd_point_names)
        toasts.error({
          title: 'Произошла ошибка',
          description: error.response.data.detail || 'ГТП не обновлена',
        })
      },
    },
  )
  return { mutate, isLoading }
}
