import { Box, Button, useDisclosure } from '@chakra-ui/react'

import { TextTitle } from 'shared/ui'

import { SetNewPasswordModal } from './SetNewPasswordModal'

// misc
import { HiMiniFingerPrint } from 'react-icons/hi2'

export const SetNewPassword = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const setNewPasswordModalProps = {
    isOpen,
    onClose,
  }

  return (
    <Box mt={'10px'}>
      <TextTitle size={'small'}>
        Установка нового пароля к вашей учетной записи
      </TextTitle>
      <SetNewPasswordModal {...setNewPasswordModalProps} />
      <Button
        size={'sm'}
        mt="15px"
        type="submit"
        isLoading={false}
        isDisabled={false}
        colorScheme={'red'}
        color="white"
        display={'flex'}
        alignItems={'center'}
        onClick={() => onOpen()}
      >
        Установить новый пароль
        <Box fontSize={'22px'} ml={'10px'}>
          <HiMiniFingerPrint />
        </Box>
      </Button>
    </Box>
  )
}
