import React from 'react'

import { AdditionalRequisitesResponse } from 'shared/models'
import { ListView } from 'shared/ui'
import { AdditionalRequisiteItem } from './AdditionalRequisiteItem'

interface EditAdditionalRequisite {
  additionalRequisite: AdditionalRequisitesResponse[]
}

export const EditAdditionalRequisite: React.FC<EditAdditionalRequisite> = (
  props,
) => {
  const { additionalRequisite } = props

  return (
    <ListView.Lists>
      {additionalRequisite.map((requisite) => (
        <AdditionalRequisiteItem
          key={requisite.add_requisite_id}
          additional_requisite={requisite.additional_requisite}
          add_requisite_id={requisite.add_requisite_id}
          code_id={requisite.code_id}
        />
      ))}
    </ListView.Lists>
  )
}
