import React, { useCallback, useEffect, useState } from 'react'
import { Text, Button, Flex, IconButton, Box } from '@chakra-ui/react'

import { Access } from 'app/providers'
import { FormInputControl, ListView } from 'shared/ui'
import { accessDict, modulesDict } from 'shared/dictionary'

import {
  EditEnergySystemFormData,
  useEditEnergySystemForm,
} from './UseEditEnergySystemForm'
import { useUpdateEnergySystems } from '../models/services/useUpdateEnegrySystems'
import { DeleteEnergySystem } from '../../deleteEnergySystem'

//  misc
import { HiMiniXMark, HiOutlinePencilSquare } from 'react-icons/hi2'

interface EnergySystemItemProps {
  system_name: string
  ue_system_id: string
  code_id: number
}

export const EnergySystemItem: React.FC<EnergySystemItemProps> = (props) => {
  const { code_id, system_name, ue_system_id } = props
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const {
    mutate: updateEnergySystems,
    isLoading: isUpdateEnergySystemLoading,
  } = useUpdateEnergySystems()

  const { register, handleSubmit, errors, reset, isDirty, watchedFields } =
    useEditEnergySystemForm()

  useEffect(() => {
    if (ue_system_id)
      reset({
        system_name,
      })
  }, [code_id, ue_system_id, system_name, reset])

  const handleEditClick = useCallback(() => {
    if (isEdit) {
      reset()
      setIsEdit(false)
    } else setIsEdit(!isEdit)
  }, [isEdit])

  const onSubmit = ({ system_name }: EditEnergySystemFormData) => {
    updateEnergySystems({
      system_name,
      ue_system_id,
      successAction: () => setIsEdit(false),
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ListView.Stacks>
        <Flex alignItems={'center'} ml={'5px'}>
          <Text w={'35px'} fontSize={'sm'}>
            {code_id}
          </Text>

          {isEdit ? (
            <Flex mr={'5px'} w={'550px'} alignItems={'center'}>
              <Text
                mr={'5px'}
                color={'#718096'}
                fontSize={'12px'}
                fontWeight={'500'}
              >
                Имя:
              </Text>
              <FormInputControl
                smallErrorTextInside
                size="xs"
                name="system_name"
                register={register}
                errors={errors}
                watchedFields={watchedFields}
                isRequired
                placeholder="Имя ОЭС"
                type="text"
              />
            </Flex>
          ) : (
            <Text fontSize={'sm'} textAlign={'left'} maxW={'4xl'}>
              {system_name}
            </Text>
          )}
        </Flex>

        <Flex alignItems={'center'}>
          {isEdit && isDirty && (
            <Box>
              <Flex justifyContent={'flex-start'}>
                <Button
                  mr="5px"
                  size={'xs'}
                  colorScheme="blue"
                  type="submit"
                  isDisabled={isUpdateEnergySystemLoading}
                  isLoading={isUpdateEnergySystemLoading}
                >
                  Сохранить
                </Button>
              </Flex>
            </Box>
          )}
          {isEdit && (
            <Box mr={'15px'}>
              <DeleteEnergySystem
                ue_system_id={ue_system_id}
                system_name={system_name}
              />
            </Box>
          )}

          <Access
            permissions={[accessDict.update_read]}
            module={modulesDict.reference_books}
          >
            <IconButton
              size="xs"
              aria-label="Edit Activity"
              icon={isEdit ? <HiMiniXMark /> : <HiOutlinePencilSquare />}
              onClick={handleEditClick}
              fontSize="20px"
              // {...(isEdit ? { colorScheme: 'red' } : {})}
            />
          </Access>
        </Flex>
      </ListView.Stacks>
    </form>
  )
}
