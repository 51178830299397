import { filterItemsByRole } from 'app/providers'
import { SelectOption } from 'shared/models'
import { ROLES } from './roles'

export const rolesDataItems: SelectOption[] = [
  {
    value: 'superadmin',
    label: 'Супер Администратор',
    access: [ROLES.SUPERADMIN],
  },
  {
    value: 'admin',
    label: 'Администратор',
    access: [ROLES.SUPERADMIN],
  },
  {
    value: 'user',
    label: 'Пользователь',
  },
]

export const rolesData = () => filterItemsByRole(rolesDataItems)
