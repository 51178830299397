import React, { useCallback, useContext, useEffect } from 'react'
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  useColorModeValue,
  InputGroup,
  InputRightElement,
  Text,
  Heading,
} from '@chakra-ui/react'

import { PageScope, useStore } from 'app/providers'
import { AuthStage } from 'widgets/authentication'

import { AuthenticationProps } from 'features/authentication'

import { CompanyLogo } from 'shared/ui'
import { queryKeys } from 'shared/constants'
import { useLoginForm } from './useLoginForm'

// misc
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'

interface LoginFormPropsType {
  setAuthStage: React.Dispatch<React.SetStateAction<AuthStage>>
}

export const LoginForm: React.FC<LoginFormPropsType> = React.memo(
  ({ setAuthStage }) => {
    const pageScope = useContext(PageScope)

    const isLoading = useStore((state) =>
      state.loaders.isLoading(queryKeys.user.data),
    )

    const { register, handleSubmit, errors, showPassword, setShowPassword } =
      useLoginForm()

    const onSubmit = useCallback(
      (data) => {
        pageScope.handleSignIn(data)
      },
      [pageScope],
    )

    const handleResetPassword = useCallback(() => {
      setAuthStage(AuthStage.reset)
    }, [setAuthStage])

    return (
      <Flex
        minH="100vh"
        align="center"
        justify="center"
        bg={useColorModeValue('gray.50', 'gray.800')}
      >
        <Stack spacing={8} mx="auto" py={12} px={6} alignItems={'center'}>
          <CompanyLogo />
          <Box
            rounded="lg"
            bg={useColorModeValue('white', 'gray.700')}
            boxShadow="lg"
            p={8}
            width={'450px'}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing={4}>
                <FormControl id="login" isInvalid={!!errors.username}>
                  <Heading
                    color="#3435bc"
                    fontSize={{ base: '2xl' }}
                    fontWeight={'medium'}
                    mb={5}
                    textAlign={'center'}
                  >
                    Введите логин и пароль
                  </Heading>
                  <FormLabel>Логин</FormLabel>
                  <Input
                    type="text"
                    placeholder="Введите логин"
                    {...register('username')}
                    data-testid="username-input"
                  />
                  <Text color="red.600">{errors.username?.message}</Text>
                </FormControl>
                <FormControl
                  id="password"
                  isInvalid={!!errors.password}
                  data-testid="password-control"
                >
                  <FormLabel>Пароль</FormLabel>
                  <InputGroup>
                    <Input
                      data-testid="password-input"
                      type={showPassword ? 'text' : 'password'}
                      placeholder="Введите пароль"
                      {...register('password')}
                    />
                    <InputRightElement
                      cursor="pointer"
                      onClick={() => setShowPassword(!showPassword)}
                      data-testid="toggle-password"
                    >
                      {showPassword ? (
                        <ViewOffIcon color="teal.400" />
                      ) : (
                        <ViewIcon color="teal.400" />
                      )}
                    </InputRightElement>
                  </InputGroup>
                  <Text color="red.600" data-testid="password-error">
                    {errors.password?.message}
                  </Text>
                </FormControl>
                <Stack spacing={10}>
                  <Stack
                    direction={{ base: 'column', sm: 'row' }}
                    align={'start'}
                    justify={'space-between'}
                  >
                    <Text
                      onClick={handleResetPassword}
                      cursor="pointer"
                      color={'blue.400'}
                      _hover={{ color: 'blue.600' }}
                    >
                      Забыли пароль?
                    </Text>
                  </Stack>
                  <Button
                    type="submit"
                    isLoading={isLoading}
                    isDisabled={isLoading}
                    // bg="teal.400"
                    bg="#3451bc"
                    color="white"
                    _hover={{
                      // bg: 'teal.500',
                      bg: '#3435bc',
                    }}
                    data-testid="submit-button"
                  >
                    Вход
                  </Button>
                </Stack>
              </Stack>
            </form>
          </Box>
        </Stack>
      </Flex>
    )
  },
)
