import { AxiosError } from 'axios'
import { UseMutateFunction, useMutation } from 'react-query'

import { UserService } from 'shared/services'
import { toasts } from 'shared/utils'

export function useLogOut(): {
  mutate: UseMutateFunction<void, AxiosError, undefined, unknown>
} {
  const { mutate } = useMutation<void, AxiosError, undefined>(
    () => UserService.fetchLogOut().then(() => {}),
    {},
  )

  return { mutate }
}
