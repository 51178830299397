import React from 'react'

import { ListView } from 'shared/ui'
import { SubjectsResponse } from 'shared/models'
import { SubjectItem } from './SubjectItem'

interface EditSubjectsProps {
  subjects: SubjectsResponse[]
}

export const EditSubjects: React.FC<EditSubjectsProps> = (props) => {
  const { subjects } = props

  return (
    <ListView.Lists>
      {subjects.map((subject) => (
        <SubjectItem
          key={subject.subject_id}
          region_code={subject.region_code}
          region_name={subject.region_name}
          subject_id={subject.subject_id}
        />
      ))}
    </ListView.Lists>
  )
}
