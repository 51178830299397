import { useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Flex, Grid, GridItem } from '@chakra-ui/react'

import { CreateCompany } from 'features/companies'
import { COMPANIES_ROUTE } from 'shared/constants'

// misc
import { HiArrowLeftCircle } from 'react-icons/hi2'
import { ScrollToTop } from 'shared/ui'

export const CreateCompanyPage = () => {
  const navigate = useNavigate()

  const scrollContainerRef = useRef(null)

  return (
    <Grid
      templateAreas={`"header" "main"`}
      gridTemplateRows={'50px 1fr'}
      gridTemplateColumns={'1fr'}
      height="91vh"
      overflow={'100'}
      color="blackAlpha.700"
      fontWeight="bold"
    >
      <ScrollToTop scrollContainerRef={scrollContainerRef} />
      <GridItem pl="2" area={'header'} borderBottom={'1px solid #dfe0eb'}>
        <Flex
          alignItems={'center'}
          h={'100%'}
          w={'fit-content'}
          p="0 2"
          color="#28aa72"
          cursor={'pointer'}
          _hover={{ cursor: 'pointer', color: '#24c665' }}
          transition={'0.2s'}
          onClick={() => navigate(COMPANIES_ROUTE)}
        >
          <HiArrowLeftCircle fontSize={'30px'} cursor={'pointer'} />
        </Flex>
      </GridItem>
      <GridItem area={'main'} height={'87vh'}>
        <Box
          ref={scrollContainerRef}
          style={{
            width: '100%',
            overflowY: 'scroll',
            overflowX: 'hidden',
            height: 'calc(100vh - 110px)',
            paddingBottom: '50px',
          }}
        >
          <Box borderBottom={'1px solid #dfe0eb'} p={'25px'} mb="10px">
            <CreateCompany />
          </Box>
        </Box>
      </GridItem>
    </Grid>
  )
}
