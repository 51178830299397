export const ROLES = {
  ADMIN: 'admin',
  USER: 'user',
  SUPERADMIN: 'superadmin',
} as const

export const roleColorScheme = {
  [ROLES.SUPERADMIN]: '#607399',
  [ROLES.ADMIN]: '#6ba0d1',
  [ROLES.USER]: '#67a885',
}
