/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'

export interface ColumnProps {
  title: string
  accessor: string
  CellComponent?: React.FC<any>
  maxWidth?: number
  minWidth?: number
  width?: string | number
  disableFilters?: boolean
  sticky?: string
  className?: string
  headerClassName?: string
  getSortByToggleProps?: (props?: unknown) => unknown
  isSorted?: boolean
  isSortedDesc?: boolean
  disableSortBy?: boolean
}

export function useColumns(columnPropsArray: ColumnProps[]) {
  return React.useMemo(() => {
    return columnPropsArray.map((columnProps) => {
      const {
        accessor,
        CellComponent,
        maxWidth,
        minWidth,
        width,
        disableFilters,
        sticky,
        className,
        headerClassName,
        getSortByToggleProps,
        isSorted,
        isSortedDesc,
        title,
        disableSortBy = false,
      } = columnProps

      const column = {
        Header: title,
        id: accessor,
        ...(typeof accessor !== 'undefined' ? { accessor } : {}),
        ...(CellComponent && {
          Cell: (props: any) => <CellComponent {...props} />,
        }),
        ...(maxWidth && { maxWidth }),
        ...(minWidth && { minWidth }),
        ...(width && { width }),
        ...(disableFilters && { disableFilters }),
        ...(sticky && { sticky }),
        ...(className && { className }),
        ...(headerClassName && { headerClassName }),
        ...(getSortByToggleProps && { getSortByToggleProps }),
        ...(isSorted && { isSorted }),
        ...(isSortedDesc && { isSortedDesc }),
        disableSortBy,
      }

      return column
    })
  }, [columnPropsArray])
}
