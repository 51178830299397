import { useCallback, useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import { Collapse, Box, Flex, Text, Button } from '@chakra-ui/react'

import {
  useGetAllEnergySystems,
  useGetAllGroupDelivery,
  useGetAllPriceZones,
  useGetAllSubjects,
} from 'entities/referenceBooks'

import {
  DatePickerField,
  DatePickerInput,
  FormInputBlock,
  FormInputControl,
  MaskedInput,
  SelectInputForm,
  SelectInputMultiForm,
  TextLabelTitle,
  TextTitle,
} from 'shared/ui'

import {
  AddNewActivityFormData,
  useAddNewActivityForm,
} from './UseAddNewActivityAreaForm'
import { useAddNewActivityArea } from '../models/services/addNewActivityAreaService'

//  misc
import { HiMiniPlusCircle, HiMiniXMark } from 'react-icons/hi2'
import { debounce } from 'lodash'

interface AddNewActivityAreaProps {
  isOpen: boolean
  onToggle: () => void
}

interface PayloadType {
  time_zone: string
  area_name: string
  price_zone_id: string
  ue_system_id: string
  subject_id: string
  successAction: () => void
  errorAction: (gd_point_names: string[]) => void
  related_group_delivery_points?: {
    date_from: string
    date_to?: string
    gd_point_names: string[]
  }
}

export const AddNewActivityArea: React.FC<AddNewActivityAreaProps> = (
  props,
) => {
  const [errorPoints, setErrorPoints] = useState<{
    isError: boolean
    gd_point_names?: string[]
  }>({
    isError: false,
    gd_point_names: [],
  })

  const { isOpen, onToggle } = props

  const { priceZonesData } = useGetAllPriceZones({
    enabled: true,
  })
  const { energySystemsData } = useGetAllEnergySystems({
    enabled: true,
  })
  const { subjectsData } = useGetAllSubjects({
    enabled: true,
  })
  const { groupDeliveryData } = useGetAllGroupDelivery({
    enabled: true,
  })

  const {
    mutate: addNewActivityArea,
    isLoading: isAddNewActivityAreaPropsLoading,
  } = useAddNewActivityArea()

  const {
    register,
    handleSubmit,
    errors,
    reset,
    isDirty,
    watchedFields,
    control,
    setValue,
    setError,
    watch,
  } = useAddNewActivityForm()

  const dateFrom = watch('related_group_delivery_points.date_from')
  const dateTo = watch('related_group_delivery_points.date_to')

  const onSubmit = ({
    time_zone,
    area_name,
    price_zone_id,
    ue_system_id,
    subject_id,
    related_group_delivery_points,
  }: AddNewActivityFormData) => {
    const payload: PayloadType = {
      time_zone: String(time_zone),
      area_name,
      price_zone_id,
      ue_system_id,
      subject_id,
      successAction: () => {
        onToggle()
        reset()
      },
      errorAction(gd_point_names) {
        setErrorPoints({ isError: true, gd_point_names })
      },
    }

    if (related_group_delivery_points) {
      const pointNamesArray = related_group_delivery_points.gd_point_names?.map(
        (point) => point.label,
      )
      payload.related_group_delivery_points = {
        date_from: moment(related_group_delivery_points.date_from).format(
          'yyyy-MM-DD',
        ),
        date_to: related_group_delivery_points.date_to
          ? moment(related_group_delivery_points.date_to).format('yyyy-MM-DD')
          : null,
        gd_point_names: pointNamesArray || [],
      }
    }
    addNewActivityArea(payload)
  }

  useEffect(() => {
    setErrorPoints({ isError: false, gd_point_names: [] })
  }, [dateFrom, dateTo])

  useEffect(() => {
    const currentGdPointNames = watch(
      'related_group_delivery_points.gd_point_names',
    )
    if (errorPoints?.gd_point_names && currentGdPointNames) {
      const updatedGdPointNames = currentGdPointNames.map((item) => ({
        ...item,
        isErrored: errorPoints?.gd_point_names.includes(item.label),
      }))
      setValue(
        'related_group_delivery_points.gd_point_names',
        updatedGdPointNames,
        {
          shouldValidate: true,
        },
      )
    }
  }, [errorPoints?.gd_point_names, setValue, watch])

  useEffect(() => {
    if (errorPoints?.gd_point_names?.length > 0) handleSetError()
  }, [errorPoints?.gd_point_names])

  const handleSetError = debounce(() => {
    setError('related_group_delivery_points.gd_point_names', {
      type: 'custom',
      message: 'Проверьте ГТП',
    })
  }, 0)

  const handleResetForm = useCallback(() => {
    reset()
  }, [reset])

  const handleAddDeliveryPoints = useCallback(() => {
    setValue('related_group_delivery_points', {
      date_from: '',
      date_to: '',
      gd_point_names: [],
    })
  }, [setValue])

  const handleRemoveDeliveryPoints = useCallback(() => {
    setValue('related_group_delivery_points', undefined)
  }, [setValue])

  const commonInputProps = useMemo(
    () => ({
      control,
      register,
      errors,
      watchedFields,
      size: 'sm' as 'sm',
      smallErrorTextInside: true,
      isRequired: true,
    }),
    [register, errors, watchedFields, control],
  )

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Collapse in={isOpen} animateOpacity style={{ overflow: 'inherit' }}>
        <Flex borderBottom={'1px solid #dfe0eb'} flexDir={'column'}>
          <Box p={'10px'}>
            <TextTitle size={'small'}>
              Добавление новой зоны деятельности ГП
            </TextTitle>
          </Box>
          <Flex>
            <Box p="10px 15px 20px 15px" w={'500px'}>
              <FormInputBlock
                titleWidth={'200px'}
                allowEdit={true}
                edit
                title={'Наименование:'}
              >
                <FormInputControl
                  name="area_name"
                  placeholder="Наименование"
                  type="text"
                  {...commonInputProps}
                />
              </FormInputBlock>
              <Box m={'8px'} />
              <FormInputBlock
                titleWidth={'200px'}
                allowEdit={true}
                edit
                title={'Ценовая зона'}
              >
                <SelectInputForm
                  placeholder="Выберите зону"
                  data={priceZonesData}
                  isCreatable={false}
                  isClearable={true}
                  getOptionValue={(option) => (option ? option.value : '')}
                  name="price_zone_id"
                  {...commonInputProps}
                />
              </FormInputBlock>
              <Box m={'10px'} />
              <FormInputBlock
                titleWidth={'200px'}
                allowEdit={true}
                edit
                title={'ОЭС'}
              >
                <SelectInputForm
                  placeholder="Выберите ОЭС"
                  getOptionValue={(option) => (option ? option.value : '')}
                  name="ue_system_id"
                  data={energySystemsData}
                  isCreatable={false}
                  isClearable={true}
                  {...commonInputProps}
                />
              </FormInputBlock>
              <Box m={'10px'} />
              <FormInputBlock
                titleWidth={'200px'}
                allowEdit={true}
                edit
                title={'Субьект РФ'}
              >
                <SelectInputForm
                  placeholder="Субьект РФ"
                  name="subject_id"
                  data={subjectsData}
                  isCreatable={false}
                  isClearable={true}
                  getOptionValue={(option) => (option ? option.value : '')}
                  {...commonInputProps}
                />
              </FormInputBlock>
              <Box m={'10px'} />
              <FormInputBlock
                allowEdit={true}
                edit
                title={'Часовой пояс для расчета цен: МСК +'}
                titleWidth={'200px'}
              >
                <MaskedInput
                  name={'time_zone'}
                  mask={'99'}
                  placeholder={'Часовой пояс'}
                  onChangeFormatter={(inputValue: string) =>
                    inputValue.replace(/\D/g, '')
                  }
                  type={'text'}
                  {...commonInputProps}
                />
              </FormInputBlock>
            </Box>
            <Box
              //  borderLeft={'1px solid #dfe0eb'}
              p={'13px'}
            >
              <Box>
                {!!watchedFields.related_group_delivery_points ? (
                  <Button
                    position={'relative'}
                    size={'xs'}
                    display={'flex'}
                    alignItems={'center'}
                    onClick={handleRemoveDeliveryPoints}
                  >
                    <Box fontSize={'18px'}>
                      <HiMiniXMark />
                    </Box>
                    <Text position={'relative'}>Отмена</Text>
                  </Button>
                ) : (
                  <Button
                    position={'relative'}
                    size={'xs'}
                    bg="blue.700"
                    color="white"
                    display={'flex'}
                    alignItems={'center'}
                    onClick={handleAddDeliveryPoints}
                    _hover={{
                      bg: 'blue.600',
                    }}
                  >
                    <Box fontSize={'18px'} mr={'5px'}>
                      <HiMiniPlusCircle />
                    </Box>
                    <Text position={'relative'}>Добавить набор ГТП</Text>
                  </Button>
                )}
              </Box>
              {!!watchedFields.related_group_delivery_points && (
                <Box mt={'20px'}>
                  <TextLabelTitle mb={'18px'}>
                    Добавление набора ГТП
                  </TextLabelTitle>
                  <Flex position={'relative'}>
                    <Box w={'350px'}>
                      <FormInputBlock
                        valueWidth={'150px'}
                        titleWidth={'80px'}
                        allowEdit={true}
                        title={'Дата с'}
                        edit={true}
                      >
                        {/* <DatePickerInput
                          maxDate={
                            watchedFields?.related_group_delivery_points
                              ?.date_to
                              ? moment(
                                  watchedFields?.related_group_delivery_points
                                    ?.date_to,
                                  'DD.MM.yyyy',
                                ).toDate()
                              : null
                          }
                          showMonthYearPicker
                          name={'related_group_delivery_points.date_from'}
                          placeholder={'Дата от'}
                          type={'text'}
                          theme="monthPicker"
                          {...commonInputProps}
                        /> */}
                        <DatePickerField
                          maxDate={
                            watchedFields?.related_group_delivery_points
                              ?.date_to
                              ? moment(
                                  watchedFields?.related_group_delivery_points
                                    ?.date_to,
                                  'DD.MM.yyyy',
                                ).toDate()
                              : null
                          }
                          showMonthYearPicker
                          name={'related_group_delivery_points.date_from'}
                          placeholder={'Дата от'}
                          type={'text'}
                          theme="monthPicker"
                          {...commonInputProps}
                        />
                      </FormInputBlock>
                      <Box m={'8px'} />
                      <FormInputBlock
                        titleWidth={'80px'}
                        allowEdit={true}
                        title={'Дата по'}
                        edit={true}
                      >
                        {/* <DatePickerInput
                          minDate={
                            watchedFields?.related_group_delivery_points
                              ?.date_from
                              ? moment(
                                  watchedFields?.related_group_delivery_points
                                    ?.date_from,
                                  'DD.MM.yyyy',
                                ).toDate()
                              : null
                          }
                          showMonthYearPicker
                          name={'related_group_delivery_points.date_to'}
                          placeholder={'Дата по'}
                          type={'text'}
                          theme="monthPicker"
                          {...commonInputProps}
                          isRequired={false}
                        /> */}
                        <DatePickerField
                          minDate={
                            watchedFields?.related_group_delivery_points
                              ?.date_from
                              ? moment(
                                  watchedFields?.related_group_delivery_points
                                    ?.date_from,
                                  'DD.MM.yyyy',
                                ).toDate()
                              : null
                          }
                          showMonthYearPicker
                          name={'related_group_delivery_points.date_to'}
                          placeholder={'Дата по'}
                          type={'text'}
                          theme="monthPicker"
                          {...commonInputProps}
                          isRequired={false}
                        />
                      </FormInputBlock>
                    </Box>
                    <Box w={'500px'}>
                      <Flex>
                        <TextLabelTitle w={'50px'}>ГТП: </TextLabelTitle>
                        <SelectInputMultiForm
                          isCreatable
                          isReadOnly={false}
                          name={'related_group_delivery_points.gd_point_names'}
                          data={groupDeliveryData}
                          placeholder="Выберите ГТП"
                          isClearable
                          isMulti
                          {...commonInputProps}
                        />
                      </Flex>
                    </Box>
                  </Flex>
                </Box>
              )}
            </Box>
          </Flex>

          {isDirty && (
            <Box p="0 0 25px 15px">
              <Flex justifyContent={'flex-start'}>
                <Button mr="5px" onClick={handleResetForm} size={'xs'}>
                  <Flex alignItems={'center'}>Очистить</Flex>
                </Button>
                <Button
                  mr="5px"
                  size={'xs'}
                  colorScheme="blue"
                  type="submit"
                  isDisabled={isAddNewActivityAreaPropsLoading}
                  isLoading={isAddNewActivityAreaPropsLoading}
                >
                  Сохранить
                </Button>
              </Flex>
            </Box>
          )}
        </Flex>
      </Collapse>
    </form>
  )
}
