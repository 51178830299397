import React, { useState } from 'react'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import './Tooltip.scss'

interface TooltipProps {
  content: React.ReactNode
  children: React.ReactNode
  id: string
  delayShow?: number
  place?:
    | 'top'
    | 'top-start'
    | 'top-end'
    | 'right'
    | 'right-start'
    | 'right-end'
    | 'bottom'
    | 'bottom-start'
    | 'bottom-end'
    | 'left'
    | 'left-start'
    | 'left-end'
}

export const Tooltip: React.FC<TooltipProps> = ({
  content,
  children,
  id,
  delayShow = 0,
  place = 'top',
}) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false)

  return (
    <>
      <div
        onMouseEnter={() => setIsTooltipVisible(true)}
        onMouseLeave={() => setIsTooltipVisible(false)}
        // data-tooltip-offset={50}
        data-tooltip-delay-show={delayShow}
        data-tooltip-id={id}
        data-tooltip-place={place}
      >
        {children}
      </div>
      <ReactTooltip
        id={id}
        className={`example ${isTooltipVisible ? 'example-visible' : ''}`}
      >
        {content}
      </ReactTooltip>
    </>
  )
}
