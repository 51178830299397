import { AxiosPromise } from 'axios'

import {
  CalculationSchemesResponse,
  AdditionalRequisitesResponse,
  DeleteAdditionalRequisitesRequest,
  UpdateAdditionalRequisitesRequest,
  CreateAdditionalRequisitesRequest,
  TypesContractsResponse,
  DeleteTypesContractsRequest,
  UpdateTypesContractsRequest,
  CreateTypesContractsRequest,
  TypesCounteragentsResponse,
  TypesCounteragentDetailResponse,
  TypesCounteragentDetailRequest,
  UpdateTypesCounteragentRequest,
  DeleteTypesCounteragentRequest,
  CreateTypesCounteragentRequest,
  CreateRelatedTypeContractRequest,
  DeleteRelatedTypeContractRequest,
  UpdateRelatedTypeContractRequest,
} from 'shared/models'

import { ServiceBase } from './serviceBase'

export class CounteragentsContractsService extends ServiceBase {
  protected static BASE_PATH = 'ref_books_counteragents_contracts'

  public static getAllCalculationSchemes(): AxiosPromise<
    CalculationSchemesResponse[]
  > {
    return this.get<CalculationSchemesResponse[]>(
      `${this.BASE_PATH}/list_calculation_schemes`,
    )
  }

  public static getAllAdditionalRequisites(): AxiosPromise<
    AdditionalRequisitesResponse[]
  > {
    return this.get<AdditionalRequisitesResponse[]>(
      `${this.BASE_PATH}/list_additional_requisites_counteragents`,
    )
  }

  public static deleteHAdditionalRequisite({
    add_requisite_id,
  }: DeleteAdditionalRequisitesRequest): AxiosPromise<undefined> {
    return this.delete<undefined>(
      `${this.BASE_PATH}/additional_requisite_counteragent/${add_requisite_id}`,
    )
  }

  public static updateAdditionalRequisite({
    add_requisite_id,
    additional_requisite,
  }: UpdateAdditionalRequisitesRequest): AxiosPromise<undefined> {
    return this.patch<undefined>(
      `${this.BASE_PATH}/update_additional_requisite_counteragent/${add_requisite_id}`,
      {
        additional_requisite,
      },
    )
  }

  public static createAdditionalRequisite({
    additional_requisite,
  }: CreateAdditionalRequisitesRequest): AxiosPromise<undefined> {
    return this.post<undefined>(
      `${this.BASE_PATH}/create_additional_requisite_counteragent`,
      {
        additional_requisite,
      },
    )
  }

  public static getTypesContracts(): AxiosPromise<TypesContractsResponse[]> {
    return this.get<TypesContractsResponse[]>(
      `${this.BASE_PATH}/list_types_contracts`,
    )
  }

  public static deleteTypesContracts({
    type_contract_id,
  }: DeleteTypesContractsRequest): AxiosPromise<undefined> {
    return this.delete<undefined>(
      `${this.BASE_PATH}/additional_type_contract/${type_contract_id}`,
    )
  }

  public static updateTypesContracts({
    full_name,
    short_name,
    type_contract_id,
  }: UpdateTypesContractsRequest): AxiosPromise<undefined> {
    return this.patch<undefined>(
      `${this.BASE_PATH}/update_type_contract/${type_contract_id}`,
      {
        full_name,
        short_name,
      },
    )
  }

  public static createTypesContracts({
    full_name,
    short_name,
  }: CreateTypesContractsRequest): AxiosPromise<undefined> {
    return this.post<undefined>(`${this.BASE_PATH}/create_type_contract`, {
      full_name,
      short_name,
    })
  }

  public static getTypesCounteragents(): AxiosPromise<
    TypesCounteragentsResponse[]
  > {
    return this.get<TypesCounteragentsResponse[]>(
      `${this.BASE_PATH}/list_types_counteragents`,
    )
  }

  public static getTypesCounteragentDetails({
    type_counteragent_id,
  }: TypesCounteragentDetailRequest): AxiosPromise<
    TypesCounteragentDetailResponse[]
  > {
    return this.get<TypesCounteragentDetailResponse[]>(
      `${this.BASE_PATH}/related_type_contract/${type_counteragent_id}`,
    )
  }

  public static updateTypesCounteragent({
    full_name,
    short_name,
    type_counteragent_id,
  }: UpdateTypesCounteragentRequest): AxiosPromise<undefined> {
    return this.patch<undefined>(
      `${this.BASE_PATH}/update_type_counteragent/${type_counteragent_id}`,
      {
        full_name,
        short_name,
      },
    )
  }

  public static deleteTypesCounteragent({
    type_counteragent_id,
  }: DeleteTypesCounteragentRequest): AxiosPromise<undefined> {
    return this.delete<undefined>(
      `${this.BASE_PATH}/type_counteragent/${type_counteragent_id}`,
    )
  }

  public static createTypesCounteragent({
    full_name,
    short_name,
    related_type_contract,
  }: CreateTypesCounteragentRequest): AxiosPromise<undefined> {
    return this.post<undefined>(`${this.BASE_PATH}/create_type_counteragent`, {
      full_name,
      short_name,
      ...(related_type_contract && {
        related_type_contract,
      }),
    })
  }

  public static createRelatedTypeContract({
    type_contract_id,
    supplier,
    buyer,
    type_counteragent_id,
  }: CreateRelatedTypeContractRequest): AxiosPromise<undefined> {
    return this.post<undefined>(
      `${this.BASE_PATH}/create_related_type_contract`,
      {
        type_contract_id,
        supplier,
        buyer,
        type_counteragent_id,
      },
    )
  }

  public static updateRelatedTypeContract({
    supplier,
    buyer,
    type_counteragent_id,
    related_tc_id,
    type_contract_id,
  }: UpdateRelatedTypeContractRequest): AxiosPromise<undefined> {
    return this.patch<undefined>(
      `${this.BASE_PATH}/update_related_type_contract/${related_tc_id}`,
      {
        supplier,
        buyer,
        type_counteragent_id,
        related_tc_id,
        type_contract_id,
      },
    )
  }

  public static deleteRelatedTypeContract({
    related_tc_id,
  }: DeleteRelatedTypeContractRequest): AxiosPromise<undefined> {
    return this.delete<undefined>(
      `${this.BASE_PATH}/related_type_contract/${related_tc_id}`,
    )
  }
}
