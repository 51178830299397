import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

export interface CreateTypeCounteragentFormData {
  short_name?: string
  full_name?: string
  related_type_contract?: {
    type_contract_id?: string
    supplier?: boolean
    buyer?: boolean
  }
}

const relatedTypeContractSchema = yup.object().shape({
  buyer: yup.boolean(),
  supplier: yup.boolean(),
  type_contract_id: yup.string().required('Вид договора обязательное поле'),
})

const SCHEMA_VALIDATION = yup.object().shape({
  short_name: yup.string().required('Сокращение обязательное поле'),
  full_name: yup.string().required('Название обязательное поле'),
  related_type_contract: yup.lazy((value) =>
    value ? relatedTypeContractSchema : yup.object().notRequired(),
  ),
})

export const useCreateTypeCounteragentForm = () => {
  const {
    setValue,
    register,
    handleSubmit,
    formState,
    resetField,
    reset,
    control,
    watch,
    setError,
    clearErrors,
  } = useForm<CreateTypeCounteragentFormData>({
    resolver: yupResolver(SCHEMA_VALIDATION),
    mode: 'onChange',
  })

  const watchedFields = watch()

  const buyer = watch('related_type_contract.buyer')
  const supplier = watch('related_type_contract.supplier')

  useEffect(() => {
    if (formState.isDirty && !buyer && !supplier) {
      setError('related_type_contract.buyer', {
        type: 'manual',
        message: 'Необходимо выбрать поставщика или покупателя',
      })
      setError('related_type_contract.supplier', {
        type: 'manual',
        message: 'Необходимо выбрать поставщика или покупателя',
      })
    } else {
      clearErrors('related_type_contract.buyer')
      clearErrors('related_type_contract.supplier')
    }
  }, [buyer, supplier, setError, clearErrors])

  return {
    reset,
    register,
    handleSubmit,
    errors: formState.errors,
    isDirty: formState.isDirty,
    isValid: formState.isValid,
    isSubmitting: formState.isSubmitting,
    control,
    resetField,
    setValue,
    watchedFields,
    setError,
    watch,
  }
}
