import { create, StoreApi, UseBoundStore } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { devtools, persist } from 'zustand/middleware'
import { mergeDeepLeft } from 'ramda'

import { userStore } from 'entities/User'
import { StoreSchema } from '../types/storeTypes'
import { LOCAL_STORAGE_ENTITY } from 'shared/constants'

type WithSelectors<S> = S extends { getState: () => infer T }
  ? S & { use: { [K in keyof T]: () => T[K] } }
  : never

const createSelectors = <S extends UseBoundStore<StoreApi<object>>>(
  _store: S,
) => {
  let store = _store as WithSelectors<typeof _store>
  store.use = {}
  for (let k of Object.keys(store.getState())) {
    ;(store.use as any)[k] = () => store((s) => s[k as keyof typeof s])
  }
  return store
}

const loadersStore = (set, get) => ({
  loadings: {},
  setLoading: (queryKey: string, isLoading: boolean) =>
    set((state) => {
      state.loaders.loadings[queryKey] = isLoading
    }),
  isLoading: (queryKey: string) => get().loaders.loadings[queryKey] || false,
})

export const useBaseStore = create<StoreSchema>()(
  persist(
    devtools(
      immer((set, get) => ({
        loaders: loadersStore(set, get),
        user: userStore(set, get),
      })),
      { store: LOCAL_STORAGE_ENTITY.STORE_KEY },
    ),
    {
      name: 'storage',
      getStorage: () => localStorage,
      partialize: (state) => {
        //NOTE: include or disable persisted state
        return { loaders: state.loaders }
      },
      merge: (persistedState, currentState) =>
        mergeDeepLeft(persistedState, currentState),
    },
  ),
)

export const useStore = createSelectors(useBaseStore)
