import { ComponentProps, ReactNode } from 'react'
import { Text } from '@chakra-ui/react'

interface TextTitleProps extends Omit<ComponentProps<typeof Text>, 'size'> {
  size?: 'small' | 'medium' | 'large' | 'extraSmall'
  children: ReactNode
}

export const TextTitle: React.FC<TextTitleProps> = (props) => {
  const { size = 'medium', children, ...rest } = props

  const stylesVarian = {
    extraSmall: {
      fontSize: '12px',
      fontWeight: '700',
      pl: '8px',
    },
    small: {
      fontSize: '14px',
      fontWeight: '700',
      pl: '10px',
    },
    medium: {
      fontSize: '18px',
      fontWeight: '700',
      pl: '10px',
    },
    large: {
      fontSize: '20px',
      fontWeight: '700',
      pl: '10px',
    },
  }

  const styles = {
    color: 'gray.600',
    borderLeft: '3px solid #319795',
    w: 'fit-content',
    ...stylesVarian[size],
  }

  return (
    <Text {...styles} {...rest}>
      {children}
    </Text>
  )
}
