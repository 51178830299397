import React from 'react'

import { ProductsResponse } from 'shared/models'
import { PriceProductsItem } from './PriceProductsItem'
import { ListView } from 'shared/ui'

interface PriceCategoriesProps {
  products: ProductsResponse[]
}

export const ProductsDataList: React.FC<PriceCategoriesProps> = (props) => {
  const { products } = props

  return (
    <ListView.Lists>
      {products.map((product) => (
        <PriceProductsItem
          key={product.product_id}
          product={product.product}
          product_id={product.product_id}
          code_id={product.code_id}
        />
      ))}
    </ListView.Lists>
  )
}
