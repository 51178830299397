import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {
  CompanyActualAddress,
  CompanyLegalAddress,
  CompanyMailingAddress,
} from 'shared/models'
import { useFormErrorToast } from 'shared/hooks'

interface GeneralInfo {
  short_name?: string
  full_name?: string
  ogrn?: string
  inn?: string
  kpp?: string
}

interface BankDetails {
  account_number?: string
  bic?: string
  bank_name?: string
  correspondent_account?: string
}

interface Contacts {
  email?: string
  phone_number?: string
}

interface Director {
  director_position?: string
  basis_of_action?: string
  director_last_name?: string
  director_first_name?: string
  director_middle_name?: string
}

export interface CreateCompanyFormData {
  general_info?: GeneralInfo
  bank_details?: BankDetails
  contacts?: Contacts
  director?: Director
  mailing_address?: CompanyMailingAddress
  actual_address?: CompanyActualAddress
  legal_address?: CompanyLegalAddress
}

const SCHEMA_VALIDATION = yup.object().shape({
  general_info: yup.object().shape({
    short_name: yup.string().required('Краткое наименование обязательное поле'),
    full_name: yup.string().required('Полное наименование обязательное поле'),
    ogrn: yup
      .string()
      .required('ОГРН обязательное поле')
      // .matches(/^[0-9]{13,15}$/, 'ОГРН должен состоять из 13 или 15 цифр'),
      .matches(/^(\d{13}|\d{15})$/, 'ОГРН должен состоять из 13 или 15 цифр'),
    // inn: str = Field(pattern=r'^(\d{10}|\d{12})$')
    inn: yup
      .string()
      .required('ИНН обязательное поле')
      .matches(/^(\d{10}|\d{12})$/, 'ИНН должен состоять из 10 или 12 цифр'),
    kpp: yup
      .string()
      .required('КПП обязательное поле')
      .matches(/^[0-9]{9}$/, 'КПП должен состоять из 9 цифр'),
  }),
  legal_address: yup.object().shape({
    postal_code: yup.string().required('Почтовый индекс обязателен').max(6),
    region: yup.string().required('Регион обязателен'),
    district: yup.string(),
    city: yup.string().required('Город обязателен'),
    street: yup.string().required('Улица обязательна'),
    house_number: yup.string().required('Номер дома обязателен'),
    block: yup.string(),
    building: yup.string(),
    apartment: yup.string(),
  }),
  mailing_address: yup.object().shape({
    postal_code: yup.string().required('Почтовый индекс обязателен').max(6),
    region: yup.string().required('Регион обязателен'),
    district: yup.string(),
    city: yup.string().required('Город обязателен'),
    street: yup.string().required('Улица обязательна'),
    house_number: yup.string().required('Номер дома обязателен'),
    block: yup.string(),
    building: yup.string(),
    apartment: yup.string(),
  }),
  actual_address: yup.object().shape({
    postal_code: yup.string().required('Почтовый индекс обязателен').max(6),
    region: yup.string().required('Регион обязателен'),
    district: yup.string(),
    city: yup.string().required('Город обязателен'),
    street: yup.string().required('Улица обязательна'),
    house_number: yup.string().required('Номер дома обязателен'),
    block: yup.string(),
    building: yup.string(),
    apartment: yup.string(),
  }),
  bank_details: yup.object().shape({
    account_number: yup
      .string()
      .required('Номер счета обязательное поле')
      .matches(/^[0-9]{20}$/, 'Номер счета должен состоять из 20 цифр'),

    bic: yup.string().required('БИК обязательное поле'),
    bank_name: yup.string().required('Название банка обязательное поле'),
    correspondent_account: yup
      .string()
      .required('Корреспондентский счет обязательное поле')
      .matches(
        /^[0-9]{20}$/,
        'Корреспондентский счет должен состоять из 20 цифр',
      ),
  }),
  contacts: yup.object().shape({
    email: yup
      .string()
      .required('email обязательное поле')
      .email('email должен быть валидный')
      .matches(
        /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        'email должен содержать точку и доменное имя',
      ),
    phone_number: yup
      .string()
      .required('Телефон обязательное поле')
      .test(
        'is-full-phone-number',
        'Введите полный номер телефона',
        function (value) {
          // Удаляем маску из значения телефона перед проверкой длины
          const phoneNumberWithoutMask = value?.replace(/\D/g, '') // Удалить все нецифровые символы
          return phoneNumberWithoutMask && phoneNumberWithoutMask.length === 11 // Длина формата номера телефона без маски +7XXXXXXXXXX
        },
      ),
  }),
  director: yup.object().shape({
    director_last_name: yup.string().required('Фамилия обязательное поле'),
    director_first_name: yup.string().required('Имя обязательное поле'),
    director_middle_name: yup.string().required('Отчество обязательное поле'),
    basis_of_action: yup
      .string()
      .required('Основание действия обязательное поле'),
    director_position: yup.string().required('Должность  обязательное поле'),
  }),
})

export const useCreateCompanyForm = () => {
  const {
    setValue,
    getValues,
    register,
    handleSubmit,
    formState,
    resetField,
    reset,
    control,
    watch,
  } = useForm<CreateCompanyFormData>({
    resolver: yupResolver(SCHEMA_VALIDATION),
    mode: 'onChange',
    defaultValues: {
      general_info: {
        short_name: '',
        full_name: '',
        ogrn: '',
        inn: '',
        kpp: '',
      },
      bank_details: {
        account_number: '',
        bic: '',
        bank_name: '',
        correspondent_account: '',
      },
      contacts: {
        email: '',
        phone_number: '',
      },
      director: {
        director_position: '',
        basis_of_action: '',
        director_last_name: '',
        director_first_name: '',
        director_middle_name: '',
      },
      legal_address: {
        postal_code: '',
        region: '',
        district: '',
        city: '',
        street: '',
        house_number: '',
        block: '',
        building: '',
        apartment: '',
      },
      actual_address: {
        postal_code: '',
        region: '',
        district: '',
        city: '',
        street: '',
        house_number: '',
        block: '',
        building: '',
        apartment: '',
      },
      mailing_address: {
        postal_code: '',
        region: '',
        district: '',
        city: '',
        street: '',
        house_number: '',
        block: '',
        building: '',
        apartment: '',
      },
    },
  })

  const watchedFields = watch()

  useFormErrorToast(formState.errors)

  return {
    reset,
    register,
    handleSubmit,
    errors: formState.errors,
    isDirty: formState.isDirty,
    isValid: formState.isValid,
    isSubmitting: formState.isSubmitting,
    control,
    resetField,
    setValue,
    getValues,
    watchedFields,
    watch,
  }
}
