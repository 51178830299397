import React from 'react'
import { CellProps } from 'react-table'
import { useNavigate } from 'react-router-dom'
import { Flex, Box } from '@chakra-ui/react'

import { ROLES, USERS_ROUTE, USER_PROFILE_ROUTE } from 'shared/constants'
import { IDeleteUserProps, RowData } from '../models/types/tableTypes'

//misc
import { HiOutlinePencilSquare } from 'react-icons/hi2'
import { rolesDict } from 'shared/dictionary'
import { RoleTag } from 'shared/ui'

const ActionCellComponent = React.memo(
  ({
    row,
    handleDeleteUser,
    userId,
  }: {
    row: CellProps<RowData, string>
    handleDeleteUser: (data: IDeleteUserProps) => void
    userId: string
  }) => {
    const navigate = useNavigate()

    return (
      <Flex justifyContent={'center'} gridArea={'full_name'}>
        <Box
          mr={3}
          className="icon-edit"
          fontSize={'20px'}
          cursor={'pointer'}
          color={'#c2c2c2'}
          _hover={{ cursor: 'pointer', color: '#3435bc' }}
          onClick={() =>
            row.original.user_id === userId
              ? navigate(USER_PROFILE_ROUTE)
              : navigate(`${USERS_ROUTE}/${row.original.user_id}`)
          }
        >
          <HiOutlinePencilSquare />
        </Box>
      </Flex>
    )
  },
)

export const TableStructure = (
  handleDeleteUser: (data: IDeleteUserProps) => void,
  role: string,
  userId: string,
) => {
  const action = {
    title: ' ',
    disableSortBy: true, // Отключение сортировки
    accessor: '',
    minWidth: 80,
    width: 80,
    CellComponent: ({ row }: CellProps<RowData, string>) => {
      return (
        <ActionCellComponent
          row={row}
          userId={userId}
          handleDeleteUser={handleDeleteUser}
        />
      )
    },
  }

  const structure = [
    {
      title: 'ФИО',
      accessor: 'full_name',
      CellComponent: ({ row }: CellProps<RowData, string>) => {
        return (
          <Flex
            justifyContent={'space-between'}
            gridArea={'full_name'}
            _hover={{
              '.icon': { color: '#3435bc' },
            }}
          >
            <Box>{`${row.original?.last_name || ''} ${
              row.original?.first_name || ''
            } ${row.original?.middle_name || ''}`}</Box>
          </Flex>
        )
      },
    },
    {
      title: 'Роль',
      accessor: 'role_name',
      CellComponent: ({ row }: CellProps<RowData, string>) => {
        return (
          <Flex>
            <RoleTag size="small" role={row.original?.role_name}>
              {rolesDict[row.original?.role_name]}
            </RoleTag>
          </Flex>
        )
      },
    },
    {
      title: 'Должность',
      accessor: 'position_name',
    },
    {
      title: 'Логин',
      accessor: 'username',
    },
    {
      title: 'Email',
      accessor: 'email',
    },
  ]

  if (role === ROLES.ADMIN || role === ROLES.SUPERADMIN) structure.push(action)

  return structure
}
