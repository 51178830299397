import { useQuery } from 'react-query'

import { IUsersData } from 'shared/models'
import { queryKeys } from 'shared/constants'
import { UserService } from 'shared/services'

interface UseGetUsersResult {
  data: IUsersData[] | undefined
  isError: boolean
  isFetching: boolean
  usersSelectData: UserSelectOption[] | undefined
}

interface UserSelectOption {
  value: string
  label: string
}

interface UseGetUsers {
  enabled: boolean
}

export function useGetUsers({ enabled }: UseGetUsers): UseGetUsersResult {
  const { data, isError, isFetching } = useQuery<IUsersData[]>({
    queryKey: [queryKeys.users.allUsers],
    queryFn: () => UserService.getUsers().then((response) => response.data),
    enabled,
    retry: false,
  })

  const usersSelectData = data
    ? data.map((user) => ({
        value: user.user_id,
        label: `${user.last_name} ${user.first_name}`,
        role: user.role_name,
      }))
    : []

  return { data, isError, isFetching, usersSelectData }
}
