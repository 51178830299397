import {
  PermissionAction,
  PermissionSubject,
  PermissionsData,
} from '../model/types/user'

export const getPermissions = (
  fakePermissions: PermissionsData[],
): Record<PermissionSubject, PermissionAction[]> => {
  const transformed: Record<PermissionSubject, PermissionAction[]> = {}

  fakePermissions.forEach(({ action, subject }) => {
    subject.forEach((sub) => {
      if (!transformed[sub]) {
        transformed[sub] = []
      }
      action.forEach((act) => {
        if (!transformed[sub].includes(act)) {
          transformed[sub].push(act)
        }
      })
    })
  })

  return transformed
}

export const fakePermissions = [
  {
    action: ['read'],
    subject: ['Users'],
    fields: ['login', 'email'],
    conditions: {
      id: {
        $eq: 1,
      },
    },
  },
  {
    action: ['read', 'delete'],
    subject: ['Posts'],
    fields: ['login', 'email'],
    conditions: {
      id: {
        $eq: 1,
      },
    },
  },
  {
    action: ['read', 'delete', 'update', 'create'],
    subject: ['Titles'],
    fields: ['login', 'email'],
    conditions: {
      id: {
        $eq: 1,
      },
    },
  },
]
