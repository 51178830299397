import { LOCAL_STORAGE_ENTITY } from 'shared/constants'
import { localStorageHandler } from 'shared/utils'

interface AccessibleItem {
  access?: string[]
  [key: string]: any
}

export function filterItemsByRole<T extends AccessibleItem>(items: T[]): T[] {
  const userRole = localStorageHandler.getFromLocalStorage<string | null>(
    LOCAL_STORAGE_ENTITY.USER_ROLE,
    null,
  )

  return items.filter((item) => {
    if (!item.access) return true
    return item.access.includes(userRole!)
  })
}
