import { useCallback } from 'react'
import { Flex, useDisclosure } from '@chakra-ui/react'

import { Access } from 'app/providers'
import { PERMISSIONS, PERMISSIONS_ENTITY, ROLES } from 'shared/constants'
import { AddUser } from 'features/users'
import { ButtonIcon } from 'shared/ui'

import './Users.scss'

// misc
import { HiUserGroup, HiUserPlus } from 'react-icons/hi2'

export const UserSettingsHeader: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleAddUser = useCallback(() => {
    onOpen()
  }, [onOpen])

  const addUserProps = {
    isOpen,
    onOpen,
    onClose,
  }

  return (
    <>
      <Access roles={[ROLES.SUPERADMIN, ROLES.ADMIN]}>
        <AddUser {...addUserProps} />
      </Access>

      <Flex justify={'flex-end'} alignItems={'center'} h={'100%'} pr="5">
        <Flex>
          <Access roles={[ROLES.SUPERADMIN, ROLES.ADMIN]}>
            <ButtonIcon
              icon={HiUserPlus}
              onClick={handleAddUser}
              aria-label={''}
            />
          </Access>
        </Flex>
      </Flex>
    </>
  )
}
