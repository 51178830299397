import { AxiosError, AxiosResponse } from 'axios'
import { UseMutateFunction, useMutation, useQueryClient } from 'react-query'

import { queryKeys } from 'shared/constants'
import { ExtendedAxiosError } from 'shared/models'
import { CounteragentsContractsService } from 'shared/services'
import { toasts } from 'shared/utils'

interface UpdateAdditionalRequisiteMutation {
  successAction: () => void
  additional_requisite: string
  add_requisite_id: string
}

export function useUpdateAdditionalRequisite(): {
  mutate: UseMutateFunction<
    AxiosResponse<undefined>,
    AxiosError,
    UpdateAdditionalRequisiteMutation,
    unknown
  >
  isLoading: boolean
} {
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation(
    ({
      additional_requisite,
      add_requisite_id,
    }: UpdateAdditionalRequisiteMutation) =>
      CounteragentsContractsService.updateAdditionalRequisite({
        additional_requisite,
        add_requisite_id,
      }),
    {
      onSuccess: (
        data: AxiosResponse<undefined>,
        variables: UpdateAdditionalRequisiteMutation,
      ) => {
        if (variables.successAction) {
          variables.successAction()
          toasts.success({
            title: 'Данные успешно обновлены',
            description: `Данные дополнительного реквезитаы успешно обновлены`,
          })
        }
        queryClient.invalidateQueries([
          queryKeys.counteragentsContracts.additionalRequisite
            .allAdditionalRequisite,
        ])
      },
      onError: (error: ExtendedAxiosError) =>
        toasts.error({
          title: 'Произошла ошибка',
          description:
            error.response.data.detail ||
            'Данные дополнительного реквезитаы не были обновлены',
        }),
    },
  )
  return { mutate, isLoading }
}
