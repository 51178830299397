import { AxiosError, AxiosResponse } from 'axios'
import { UseMutateFunction, useMutation, useQueryClient } from 'react-query'
import { queryKeys } from 'shared/constants'
import { ExtendedAxiosError } from 'shared/models'

import { ReferenceBookService } from 'shared/services'
import { toasts } from 'shared/utils'

interface AddNewNewPriceZoneMutation {
  successAction: () => void
  zone_name: string
}

export function useAddNewPriceZone(): {
  mutate: UseMutateFunction<
    AxiosResponse<undefined>,
    AxiosError,
    AddNewNewPriceZoneMutation,
    unknown
  >
  isLoading: boolean
} {
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation(
    ({ zone_name }: AddNewNewPriceZoneMutation) =>
      ReferenceBookService.addNewPriceZone({
        zone_name,
      }),
    {
      onSuccess: (
        data: AxiosResponse<undefined>,
        variables: AddNewNewPriceZoneMutation,
      ) => {
        if (variables.successAction) {
          variables.successAction()
          toasts.success({
            title: 'Успех',
            description: `Новыая ценовая зона успешно добвлена`,
          })
        }
        queryClient.invalidateQueries([
          queryKeys.referenceBooks.priceZones.allPriceZones,
        ])
      },
      onError: (error: ExtendedAxiosError) =>
        toasts.error({
          title: 'Произошла ошибка',
          description:
            error.response.data.detail ||
            'Новыая ценовая зона не была добвлена',
        }),
    },
  )
  return { mutate, isLoading }
}
