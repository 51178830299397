import {
  accessDict,
  accessTranslateDict,
  inheritanceDict,
  inheritanceTranslateDict,
  modulesDict,
  modulesTranslateDict,
} from 'shared/dictionary'
import { SelectOption } from 'shared/models'

enum AccessType {
  access = 'access',
  inheritance = 'inheritance',
}

// function generateAccessData(
//   keys: string[],
//   context: AccessType.access | AccessType.inheritance,
// ): SelectOption[] {
//   const dict = context === AccessType.access ? accessDict : inheritanceDict
//   const translateDict =
//     context === AccessType.access
//       ? accessTranslateDict
//       : inheritanceTranslateDict

//   return keys.map((key) => ({
//     value: dict[key],
//     label: translateDict[key] || key,
//   }))
// }

function generateAccessData(
  keys: string[],
  context: AccessType.access | AccessType.inheritance,
): SelectOption[] {
  const dict = context === AccessType.access ? accessDict : inheritanceDict
  const translateDict =
    context === AccessType.access
      ? accessTranslateDict
      : inheritanceTranslateDict

  const sortOrder = [
    accessDict.full_access,
    accessDict.update_read,
    accessDict.partial_access,
    accessDict.read,
    accessDict.no_access,
  ]

  let accessData = keys.map((key) => ({
    value: dict[key],
    label: translateDict[key] || key,
  }))

  accessData.sort((a, b) => {
    return sortOrder.indexOf(a.value) - sortOrder.indexOf(b.value)
  })

  return accessData
}

export const accordionModulesSettings = [
  {
    tabLabel: 'Базовые модули',
    modules: [
      {
        moduleName: modulesDict.companies,
        label: modulesTranslateDict.companies,
        data: generateAccessData(
          [accessDict.full_access, accessDict.update_read, accessDict.read],
          AccessType.access,
        ),
        inheritanceData: null,
      },
      {
        moduleName: modulesDict.reference_books,
        label: modulesTranslateDict.reference_books,
        data: generateAccessData(
          [accessDict.update_read, accessDict.read],
          AccessType.access,
        ),
        inheritanceData: null,
      },
    ],
  },
  {
    tabLabel: 'Биллинг модули',
    modules: [
      {
        moduleName: modulesDict.prices,
        label: modulesTranslateDict.prices,
        data: generateAccessData(
          [inheritanceDict.read, accessDict.full_access],
          AccessType.access,
        ),
        inheritanceData: null,
      },
      {
        moduleName: modulesDict.counter_agents,
        label: modulesTranslateDict.counter_agents,
        data: generateAccessData(
          [
            accessDict.full_access,
            accessDict.partial_access,
            accessDict.no_access,
          ],
          AccessType.access,
        ),
        inheritanceData: generateAccessData(
          [
            inheritanceDict.read,
            inheritanceDict.update_read,
            inheritanceDict.no_access,
          ],
          AccessType.inheritance,
        ),
      },
      {
        moduleName: modulesDict.volumes,
        label: modulesTranslateDict.volumes,
        data: generateAccessData(
          [
            accessDict.full_access,
            accessDict.partial_access,
            accessDict.no_access,
          ],
          AccessType.access,
        ),
        inheritanceData: generateAccessData(
          [
            inheritanceDict.read,
            inheritanceDict.update_read,
            inheritanceDict.no_access,
          ],
          AccessType.inheritance,
        ),
      },
      {
        moduleName: modulesDict.finance,
        label: modulesTranslateDict.finance,
        data: generateAccessData(
          [
            accessDict.full_access,
            accessDict.partial_access,
            accessDict.no_access,
          ],
          AccessType.access,
        ),
        inheritanceData: generateAccessData(
          [
            inheritanceDict.read,
            inheritanceDict.update_read,
            inheritanceDict.no_access,
          ],
          AccessType.inheritance,
        ),
      },
    ],
  },
  {
    tabLabel: 'Вспомогательные модули',
    modules: [
      {
        moduleName: modulesDict.budget,
        label: modulesTranslateDict.budget,
        data: generateAccessData(
          [
            accessDict.full_access,
            accessDict.partial_access,
            accessDict.no_access,
          ],
          AccessType.access,
        ),
        inheritanceData: generateAccessData(
          [
            inheritanceDict.read,
            inheritanceDict.update_read,
            inheritanceDict.no_access,
          ],
          AccessType.inheritance,
        ),
      },
      {
        moduleName: modulesDict.reporting,
        label: modulesTranslateDict.reporting,
        data: generateAccessData(
          [
            accessDict.full_access,
            accessDict.partial_access,
            accessDict.no_access,
          ],
          AccessType.access,
        ),
        inheritanceData: generateAccessData(
          [inheritanceDict.read, inheritanceDict.no_access],
          AccessType.inheritance,
        ),
      },
      {
        moduleName: modulesDict.analytics,
        label: modulesTranslateDict.analytics,
        data: generateAccessData(
          [
            accessDict.full_access,
            accessDict.partial_access,
            accessDict.no_access,
          ],
          AccessType.access,
        ),
        inheritanceData: generateAccessData(
          [inheritanceDict.read, inheritanceDict.no_access],
          AccessType.inheritance,
        ),
      },
    ],
  },
]
