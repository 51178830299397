import { Button, Flex, Text } from '@chakra-ui/react'

import { BaseModal } from './BaseModal'

interface ModalConfirmationProps {
  onClose: () => void
  isOpen: boolean
  id: string
  title: string
  onSuccessAction?: () => void
  isLoading?: boolean
  toasts?: () => void
  buttonColorScheme?: string
  description?: string
}

export const ConfirmationModal: React.FC<ModalConfirmationProps> = (props) => {
  const {
    id,
    onClose,
    onSuccessAction,
    isLoading,
    title,
    toasts,
    isOpen,
    description,
    buttonColorScheme = 'blue',
  } = props

  const handleActionConfirm = () => {
    onClose()
    onSuccessAction?.()
    toasts?.()
  }

  const modalProps = {
    header: title,
    onClose,
    isOpen,
    id,
  }

  return (
    <BaseModal {...modalProps}>
      <Flex justifyContent={'flex-end'} mt="35px">
        {description && <Text>{description} </Text>}
        <Button fontWeight={'500'} mr="15px" onClick={onClose}>
          Отмена
        </Button>
        <Button
          fontWeight={'500'}
          onClick={handleActionConfirm}
          colorScheme={buttonColorScheme}
          isDisabled={isLoading}
          isLoading={isLoading}
        >
          Подтвердить
        </Button>
      </Flex>
    </BaseModal>
  )
}
