import React, { useEffect } from 'react'
import { Box, Button, Flex, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'

import { useDeleteUser } from 'features/users'
import { BaseModal, RoleTag } from 'shared/ui'
import { ROLES, SETTINGS_ROUTE, SETTINGS_USERS_ROUTE } from 'shared/constants'
import { rolesDict } from 'shared/dictionary'

interface IDeleteUserProps {
  userId: string
  userName: string
  userLogin: string
  userRole: string
}

interface IDeleteUser {
  deleteUserData: IDeleteUserProps
  onClose: () => void
  isOpen: boolean
}

export const DeleteUserModal: React.FC<IDeleteUser> = (props) => {
  const { deleteUserData, onClose, isOpen } = props
  const navigate = useNavigate()
  const { mutate, isLoading } = useDeleteUser()

  const handleDeleteUser = () =>
    mutate({
      userId: deleteUserData.userId,
      userName: deleteUserData.userName,
      successAction: () => {
        onClose()
        navigate(SETTINGS_USERS_ROUTE)
      },
    })

  const handleCloseModal = () => onClose()

  return (
    <BaseModal
      header="Удаление пользователя"
      id="delete user"
      isOpen={isOpen}
      onClose={handleCloseModal}
    >
      <Box>
        <Text fontWeight={'600'} mb={'5px'}>
          Вы действительно  хотите удалить пользователя?
        </Text>

        <Flex>
          <Text color={'gray.500'} w="80px" mb={'5px'}>
            ФИО:
          </Text>
          <Text fontWeight={'700'}>{deleteUserData?.userName}</Text>
        </Flex>

        <Flex>
          <Text color={'gray.500'} w="80px" mb={'5px'}>
            Логин:
          </Text>
          <Text fontWeight={'700'}>{deleteUserData?.userLogin}</Text>
        </Flex>

        <Flex>
          <Text color={'gray.500'} w="80px" mb={'5px'}>
            Роль:
          </Text>
          <Box>
            <RoleTag size="medium" role={deleteUserData?.userRole}>
              {rolesDict[deleteUserData?.userRole]}
            </RoleTag>
          </Box>
        </Flex>
      </Box>
      <Flex justifyContent={'flex-end'} mt="35px">
        <Button fontWeight={'500'} mr="15px" onClick={onClose}>
          Отмена
        </Button>
        <Button
          fontWeight={'500'}
          onClick={handleDeleteUser}
          colorScheme={'red'}
          isDisabled={isLoading}
          isLoading={isLoading}
        >
          Удалить
        </Button>
      </Flex>
    </BaseModal>
  )
}
