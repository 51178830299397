import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Flex } from '@chakra-ui/react'
import moment from 'moment'

import { Access } from 'app/providers'
import { accessDict, modulesDict } from 'shared/dictionary'
import { formatDateWithNominativeMonth } from 'shared/utils'
import {
  DatePickerField,
  DatePickerInput,
  EditButton,
  FormInputBlock,
  PopoverMessage,
  SelectInputMultiForm,
  SubmitCancelButtons,
  TextLabelTitle,
} from 'shared/ui'

import { useEditGroupDeliveryForm } from './UseEditGroupDeliveryForm'
import { useUpdateGroupDelivery } from '../models/services/useUpdateGroupDelivery'
import { DeleteGroupDelivery } from '../../deleteGroupDelivery'
import { checkPeriodsOverlap } from '../utils/checkPeriodsOverlap'
import { debounce } from 'lodash'

interface GroupDeliveryPeriods {
  date_from: string
  date_to: string
  id: string
}

interface GroupDeliveryProps {
  groupDeliveryData: {
    value: string
    label: string
  }[]
  gd_point_names: string[]
  date_from: string
  id: string
  date_to: string
  gs_activity_areas_id: string
  groupDeliveryPeriods: GroupDeliveryPeriods[]
}

export const EditGroupDelivery: React.FC<GroupDeliveryProps> = React.memo(
  (props) => {
    const {
      groupDeliveryPeriods,
      gd_point_names,
      date_from,
      date_to,
      groupDeliveryData,
      gs_activity_areas_id,
      id,
    } = props

    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [isOpenNotification, setIsOpenNotification] = useState<{
      isOpen: boolean
      message: string
    }>({
      isOpen: false,
      message: '',
    })

    const [errorPoints, setErrorPoints] = useState<{
      isError: boolean
      gd_point_names?: string[]
    }>({
      isError: false,
      gd_point_names: [],
    })
    const [filteredData, setFilteredData] = useState<
      GroupDeliveryProps['groupDeliveryData']
    >([])

    const { mutate: updateGroupDelivery, isLoading: isUpdatingGroupDelivery } =
      useUpdateGroupDelivery()

    const {
      register,
      handleSubmit,
      errors,
      reset,
      control,
      watchedFields,
      watch,
      setValue,
      getValues,
      isDirty,
      setError,
    } = useEditGroupDeliveryForm()

    const selectedOptions = watch('gd_point_names')
    const dateFrom = watch('date_from')
    const dateTo = watch('date_to')

    const transformGDPointNames = useCallback(() => {
      return gd_point_names.map((pointName) => {
        const matchingItem = groupDeliveryData.find(
          (item) => item.label === pointName,
        )
        return {
          label: pointName,
          value: matchingItem ? matchingItem.value : pointName,
        }
      })
    }, [gd_point_names, groupDeliveryData])

    useEffect(() => {
      if (!isEdit)
        setIsOpenNotification({
          isOpen: false,
          message: '',
        })
      else checkPeriods()
    }, [isEdit, dateFrom, dateTo, groupDeliveryPeriods])

    useEffect(() => {
      if (gd_point_names && date_from) {
        const transformedGDPointNames = transformGDPointNames()
        reset({
          date_from: moment(date_from).format('DD.MM.yyyy'),
          date_to: date_to ? moment(date_to).format('DD.MM.yyyy') : null,
          gd_point_names: transformedGDPointNames,
        })
      }
    }, [date_from, date_to, reset, transformGDPointNames])

    useEffect(() => {
      const selectedValues = new Set(selectedOptions?.map((opt) => opt.value))
      const availableOptions = groupDeliveryData.filter(
        (option) => !selectedValues.has(option.value),
      )
      setFilteredData(availableOptions)
    }, [groupDeliveryData, selectedOptions])

    useEffect(() => {
      setErrorPoints({ isError: false, gd_point_names: [] })
    }, [dateFrom, dateTo])

    useEffect(() => {
      const currentGdPointNames = watch('gd_point_names')
      if (errorPoints?.gd_point_names && currentGdPointNames) {
        const updatedGdPointNames = currentGdPointNames.map((item) => ({
          ...item,
          isErrored: errorPoints?.gd_point_names.includes(item.label),
        }))
        setValue('gd_point_names', updatedGdPointNames, {
          shouldValidate: true,
        })
      }
    }, [errorPoints?.gd_point_names, setValue, watch])

    useEffect(() => {
      if (errorPoints?.gd_point_names?.length > 0) handleSetError()
    }, [errorPoints?.gd_point_names])

    const handleSetError = debounce(() => {
      setError('gd_point_names', {
        type: 'custom',
        message: 'Проверьте ГТП',
      })
    }, 100)

    const checkPeriods = useCallback(() => {
      const newPeriod = {
        date_from: dateFrom
          ? moment(dateFrom, 'DD.MM.YYYY').format('YYYY-MM-DD')
          : null,
        date_to: dateTo
          ? moment(dateTo, 'DD.MM.YYYY').format('YYYY-MM-DD')
          : null,
      }

      if (newPeriod.date_from && newPeriod.date_to) {
        checkPeriodsOverlap(
          groupDeliveryPeriods,
          newPeriod,
          id,
          setIsOpenNotification,
        )
      }
    }, [dateFrom, dateTo, groupDeliveryPeriods, id, setIsOpenNotification])

    const handleEditClick = useCallback(() => {
      setIsEdit(!isEdit)
    }, [isEdit])

    const handleResetForm = useCallback(() => {
      reset()
    }, [reset])

    const onSubmit = ({
      date_from: dateFrom,
      date_to: dateTo,
      gd_point_names,
    }) => {
      if (isOpenNotification.isOpen) return

      const pointNamesArray = gd_point_names.map((point) => point.label)

      updateGroupDelivery({
        date_from: moment(date_from).format('yyyy-MM-DD'),
        date_to: date_to ? moment(date_to).format('yyyy-MM-DD') : null,
        new_date_from: moment(dateFrom).format('YYYY-MM-DD'),
        new_date_to: dateTo ? moment(dateTo).format('YYYY-MM-DD') : null,
        gd_point_names: pointNamesArray,
        gs_activity_areas_id,
        successAction: () => setIsEdit(false),
        errorAction(gd_point_names) {
          setErrorPoints({ isError: true, gd_point_names })
        },
      })
    }

    const commonInputProps = useMemo(
      () => ({
        control,
        register,
        errors,
        watchedFields,
        size: 'sm' as 'sm',
        smallErrorTextInside: true,
        isRequired: true,
      }),
      [register, errors, watchedFields, control],
    )

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex position={'relative'}>
          <PopoverMessage
            triggerElement={<Box position={'absolute'} w={'300px'} />}
            isOpen={isOpenNotification.isOpen}
            message={isOpenNotification.message}
            title="Проверьте выбранный период"
            onClick={() =>
              setIsOpenNotification({ isOpen: false, message: null })
            }
          />
          <Access
            permissions={[accessDict.update_read]}
            module={modulesDict.reference_books}
          >
            <Flex
              w="100%"
              justifyContent="flex-end"
              position={'absolute'}
              right={'15px'}
              top={'15px'}
            >
              <EditButton
                handleEditClick={handleEditClick}
                isEdit={isEdit}
                size={'xs'}
              />
            </Flex>
          </Access>

          <Box p={'10px'} w={'350px'}>
            <FormInputBlock
              valueWidth={'150px'}
              titleWidth={'80px'}
              allowEdit={true}
              title={'Дата с'}
              edit={isEdit}
              value={formatDateWithNominativeMonth(new Date(date_from))}
            >
              <DatePickerField
                format="dd.MM.yyyy"
                maxDate={dateTo ? moment(dateTo, 'DD.MM.yyyy').toDate() : null}
                showMonthYearPicker
                name={'date_from'}
                placeholder={'Дата от'}
                type={'text'}
                theme="monthPicker"
                {...commonInputProps}
              />
            </FormInputBlock>
            <Box m={'8px'} />

            <FormInputBlock
              titleWidth={'80px'}
              allowEdit={true}
              title={'Дата по'}
              edit={isEdit}
              value={
                date_to
                  ? formatDateWithNominativeMonth(new Date(date_to))
                  : 'Текущий момент'
              }
            >
              <DatePickerField
                format="dd.MM.yyyy"
                minDate={
                  dateFrom ? moment(dateFrom, 'DD.MM.yyyy').toDate() : null
                }
                showMonthYearPicker
                name={'date_to'}
                placeholder={'Дата до'}
                type={'text'}
                theme="monthPicker"
                {...commonInputProps}
              />
            </FormInputBlock>
          </Box>
          <Box p={'10px'} w={'500px'}>
            <Flex>
              <TextLabelTitle w={'50px'}>ГТП: </TextLabelTitle>
              <SelectInputMultiForm
                isCreatable
                isReadOnly={!isEdit}
                name={'gd_point_names'}
                data={filteredData}
                placeholder="Выберите ГТП"
                isClearable
                isMulti
                {...commonInputProps}
              />
            </Flex>
          </Box>
        </Flex>
        {isEdit && (
          <Flex p={'15px'}>
            <SubmitCancelButtons
              isDirty={isDirty}
              isUpdating={isUpdatingGroupDelivery}
              handleCancel={handleResetForm}
            />
            <Box ml={isDirty ? '10px' : '0'}>
              <DeleteGroupDelivery
                gs_activity_areas_id={gs_activity_areas_id}
                date_from={date_from}
                date_to={date_to}
                cancelEdit={() => setIsEdit(false)}
              />
            </Box>
          </Flex>
        )}
      </form>
    )
  },
)
