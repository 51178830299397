import { Icon } from '@chakra-ui/react'

import {
  AGREEMENT,
  CALENDAR,
  COUNTER_PARTIES,
  REFERENCE_BOOKS_ROUTE,
  PRICE_CATEGORIES,
  REGIONS,
} from 'shared/constants'

import {
  FcBullish,
  FcMoneyTransfer,
  FcRatings,
  FcBusinessContact,
  FcInspection,
  FcExport,
  FcAddressBook,
} from 'react-icons/fc'

export const CARDS_DATA = [
  // {
  //   heading: 'Обьемы',
  //   icon: <Icon as={FcBullish} w={6} h={6} />,
  //   links: [
  //     { name: 'Импорт почасовых данных', url: '' },
  //     { name: 'Работа с показаниями', url: '' },
  //     { name: 'Проверка данных', url: '' },
  //     { name: 'Отчеты о расходе', url: '' },
  //   ],
  // },
  // {
  //   heading: 'Цены',
  //   icon: <Icon as={FcRatings} w={6} h={6} />,
  //   links: [
  //     { name: 'Импорт ценовых параметров', url: '' },
  //     { name: 'Проверка данных', url: '' },
  //     { name: 'Справочник', url: '' },
  //   ],
  // },
  // {
  //   heading: 'финансы',
  //   icon: <Icon as={FcMoneyTransfer} w={6} h={6} />,
  //   links: [
  //     { name: 'Сформировать документы', url: '' },
  //     { name: 'Импорт платежей', url: '' },
  //     { name: 'Проверить документы от поставщика', url: '' },
  //     { name: 'Работа с задолженностью', url: '' },
  //   ],
  // },
  // {
  //   heading: 'Контрагенты и договоры',
  //   icon: <Icon as={FcBusinessContact} w={6} h={6} />,
  //   links: [
  //     { name: 'Добавить контрагента', url: '' },
  //     { name: 'Справочник контрагентов', url: '' },
  //   ],
  // },
  // {
  //   heading: 'Внутренняя отчетность',
  //   icon: <Icon as={FcInspection} w={6} h={6} />,
  //   links: [{ name: 'Отчет о маржинальной прибыли', url: '' }],
  // },
  // {
  //   heading: 'Внешняя отчетность',
  //   icon: <Icon as={FcExport} w={6} h={6} />,
  //   links: [
  //     { name: 'ГИС ТЭК', url: '' },
  //     { name: 'Региональная отчетность', url: '' },
  //   ],
  // },
  {
    heading: 'Справочники',
    icon: <Icon as={FcAddressBook} w={6} h={6} />,
    links: [
      { name: 'Календарь', url: `${REFERENCE_BOOKS_ROUTE}/${CALENDAR}` },
      { name: 'Регионы', url: `${REFERENCE_BOOKS_ROUTE}/${REGIONS}` },
      {
        name: 'Ценовые категории',
        url: `${REFERENCE_BOOKS_ROUTE}/${PRICE_CATEGORIES}`,
      },
      {
        name: 'Контрагенты и договора',
        url: `${REFERENCE_BOOKS_ROUTE}/${COUNTER_PARTIES}`,
      },
    ],
  },
]
