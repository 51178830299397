import { AxiosError, AxiosResponse } from 'axios'
import { UseMutateFunction, useMutation, useQueryClient } from 'react-query'
import { queryKeys } from 'shared/constants'

import { AddNewUserRequest, AddNewUserResponse } from 'shared/models'
import { UserService } from 'shared/services'
import { toasts } from 'shared/utils'

interface AddUserWithSuccessAction extends AddNewUserRequest {
  successAction: () => void
}

export function useAddUser(): {
  mutate: UseMutateFunction<
    AxiosResponse<AddNewUserResponse>,
    AxiosError,
    AddUserWithSuccessAction,
    unknown
  >
  isLoading: boolean
} {
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation(
    ({
      email,
      login,
      role,
      last_name,
      first_name,
      middle_name,
      position_name,
      users_companies,
    }: AddUserWithSuccessAction) =>
      UserService.addNewUser({
        email,
        login,
        role,
        last_name,
        first_name,
        middle_name,
        position_name,
        users_companies,
      }),
    {
      onSuccess: (
        data: AxiosResponse<AddNewUserResponse>,
        variables: AddUserWithSuccessAction,
      ) => {
        if (variables.successAction) {
          variables.successAction()
          toasts.success({
            title: 'Пользователь добавлен',
            description: `Пользователю с электронной почтой ${variables.email} было отправленно письмо для подтверждения регистрации`,
          })
        }

        queryClient.setQueryData([queryKeys.user.addNewUser], data)
        queryClient.invalidateQueries([
          queryKeys.dictionaries.employeesPositions,
        ])
        queryClient.invalidateQueries([queryKeys.users.allUsers])
        queryClient.invalidateQueries([queryKeys.companies.editableCompanies])
        queryClient.invalidateQueries([queryKeys.companies.allCompanies])
      },
      onError: () =>
        toasts.error({
          title: 'Произошла ошибка',
          description: 'Пользователь не был добавлен',
        }),
    },
  )
  return { mutate, isLoading }
}
