import { AxiosPromise } from 'axios'

import { ServiceBase } from './serviceBase'
export class RolesService extends ServiceBase {
  protected static BASE_PATH = 'roles'

  public static getRoles(): AxiosPromise<any> {
    return this.get<any>(`${this.BASE_PATH}`)
  }
}
