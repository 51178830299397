import { ReactNode, ComponentProps } from 'react'
import { Text } from '@chakra-ui/react'

interface TextLabelTitleProps
  extends Omit<ComponentProps<typeof Text>, 'size'> {
  size?: 'small' | 'medium' | 'large'
}

export const TextLabelTitle: React.FC<TextLabelTitleProps> = (props) => {
  const { size = 'small', children, ...rest } = props

  const styles = {
    small: {
      color: '#718096',
      weight: '120px',
      fontSize: '12px',
      fontWeight: '500',
    },
    medium: {
      color: '#718096',
      weight: '120px',
      fontSize: '16px',
      fontWeight: '500',
    },
  }

  return (
    <Text {...styles[size]} {...rest}>
      {children}
    </Text>
  )
}
