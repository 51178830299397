import { Box, Button, Flex, Text } from '@chakra-ui/react'

import { BaseModal } from 'shared/ui'
import { TypesCounteragentDetailResponse } from 'shared/models'
import { useDeleteRelatedTypeContract } from '../models/services/deleteRelatedTypeContractService'

// misc
import warningLogo from 'assets/svg/warning_logo.svg'

interface DeleteRelatedTypeContract {
  onClose: () => void
  isOpen: boolean
  actionData: TypesCounteragentDetailResponse
}

export const DeleteRelatedTypeContract: React.FC<DeleteRelatedTypeContract> = (
  props,
) => {
  const { actionData, isOpen, onClose } = props

  const { mutate: deleteRelatedTypeContract, isLoading } =
    useDeleteRelatedTypeContract()

  const handleCloseModal = () => onClose()

  const handleDeleteTypeCounteragents = () => {
    deleteRelatedTypeContract({
      type_counteragent_id: actionData.type_counteragent_id,
      related_tc_id: actionData.related_tc_id,
      successAction: () => {
        onClose()
      },
    })
  }

  return (
    <div>
      <BaseModal
        header="Удаление связанного вида договора"
        id="delete user"
        isOpen={isOpen}
        onClose={handleCloseModal}
      >
        <Box>
          <Flex justifyContent={'center'}>
            <Box w={'50px'} mb={'15px'}>
              <img src={warningLogo} alt="Warning" />
            </Box>
          </Flex>
          <Text
            fontWeight={'800'}
            mb={'5px'}
            color={'red.500'}
            textAlign={'center'}
          >
            Связанный вида договора будет удален
          </Text>
          <Text fontWeight={'600'} mb={'5px'} textAlign={'center'}>
            Вы действительно хотите удалить следующий вид договора{' '}
            <b>{actionData.full_name}</b>?
          </Text>
        </Box>
        <Flex justifyContent={'flex-end'} mt="35px">
          <Button fontWeight={'500'} mr="15px" onClick={onClose}>
            Отмена
          </Button>
          <Button
            fontWeight={'500'}
            onClick={handleDeleteTypeCounteragents}
            colorScheme={'red'}
            isDisabled={isLoading}
            isLoading={isLoading}
          >
            Удалить
          </Button>
        </Flex>
      </BaseModal>
    </div>
  )
}
