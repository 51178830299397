import React from 'react'
import { Text, Flex } from '@chakra-ui/react'
import { ListView } from 'shared/ui'

interface SalesAllowancesItemProps {
  allowance: string
  sale_allowance_id: string
  code_id: number
}

export const SalesAllowancesItem: React.FC<SalesAllowancesItemProps> = (
  props,
) => {
  const { code_id, allowance } = props

  return (
    <ListView.Stacks>
      <Flex alignItems={'center'} ml={'5px'}>
        <Text w={'35px'} fontSize={'sm'}>
          {code_id}
        </Text>

        <Text fontSize={'sm'} textAlign={'left'} maxW={'4xl'}>
          {allowance}
        </Text>
      </Flex>
    </ListView.Stacks>
  )
}
