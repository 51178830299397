import { useQuery } from 'react-query'

import { queryKeys } from 'shared/constants'
import { UserService } from 'shared/services'

interface EmployeePosition {
  value: string
  label: string
}

interface UseGetEmployeesPositionsResult {
  data: string[] | undefined
  isError: boolean
  isFetching: boolean
  employeesPositionsData: EmployeePosition[] | undefined
}

interface UseGetUsers {
  enabled: boolean
}

export function useGetEmployeesPositions({
  enabled,
}: UseGetUsers): UseGetEmployeesPositionsResult {
  const { data, isError, isFetching } = useQuery<string[], Error>({
    queryKey: [queryKeys.dictionaries.employeesPositions],
    queryFn: () =>
      UserService.getEmployeesPositions().then((response) => response.data),
    enabled,
    retry: false,
  })

  const employeesPositionsData = data?.map((position) => ({
    value: position,
    label: position,
  }))

  return { data, isError, isFetching, employeesPositionsData }
}
