import { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'

import { UserInfoResponse } from 'shared/models'
import { queryKeys } from 'shared/constants'
import { UserService } from 'shared/services'

interface UseGetUserInfoResult {
  data: AxiosResponse<UserInfoResponse> | undefined
  usersInfo: UserInfoResponse | null
  isError: boolean
  isFetching: boolean
  isLoading: boolean
}

interface UseGetUserInfo {
  userId: string
  enabled?: boolean
  successAction?: () => void
  errorAction?: () => void
}

export function useGetUsersInfoById({
  userId,
  enabled = false,
  successAction = () => {},
  errorAction = () => {},
}: UseGetUserInfo): UseGetUserInfoResult {
  const { data, isError, isFetching, isLoading } = useQuery<
    AxiosResponse<UserInfoResponse>
  >({
    queryKey: [queryKeys.users.userInfo, userId],
    queryFn: () => UserService.getUserInfoById(userId),
    enabled,
    retry: false,
    onSuccess: (responseData) => successAction(),
    onError: () => errorAction(),
  })

  const usersInfo = data ? data.data : null

  return { data, usersInfo, isError, isFetching, isLoading }
}
