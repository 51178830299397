import React from 'react'

import { TariffTransmissionServiceResponse } from 'shared/models'
import { ListView } from 'shared/ui'
import { TransmissionServiceItem } from './TransmissionServiceItem'

interface TransmissionServiceProps {
  tariffTransmission: TariffTransmissionServiceResponse[]
}

export const TransmissionServiceDataList: React.FC<TransmissionServiceProps> = (
  props,
) => {
  const { tariffTransmission } = props

  return (
    <ListView.Lists>
      {tariffTransmission.map((tariff) => (
        <TransmissionServiceItem
          key={tariff.tariff_service_id}
          tariff={tariff.tariff}
          tariff_service_id={tariff.tariff_service_id}
          code_id={tariff.code_id}
        />
      ))}
    </ListView.Lists>
  )
}
