import { useQuery } from 'react-query'

import { GroupDeliveryResponse } from 'shared/models'
import { queryKeys } from 'shared/constants'
import { ReferenceBookService } from 'shared/services'

interface GroupDelivery {
  value: string
  label: string
}

interface UseGetAllGroupDeliveryResult {
  data: GroupDeliveryResponse[] | undefined
  isError: boolean
  isFetching: boolean
  isLoading: boolean
  groupDeliveryData: GroupDelivery[] | undefined
}

interface UseAllGroupDelivery {
  enabled: boolean
}

export function useGetAllGroupDelivery({
  enabled,
}: UseAllGroupDelivery): UseGetAllGroupDeliveryResult {
  const { data, isError, isFetching, isLoading } = useQuery<
    GroupDeliveryResponse[]
  >({
    queryKey: [queryKeys.referenceBooks.activityAreas.allGroupDelivery],
    queryFn: () =>
      ReferenceBookService.getAllGroupDelivery().then(
        (response) => response.data,
      ),
    enabled,
    retry: false,
  })

  const groupDeliveryData = data?.map((group) => ({
    value: group.gd_points_id,
    label: group.gd_point_name,
  }))

  return { data, isError, isFetching, isLoading, groupDeliveryData }
}
