import { type ReactNode } from 'react'
import { Outlet } from 'react-router-dom'

import './Layout.scss'

type LayoutOwnPropsType = {
  navbarSlot?: ReactNode
  headerSlot?: ReactNode
  bottomSlot?: ReactNode
  sidebarSlot?: ReactNode
}

export function Layout(props: LayoutOwnPropsType) {
  const { navbarSlot, headerSlot, bottomSlot, sidebarSlot } = props

  return (
    <div className="layout">
      {headerSlot && <div className="header">{headerSlot}</div>}
      {navbarSlot && <div className="navbar"> {navbarSlot}</div>}
      <div className="layout-body">
        <div className="outlet">
          <Outlet />
        </div>
        {sidebarSlot && <aside className="layout">{sidebarSlot}</aside>}
      </div>
      {bottomSlot}
    </div>
  )
}
