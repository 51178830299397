import { useQuery } from 'react-query'

import { SalesAllowancesResponse } from 'shared/models'
import { queryKeys } from 'shared/constants'
import { PriceCategoriesService } from 'shared/services'

interface SalesAllowancesData {
  value: string
  label: string
}

interface UseGetSalesAllowancesResult {
  data: SalesAllowancesResponse[] | undefined
  isError: boolean
  isFetching: boolean
  isLoading: boolean
  salesAllowancesData: SalesAllowancesData[] | undefined
}

interface UseAllSalesAllowances {
  enabled: boolean
}

export function useGetAllSalesAllowances({
  enabled,
}: UseAllSalesAllowances): UseGetSalesAllowancesResult {
  const { data, isError, isFetching, isLoading } = useQuery<
    SalesAllowancesResponse[]
  >({
    queryKey: [queryKeys.priceCategories.salesAllowances.allSalesAllowances],
    queryFn: () =>
      PriceCategoriesService.getSalesAllowances().then(
        (response) => response.data,
      ),
    enabled,
    retry: false,
  })

  const salesAllowancesData = data?.map((allowance) => ({
    value: allowance.sale_allowance_id,
    label: allowance.allowance,
  }))

  return { data, isError, isFetching, isLoading, salesAllowancesData }
}
