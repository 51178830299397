/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useMemo, useState } from 'react'
import { useDisclosure } from '@chakra-ui/react'

// import MOCK_DATA from './mockData.json'
// import MOCK_DATA from './mockDataSmall.json'

import { useGetUserInfo } from 'entities/User'
import { DeleteUserModal } from 'features/users'
import { BasicTable } from 'shared/ui'

import { TableStructure } from './TableStructure'
import { IDeleteUserProps } from '../models/types/tableTypes'
import { IUsersData } from 'shared/models'

interface ITableEntityProps {
  loading: boolean
  users: IUsersData[]
}

export const TableEntity: React.FC<ITableEntityProps> = (props) => {
  const { loading, users } = props
  const { userInfo } = useGetUserInfo({
    enabled: false,
  })

  const [deleteUserData, setDeleteUserData] = useState<IDeleteUserProps | null>(
    null,
  )

  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleDeleteUser = useCallback(
    ({ userId, userName }: IDeleteUserProps) => {
      // setDeleteUserData({ userId, userName })
      onOpen()
    },
    [onOpen, isOpen],
  )

  const columns = useMemo(
    () =>
      TableStructure(handleDeleteUser, userInfo?.role_name, userInfo.user_id),
    [],
  )

  const data = useMemo(() => users, [users])

  const tableProps = useMemo(
    () => ({
      tableStyles: {
        tableMaxHeight: '80vh',
        tableWidth: '100%',
      },
      data,
      columns,
      emptyText: 'Пользователей не найдено, добавьте нового пользователя.',
      loading,
    }),
    [data, columns, loading],
  )

  const deleteUserModalProps = {
    isOpen,
    onClose,
    deleteUserData,
  }

  return (
    <>
      <DeleteUserModal {...deleteUserModalProps} />
      <BasicTable {...tableProps} />
    </>
  )
}
