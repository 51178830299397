import React, { forwardRef, useCallback } from 'react'
import moment from 'moment'
import Calendar from 'react-datepicker'
import ru from 'date-fns/locale/ru'
import clsx from 'clsx'

import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  Path,
  UseFormRegister,
} from 'react-hook-form'

import { FormInputControl } from '../FormInputControl'

interface DatePickerInputProps<T> {
  control: Control<T>
  register: UseFormRegister<T>
  errors: FieldErrors<T>
  watchedFields: T
  name: Path<T>
  placeholder: string
  type: string
  isRequired: boolean
  onChangeFormatter?: (inputValue: string) => string
  showMonthYearPicker?: boolean
  showFullMonthYearPicker?: boolean
  showTimeSelect?: boolean
  inline?: boolean
  isClearable?: boolean
  format?: string
  theme?: 'holydays' | 'dates' | 'monthPicker'
  minDate?: Date
  maxDate?: Date
  isReadOnly?: boolean
  size?: 'sm' | 'md' | 'xs'
  smallErrorTextInside?: boolean
}

const classes = {
  holydays: 'holydaysWrapper',
  dates: 'datesWrapper',
  monthPicker: 'monthPicker',
}

const getDayClassName = (date) => {
  const dayOfWeek = moment(date).day()
  return dayOfWeek === 0 || dayOfWeek === 6 ? 'weekend-day' : ''
}

export const DatePickerInput = <TFieldValues extends FieldValues>({
  control,
  name,
  placeholder,
  register,
  errors,
  watchedFields,
  isRequired,
  theme,
  format = 'dd.MM.yyyy',
  smallErrorTextInside = false,
  showMonthYearPicker = false,
  showFullMonthYearPicker = false,
  showTimeSelect = false,
  isClearable = false,
  inline = false,
  isReadOnly = false,
  minDate = null,
  maxDate = null,
  size = 'md',
  type = 'text',
  ...rest
}: DatePickerInputProps<TFieldValues>) => {
  const className = classes[theme] || classes.dates

  const ExampleCustomInput = forwardRef<HTMLInputElement, any>(
    ({ handleReset, value, ...restProps }, ref) => {
      // const handleKeyDown = useCallback(
      //   (event: React.KeyboardEvent) => {
      //     if (event.key === 'Backspace') handleReset()
      //   },
      //   [handleReset],
      // )

      return (
        <FormInputControl
          {...restProps}
          size={size}
          // onKeyDown={handleKeyDown}
          // isReadOnly={true}
          name={name}
          register={register}
          errors={errors}
          watchedFields={watchedFields}
          placeholder={placeholder}
          type={type}
          isRequired={isRequired}
          smallErrorTextInside={smallErrorTextInside}
          ref={ref}
        />
      )
    },
  )

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, value } }) => {
        // const handleChange = useCallback(
        //   (date) => {
        //     const formattedDate = date ? moment(date).format('DD.MM.yyyy') : ''
        //     onChange(formattedDate)
        //   },
        //   [onChange],
        // )

        const selected = value ? moment(value, 'DD.MM.yyyy').toDate() : null

        const handleReset = useCallback(() => {
          onChange('')
        }, [onChange])

        return (
          <div className={clsx(className)}>
            <Calendar
              minDate={minDate}
              maxDate={maxDate}
              dayClassName={getDayClassName}
              customInput={<ExampleCustomInput handleReset={handleReset} />}
              // startDate={moment().toDate()}
              // startDate={startDate}
              disabledKeyboardNavigation
              // open
              selected={selected}
              onChange={onChange}
              // id={id}
              // onBlur={onBlur}
              // dateFormat={'dd.MM.yyyy'}
              dateFormat={format}
              showMonthYearPicker={showMonthYearPicker}
              showFullMonthYearPicker={showFullMonthYearPicker}
              showTimeSelect={showTimeSelect}
              showPopperArrow={false}
              locale={ru}
              inline={inline}
              shouldCloseOnSelect
              isClearable={isClearable}
              // popperContainer={CalendarContainer}
              {...rest}
            />
          </div>
        )
      }}
    />
  )
}
