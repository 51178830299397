import React, { useCallback, useState, memo } from 'react'

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Box,
  Text,
  Center,
  Spinner,
} from '@chakra-ui/react'

import { DeleteCompany, EditCompany } from 'features/companies'
import { useGetEditableCompanies } from 'entities/companies'

import { Access } from 'app/providers'
import { useGetUserInfo } from 'entities/User'
import { accessDict, modulesDict } from 'shared/dictionary'
import { TextTitle } from 'shared/ui'

// misc
import { ChevronDownIcon } from '@chakra-ui/icons'

export const CompaniesList: React.FC = memo(() => {
  const { userInfo } = useGetUserInfo({
    enabled: false,
  })

  const { data, isLoading } = useGetEditableCompanies({
    enabled: !!userInfo?.user_id,
  })

  const [expandedIds, setExpandedIds] = useState<string[]>([])

  const handleToggle = useCallback((id: string) => {
    setExpandedIds((currentIds) =>
      currentIds.includes(id)
        ? currentIds.filter((currentId) => currentId !== id)
        : [...currentIds, id],
    )
  }, [])

  if (isLoading)
    return (
      <Center>
        <Spinner
          thickness="3px"
          speed="0.45s"
          emptyColor="gray.200"
          color="blue.500"
          size="lg"
        />
      </Center>
    )

  if (!data || data.length === 0) return <Text>Компании отсутствуют</Text>

  return (
    <>
      <TextTitle>Список компаний</TextTitle>
      <Box
        mt="20px"
        borderLeft="1px solid #dfe0eb"
        borderRight="1px solid #dfe0eb"
      >
        <Accordion allowMultiple width="100%" rounded="lg" display="block">
          {data &&
            data.map((company) => (
              <AccordionItem key={company.company_id}>
                <AccordionButton
                  onClick={() => handleToggle(company.company_id)}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  p={2}
                  bg="#ebeeff3c"
                >
                  <Text fontSize="16px" fontWeight="700">
                    {company.short_name}
                  </Text>
                  <ChevronDownIcon />
                </AccordionButton>

                <AccordionPanel pb={4}>
                  <EditCompany
                    companyId={company.company_id}
                    isExpanded={expandedIds.includes(company.company_id)}
                  />
                  <Access
                    permissions={[accessDict.full_access]}
                    module={modulesDict.companies}
                  >
                    <DeleteCompany
                      companyId={company.company_id}
                      companyName={company.short_name}
                    />
                  </Access>
                </AccordionPanel>
              </AccordionItem>
            ))}
        </Accordion>
      </Box>
    </>
  )
})
