import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  memo,
} from 'react'
import { useMatch } from 'react-router-dom'
import { Flex, Spinner } from '@chakra-ui/react'
import { useQueryClient } from 'react-query'

import { Authentication } from 'widgets'
import { useGetUserInfo } from 'entities/User'
import { useGetUsers } from 'entities/Users'

import {
  AuthenticationProps,
  useAuthentication,
  useCheckRefresh,
  useLogOut,
} from 'features/authentication'

import { Loader, LoadingOverlay } from 'shared/ui'
import { ServiceBase } from 'shared/services'
import { LOCAL_STORAGE_ENTITY, REGISTRATION_ROUTE } from 'shared/constants'
import { localStorageHandler, toasts } from 'shared/utils'

import { PageScope } from '../models/scope/AuthProviderContext'
import { useGetEmployeesPositions } from 'entities/employeesPositions'
import { useGetAllCompanies } from 'entities/companies'
import { useGetUsersPermissions } from 'entities/usersPermissions'
import { AccessContext } from 'app/providers/accessProvider'

enum AppPage {
  pending,
  auth,
  app,
}

interface AppProps {
  children: React.ReactNode
}

export const AuthProvider: React.FC<AppProps> = memo(({ children }) => {
  const [loadResources, setLoadResources] = useState<boolean>(false)
  const accessContext = useContext(AccessContext)

  const queryClient = useQueryClient()

  const { mutate: useFetchLogOut } = useLogOut()

  useGetUsers({
    enabled: loadResources,
  })

  useGetEmployeesPositions({
    enabled: loadResources,
  })

  useGetAllCompanies({
    enabled: loadResources,
  })

  const { mutate: authenticate, isLoading } = useAuthentication()

  const isRegistrationUrl = useMatch(REGISTRATION_ROUTE)
  const [appState, setAppState] = useState<AppPage>(AppPage.pending)

  const loadAppResources = () => setLoadResources(true)

  const onLogout = useCallback(() => {
    setAppState(AppPage.auth)
    localStorageHandler.removeFromLocalStorage(LOCAL_STORAGE_ENTITY.AUTH_KEY)
    localStorageHandler.removeFromLocalStorage(LOCAL_STORAGE_ENTITY.USER_ROLE)
    accessContext.setAccess([], [])
    setLoadResources(false)
    queryClient.clear()
  }, [
    setAppState,
    localStorageHandler,
    accessContext,
    setLoadResources,
    queryClient,
  ])

  useEffect(() => {
    if (isRegistrationUrl) setAppState(AppPage.auth)
  }, [isRegistrationUrl])

  const token: string = localStorage.getItem(LOCAL_STORAGE_ENTITY.AUTH_KEY)
  const checkTokenExists: boolean = !!token

  useEffect(() => {
    if (!token) onLogout()
  }, [token])

  useCheckRefresh(
    checkTokenExists,
    (token) => {
      localStorage.setItem(LOCAL_STORAGE_ENTITY.AUTH_KEY, token)
      setAppState(isRegistrationUrl ? AppPage.auth : AppPage.app)
    },
    () => onLogout(),
  )

  const { userInfo, isLoading: isLoadingUserInfo } = useGetUserInfo({
    enabled: checkTokenExists && appState === AppPage.app,
    successAction: () => {
      localStorage.setItem(LOCAL_STORAGE_ENTITY.AUTH_KEY, token)
      setAppState(isRegistrationUrl ? AppPage.auth : AppPage.app)
    },
    errorAction: () => onLogout(),
  })

  const { userModuleRights } = useGetUsersPermissions({
    userId: userInfo?.user_id,
    enabled: !!userInfo?.user_id,
  })

  useEffect(() => {
    ServiceBase.setOnAuthErrorCallback(() => {
      setAppState(AppPage.auth)
    })
    return () => ServiceBase.clearOnAuthErrorCallback()
  }, [])

  useEffect(() => {
    if (appState === AppPage.app && userInfo && userModuleRights) {
      const userRoles = [userInfo.role_name]
      const userPermissions = userModuleRights.map(({ module, access }) => ({
        module,
        access,
      }))

      accessContext.setAccess(userRoles, userPermissions)
      loadAppResources()
    }
  }, [userInfo, userModuleRights, appState])

  const handleLogout = useCallback(() => {
    onLogout()
    toasts.success({
      description: 'Выход из системы выполнен',
    })
  }, [onLogout])

  const handleSignIn = useCallback(
    ({ username, password }: AuthenticationProps) => {
      authenticate({
        username,
        password,
        successAction: (token: string) => {
          localStorage.setItem(LOCAL_STORAGE_ENTITY.AUTH_KEY, token)
          ServiceBase.setAuthToken(token)
          setAppState(AppPage.app)
          loadAppResources()
        },
      })
    },
    [authenticate],
  )

  if (isLoadingUserInfo)
    return (
      <>
        <Flex align="center" justify="center" height="100vh">
          <Loader size="md" />
        </Flex>
      </>
    )

  const renderContent = () => {
    switch (appState) {
      case AppPage.pending:
        return (
          <Flex align="center" justify="center" height="100vh">
            <Loader size="md" />
          </Flex>
        )
      case AppPage.auth:
        return (
          <PageScope.Provider value={{ handleLogout, handleSignIn, isLoading }}>
            <Authentication isRegistrationUrl={isRegistrationUrl} />
          </PageScope.Provider>
        )
      case AppPage.app:
        return (
          <PageScope.Provider value={{ handleLogout, handleSignIn, isLoading }}>
            <LoadingOverlay>{children}</LoadingOverlay>
          </PageScope.Provider>
        )
      default:
        return null
    }
  }
  return renderContent()
})
