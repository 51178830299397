import { useQuery } from 'react-query'

import { EnergySystemResponse } from 'shared/models'
import { queryKeys } from 'shared/constants'
import { ReferenceBookService } from 'shared/services'

interface EnergySystems {
  value: string
  label: string
}

interface UseGetEnergySystemsResult {
  data: EnergySystemResponse[] | undefined
  isError: boolean
  isFetching: boolean
  isLoading: boolean
  energySystemsData: EnergySystems[] | undefined
}

interface UseAllEnergySystems {
  enabled: boolean
}

export function useGetAllEnergySystems({
  enabled,
}: UseAllEnergySystems): UseGetEnergySystemsResult {
  const { data, isError, isFetching, isLoading } = useQuery<
    EnergySystemResponse[]
  >({
    queryKey: [queryKeys.referenceBooks.energySystems.allEnergySystems],
    queryFn: () =>
      ReferenceBookService.getAllEnergySystems().then(
        (response) => response.data,
      ),
    enabled,
    retry: false,
  })

  const energySystemsData = data?.map((system) => ({
    value: system.ue_system_id,
    label: system.system_name,
  }))

  return { data, isError, isFetching, isLoading, energySystemsData }
}
