import { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'

import { UserCompaniesResponse } from 'shared/models'
import { queryKeys } from 'shared/constants'
import { CompaniesService } from 'shared/services'

interface UserCompaniesData {
  value: string
  label: string
}

interface UseGetUserInfoResult {
  data: AxiosResponse<UserCompaniesResponse[]> | undefined
  userCompanies: UserCompaniesResponse[] | null
  isError: boolean
  isFetching: boolean
  isLoading: boolean
  userCompaniesData: UserCompaniesData[] | undefined
}

interface UseGetUserInfo {
  userId: string
  enabled?: boolean
  successAction?: () => void
  errorAction?: () => void
}

export function useGetUserCompanies({
  userId,
  enabled = false,
  successAction = () => {},
  errorAction = () => {},
}: UseGetUserInfo): UseGetUserInfoResult {
  const { data, isError, isFetching, isLoading } = useQuery<
    AxiosResponse<UserCompaniesResponse[]>
  >({
    queryKey: [queryKeys.user.companies, userId],
    queryFn: () => CompaniesService.getUserCompanies(userId),
    enabled,
    retry: false,
    onSuccess: (responseData) => successAction && successAction(),
    onError: () => errorAction && errorAction(),
  })

  const userCompanies = data ? data.data : null

  const userCompaniesData = data?.data?.map((company) => ({
    value: company.company_id,
    label: company.short_name,
  }))

  return {
    data,
    userCompanies,
    isError,
    isFetching,
    isLoading,
    userCompaniesData,
  }
}
