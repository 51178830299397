import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { ChakraProvider, useDisclosure } from '@chakra-ui/react'
import { createStandaloneToast } from '@chakra-ui/react'

import { WORK_MODE } from 'shared/constants'

import { AppRouter, ModalProvider, queryClient } from './providers'

import { AccessProvider } from './providers/accessProvider'

function AppEntry() {
  const modalDisclosure = useDisclosure()
  const { ToastContainer } = createStandaloneToast()

  return (
    <AccessProvider>
      <ChakraProvider>
        <QueryClientProvider client={queryClient}>
          <ModalProvider modalDisclosure={modalDisclosure}>
            <AppRouter />
          </ModalProvider>
          <ToastContainer />
          {import.meta.env.MODE === WORK_MODE.DEVELOPMENT && (
            <ReactQueryDevtools />
          )}
        </QueryClientProvider>
      </ChakraProvider>
    </AccessProvider>
  )
}

export default AppEntry
