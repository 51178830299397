import React, { useEffect, useState, ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Flex, Heading, Progress, Text } from '@chakra-ui/react'

import { HOME_ROUTE } from 'shared/constants'
import { useAccess } from './UseAccess'

interface PrivateRouteProps {
  permissions?: string[]
  module?: string
  roles?: string[]
  children: ReactNode
}

export const PrivateRouteGate: React.FC<PrivateRouteProps> = ({
  permissions,
  module,
  roles,
  children,
}) => {
  const { hasAccess } = useAccess()
  const navigate = useNavigate()
  const [progress, setProgress] = useState(100)
  const [allowed, setAllowed] = useState<boolean>(true)
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => setIsVisible(true), 100)
    const accessAllowed = hasAccess({ permissions, module, roles })
    setAllowed(accessAllowed)

    if (!accessAllowed) {
      const intervalId = setInterval(() => {
        setProgress((prevProgress) => {
          const newProgress = prevProgress - 20
          if (newProgress <= 0) {
            clearInterval(intervalId)
            clearTimeout(timer)
            navigate(HOME_ROUTE)
            return 0
          }
          return newProgress
        })
      }, 1000)
      return () => {
        clearInterval(intervalId)
        clearTimeout(timer)
      }
    } else {
      return () => clearTimeout(timer)
    }
  }, [permissions, module, roles, hasAccess, navigate])

  if (!isVisible) return <Box w={'100%'} h={'100%'} bg={'white'} />

  if (allowed) return <>{children}</>

  return (
    <Flex w={'100%'} h={'100%'} alignItems={'center'} justifyContent={'center'}>
      <Box maxW={'1200px'}>
        <Heading color={'green.400'} textAlign={'center'}>
          Ограничение доступа
        </Heading>
        <Text textAlign={'center'} fontSize="30px" mt="30px">
          У вас нет прав для просмотра этой страницы.
        </Text>
        <Text fontSize="25px" textAlign={'center'} mt="10px" color={'gray.500'}>
          Вы будете переадресованы на главную страницу через {progress / 20}{' '}
          секунд.
        </Text>
        <Progress
          mt="25px"
          borderRadius={'25px'}
          value={progress}
          hasStripe
          isAnimated
          colorScheme="green"
          height="25px"
        />
      </Box>
    </Flex>
  )
}
