import { PathMatch, useNavigate } from 'react-router-dom'
import { Box, Heading, Text, Button } from '@chakra-ui/react'

import { HOME_ROUTE } from 'shared/constants'

export const NotFound: React.FC = () => {
  const navigate = useNavigate()

  const handleGoHome = () => {
    navigate(HOME_ROUTE)
  }
  return (
    <Box textAlign="center" py={10} px={6}>
      <Heading
        display="inline-block"
        as="h1"
        size="4xl"
        bgGradient="linear(to-r, teal.400, teal.600)"
        backgroundClip="text"
      >
        404
      </Heading>
      <Text fontSize="18px" mt={3} mb={2}>
        Страница не найдена
      </Text>
      <Text color={'gray.500'} mb={6}>
        Страница, которую вы ищете, похоже, не существует.
      </Text>

      <Button
        colorScheme="teal"
        bgGradient="linear(to-r, teal.400, teal.500, teal.600)"
        color="white"
        variant="solid"
        onClick={handleGoHome}
      >
        Вернутся на главаную
      </Button>
    </Box>
  )
}
