import React from 'react'

import { CalculationSchemesResponse } from 'shared/models'
import { ListView } from 'shared/ui'
import { CalculationSchemesItem } from './CalculationSchemesItem'

interface CalculationSchemesProps {
  calculationSchemes: CalculationSchemesResponse[]
}

export const CalculationSchemesDataList: React.FC<CalculationSchemesProps> = (
  props,
) => {
  const { calculationSchemes } = props

  return (
    <ListView.Lists>
      {calculationSchemes.map((scheme) => (
        <CalculationSchemesItem
          key={scheme.calc_schemes_id}
          schema_name={scheme.schema_name}
          calc_schemes_id={scheme.calc_schemes_id}
          code_id={scheme.code_id}
        />
      ))}
    </ListView.Lists>
  )
}
