import { useRef } from 'react'
import { Box, Flex, Grid, GridItem } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'

import { Access } from 'app/providers'
import { CompaniesList } from 'widgets'
import { ButtonIcon, ScrollToTop } from 'shared/ui'
import { CREATE_COMPANY_ROUTE } from 'shared/constants'
import { accessDict, modulesDict } from 'shared/dictionary'

// misc
import { HiMiniPlusCircle } from 'react-icons/hi2'

export const CompaniesPage = () => {
  const navigate = useNavigate()

  const scrollContainerRef = useRef(null)

  const handleAddCompany = () => navigate(CREATE_COMPANY_ROUTE)

  return (
    <Grid
      templateAreas={`"header" "main"`}
      gridTemplateRows={'50px 1fr'}
      gridTemplateColumns={'1fr'}
      height="91vh"
      overflow={'100'}
      gap="1"
      color="blackAlpha.700"
      fontWeight="bold"
    >
      <ScrollToTop scrollContainerRef={scrollContainerRef} />
      <GridItem pl="2" area={'header'} borderBottom={'1px solid #dfe0eb'}>
        <Flex justify={'flex-end'} alignItems={'center'} h={'100%'} pr="10">
          <Access
            permissions={[accessDict.full_access]}
            module={modulesDict.companies}
          >
            <Flex>
              <ButtonIcon
                icon={HiMiniPlusCircle}
                onClick={handleAddCompany}
                aria-label={''}
              />
            </Flex>
          </Access>
        </Flex>
      </GridItem>
      <GridItem area={'main'}>
        <Box
          ref={scrollContainerRef}
          style={{
            width: '100%',
            overflowY: 'scroll',
            overflowX: 'hidden',
            height: 'calc(100vh - 115px)',
            paddingBottom: '50px',
          }}
        >
          <Box borderBottom={'1px solid #dfe0eb'} p={'25px'} mb="10px">
            <CompaniesList />
          </Box>
        </Box>
      </GridItem>
    </Grid>
  )
}
