import { AxiosError, AxiosResponse } from 'axios'
import { UseMutateFunction, useMutation, useQueryClient } from 'react-query'
import { queryKeys } from 'shared/constants'
import { ExtendedAxiosError } from 'shared/models'

import { ReferenceBookService } from 'shared/services'
import { toasts } from 'shared/utils'

interface UpdatePriceZoneMutation {
  successAction: () => void
  zone_name: string
  price_zone_id: string
}

export function useUpdatePriceZone(): {
  mutate: UseMutateFunction<
    AxiosResponse<undefined>,
    AxiosError,
    UpdatePriceZoneMutation,
    unknown
  >
  isLoading: boolean
} {
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation(
    ({ zone_name, price_zone_id }: UpdatePriceZoneMutation) =>
      ReferenceBookService.updatePriceZone({
        zone_name,
        price_zone_id,
      }),
    {
      onSuccess: (
        data: AxiosResponse<undefined>,
        variables: UpdatePriceZoneMutation,
      ) => {
        if (variables.successAction) {
          variables.successAction()
          toasts.success({
            title: 'Данные ценовой зоны обновлены',
            description: `Данные ценовой зоны  успешно обновлены`,
          })
        }
        queryClient.invalidateQueries([
          queryKeys.referenceBooks.priceZones.allPriceZones,
        ])
      },
      onError: (error: ExtendedAxiosError) =>
        toasts.error({
          title: 'Произошла ошибка',
          description:
            error.response.data.detail ||
            'Данные ценовой зоны не были обновлены',
        }),
    },
  )
  return { mutate, isLoading }
}
