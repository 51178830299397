import { useCallback, useState } from 'react'
import { Box, Button } from '@chakra-ui/react'
import { useDisclosure } from '@chakra-ui/react'

import { IDeleteUserProps } from 'widgets/settingsUserTable'
import { UserInfoResponse } from 'shared/models'

import { DeleteUserModal } from './DeleteUserModal'

// misc
import { HiOutlineArchiveBoxXMark } from 'react-icons/hi2'

interface IDeleteUserSettingsProps {
  userId: string
  isLoading: boolean
  usersInfo: UserInfoResponse
}

export const DeleteUserSettings: React.FC<IDeleteUserSettingsProps> = (
  props,
) => {
  const { userId, usersInfo } = props

  const [deleteUserData, setDeleteUserData] = useState<IDeleteUserProps | null>(
    null,
  )

  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleDeleteUser = useCallback(
    ({ userId, userName, userLogin, userRole }: IDeleteUserProps) => {
      setDeleteUserData({ userId, userName, userLogin, userRole })
      onOpen()
    },
    [onOpen, isOpen],
  )

  const deleteUserModalProps = {
    isOpen,
    onClose,
    deleteUserData,
  }

  return (
    <Box borderBottom={'1px solid #dfe0eb'} p={'10px 15px 15px 15px'} mb="22px">
      <DeleteUserModal {...deleteUserModalProps} />

      <Button
        size={'sm'}
        type="submit"
        isLoading={false}
        isDisabled={false}
        colorScheme={'red'}
        color="white"
        display={'flex'}
        alignItems={'center'}
        // _hover={{
        //   bg: '#cd3b47',
        // }}
        onClick={() =>
          handleDeleteUser({
            userId: userId,
            userName: `${usersInfo?.first_name || ''} ${
              usersInfo?.last_name || ''
            } ${usersInfo?.middle_name || ''}`,
            userLogin: usersInfo?.username || '',
            userRole: usersInfo?.role_name || '',
          })
        }
      >
        Удалить пользователя
        <Box fontSize={'22px'} ml={'10px'}>
          <HiOutlineArchiveBoxXMark />
        </Box>
      </Button>
    </Box>
  )
}
