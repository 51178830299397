import { AxiosError, AxiosResponse } from 'axios'
import { UseMutateFunction, useMutation, useQueryClient } from 'react-query'
import { queryKeys } from 'shared/constants'

import {
  UpdateUserInheritancesResponse,
  UpdateUserInheritancesRequest,
} from 'shared/models'
import { ModulesService } from 'shared/services'
import { toasts } from 'shared/utils'

interface UpdateUserInheritanceMutation {
  userId: string
  fromUsers: string[]
  successAction?: () => void
}

export function useUpdateUserInheritance(): {
  mutate: UseMutateFunction<
    AxiosResponse<UpdateUserInheritancesResponse>,
    AxiosError,
    UpdateUserInheritanceMutation,
    UpdateUserInheritancesRequest
  >
  isLoading: boolean
} {
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation(
    ({ userId, fromUsers }: UpdateUserInheritanceMutation) =>
      ModulesService.updateUserInheritance({
        fromUsers,
        userId,
      }),
    {
      onSuccess: (
        data: AxiosResponse<UpdateUserInheritancesResponse>,
        variables: UpdateUserInheritanceMutation,
      ) => {
        toasts.success({
          title: 'Наследование пользователя обновлено',
          description: `Наследование у пользователя успешно обновлено`,
        })
        queryClient.invalidateQueries([
          queryKeys.modules.userInheritance,
          variables.userId,
        ])
      },
      onError: () =>
        toasts.error({
          title: 'Произошла ошибка',
          description: 'Наследование у пользователя не были обновлены',
        }),
    },
  )
  return { mutate, isLoading }
}
