import { rolesDict } from 'shared/dictionary'
import { EmptyValueReplacer } from 'shared/ui'

export function normalizeValues<T>(value: T | any, type?: string): T | any {
  if (value === '') return <EmptyValueReplacer />

  switch (type) {
    case 'roles':
      return rolesDict[value]
  }
  return value
}
