import { useQuery } from 'react-query'

import { CompaniesResponse } from 'shared/models'
import { queryKeys } from 'shared/constants'
import { CompaniesService } from 'shared/services'

interface UseGetCompaniesResult {
  data: CompaniesResponse[] | undefined
  isError: boolean
  isFetching: boolean
  allCompaniesData: CompaniesSelectOption[] | undefined
}

interface CompaniesSelectOption {
  value: string
  label: string
}

interface UseGetAllCompanies {
  enabled: boolean
}

export function useGetAllCompanies({
  enabled,
}: UseGetAllCompanies): UseGetCompaniesResult {
  const { data, isError, isFetching } = useQuery<CompaniesResponse[]>({
    queryKey: [queryKeys.companies.allCompanies],
    queryFn: () =>
      CompaniesService.getAllCompanies().then((response) => response.data),
    enabled,
    retry: false,
  })

  const allCompaniesData = data
    ? data.map((company) => ({
        value: company.company_id,
        label: company.short_name,
      }))
    : []

  return { data, isError, isFetching, allCompaniesData }
}
