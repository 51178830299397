import { Link, useMatch } from 'react-router-dom'
import clsx from 'clsx'

interface CustomLinkProps extends React.ComponentPropsWithoutRef<'a'> {
  to: string
  className?: string
}

export const CustomLink: React.FC<CustomLinkProps> = ({
  children,
  to,
  className,
  ...rest
}) => {
  // const match = useMatch({ path: to, end: to.length === 1 })
  const match = useMatch({ path: to, end: to === '/' })

  return (
    <Link className={clsx(className, { active: match })} to={to} {...rest}>
      {children}
    </Link>
  )
}
