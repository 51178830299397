import React from 'react'

import { SalesAllowancesResponse } from 'shared/models'
import { ListView } from 'shared/ui'
import { SalesAllowancesItem } from './SalesAllowancesItem'

interface SalesAllowancesProps {
  salesAllowances: SalesAllowancesResponse[]
}

export const SalesAllowancesDataList: React.FC<SalesAllowancesProps> = (
  props,
) => {
  const { salesAllowances } = props

  return (
    <ListView.Lists>
      {salesAllowances.map((allowance) => (
        <SalesAllowancesItem
          key={allowance.sale_allowance_id}
          allowance={allowance.allowance}
          sale_allowance_id={allowance.sale_allowance_id}
          code_id={allowance.code_id}
        />
      ))}
    </ListView.Lists>
  )
}
