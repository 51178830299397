import { createStandaloneToast, UseToastOptions } from '@chakra-ui/react'

const { toast } = createStandaloneToast()

interface ToastOptions extends UseToastOptions {
  title?: string
  description?: string | JSX.Element
}

const showToastAfterDelay = (func: () => void) => {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      func()
      resolve()
    }, 0)
  })
}

function showToast(
  type: 'success' | 'error' | 'info' | 'warning',
  options?: ToastOptions,
) {
  // Используем заголовок, описание и тип для создания более уникального ID
  const contentBasedId = options
    ? `${options.title || 'Notification'}-${
        options.description || 'Description'
      }-${type}`
    : `toast-${type}`

  const defaultTitles = {
    success: 'Успех',
    error: 'Ошибка',
    info: 'Информация',
    warning: 'Внимание',
  }

  const defaultDescriptions = {
    success: 'Операция выполнена успешно',
    error: 'Во время выполнения запроса произошла ошибка',
    info: '',
    warning: '',
  }

  const baseOptions = {
    duration: 6000,
    isClosable: true,
    position: 'bottom-left' as const,
  }

  const toastOptions: ToastOptions = {
    id: contentBasedId,
    title: options?.title || defaultTitles[type],
    description: options?.description || defaultDescriptions[type],
    ...baseOptions,
    ...options,
    status: type,
  }

  if (!toast.isActive(toastOptions.id)) {
    toast(toastOptions)
  }
}

export const toasts = {
  success: (options?: ToastOptions) => showToast('success', options),
  error: (options?: ToastOptions) => showToast('error', options),
  info: (options?: ToastOptions) => showToast('info', options),
  warning: (options?: ToastOptions) => showToast('warning', options),
}
