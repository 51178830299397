import { useForm } from 'react-hook-form'

export interface EditUserInheritanceFormData {
  users: { value: string; label: string }[]
}

export const useEditUserInheritanceForm = () => {
  const {
    setValue,
    register,
    handleSubmit,
    formState,
    resetField,
    reset,
    control,
  } = useForm<EditUserInheritanceFormData>({})

  return {
    reset,
    register,
    handleSubmit,
    errors: formState.errors,
    isDirty: formState.isDirty,
    isValid: formState.isValid,
    isSubmitting: formState.isSubmitting,
    control,
    resetField,
    setValue,
  }
}
