import React, { useCallback, useEffect, useState, useMemo } from 'react'

import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
} from 'react-hook-form'

import { Flex, Button, Box } from '@chakra-ui/react'

import { useGetEmployeesPositions } from 'entities/employeesPositions'
import { useUpdateUserProfile } from 'features/users'

import {
  EditButton,
  FormInputBlock,
  FormInputControl,
  Loader,
  RoleTag,
  SelectInput,
  SelectInputForm,
  TextTitle,
} from 'shared/ui'

import { ROLES, rolesData } from 'shared/constants'
import { normalizeValues } from 'shared/utils'
import { UserInfoResponse } from 'shared/models'

import {
  EditUserProfileFormData,
  useEditUserProfileForm,
} from './UseEditUserProfileForm'

// misc
import { MdOutlineSave } from 'react-icons/md'
import { HiMiniXMark, HiOutlinePencilSquare } from 'react-icons/hi2'

interface EditUserProfileProps {
  userId: string
  isLoading: boolean
  usersInfo: UserInfoResponse
  isCurrentUserAbleToEditProfile: boolean
  currentUserRole: string
}

interface EditProps {
  handleResetForm: () => void
  errors: FieldErrors<EditUserProfileFormData>
  control: Control<EditUserProfileFormData>
  isDirty: boolean
  isLoadingUpdate: boolean
  register: UseFormRegister<EditUserProfileFormData>
  isCurrentUserAbleToEditProfile: boolean
  currentUserRole: string
}

interface ViewProps {
  email: string
  role: string
  username: string
  last_name: string
  first_name: string
  position_name: string
  middle_name?: string
}

export const EditUserProfile: React.FC<EditUserProfileProps> = (props) => {
  const {
    userId,
    usersInfo,
    isLoading,
    isCurrentUserAbleToEditProfile,
    currentUserRole,
  } = props

  const [isEdit, setIsEdit] = useState<boolean>(false)

  const { mutate: updateUserInfo, isLoading: isLoadingUpdate } =
    useUpdateUserProfile()

  const {
    register,
    handleSubmit,
    errors,
    reset,
    control,
    isDirty,
    watchedFields,
  } = useEditUserProfileForm()

  const memoizedRolesData = useMemo(
    () => rolesData(),
    [isCurrentUserAbleToEditProfile],
  )

  const { employeesPositionsData } = useGetEmployeesPositions({
    enabled: false,
  })

  useEffect(() => {
    if (usersInfo) {
      reset({
        email: usersInfo.email,
        role: usersInfo.role_name,
        username: usersInfo.username,
        last_name: usersInfo.last_name,
        first_name: usersInfo.first_name,
        position_name: usersInfo.position_name,
        middle_name: usersInfo?.middle_name || '',
      })
    }
  }, [usersInfo, reset])

  const onSubmit = ({
    email,
    role,
    username,
    last_name,
    first_name,
    middle_name,
    position_name,
  }: EditUserProfileFormData) => {
    updateUserInfo({
      userId,
      email,
      username,
      role,
      last_name,
      first_name,
      middle_name,
      position_name,
      successAction: () => setIsEdit(false),
    })
  }

  const handleResetForm = useCallback(() => {
    reset()
  }, [reset])

  const handleEditClick = useCallback(() => {
    setIsEdit(!isEdit)
  }, [isEdit])

  return (
    <Box borderBottom={'1px solid #dfe0eb'} p={'15px'}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextTitle size="small">Персональные данные</TextTitle>
        {isLoading ? (
          <Flex justifyContent="center" minHeight="200px">
            <Loader size="md" />
          </Flex>
        ) : (
          <Box w={'100%'} position={'relative'}>
            <Flex
              w="100%"
              justifyContent="flex-end"
              position={'absolute'}
              right={'10px'}
              top={'0'}
            >
              {isCurrentUserAbleToEditProfile && (
                <EditButton handleEditClick={handleEditClick} isEdit={isEdit} />
              )}
            </Flex>
            <Box m={'5px 0'} w={'500px'}>
              <FormInputBlock
                allowEdit={isCurrentUserAbleToEditProfile}
                title={'ФИО:'}
                edit={isEdit}
                value={`${usersInfo.last_name || ''} ${
                  usersInfo.first_name || ''
                } ${usersInfo?.middle_name || ''}`}
              >
                <FormInputControl
                  smallErrorTextInside
                  size="sm"
                  name="last_name"
                  register={register}
                  errors={errors}
                  watchedFields={watchedFields}
                  isRequired={true}
                  placeholder="Фамилия"
                  type="text"
                />
                <FormInputControl
                  smallErrorTextInside
                  size="sm"
                  name="first_name"
                  register={register}
                  errors={errors}
                  watchedFields={watchedFields}
                  isRequired={true}
                  placeholder="Имя"
                  type="text"
                />
                <FormInputControl
                  smallErrorTextInside
                  size="sm"
                  name="middle_name"
                  register={register}
                  errors={errors}
                  watchedFields={watchedFields}
                  isRequired={false}
                  placeholder="Отчество"
                  type="text"
                />
              </FormInputBlock>
              <Box m={'8px 0'} />
              <FormInputBlock
                allowEdit={isCurrentUserAbleToEditProfile}
                title={'Логин:'}
                edit={isEdit}
                value={usersInfo.username}
              >
                <FormInputControl
                  smallErrorTextInside
                  size="sm"
                  name="username"
                  register={register}
                  errors={errors}
                  watchedFields={watchedFields}
                  isRequired={true}
                  placeholder="Логин"
                  type="text"
                />
              </FormInputBlock>
              <Box m={'8px 0'} />
              <FormInputBlock
                allowEdit={isCurrentUserAbleToEditProfile}
                title={'Email:'}
                edit={isEdit}
                value={usersInfo.email}
              >
                <FormInputControl
                  smallErrorTextInside
                  size="sm"
                  name="email"
                  register={register}
                  errors={errors}
                  watchedFields={watchedFields}
                  isRequired={true}
                  placeholder="Email"
                  type="text"
                />
              </FormInputBlock>
              <Box m={'8px 0'} />
              <FormInputBlock
                allowEdit={currentUserRole === ROLES.SUPERADMIN}
                title={'Роль:'}
                edit={isEdit}
                valueComponent={
                  <Box ml={'10px'}>
                    <RoleTag role={usersInfo.role_name} size="small">
                      <>{normalizeValues(usersInfo.role_name, 'roles')}</>
                    </RoleTag>
                  </Box>
                }
              >
                <SelectInputForm
                  isRequired
                  isReadOnly={currentUserRole !== ROLES.SUPERADMIN}
                  placeholder="Выберите тип пользователя"
                  data={memoizedRolesData}
                  watchedFields={watchedFields}
                  errors={errors}
                  size={'sm'}
                  register={register}
                  smallErrorTextInside
                  control={control}
                  name="role"
                  isCreatable={false}
                  isClearable={true}
                  getOptionValue={(option) => (option ? option.value : '')}
                />
              </FormInputBlock>
              <Box m={'8px 0'} />
              <FormInputBlock
                allowEdit={isCurrentUserAbleToEditProfile}
                title={'Должность:'}
                edit={isEdit}
                value={usersInfo.position_name}
              >
                <SelectInputForm
                  placeholder="Выберите должность"
                  isRequired
                  watchedFields={watchedFields}
                  errors={errors}
                  size={'sm'}
                  register={register}
                  smallErrorTextInside
                  control={control}
                  name="position_name"
                  data={employeesPositionsData}
                  isCreatable={true}
                  isClearable={true}
                  notificationText="Новая должность будет создана"
                  getOptionValue={(option) => (option ? option.value : '')}
                />
              </FormInputBlock>
            </Box>
          </Box>
        )}

        {isDirty && (
          <Flex justifyContent={'flex-start'} mt="15px">
            <Button
              size={'xs'}
              type="submit"
              isLoading={isLoadingUpdate}
              isDisabled={isLoadingUpdate}
              bg="teal.400"
              color="white"
              display={'flex'}
              alignItems={'center'}
              _hover={{
                bg: 'teal.500',
              }}
            >
              Сохранить
              <Box fontSize={'22px'} ml={'5px'}>
                <MdOutlineSave />
              </Box>
            </Button>
            <Button ml="15px" onClick={handleResetForm} size={'xs'}>
              Отмена
            </Button>
          </Flex>
        )}
      </form>
    </Box>
  )
}
