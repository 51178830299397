import React from 'react'

import { PriceCategoriesResponse } from 'shared/models'
import { ListView } from 'shared/ui'
import { PriceCategoriesItem } from './PriceCategoriesItem'

interface PriceCategoriesProps {
  priceCategories: PriceCategoriesResponse[]
}

export const PriceCategoriesDataList: React.FC<PriceCategoriesProps> = (
  props,
) => {
  const { priceCategories } = props

  return (
    <ListView.Lists>
      {priceCategories.map((category) => (
        <PriceCategoriesItem
          key={category.price_category_id}
          category_name={category.category}
          price_category_id={category.price_category_id}
          code_id={category.code_id}
        />
      ))}
    </ListView.Lists>
  )
}
