export const getThemes = (error, success) => ({
  md: {
    menuPortal: (base) => ({ ...base, zIndex: 9999999 }),
    control: (base, { menuIsOpen }) => {
      return {
        ...base,

        minHeight: '25px',
        borderRadius: 6,
        background: 'transparent',
        width: '100%',
        fontSize: '14px',
        lineHeight: '16px',
        boxShadow: 'none',
        cursor: 'pointer',
        border: error
          ? '2px solid red'
          : menuIsOpen
          ? '1px solid #3182ce'
          : '1px solid #dde8ef',
        '&:hover': {
          border: error ? '2px solid red' : '1px solid #3182ce',
        },
        display: 'flex',
        justifyContent: 'space-between',
        fontWeight: 400,
        alignItems: 'center',
      }
    },
    placeholder(base, state) {
      return {
        ...base,
        // display: state.isFocused && 'none',
        position: state.isFocused && 'absolute',
      }
    },
    clearIndicator: (base) => ({
      ...base,
      cursor: 'pointer',
      padding: '0',
      margin: '0 -15px 0 0',
      zIndex: 2,
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
      transition: 'all .2s ease',
      color: `blue`,
    }),
    menu: (provided) => ({
      ...provided,
      boxShadow: 'none',
      marginBottom: 0,
      marginTop: 0,
      borderTop: '1px solid #dde8ef;',
      borderBottomLeftRadius: '4px',
      borderBottomRightRadius: '4px',
      cursor: 'pointer',
      zIndex: '1000',
    }),
    menuList: (provided) => ({
      ...provided,
      borderRadius: '8px',
      height: 'auto',
      boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.16)',
      padding: 0,
      margin: 0,
      paddingBottom: 0,
      paddingTop: 0,
      cursor: 'pointer',
    }),
    valueContainer: (base) => ({
      ...base,
      // fontWeight: 'bold',
      color: 'black',
      paddingLeft: '16px',
      fontSize: '1rem',
      minHeight: '40px',
      borderRadius: '20px',
      display: 'flex',
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
      alignItems: 'center',
      // overflow: 'hidden',
      overflow: 'visible',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    }),
    indicatorContainer: (base) => ({
      ...base,
      background: 'red',
    }),
    multiValue: (base, state) => ({
      ...base,
      background: state.data.isFixed ? '#d6d6d6' : '#00b8d91a',
      color: state.data.isFixed ? '#333' : '#fff',
      borderRadius: '8px',
      paddingRight: '0',
      fontSize: '20px',
      fontWeight: 600,
      boxShadow: 'rgba(99, 99, 99, 0.2) 0px 1px 3px 0px',
      marginRight: '15px',
      marginBottom: '5px',
    }),
    multiValueLabel: (base, state) =>
      state.data.isFixed
        ? {
            ...base,
            backgroundColor: '#e1e1e1',
            borderRadius: '8px',
            borderRightRadius: '0',
            color: '#6f6f6f',
            padding: '6px 10px',
          }
        : {
            ...base,
            backgroundColor: '#f8f8f8',
            borderRadius: '8px',
            color: 'black',
            borderBottomRightRadius: '0',
            borderTopRightRadius: '0',
            padding: '6px 10px',
            overflow: 'visible',
          },
    multiValueRemove: (base, state) =>
      state.data.isFixed
        ? { ...base, display: 'none' }
        : {
            ...base,
            backgroundColor: '#f1f1f1',
            borderBottomRightRadius: '8px',
            borderTopRightRadius: '8px',
            color: 'gray',
            // color: '#fff',
            ':hover': {
              backgroundColor: '#de3d45',
              color: 'white',
            },
          },
    option: (base, { isSelected }) => ({
      ...base,
      backgroundColor: isSelected ? `white` : `white`,
      color: `black`,
      fontSize: '1rem',
      cursor: 'pointer',
      ':active': {
        ...base[':active'],
        // backgroundColor: '#EAF1F5',
        backgroundColor: '#3182ce',
        cursor: 'pointer',
      },
      ':hover': {
        ...base[':active'],
        // backgroundColor: '#EAF1F5',
        backgroundColor: '#3182ce',
        cursor: 'pointer',
        color: '#fff',
      },
    }),
    singleValue: (base) => ({
      ...base,
      fontSize: '1rem',
      lineHeight: '19px',
      color: `black`,
      overflow: 'visible',
    }),
  },
  sm: {
    menuPortal: (base) => ({ ...base, zIndex: 9999999 }),
    control: (base, { menuIsOpen }) => {
      return {
        ...base,
        minHeight: '25px',
        borderRadius: 6,
        background: 'transparent',
        width: '100%',
        fontSize: '12px',
        lineHeight: '12px',
        cursor: 'pointer',
        border: error
          ? '1px solid red'
          : menuIsOpen
          ? '1px solid #3182ce'
          : '1px solid #16b164',
        '&:hover': {
          border: error ? '1px solid red' : '1px solid #3182ce',
        },
        boxShadow: success
          ? '0 0 0 1px #04bcb0b6'
          : error
          ? '0 0 0 1px #E53E3E'
          : 'none',
        display: 'flex',
        justifyContent: 'space-between',
        fontWeight: 400,
        alignItems: 'center',
      }
    },
    placeholder(base, state) {
      return {
        ...base,
        position: state.isFocused && 'absolute',
      }
    },
    clearIndicator: (base) => ({
      ...base,
      cursor: 'pointer',
      padding: '0',
      margin: '0 -23px 0 0',
      zIndex: 2,
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
      transition: 'all .2s ease',
      color: `blue`,
    }),
    menu: (provided) => ({
      ...provided,
      boxShadow: 'none',
      marginBottom: 0,
      marginTop: 0,
      borderTop: '1px solid #dde8ef;',
      borderBottomLeftRadius: '4px',
      borderBottomRightRadius: '4px',
      cursor: 'pointer',
      zIndex: '1000',
    }),
    menuList: (provided) => ({
      ...provided,
      borderRadius: '8px',
      height: 'auto',
      boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.16)',
      padding: 0,
      margin: 0,
      paddingBottom: 0,
      paddingTop: 0,
      cursor: 'pointer',
    }),

    // multiValueRemove: (base, state) =>
    // state.data.isFixed
    //   ? { ...base, display: 'none' }
    //   : {
    //       ...base,
    //       backgroundColor: '#f1f1f1',
    //       borderBottomRightRadius: '8px',
    //       borderTopRightRadius: '8px',
    //       color: 'gray',
    //       ':hover': {
    //         backgroundColor: '#de3d45',
    //         color: 'white',
    //       },
    //     },

    valueContainer: (base, state) => {
      return state.isMulti
        ? {
            ...base,
            color: 'black',
            paddingLeft: '10px',
            fontSize: '14px',
            minHeight: '32px',
            borderRadius: '20px',
            display: 'flex',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
            alignItems: 'center',
            whiteSpace: 'nowrap',
            // FIX:
            // overflow: 'hidden',
            overflow: 'visible',
            textOverflow: 'ellipsis',
          }
        : {
            ...base,
            color: 'black',
            paddingLeft: '10px',
            fontSize: '14px',
            minHeight: '32px',
            borderRadius: '20px',
            display: 'flex',
            justifyContent: 'flex-start',
            flexWrap: 'nowrap',
            alignItems: 'center',
            maxHeight: '32px',
            whiteSpace: 'nowrap',
            // FIX:
            // overflow: 'hidden',
            overflow: 'visible',
            textOverflow: 'ellipsis',
          }
    },
    indicatorContainer: (base) => ({
      ...base,
      background: 'red',
    }),
    multiValue: (base, state) => ({
      ...base,
      background: state.data.isFixed ? '#d6d6d6' : '#00b8d91a',
      color: state.data.isFixed ? '#333' : '#fff',
      borderRadius: '8px',
      paddingRight: '0',
      fontSize: '15px',
      fontWeight: 500,
      boxShadow: 'rgba(99, 99, 99, 0.2) 0px 1px 3px 0px',
      marginRight: '15px',
      marginBottom: '5px',
    }),
    // NOTE: multiValueLabel
    multiValueLabel: (base, state) => {
      const styles = {
        errored: {
          ...base,
          backgroundColor: '#de3d45',
          color: 'white',
          borderRadius: '8px',
          padding: '3px 6px',
          borderBottomRightRadius: '0',
          borderTopRightRadius: '0',
        },
        fixed: {
          ...base,
          backgroundColor: '#e1e1e1',
          borderRadius: '8px',
          borderRightRadius: '0',
          color: '#6f6f6f',
          padding: '3px 6px',
        },
        normal: {
          ...base,
          backgroundColor: '#f8f8f8',
          borderRadius: '8px',
          color: 'black',
          borderBottomRightRadius: '0',
          borderTopRightRadius: '0',
          padding: '3px 6px',
          overflow: 'visible',
        },
      }

      if (state.data.isErrored) return styles.errored
      else if (state.data.isFixed) return styles.fixed
      else return styles.normal
    },
    multiValueRemove: (base, state) =>
      state.data.isFixed
        ? { ...base, display: 'none' }
        : {
            ...base,
            backgroundColor: '#f1f1f1',
            borderBottomRightRadius: '8px',
            borderTopRightRadius: '8px',
            color: 'gray',
            ':hover': {
              backgroundColor: '#de3d45',
              color: 'white',
            },
          },
    option: (base, { isSelected }) => ({
      ...base,
      backgroundColor: isSelected ? `white` : `white`,
      color: `black`,
      padding: '5px 8px',
      fontSize: '14px',
      cursor: 'pointer',
      ':active': {
        ...base[':active'],

        backgroundColor: '#f1f2f4',
        cursor: 'pointer',
      },
      ':hover': {
        ...base[':active'],
        backgroundColor: '#f1f2f4',
        cursor: 'pointer',
        color: 'black',
      },
    }),
    singleValue: (base) => ({
      ...base,
      fontSize: '14px',
      lineHeight: '14px',
      color: `black`,
      overflow: 'visible',
      whiteSpace: 'break-spaces',
      maxHeight: '32px',
    }),
  },
})
