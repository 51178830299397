import React, { useCallback, useMemo, useState } from 'react'

import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
} from '@chakra-ui/react'

import { TypesCounteragentsResponse } from 'shared/models'
import { TextLabelTitle, TextTitle } from 'shared/ui'

import { TypeCounteragentItem } from './TypeCounteragentItem'

// misc
import { ChevronDownIcon } from '@chakra-ui/icons'

interface EditActivityProps {
  typesCounteragents: TypesCounteragentsResponse[]
}

export const EditTypeCounteragents: React.FC<EditActivityProps> = (props) => {
  const { typesCounteragents } = props

  const [expandedIds, setExpandedIds] = useState<string[]>([])

  const idToIndexMap = useMemo(() => {
    return typesCounteragents.reduce((acc, type, index) => {
      acc[type.type_counteragent_id] = index
      return acc
    }, {} as Record<string, number>)
  }, [typesCounteragents])

  const expandedIndexes = useMemo(
    () =>
      expandedIds
        .map((id) => idToIndexMap[id])
        .filter((index) => index !== undefined),
    [expandedIds, idToIndexMap],
  )

  const handleToggle = useCallback((id: string) => {
    setExpandedIds((currentIds) =>
      currentIds.includes(id)
        ? currentIds.filter((currentId) => currentId !== id)
        : [...currentIds, id],
    )
  }, [])

  return (
    <Box p={'15px'}>
      <TextTitle size="small">Список видов контрагентов</TextTitle>
      {typesCounteragents && typesCounteragents.length > 0 ? (
        <Box
          mt="15px"
          borderLeft="1px solid #dfe0eb"
          borderRight="1px solid #dfe0eb"
        >
          <Accordion
            allowMultiple
            width="100%"
            rounded="lg"
            display="block"
            index={expandedIndexes}
            onChange={(newIndexes) => {
              const indexes = Array.isArray(newIndexes)
                ? newIndexes
                : [newIndexes]
              setExpandedIds(
                indexes.map(
                  (index) => typesCounteragents[index].type_counteragent_id,
                ),
              )
            }}
          >
            {typesCounteragents.map((type, index) => (
              <AccordionItem key={type.type_counteragent_id}>
                <AccordionButton
                  onClick={() => handleToggle(type.type_counteragent_id)}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  p={2}
                  bg="#ebeeff3c"
                >
                  <Text fontSize="16px" fontWeight="600" color={'#4E5A70'}>
                    {type.full_name}
                  </Text>
                  <ChevronDownIcon />
                </AccordionButton>

                <AccordionPanel pb={4}>
                  <TypeCounteragentItem
                    isExpanded={expandedIds.includes(type.type_counteragent_id)}
                    type_counteragent_id={type.type_counteragent_id}
                    short_name={type.short_name}
                    full_name={type.full_name}
                    code_id={type.code_id}
                    setExpandedIds={setExpandedIds}
                  />
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </Box>
      ) : (
        <Box p={'15px'}>
          <TextLabelTitle size="medium">Отсутствуют</TextLabelTitle>
        </Box>
      )}
      <Box pb={'450px'} />
    </Box>
  )
}
