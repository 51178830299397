import { Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react'

import {
  CalculationSchemesList,
  AdditionalRequisiteList,
  TypesContractsList,
  TypeCounteragents,
} from 'widgets'

export const CounteragentsContractsPage: React.FC = () => {
  return (
    <Tabs>
      <TabList p={'10px'} pb={0}>
        <Tab>
          <Text fontSize={'18px'}>Схемы расчетов</Text>
        </Tab>
        <Tab>
          <Text fontSize={'18px'}>Дополнительные реквизиты</Text>
        </Tab>
        <Tab>
          <Text fontSize={'18px'}>Виды договоров</Text>
        </Tab>
        <Tab>
          <Text fontSize={'18px'}>Виды контрагентов</Text>
        </Tab>
      </TabList>

      <TabPanels>
        <TabPanel padding={0}>
          <CalculationSchemesList />
        </TabPanel>
        <TabPanel padding={0}>
          <AdditionalRequisiteList />
        </TabPanel>
        <TabPanel padding={0}>
          <TypesContractsList />
        </TabPanel>
        <TabPanel padding={0}>
          <TypeCounteragents />
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}
