import { createContext, useContext, ReactNode } from 'react'
import { UseDisclosureReturn } from '@chakra-ui/react'

const ModalContext = createContext<UseDisclosureReturn | undefined>(undefined)

interface ModalProviderProps {
  children: ReactNode
  modalDisclosure: UseDisclosureReturn
}

export const ModalProvider: React.FC<ModalProviderProps> = ({
  children,
  modalDisclosure,
}) => {
  return (
    <ModalContext.Provider value={modalDisclosure}>
      {children}
    </ModalContext.Provider>
  )
}

export const useModal = (): UseDisclosureReturn => {
  const context = useContext(ModalContext)
  if (!context) throw new Error('useModal must be used within a ModalProvider')
  return context
}
