import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

interface ModulePermissions {
  access: string
  inheritance: string
}

export interface EditUserPermissionFormData {
  companies: ModulePermissions
  reference_books: ModulePermissions
  counter_agents: ModulePermissions
  volumes: ModulePermissions
  prices: ModulePermissions
  finance: ModulePermissions
  budget: ModulePermissions
  reporting: ModulePermissions
}

export const useEditUserPermissionForm = () => {
  const {
    setValue,
    register,
    handleSubmit,
    formState,
    resetField,
    reset,
    control,
    watch,
  } = useForm<EditUserPermissionFormData>()

  return {
    reset,
    register,
    handleSubmit,
    errors: formState.errors,
    isDirty: formState.isDirty,
    isValid: formState.isValid,
    isSubmitting: formState.isSubmitting,
    control,
    resetField,
    setValue,
    watch,
  }
}
