import { useQuery } from 'react-query'

import { TypesCounteragentsResponse } from 'shared/models'
import { queryKeys } from 'shared/constants'
import { CounteragentsContractsService } from 'shared/services'

interface UseGetTypesCounteragentsResult {
  data: TypesCounteragentsResponse[] | undefined
  isError: boolean
  isFetching: boolean
  isLoading: boolean
}

interface UseGetTypeCounteragents {
  enabled: boolean
}

export function useGetTypeCounteragents({
  enabled,
}: UseGetTypeCounteragents): UseGetTypesCounteragentsResult {
  const { data, isError, isFetching, isLoading } = useQuery<
    TypesCounteragentsResponse[]
  >({
    queryKey: [
      queryKeys.counteragentsContracts.typeCounteragents.allTypesCounteragents,
    ],
    queryFn: () =>
      CounteragentsContractsService.getTypesCounteragents().then(
        (response) => response.data,
      ),
    enabled,
    retry: false,
  })

  return { data, isError, isFetching, isLoading }
}
