// FIX: Change to useAccess and delete all this things
import { ROLES } from 'shared/constants'
import { Role } from 'shared/models'

type CanEditParams = {
  currentUserRole: Role
  editableUserRole: Role
  currentUserId: string
  editableUserId: string
}

export const canEditProfile = ({
  currentUserRole,
  editableUserRole,
  currentUserId,
  editableUserId,
}: CanEditParams): boolean => {
  if (currentUserId === editableUserId) return false
  if (currentUserRole === ROLES.SUPERADMIN) return true
  if (currentUserRole === ROLES.ADMIN && editableUserRole === ROLES.USER)
    return true
  return false
}

export const canEditAccess = ({
  currentUserRole,
  editableUserRole,
  currentUserId,
  editableUserId,
}: CanEditParams): boolean => {
  if (currentUserId === editableUserId) return false
  if (currentUserRole === ROLES.SUPERADMIN) return true
  return false
}

export const canEditCompanies = ({
  currentUserRole,
  editableUserRole,
  currentUserId,
  editableUserId,
}: CanEditParams): boolean => {
  if (currentUserId === editableUserId) return false
  if (currentUserRole === ROLES.SUPERADMIN) return true
  if (currentUserRole === ROLES.ADMIN && editableUserRole === ROLES.USER)
    return true
  return false
}
