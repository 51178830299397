import { AxiosError, AxiosResponse } from 'axios'
import { UseMutateFunction, useMutation, useQueryClient } from 'react-query'

import { queryKeys } from 'shared/constants'
import { ExtendedAxiosError } from 'shared/models'
import { CounteragentsContractsService } from 'shared/services'
import { toasts } from 'shared/utils'

interface CreateRelatedContractMutation {
  successAction: () => void
  type_counteragent_id: string
  type_contract_id: string
  supplier: boolean
  buyer: boolean
}

export function useCreateRelatedContract(): {
  mutate: UseMutateFunction<
    AxiosResponse<undefined>,
    AxiosError,
    CreateRelatedContractMutation,
    unknown
  >
  isLoading: boolean
} {
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation(
    ({
      supplier,
      buyer,
      type_counteragent_id,
      type_contract_id,
    }: CreateRelatedContractMutation) =>
      CounteragentsContractsService.createRelatedTypeContract({
        supplier,
        buyer,
        type_counteragent_id,
        type_contract_id,
      }),
    {
      onSuccess: (
        data: AxiosResponse<undefined>,
        variables: CreateRelatedContractMutation,
      ) => {
        if (variables.successAction) {
          variables.successAction()
          toasts.success({
            title: 'Успех',
            description: `Связанные контракт успешно добавлен`,
          })
        }
        queryClient.invalidateQueries([
          queryKeys.counteragentsContracts.typeCounteragents
            .detailTypesCounteragents,
          variables.type_counteragent_id,
        ])
      },
      onError: (error: ExtendedAxiosError) =>
        toasts.error({
          title: 'Произошла ошибка',
          description:
            error.response.data.detail || 'Связанные контракт не добавлен',
        }),
    },
  )
  return { mutate, isLoading }
}
