import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'

import { LOCAL_STORAGE_ENTITY } from 'shared/constants/localStorage'
import { localStorageHandler, toasts } from 'shared/utils'

export const getApiConfig = (): AxiosRequestConfig => {
  const token = localStorage.getItem(LOCAL_STORAGE_ENTITY.AUTH_KEY)

  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  }

  if (token) config.headers!.Authorization = `Bearer ${token}`
  return config
}

export const api: AxiosInstance = axios.create(getApiConfig())

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config

    if (
      error.response.status === 401 &&
      originalRequest.url === `${import.meta.env.VITE_APP_BASE_URL}/users/token`
    ) {
      ServiceBase.onAuthErrorCallback()
      return Promise.reject(error)
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true

      try {
        const refreshApi = axios.create({ withCredentials: true })

        const response = await refreshApi.post(
          `${import.meta.env.VITE_APP_BASE_URL}/users/token/refresh`,
        )

        localStorage.setItem(
          LOCAL_STORAGE_ENTITY.AUTH_KEY,
          response.data.access_token,
        )
        ServiceBase.setAuthToken(response.data.access_token)

        originalRequest.headers[
          'Authorization'
        ] = `Bearer ${response.data.access_token}`

        return api(originalRequest)
      } catch (err) {
        console.log('REFRESH TOKEN ERROR', err)
        localStorageHandler.removeFromLocalStorage(
          LOCAL_STORAGE_ENTITY.AUTH_KEY,
        )
        localStorageHandler.removeFromLocalStorage(
          LOCAL_STORAGE_ENTITY.USER_ROLE,
        )
        toasts.info({
          title: 'Сессия вашей авторизации истекла',
          description: 'Пожалуйста, авторизуйтесь снова.',
        })
        ServiceBase.onAuthErrorCallback()

        return Promise.reject(err)
      }
    }

    return Promise.reject(error)
  },
)

export abstract class ServiceBase {
  protected static URL: string = import.meta.env.VITE_APP_BASE_URL || ''

  protected static serviceApi: AxiosInstance = api

  protected static accessToken: string | undefined

  public static onAuthErrorCallback: (() => void) | null = null

  public static setOnAuthErrorCallback(callback: () => void): void {
    this.onAuthErrorCallback = callback
  }

  public static clearOnAuthErrorCallback(): void {
    this.onAuthErrorCallback = null
  }

  public static setAuthToken(token: string): void {
    this.accessToken = token
    const headers = this.serviceApi.defaults.headers as Record<string, string>
    headers.Authorization = `Bearer ${token}`
  }

  public static urlBuilder(url: string): string {
    return `${this.URL}/${url}`
  }

  protected static async get<T>(
    url: string,
    options?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> {
    return this.serviceApi.get<T>(this.urlBuilder(url), options)
  }

  protected static async post<T>(
    url: string,
    data?: unknown,
    options?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> {
    return this.serviceApi.post<T>(this.urlBuilder(url), data, options)
  }

  protected static async put<T>(
    url: string,
    data?: unknown,
    options?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> {
    return this.serviceApi.put<T>(this.urlBuilder(url), data, options)
  }

  protected static async patch<T>(
    url: string,
    data?: unknown,
    options?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> {
    return this.serviceApi.patch<T>(this.urlBuilder(url), data, options)
  }

  protected static async delete<T>(
    url: string,
    options?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> {
    return this.serviceApi.delete<T>(this.urlBuilder(url), options)
  }
}
