import { AxiosPromise } from 'axios'
import { ServiceBase } from './serviceBase'

import {
  RegistrationRequest,
  RegistrationResponse,
  ResetPasswordRequest,
  AddNewUserRequest,
  UsersResponse,
  AddNewUserResponse,
  UserInfoResponse,
  IUsersData,
  RegisterNewUserRequest,
  ResetPasswordResponse,
  UpdateUserInfoRequest,
  UpdateUserInfoResponse,
  UpdateUserPersonalProfileRequest,
} from 'shared/models'

export class UserService extends ServiceBase {
  protected static BASE_PATH = 'users'

  public static fetchRegistration(
    data: RegisterNewUserRequest,
  ): AxiosPromise<RegistrationResponse> {
    const { password, token } = data
    return this.post(`${this.BASE_PATH}/set-password`, {
      new_password: password,
      token,
    })
  }

  public static fetchResetPassword(
    data: ResetPasswordRequest,
  ): AxiosPromise<ResetPasswordResponse> {
    const { email } = data
    return this.get(
      `${this.BASE_PATH}/set_new_password_by_forgot?email=${email}`,
    )
  }

  public static addNewUser(
    data: AddNewUserRequest,
  ): AxiosPromise<AddNewUserResponse> {
    const {
      login,
      email,
      role,
      last_name,
      first_name,
      middle_name,
      position_name,
      users_companies,
    } = data
    return this.post(`${this.BASE_PATH}/register`, {
      email,
      username: login,
      role,
      last_name,
      first_name,
      position_name,
      ...(middle_name !== undefined && { middle_name }),
      ...(users_companies !== undefined && { users_companies }),
    })
  }

  public static updateUserInfo(
    data: UpdateUserInfoRequest,
  ): AxiosPromise<UpdateUserInfoResponse> {
    const {
      email,
      username,
      role,
      userId,
      last_name,
      first_name,
      middle_name,
      position_name,
    } = data
    return this.patch(`${this.BASE_PATH}/${userId}`, {
      email,
      username,
      role,
      last_name,
      first_name,
      position_name,
      ...(middle_name !== undefined && { middle_name }),
    })
  }

  public static updateUserPersonalProfile(
    data: UpdateUserPersonalProfileRequest,
  ): AxiosPromise<UpdateUserInfoResponse> {
    const {
      isSuperAdmin,
      email,
      username,
      last_name,
      first_name,
      middle_name,
      position_name,
    } = data
    return this.patch(`${this.BASE_PATH}/update/current_user`, {
      username,
      ...(isSuperAdmin && { email }),
      ...(isSuperAdmin && { last_name }),
      ...(isSuperAdmin && { first_name }),
      ...(middle_name !== undefined && isSuperAdmin && { middle_name }),
      ...(position_name !== undefined && isSuperAdmin && { position_name }),
    })
  }

  public static getUsers(): AxiosPromise<IUsersData[]> {
    return this.get<IUsersData[]>(`${this.BASE_PATH}/list_users`)
  }

  public static getUserInfo(): AxiosPromise<UserInfoResponse> {
    return this.get<UserInfoResponse>(`${this.BASE_PATH}/info`)
  }

  public static getUserInfoById(
    userId: string,
  ): AxiosPromise<UserInfoResponse> {
    return this.get<UserInfoResponse>(`${this.BASE_PATH}/info_by_id/${userId}`)
  }

  public static deleteUser(userId: string): AxiosPromise<void> {
    return this.delete<void>(`${this.BASE_PATH}/${userId}`)
  }

  public static getEmployeesPositions(): AxiosPromise<string[]> {
    return this.get(`${this.BASE_PATH}/list_employees_positions`)
  }

  public static fetchSetNewPassword(
    new_password: string,
  ): AxiosPromise<string> {
    return this.post(`${this.BASE_PATH}/set_new_password_by_user`, {
      new_password,
    })
  }

  public static fetchLogOut(): AxiosPromise<string> {
    return this.post(`${this.BASE_PATH}/logout`)
  }
}
