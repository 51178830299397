import {
  UseMutateFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query'

import { AxiosError, AxiosResponse } from 'axios'

import { useStore } from 'app/providers/storeProvider'

import { queryKeys } from 'shared/constants'
import { AuthService } from 'shared/services'
import { AuthResponse, UserResponse } from 'shared/models'
import { toasts } from 'shared/utils'

import { AuthenticationProps } from '../types/authSchema'

interface UseMutationResult<TData, TError, TVariables> {
  mutate: UseMutateFunction<TData, TError, TVariables, unknown>
  isLoading: boolean
}

interface UseCheckRefreshResult {
  data: AxiosResponse<AuthResponse> | undefined
  isError: boolean
}

export function useCheckRefresh(
  enabled: boolean,
  onSuccess: (token: string) => void,
  onErrorAction?: () => void,
): UseCheckRefreshResult {
  const { data, isError } = useQuery<AxiosResponse<AuthResponse>>({
    queryKey: [queryKeys.authentication.refresh],
    queryFn: () => AuthService.fetchRefresh(),
    enabled,
    retry: false,
    onSuccess: (responseData) => {
      if (responseData && responseData.data && responseData.data.access_token)
        onSuccess(responseData.data.access_token)
      else
        toasts.warning({
          description: 'Попробуйте перезагрузить страницу заново',
          title: 'Access token не получен',
        })
    },
    onError: () => {
      if (onErrorAction) onErrorAction()
    },
  })
  return { data, isError }
}

interface SignInWithOnSuccessAction extends AuthenticationProps {
  successAction: (token: string) => void
}

export function useAuthentication(): UseMutationResult<
  AxiosResponse<UserResponse>,
  AxiosError,
  SignInWithOnSuccessAction
> {
  const queryKey = queryKeys.user.data
  const queryClient = useQueryClient()
  const setLoading = useStore((state) => state.loaders.setLoading)
  const isLoading = useStore((state) => state.loaders.isLoading(queryKey))

  const { mutate } = useMutation(
    ({ username, password, successAction }: SignInWithOnSuccessAction) => {
      const formData = new FormData()
      formData.append('username', username)
      formData.append('password', password)

      return AuthService.login(formData).then((response) => {
        const token: string = response.data.access_token
        if (token) successAction(token)
        return response
      })
    },
    {
      onMutate: () => setLoading(queryKey, true),
      onSuccess: (data: AxiosResponse<UserResponse>) => {
        queryClient.setQueryData([queryKey], data)
        queryClient.invalidateQueries([queryKeys.user.info])
        toasts.success({
          description: 'Вход в систему успешно выполнен',
        })
      },
      onError: (error: AxiosError) => {
        if (error.response?.status === 401)
          toasts.error({
            description: 'Неверный логин или пароль',
          })
        else
          toasts.error({
            description: 'Произошла ошибка при аутентификации',
          })
      },
      onSettled: () => setLoading(queryKey, false),
    },
  )
  return { mutate, isLoading }
}

interface LogoutSuccessAction {
  onLogout: () => void
}

export function useLogout(): UseMutationResult<
  AxiosResponse<void>,
  AxiosError,
  LogoutSuccessAction
> {
  const queryKey = queryKeys.user.logout
  const queryClient = useQueryClient()
  const setLoading = useStore((state) => state.loaders.setLoading)
  const isLoading = useStore((state) => state.loaders.isLoading(queryKey))

  const { mutate } = useMutation(
    ({ onLogout }: LogoutSuccessAction) =>
      AuthService.fetchLogout().then((response) => {
        onLogout()
        return response
      }),
    {
      onMutate: () => setLoading(queryKey, true),
      onSuccess: (data: AxiosResponse<UserResponse>) => {
        queryClient.setQueryData([queryKey], data)
      },
      onSettled: () => {
        setLoading(queryKeys.user.data, false)
        toasts.success({
          description: 'Выход из системы выполнен',
        })
      },
    },
  )
  return { mutate, isLoading }
}
