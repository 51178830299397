import { useState } from 'react'

import { Flex, Text, Box } from '@chakra-ui/react'

import { Access } from 'app/providers'
import { SelectYear } from 'widgets'
import { HolydaysCalendar } from 'widgets/holydays'
import { ClearYearHolydays } from 'features/holydays'
import { accessDict, modulesDict } from 'shared/dictionary'

const MAX_YEAR = 2050
const MIN_YEAR = 2023

export const CalendarPage = () => {
  const [selectedYear, setSelectedYear] = useState<number>(MIN_YEAR)

  const currentYear = new Date().getFullYear()

  const years = Array.from(
    { length: MAX_YEAR - currentYear + 1 },
    (_, i) => currentYear + i,
  )

  return (
    <Box
      borderBottom={'1px solid #dfe0eb'}
      mb="22px"
      pb="100px"
      background="linear-gradient(to right, #f3fbfe, #edf7ff);"
    >
      <Box background={'#fff'} mb={'25px'}>
        <Text
          textAlign={'center'}
          fontSize={'24px'}
          fontWeight={'700'}
          mt="5px"
        >
          Календарь рабочих и выходных дней
        </Text>
        <Box>
          <Box
            p={'3px'}
            pb={'10px'}
            w={'100%'}
            display={'grid'}
            gridTemplateColumns={'1fr 1fr 1fr'}
            alignItems={'center'}
          >
            <Box />
            <Flex alignItems={'center'}>
              <SelectYear
                selectedYear={selectedYear}
                setSelectedYear={setSelectedYear}
                maxYear={MAX_YEAR}
                minYear={MIN_YEAR}
              />
            </Flex>
            <Box>
              <Access
                permissions={[accessDict.update_read]}
                module={modulesDict.reference_books}
              >
                <ClearYearHolydays year={String(selectedYear)} />
              </Access>
            </Box>
          </Box>
        </Box>
      </Box>
      <HolydaysCalendar selectedYear={selectedYear} />
    </Box>
  )
}
