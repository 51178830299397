import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

export interface AddNewActivityFormData {
  area_name?: string
  price_zone_id?: string
  ue_system_id?: string
  subject_id?: string
  time_zone?: number
  related_group_delivery_points?: {
    date_from?: string
    date_to?: string
    gd_point_names?: { value: string; label: string }[]
  }
}

const relatedGroupDeliveryPointsSchema = yup.object().shape({
  date_from: yup.string().required('Дата обязательное поле'),
  gd_point_names: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string().required('Value is required'),
        label: yup.string().required('Label is required'),
      }),
    )
    .required('Добавьте хотя бы один ГТП')
    .min(1, 'Добавьте хотя бы один ГТП'),
})

const SCHEMA_VALIDATION = yup.object().shape({
  area_name: yup.string().required('Имя ГП обязательное поле'),
  price_zone_id: yup.string().required('Ценовая зона обязательное поле'),
  ue_system_id: yup.string().required('ОЭС обязательное поле'),
  subject_id: yup.string().required('Субъект РФ обязательное поле'),
  time_zone: yup
    .number()
    .typeError('Часовой пояс обязательное поле')
    .integer('Часовой пояс должен быть целым числом')
    .max(14, 'Часовой сдвиг не может быть больше 14'),
  related_group_delivery_points: yup.lazy((value) =>
    value ? relatedGroupDeliveryPointsSchema : yup.object().notRequired(),
  ),
})

export const useAddNewActivityForm = () => {
  const {
    setValue,
    register,
    handleSubmit,
    formState,
    resetField,
    reset,
    control,
    watch,
    setError,
  } = useForm<AddNewActivityFormData>({
    resolver: yupResolver(SCHEMA_VALIDATION),
    mode: 'onChange',
  })

  const watchedFields = watch()

  return {
    reset,
    register,
    handleSubmit,
    errors: formState.errors,
    isDirty: formState.isDirty,
    isValid: formState.isValid,
    isSubmitting: formState.isSubmitting,
    control,
    resetField,
    setValue,
    watchedFields,
    setError,
    watch,
  }
}
