import { AxiosError } from 'axios'
import { UseMutateFunction, useMutation, useQueryClient } from 'react-query'

import { queryKeys } from 'shared/constants'
import { CompaniesService, UserService } from 'shared/services'
import { toasts } from 'shared/utils'

interface DeleteCompany {
  companyId: string
  companyName: string
  successAction: () => void
}
export function useDeleteCompany(): {
  mutate: UseMutateFunction<void, AxiosError, DeleteCompany, unknown>
  isLoading: boolean
} {
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation<void, AxiosError, DeleteCompany>(
    ({ companyId }: DeleteCompany) =>
      CompaniesService.deleteCompany({ companyId }).then(() => {}),
    {
      onSuccess: (_, variables: DeleteCompany) => {
        if (variables.successAction) variables.successAction()

        toasts.success({
          title: 'Компания удалена',
          description: `Компания ${variables.companyName}  успешно удаленa`,
        })
        queryClient.invalidateQueries([queryKeys.companies.allCompanies])
        queryClient.invalidateQueries([queryKeys.companies.editableCompanies])
      },
      onError: (error: AxiosError) => {
        toasts.error({
          title: 'Произошла ошибка',
          description: error.message || 'Пользователь не был удален',
        })
      },
    },
  )

  return { mutate, isLoading }
}
