import React, { createContext, useContext, useState } from 'react'
import { log } from 'shared/utils'

interface Permission {
  module: string
  access: string
}

interface AccessProviderProps {
  children: React.ReactNode
}

interface AccessContextType {
  roles: string[]
  permissions: Permission[]
  setAccess: (roles: string[], permissions: Permission[]) => void
}

export const AccessContext = createContext<AccessContextType>({
  roles: [],
  permissions: [],
  setAccess: () => {},
})

export const AccessProvider: React.FC<AccessProviderProps> = ({ children }) => {
  const [roles, setRoles] = useState<string[]>([])
  const [permissions, setPermissions] = useState<Permission[]>([])

  const setAccess = (newRoles: string[], newPermissions: Permission[]) => {
    setRoles(newRoles)
    setPermissions(newPermissions)
  }

  return (
    <AccessContext.Provider value={{ roles, permissions, setAccess }}>
      {children}
    </AccessContext.Provider>
  )
}
