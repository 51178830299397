import { useQuery } from 'react-query'

import { PriceZonesResponse } from 'shared/models'
import { queryKeys } from 'shared/constants'
import { ReferenceBookService } from 'shared/services'

interface PriceZones {
  value: string
  label: string
}

interface UseGetSubjectsResult {
  data: PriceZonesResponse[] | undefined
  isError: boolean
  isFetching: boolean
  isLoading: boolean
  priceZonesData: PriceZones[] | undefined
}

interface UseAllEnergySystems {
  enabled: boolean
}

export function useGetAllPriceZones({
  enabled,
}: UseAllEnergySystems): UseGetSubjectsResult {
  const { data, isError, isFetching, isLoading } = useQuery<
    PriceZonesResponse[]
  >({
    queryKey: [queryKeys.referenceBooks.priceZones.allPriceZones],
    queryFn: () =>
      ReferenceBookService.getAllPriceZones().then((response) => response.data),
    enabled,
    retry: false,
  })

  const priceZonesData = data?.map((zone) => ({
    value: zone.price_zone_id,
    label: zone.zone_name,
  }))

  return { data, isError, isFetching, isLoading, priceZonesData }
}
