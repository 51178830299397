import React, { useMemo, useEffect, useState } from 'react'
import { PathMatch } from 'react-router-dom'

import { LoginForm, RegistrationForm, ResetForm } from 'features/authentication'

import { AuthStage } from '../models/types/types'

interface AuthenticationPropsType {
  isRegistrationUrl: PathMatch<string> | null
}

export const Authentication: React.FC<AuthenticationPropsType> = ({
  isRegistrationUrl,
}) => {
  const [authStage, setAuthStage] = useState<AuthStage>(
    isRegistrationUrl ? AuthStage.registration : AuthStage.login,
  )

  useEffect(() => {
    setAuthStage(isRegistrationUrl ? AuthStage.registration : AuthStage.login)
  }, [isRegistrationUrl])

  const currentForm = useMemo(() => {
    switch (authStage) {
      case AuthStage.login:
        return <LoginForm setAuthStage={setAuthStage} />
      case AuthStage.reset:
        return <ResetForm setAuthStage={setAuthStage} />
      case AuthStage.registration:
        return (
          <RegistrationForm
            setAuthStage={setAuthStage}
            isRegistrationUrl={isRegistrationUrl}
          />
        )
      default:
        return null
    }
  }, [authStage, isRegistrationUrl])

  return currentForm
}
