import { Box, Flex, Heading, Input, Text } from '@chakra-ui/react'

export const HomePage = () => {
  return (
    <Flex
      h="100%"
      w="100%"
      justify={'center'}
      alignItems={'center'}
      background="linear-gradient(to right, #f3fbfe, #edf7ff);"
    >
      <Box
        textAlign="center"
        py={10}
        px={6}
        display={'flex'}
        flexDir={'column'}
        alignItems={'center'}
      >
        <Text
          fontSize={'36px'}
          fontWeight={'700'}
          mt="30px"
          color="#3435bc"
          textAlign={'center'}
          opacity={'0.8'}
        >
          <span
            style={{
              fontSize: '50px',
              fontWeight: '800',
              letterSpacing: '5px',
            }}
          >
            АСПЭ
          </span>
          <br />
          Аналитическая Система Продажи Электроэнергии
        </Text>
      </Box>
    </Flex>
  )
}
