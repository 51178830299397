import { AxiosError, AxiosResponse } from 'axios'
import { UseMutateFunction, useMutation, useQueryClient } from 'react-query'

import { queryKeys } from 'shared/constants'
import { ExtendedAxiosError } from 'shared/models'
import { CounteragentsContractsService } from 'shared/services'
import { toasts } from 'shared/utils'

interface AddNewTypesContractMutation {
  successAction: () => void
  full_name: string
  short_name: string
}

export function useAddNewTypesContract(): {
  mutate: UseMutateFunction<
    AxiosResponse<undefined>,
    AxiosError,
    AddNewTypesContractMutation,
    unknown
  >
  isLoading: boolean
} {
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation(
    ({ full_name, short_name }: AddNewTypesContractMutation) =>
      CounteragentsContractsService.createTypesContracts({
        full_name,
        short_name,
      }),
    {
      onSuccess: (
        data: AxiosResponse<undefined>,
        variables: AddNewTypesContractMutation,
      ) => {
        if (variables.successAction) {
          variables.successAction()
          toasts.success({
            title: 'Успех',
            description: `Вид договора успешно добвлен`,
          })
        }
        queryClient.invalidateQueries([
          queryKeys.counteragentsContracts.typesContracts.allTypesContracts,
        ])
      },
      onError: (error: ExtendedAxiosError) =>
        toasts.error({
          title: 'Произошла ошибка',
          description:
            error.response.data.detail || 'Вид договора не была добвлена',
        }),
    },
  )
  return { mutate, isLoading }
}
