import { Collapse, Box, Flex, Text, Button } from '@chakra-ui/react'

import {
  AddNewAdditionalRequisiteFormData,
  useAddNewAdditionalRequisiteForm,
} from './UseAddNewAdditionalRequisiteForm'

import { useAddNewAdditionalRequisite } from '../models/services/addAdditionalRequisite'

//  misc
import { FormInputControl, TextTitle } from 'shared/ui'

interface AddNewAdditionalRequisiteProps {
  isOpen: boolean
  onToggle: () => void
}

export const AddNewAdditionalRequisite: React.FC<
  AddNewAdditionalRequisiteProps
> = (props) => {
  const { isOpen, onToggle } = props

  const {
    mutate: addNewAdditionalRequisite,
    isLoading: isAddNewAdditionalRequisiteLoading,
  } = useAddNewAdditionalRequisite()

  const { register, handleSubmit, errors, reset, isDirty, watchedFields } =
    useAddNewAdditionalRequisiteForm()

  const onSubmit = ({
    additional_requisite,
  }: AddNewAdditionalRequisiteFormData) => {
    addNewAdditionalRequisite({
      additional_requisite,
      successAction: () => {
        onToggle()
        reset()
      },
    })
  }

  const handleResetForm = () => reset()

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Collapse in={isOpen} animateOpacity>
        <Box borderBottom={'1px solid #dfe0eb'}>
          <Box p={'10px'}>
            <TextTitle size={'small'}>
              Добавление нового дополнительного реквизита
            </TextTitle>
          </Box>
          <Flex p="10px 15px 20px 15px">
            <Flex mr={'5px'} w={'350px'} alignItems={'center'}>
              <Text
                mr={'5px'}
                color={'#718096'}
                fontSize={'12px'}
                fontWeight={'500'}
              >
                Имя:
              </Text>
              <FormInputControl
                smallErrorTextInside
                size="xs"
                name="additional_requisite"
                register={register}
                errors={errors}
                watchedFields={watchedFields}
                isRequired
                placeholder="Имя реквизита"
                type="text"
              />
            </Flex>
          </Flex>
          {isDirty && (
            <Box p="0 0 25px 15px">
              <Flex justifyContent={'flex-start'}>
                <Button mr="5px" onClick={handleResetForm} size={'xs'}>
                  <Flex alignItems={'center'}>Очистить</Flex>
                </Button>
                <Button
                  mr="5px"
                  size={'xs'}
                  colorScheme="blue"
                  type="submit"
                  isDisabled={isAddNewAdditionalRequisiteLoading}
                  isLoading={isAddNewAdditionalRequisiteLoading}
                >
                  Сохранить
                </Button>
              </Flex>
            </Box>
          )}
        </Box>
      </Collapse>
    </form>
  )
}
