import { useMemo } from 'react'
import { Controller } from 'react-hook-form'

import {
  Flex,
  Text,
  Input,
  Button,
  FormControl,
  FormLabel,
} from '@chakra-ui/react'

import { useGetEmployeesPositions } from 'entities/employeesPositions'
import { useAddUser } from 'features/users'
import { BaseModal } from 'shared/ui/Modals'
import { SelectInput } from 'shared/ui'

import { RegistrationFormData, useAddUserForm } from './UseAddUserForm'
import { rolesData } from '../models/data/userRoles'
import { useGetEditableCompanies } from 'entities/companies'

interface AddUserProps {
  onClose: () => void
  isOpen: boolean
}

export const AddUser: React.FC<AddUserProps> = (props) => {
  const { isOpen, onClose } = props
  const { mutate: addUser, isLoading } = useAddUser()
  const { employeesPositionsData } = useGetEmployeesPositions({
    enabled: false,
  })

  const { editableCompaniesData } = useGetEditableCompanies({
    enabled: true,
  })

  const { register, handleSubmit, errors, reset, control } = useAddUserForm()
  const onSubmit = ({
    email,
    login,
    role,
    last_name,
    first_name,
    middle_name,
    position_name,
    users_companies,
  }: RegistrationFormData) => {
    addUser({
      email,
      login,
      role,
      last_name,
      first_name,
      middle_name,
      position_name,
      users_companies: users_companies
        ? users_companies.map((val) => val.value)
        : [],
      successAction: () => {
        onClose()
        reset()
      },
    })
  }

  const handleCloseModal = () => {
    onClose()
    reset()
  }

  const memoizedRolesData = useMemo(() => rolesData(), [])

  return (
    <BaseModal
      header="Добавление нового пользователя"
      id="add user"
      isOpen={isOpen}
      onClose={handleCloseModal}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl id="last_name" isInvalid={!!errors.last_name} mb={2}>
          <FormLabel>Фамилия </FormLabel>
          <Input
            type="text"
            placeholder="Введите фамилию "
            {...register('last_name')}
          />
          <Text color="red.600">{errors.last_name?.message}</Text>
        </FormControl>

        <FormControl id="first_name" isInvalid={!!errors.first_name} mb={2}>
          <FormLabel>Имя </FormLabel>
          <Input
            type="text"
            placeholder="Введите имя "
            {...register('first_name')}
          />
          <Text color="red.600">{errors.first_name?.message}</Text>
        </FormControl>

        <FormControl id="middle_name" isInvalid={!!errors.middle_name} mb={2}>
          <FormLabel>Отчество </FormLabel>
          <Input
            type="text"
            placeholder="Введите фамилию "
            {...register('middle_name')}
          />
          <Text color="red.600">{errors.middle_name?.message}</Text>
        </FormControl>

        <FormControl
          id="email"
          isInvalid={!!errors.email}
          data-testid="email-control"
          mb={2}
        >
          <FormLabel>Email</FormLabel>
          <Input
            type="text"
            placeholder="Введите email"
            {...register('email')}
            data-testid="email-input"
          />
          <Text color="red.600" data-testid="email-error">
            {errors.email?.message}
          </Text>
        </FormControl>

        <FormControl
          id="login"
          isInvalid={!!errors.login}
          data-testid="login-control"
          mb={2}
        >
          <FormLabel>Логин</FormLabel>
          <Input
            type="text"
            placeholder="Введите логин"
            {...register('login')}
            data-testid="login-input"
          />
          <Text color="red.600" data-testid="login-error">
            {errors.login?.message}
          </Text>
        </FormControl>

        <FormControl
          id="role"
          isInvalid={!!errors.role}
          data-testid="role-control"
          mb={2}
        >
          <FormLabel>Роль</FormLabel>
          <Controller
            name="role"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <SelectInput
                data={memoizedRolesData}
                placeholder="Выберите роль"
                isClearable
                value={memoizedRolesData.find(
                  (option) => option.value === value,
                )}
                onChange={(option) => onChange(option ? option.value : '')}
                error={!!errors.role}
              />
            )}
          />

          <Text color="red.600">{errors.role?.message}</Text>
        </FormControl>

        <FormControl
          id="position_name"
          isInvalid={!!errors.position_name}
          mb={2}
        >
          <FormLabel>Должность</FormLabel>
          <Controller
            name="position_name"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <SelectInput
                  isCreatable
                  data={employeesPositionsData || []}
                  placeholder="Выберите должность"
                  isClearable
                  value={
                    employeesPositionsData?.find(
                      (option) => option.value === value,
                    ) || (value ? { label: value, value: value } : null)
                  }
                  onChange={(option) => {
                    const newValue = option
                      ? option.__isNew__
                        ? option.label
                        : option.value
                      : ''
                    onChange(newValue)
                  }}
                  error={!!errors.position_name}
                  notificationText="Новая должность будет создана"
                />
              )
            }}
          />

          <Text color="red.600">{errors.position_name?.message}</Text>
        </FormControl>

        <FormControl
          id="users_companies"
          isInvalid={!!errors.users_companies}
          mb={2}
        >
          <FormLabel>Компании</FormLabel>
          <Controller
            name="users_companies"
            control={control}
            render={({ field }) => (
              <SelectInput
                data={editableCompaniesData}
                placeholder="Выберите компании"
                isClearable
                isMulti
                value={field.value}
                onChange={(selectedOptions) => {
                  field.onChange(selectedOptions)
                }}
                error={!!errors.users_companies}
              />
            )}
          />

          <Text color="red.600">{errors.users_companies?.message}</Text>
        </FormControl>

        <Flex justifyContent={'flex-end'} mt="35px">
          <Button mr="15px" onClick={handleCloseModal}>
            отмена
          </Button>
          <Button
            colorScheme="blue"
            type="submit"
            isDisabled={isLoading}
            isLoading={isLoading}
          >
            добавить
          </Button>
        </Flex>
      </form>
    </BaseModal>
  )
}
