import { Flex, Grid, GridItem } from '@chakra-ui/react'

import { UserSettingsHeader, SettingsUsersTable } from 'widgets'

export const UsersSettingPage: React.FC = () => {
  return (
    <Grid
      templateAreas={`"header" "main"`}
      gridTemplateRows={'50px 1fr'}
      gridTemplateColumns={'1fr'}
      height="91vh"
      overflow={'100'}
      gap="1"
      color="blackAlpha.700"
      fontWeight="bold"
    >
      <GridItem pl="2" area={'header'}>
        <Flex justify={'flex-end'} alignItems={'center'} h={'100%'} pr="5">
          <UserSettingsHeader />
        </Flex>
      </GridItem>
      <GridItem area={'main'} p={'5px 20px'}>
        <SettingsUsersTable />
      </GridItem>
    </Grid>
  )
}
