import React from 'react'

import { PriceZonesResponse } from 'shared/models'
import { ListView } from 'shared/ui'
import { PriceZoneItem } from './PriceZoneItem'

interface EditPriceZoneProps {
  priceZones: PriceZonesResponse[]
}

export const EditPriceZone: React.FC<EditPriceZoneProps> = (props) => {
  const { priceZones } = props

  return (
    <ListView.Lists>
      {priceZones.map((zone) => (
        <PriceZoneItem
          key={zone.price_zone_id}
          zone_name={zone.zone_name}
          price_zone_id={zone.price_zone_id}
          code_id={zone.code_id}
        />
      ))}
    </ListView.Lists>
  )
}
