import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

interface ResetFormData {
  email?: string
}

const SCHEMA_VALIDATION = yup.object({
  email: yup
    .string()
    .required('email обязательное поле')
    .email('email должен быть валидный')
    .matches(
      /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      'email должен содержать точку и доменное имя',
    ),
})

export const useResetForm = () => {
  const { register, handleSubmit, formState } = useForm<ResetFormData>({
    resolver: yupResolver(SCHEMA_VALIDATION),
  })

  return {
    register,
    handleSubmit,
    errors: formState.errors,
    isDirty: formState.isDirty,
    isValid: formState.isValid,
    isSubmitting: formState.isSubmitting,
  }
}
