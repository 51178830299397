import { Resolver, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

export interface EditUserCompaniesFormData {
  companies: { value: string; label: string }[]
}

const SCHEMA_VALIDATION = yup.object().shape({
  companies: yup.array(),
})

export const useEditUserCompaniesForm = () => {
  const {
    setValue,
    register,
    handleSubmit,
    formState,
    resetField,
    reset,
    control,
    watch,
  } = useForm<EditUserCompaniesFormData>({
    mode: 'onChange',
    resolver: yupResolver(
      SCHEMA_VALIDATION,
    ) as Resolver<EditUserCompaniesFormData>,
    defaultValues: {
      companies: [],
    },
  })

  const watchedFields = watch()

  return {
    reset,
    register,
    handleSubmit,
    errors: formState.errors,
    isDirty: formState.isDirty,
    isValid: formState.isValid,
    isSubmitting: formState.isSubmitting,
    control,
    resetField,
    setValue,
    watchedFields,
  }
}
