export const localStorageHandler = {
  saveToLocalStorage<T>(key: string, value: T): void {
    try {
      const serializedValue = JSON.stringify(value)
      window.localStorage.setItem(key, serializedValue)
    } catch (error) {
      console.error(`Error saving to localStorage: ${error}`)
    }
  },

  getFromLocalStorage<T>(key: string, defaultValue: T): T {
    try {
      const serializedItem = window.localStorage.getItem(key)
      return serializedItem ? JSON.parse(serializedItem) : defaultValue
    } catch (error) {
      console.error(`Error getting from localStorage: ${error}`)
      return defaultValue
    }
  },

  removeFromLocalStorage(key: string): void {
    try {
      window.localStorage.removeItem(key)
    } catch (error) {
      console.error(`Error removing from localStorage: ${error}`)
    }
  },
}
