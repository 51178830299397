export const genderType = {
  female: 'женский',
  male: 'мужской',
}

export const rolesDict = {
  superadmin: 'Супер Администратор',
  admin: 'Администратор',
  user: 'Пользователь',
}

export const inheritanceTypeDict = {
  read: '7a3eeac7-cf57-4839-ad7f-63eb0a7a98ba',
  update_read: '095a2152-e5bc-4bee-a2c4-e6e0645f23a0',
  no_access: '50297e51-21d3-413e-b2c7-b26a537400dc',
}

export const inheritanceTranslateDict = {
  read: 'Чтение',
  update_read: 'Редактирование',
  no_access: 'Нет доступа',
}

export const inheritanceDict = {
  read: 'read',
  update_read: 'update_read',
  no_access: 'no_access',
}

export const accessTypeDict = {
  full_access: '2d6f412b-dfdb-4d3c-b804-0088fd0d3f4a',
  partial_access: '3d2fa552-6324-4c45-bb04-e3ca1a8955f7',
  no_access: '2756f1bd-0901-4ac2-94cd-3139590d3cda',
  read: '5aa4c13d-771b-4ad2-aa5d-f43fc5377226',
  update_read: 'bb77f1d8-ef2e-4a9b-b243-6812d69ad9c7',
}

export const accessTranslateDict = {
  full_access: 'Полный доступ',
  partial_access: 'Частичный',
  no_access: 'Нет доступа',
  read: 'Чтение',
  update_read: 'Редактирование',
}

export const accessDict = {
  full_access: 'full_access',
  partial_access: 'partial_access',
  no_access: 'no_access',
  read: 'read',
  update_read: 'update_read',
}

export const modulesTypeDict = {
  companies: '2d82fe07-f263-448c-afa9-124f3e78afe8',
  reference_books: 'c461d7d4-cb9a-4dae-afe2-a4342f623267',
  users: 'b42ee65c-fb7b-49b8-9c92-f5e675789438',
  user_rights: '84b6033b-2ef2-4127-8740-154de3ae2749',
  counter_agents: '683e9775-442f-42a6-b179-178c26fafd07',
  prices: 'd4cf3deb-babb-4e58-b53f-d08a6ec83403',
  volumes: '97c2e243-bd0e-4efc-9019-7ff29719406c',
  finance: '821d1b6a-0306-4b84-93f0-ede8a636fd4a',
  budget: 'be42d1ef-ce45-4813-a316-d6da11cc57a2',
  analytics: '0aa7d661-91bc-47ef-8f1a-b54963ec4772',
  reporting: '41c67d28-6b48-4f8e-a898-a0f16b597827',
}

export const modulesTranslateDict = {
  companies: 'Компании',
  users: 'Пользователи',
  user_rights: 'Права',
  reference_books: 'Справочники',
  counter_agents: 'Контрагенты',
  prices: 'Цены',
  volumes: 'Объемы',
  finance: 'Финансы',
  budget: 'Бюджет',
  analytics: 'Аналитика',
  reporting: 'Отчетность',
}

export const modulesDict = {
  companies: 'companies',
  users: 'users',
  user_rights: 'user_rights',
  reference_books: 'reference_books',
  counter_agents: 'counter_agents',
  prices: 'prices',
  volumes: 'volumes',
  finance: 'finance',
  budget: 'budget',
  analytics: 'analytics',
  reporting: 'reporting',
}
