/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { CSSProperties, useMemo, useState, useRef } from 'react'
import clsx from 'clsx'

import {
  useTable,
  useSortBy,
  useFilters,
  useGlobalFilter,
  ColumnGroup,
  HeaderGroup,
  ColumnInstance,
} from 'react-table'

import { Box, Flex, Text, Spinner } from '@chakra-ui/react'

import { ColumnProps, useColumns } from './UseColumns'
import { TableMenu } from './TableMenu'

//misc
import {
  HiOutlineChevronUp,
  HiArrowsUpDown,
  HiBarsArrowUp,
  HiBarsArrowDown,
} from 'react-icons/hi2'

import './table.css'

interface TableStyles {
  tableHeight?: string
  tableWidth?: string
  tableMaxHeight?: string
}

interface BasicTableProps {
  data: any
  emptyText?: string
  columns: ColumnProps[]
  tableStyles: TableStyles
  loading?: boolean
  showTableMenu?: boolean
  smallShadow?: boolean
  lightHeader?: boolean
  hiddenColumns?: string[]
}

export const BasicTable: React.FC<BasicTableProps> = React.memo((props) => {
  const {
    data,
    columns,
    tableStyles,
    emptyText = 'Данные отсутствуют',
    loading = false,
    showTableMenu = true,
    smallShadow = false,
    lightHeader = false,
    hiddenColumns = [],
  } = props

  const [showScrollTopButton, setShowScrollTopButton] = useState<boolean>(false)
  const tableRef = useRef<HTMLDivElement>(null)

  const memoizedColumns = useColumns(columns)

  const tableContainerStyle: CSSProperties = useMemo(
    () => ({
      maxHeight: tableStyles?.tableMaxHeight || '300px',
      width: tableStyles?.tableWidth || '100%',
      overflow: 'auto',
      boxShadow: '0 0 20px rgba(0, 0, 0, 0.15)',
      borderRadius: '8px',
      position: 'relative',
    }),
    [tableStyles],
  )

  const initialState = useMemo(
    () => ({
      hiddenColumns,
    }),
    [hiddenColumns],
  )

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement
    const top = target.scrollTop > 300
    setShowScrollTopButton(top)
  }

  const scrollToTop = () => {
    if (tableRef.current) {
      tableRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }

  // const defaultColumn = useMemo(() => {
  //   return {
  //     Filter: ColumnFilter,
  //   };
  // }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    footerGroups,
    allColumns,
    getToggleHideAllColumnsProps,
    state,
    setGlobalFilter,
    setHiddenColumns,
  } = useTable(
    {
      columns: memoizedColumns as ColumnGroup[],
      data,
      initialState,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
  ) as any


  React.useEffect(() => {
    setHiddenColumns(hiddenColumns)
  }, [hiddenColumns])

  const { globalFilter } = state



  if (loading) {
    return (
      <Flex align="center" justify="center" height="100%" minHeight="300px">
        <Spinner
          thickness="4px"
          speed="0.35s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      </Flex>
    )
  }

  if (!data.length)
    return (
      <Text
        fontSize={'16px'}
        color="gray.600"
        borderLeft="3px solid #319795"
        fontWeight={'normal'}
        w={'fit-content'}
        pl={'12px'}
      >
        {emptyText}
      </Text>
    )

  return (
    <>
      {showTableMenu && (
        <TableMenu
          width={tableStyles?.tableWidth || '100%'}
          allColumns={allColumns}
          getToggleHideAllColumnsProps={getToggleHideAllColumnsProps}
          setGlobalFilter={setGlobalFilter}
          globalFilter={globalFilter}
        />
      )}

      <div
        style={tableContainerStyle}
        // className="table-container"
        className={clsx('table-container', {
          smallShadow,
          lightHeader: lightHeader,
        })}
        onScroll={handleScroll}
        ref={tableRef}
      >
        <table {...getTableProps()} className="content-table">
          <thead>
            {headerGroups.map((headerGroup: HeaderGroup<object>) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: ColumnInstance<object>) => {
                  return (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps(), {
                        style: {
                          minWidth: column.minWidth,
                          width: column.width,
                        },
                      })}
                      className={clsx({ active: column.isSorted })}
                    >
                      <Flex
                        justifyContent={'flex-start'}
                        alignItems={'center'}
                        position={'relative'}
                      >
                        {column.render('Header')}
                        {!column.disableSortBy && (
                          <Flex
                            alignItems={'center'}
                            position={'absolute'}
                            right={'0'}
                            top={'50%'}
                            transform={'translateY(-50%)'}
                          >
                            <Box fontSize={'18px'}>
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <HiBarsArrowDown
                                    style={{ marginLeft: '12px' }}
                                  />
                                ) : (
                                  <HiBarsArrowUp
                                    style={{ marginLeft: '12px' }}
                                  />
                                )
                              ) : (
                                <HiArrowsUpDown
                                  style={{ marginLeft: '12px' }}
                                />
                              )}
                            </Box>
                          </Flex>
                        )}
                      </Flex>
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row: any) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell: any) => {
                    return (
                      <td
                        {...cell.getCellProps({
                          style: {
                            minWidth: cell.column.minWidth,
                            width: cell.column.width,
                          },
                        })}
                      >
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
          {/* <tfoot>
            {footerGroups.map((footerGroup: HeaderGroup) => (
              <tr {...footerGroup.getFooterGroupProps()}>
                {footerGroup.headers.map((column: any) => (
                  <td {...column.getFooterProps()}>
                    {column.render('Footer')}
                  </td>
                ))}
              </tr>
            ))}
          </tfoot> */}
        </table>
      </div>
      <div className="footer-container">
        <div
          className={clsx('scroll-to-top', { active: showScrollTopButton })}
          onClick={scrollToTop}
        >
          <HiOutlineChevronUp />
        </div>
      </div>
    </>
  )
})
