import React from 'react'
import { Stack, Text, Flex } from '@chakra-ui/react'
import { ListView } from 'shared/ui'

interface CalculationSchemesItemProps {
  schema_name: string
  calc_schemes_id: string
  code_id: number
}

export const CalculationSchemesItem: React.FC<CalculationSchemesItemProps> = (
  props,
) => {
  const { code_id, schema_name } = props

  return (
    <ListView.Stacks>
      <Flex alignItems={'center'} ml={'5px'}>
        <Text w={'35px'} fontSize={'sm'}>
          {code_id}
        </Text>

        <Text fontSize={'sm'} textAlign={'left'} maxW={'4xl'}>
          {schema_name}
        </Text>
      </Flex>
    </ListView.Stacks>
  )
}
