import { Box, Button, Flex, useDisclosure, Text } from '@chakra-ui/react'

import { BaseModal } from 'shared/ui'

import { useDeleteCompany } from '../models/services/deleteCompanyService'

// misc

// misc
import warningLogo from 'assets/svg/warning_logo.svg'
import { HiOutlineArchiveBoxXMark } from 'react-icons/hi2'

interface DeleteCompanyProps {
  companyId: string
  companyName: string
}

export const DeleteCompany: React.FC<DeleteCompanyProps> = (props) => {
  const { companyId, companyName } = props

  const { isOpen, onOpen, onClose } = useDisclosure()

  const { mutate: deleteCompany, isLoading } = useDeleteCompany()

  const handleCloseModal = () => onClose()

  const handleDeleteUser = () => {
    deleteCompany({
      companyId,
      companyName,
      successAction: () => onClose(),
    })
  }

  return (
    <div>
      <Button
        isDisabled={isLoading}
        isLoading={isLoading}
        colorScheme={'red'}
        size={'xs'}
        color="white"
        display={'flex'}
        alignItems={'center'}
        onClick={() => onOpen()}
      >
        Удалить компанию
        <Box fontSize={'18px'} ml={'7px'}>
          <HiOutlineArchiveBoxXMark />
        </Box>
      </Button>
      <BaseModal
        header="Удаление компании"
        id="delete user"
        isOpen={isOpen}
        onClose={handleCloseModal}
      >
        <Box>
          <Flex justifyContent={'center'}>
            <Box w={'50px'} mb={'15px'}>
              <img src={warningLogo} alt="Warning" />
            </Box>
          </Flex>
          <Text
            fontWeight={'800'}
            mb={'5px'}
            color={'red.500'}
            textAlign={'center'}
          >
            Компания будет удалена
          </Text>
          <Text fontWeight={'600'} mb={'5px'} textAlign={'center'}>
            Вы действительно  хотите удалить компанию <b>{companyName}</b>?
          </Text>
        </Box>
        <Flex justifyContent={'flex-end'} mt="35px">
          <Button fontWeight={'500'} mr="15px" onClick={onClose}>
            Отмена
          </Button>
          <Button
            fontWeight={'500'}
            onClick={handleDeleteUser}
            colorScheme={'red'}
            isDisabled={isLoading}
            isLoading={isLoading}
          >
            Удалить
          </Button>
        </Flex>
      </BaseModal>
    </div>
  )
}
