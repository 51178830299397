import { useQuery } from 'react-query'

import { Company } from 'shared/models'
import { queryKeys } from 'shared/constants'
import { CompaniesService } from 'shared/services'

interface UseGetCompanyDetailResult {
  data: Company | undefined
  isError: boolean
  isLoading: boolean
}

interface UseGetCompanyDetail {
  enabled: boolean
  companyId: string
}

export function useGetCompanyDetail({
  enabled,
  companyId,
}: UseGetCompanyDetail): UseGetCompanyDetailResult {
  const { data, isError, isLoading } = useQuery<Company>({
    queryKey: [queryKeys.companies.companyDetail, companyId],
    queryFn: () =>
      CompaniesService.getCompanyDetail(companyId).then(
        (response) => response.data,
      ),
    enabled,
    retry: false,
  })

  return { data, isError, isLoading }
}
