import React, { useCallback, useEffect, useState } from 'react'
import { Text, Button, Flex, IconButton, Box, Grid } from '@chakra-ui/react'

import { Access } from 'app/providers'
import { FormInputControl, ListView } from 'shared/ui'
import { accessDict, modulesDict } from 'shared/dictionary'

import {
  EditTypesContractsFormData,
  useEditTypesContractsForm,
} from './UseEditTypesContractsForm'

import { useUpdateTypesContracts } from '../models/services/useUpdateAdditionalRequisite'

//  misc
import { HiMiniXMark, HiOutlinePencilSquare } from 'react-icons/hi2'
import { DeleteTypesContract } from '../../deleteTypesContracts'

interface TypesContractsProps {
  full_name: string
  short_name: string
  type_contract_id: string
  code_id: number
}

export const TypesContractsItem: React.FC<TypesContractsProps> = (props) => {
  const { code_id, full_name, short_name, type_contract_id } = props
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const {
    mutate: updateTypesContract,
    isLoading: isUpdateTypesContractLoading,
  } = useUpdateTypesContracts()

  const { register, handleSubmit, errors, reset, isDirty, watchedFields } =
    useEditTypesContractsForm()

  useEffect(() => {
    if (full_name && short_name)
      reset({
        full_name,
        short_name,
      })
  }, [code_id, full_name, short_name, reset])

  const handleEditClick = useCallback(() => {
    if (isEdit) {
      reset()
      setIsEdit(false)
    } else setIsEdit(!isEdit)
  }, [isEdit])

  const onSubmit = ({ full_name, short_name }: EditTypesContractsFormData) => {
    updateTypesContract({
      full_name,
      short_name,
      type_contract_id,
      successAction: () => setIsEdit(false),
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ListView.Stacks>
        <Flex alignItems={'center'} ml={'5px'}>
          <Text w={'35px'} fontSize={'sm'}>
            {code_id}
          </Text>

          <Grid
            templateColumns={isEdit ? '350px 500px' : '300px 500px'}
            columnGap={'30px'}
          >
            {isEdit ? (
              <Flex mr={'5px'} w={'350px'} alignItems={'center'}>
                <Text
                  mr={'5px'}
                  color={'#718096'}
                  fontSize={'sm'}
                  fontWeight={'500'}
                >
                  Сокращение:
                </Text>
                <FormInputControl
                  smallErrorTextInside
                  size="xs"
                  name="short_name"
                  register={register}
                  errors={errors}
                  watchedFields={watchedFields}
                  isRequired
                  placeholder="Сокращение"
                  type="text"
                />
              </Flex>
            ) : (
              <Text fontSize={'sm'} textAlign={'left'} maxW={'4xl'}>
                {short_name}
              </Text>
            )}

            {isEdit ? (
              <Flex mr={'5px'} w={'500px'} alignItems={'center'}>
                <Text
                  mr={'5px'}
                  color={'#718096'}
                  fontSize={'sm'}
                  fontWeight={'500'}
                >
                  Наименование:
                </Text>
                <FormInputControl
                  smallErrorTextInside
                  size="xs"
                  name="full_name"
                  register={register}
                  errors={errors}
                  watchedFields={watchedFields}
                  isRequired
                  placeholder="Наименование"
                  type="text"
                />
              </Flex>
            ) : (
              <Text fontSize={'sm'} textAlign={'left'} maxW={'4xl'}>
                {full_name}
              </Text>
            )}
          </Grid>
        </Flex>

        <Flex alignItems={'center'}>
          {isEdit && isDirty && (
            <Box>
              <Flex justifyContent={'flex-start'}>
                <Button
                  mr="5px"
                  size={'xs'}
                  colorScheme="blue"
                  type="submit"
                  isDisabled={isUpdateTypesContractLoading}
                  isLoading={isUpdateTypesContractLoading}
                >
                  Сохранить
                </Button>
              </Flex>
            </Box>
          )}
          {isEdit && (
            <Box mr={'15px'}>
              <DeleteTypesContract
                type_contract_id={type_contract_id}
                short_name={short_name}
              />
            </Box>
          )}

          <Access
            permissions={[accessDict.update_read]}
            module={modulesDict.reference_books}
          >
            <IconButton
              size="xs"
              aria-label="Edit Activity"
              icon={isEdit ? <HiMiniXMark /> : <HiOutlinePencilSquare />}
              onClick={handleEditClick}
              fontSize="20px"
            />
          </Access>
        </Flex>
      </ListView.Stacks>
    </form>
  )
}
