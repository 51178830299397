import { useEffect } from 'react'
import { FieldErrors } from 'react-hook-form'
import { toasts } from 'shared/utils'

type FormErrors<T> = FieldErrors<T>

export const useFormErrorToast = <T,>(errors: FormErrors<T>) => {
  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      let count = 0
      const errorMessage = (
        <div>
          Проверьте следующие поля:
          {Object.entries(errors).flatMap(([key, value]) =>
            Object.entries(value).map(([fieldKey, error]) => {
              count++
              return (
                <div
                  key={`${key}.${fieldKey}`}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    margin: '5px 0',
                  }}
                >
                  <div
                    style={{
                      borderRadius: '50%',
                      backgroundColor: 'white',
                      color: '#337ab7',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginRight: '8px',
                      fontSize: '14px',
                      width: '18px',
                      height: '18px',
                      fontWeight: '700',
                    }}
                  >
                    {count}
                  </div>
                  <div>
                    <strong>{error.message}</strong>
                  </div>
                </div>
              )
            }),
          )}
        </div>
      )

      toasts.info({
        title: 'Ошибка валидации',
        description: errorMessage,
      })
    }
  }, [errors])
}
