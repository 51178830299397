import { AxiosError, AxiosResponse } from 'axios'
import { UseMutateFunction, useMutation, useQueryClient } from 'react-query'
import { queryKeys } from 'shared/constants'

import { UpdateUserPermissionsResponse } from 'shared/models'
import { ModulesService } from 'shared/services'
import { toasts } from 'shared/utils'

// UpdateUserPermissionsRequest,
// UpdateUserPermissionsResponse,

interface UpdatePermissionMutation {
  successAction: () => void
  errorAction: () => void
  moduleRightsId: string
  accessId: string
  inheritTypeId: string
  userId: string
  moduleName: string
}

export function useUpdateUserPermission(): {
  mutate: UseMutateFunction<
    AxiosResponse<UpdateUserPermissionsResponse>,
    AxiosError,
    UpdatePermissionMutation,
    unknown
  >
  isLoading: boolean
} {
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation(
    ({ moduleRightsId, accessId, inheritTypeId }: UpdatePermissionMutation) =>
      ModulesService.updateUserModulePermissions({
        moduleRightsId,
        accessId,
        inheritTypeId,
      }),
    {
      onSuccess: (
        data: AxiosResponse<UpdateUserPermissionsResponse>,
        variables: UpdatePermissionMutation,
      ) => {
        if (variables.successAction) {
          variables.successAction()
          // toasts.success({
          //   title: 'Данные пользователя обновлены',
          //   description: `Персональные данные пользователя успешно обновлены`,
          // })
        }

        queryClient.invalidateQueries([
          queryKeys.modules.userRights,
          variables.userId,
        ])
      },
      onError: (variables) =>
        toasts.error({
          title: 'Произошла ошибка',
          description: 'Персональные данные пользователя не были обновлены',
        }),
    },
  )
  return { mutate, isLoading }
}
