import { AxiosPromise } from 'axios'

import {
  CompaniesResponse,
  Company,
  CompanyUpdateRequest,
  UpdateUserCompaniesRequest,
  UpdateUserCompaniesResponse,
  UserCompaniesResponse,
} from 'shared/models'

import { ServiceBase } from './serviceBase'

export class CompaniesService extends ServiceBase {
  protected static BASE_PATH = 'companies'

  public static getAllCompanies(): AxiosPromise<CompaniesResponse[]> {
    return this.get<CompaniesResponse[]>(`${this.BASE_PATH}/list_companies`)
  }

  public static getUserEditableCompanies(): AxiosPromise<
    UserCompaniesResponse[]
  > {
    return this.get<UserCompaniesResponse[]>(
      `${this.BASE_PATH}/list_user_editable_companies`,
    )
  }

  public static createCompany(data: Company): AxiosPromise<undefined> {
    return this.post(`${this.BASE_PATH}/create_company`, data)
  }

  public static updateCompany({
    data,
    companyId,
  }: CompanyUpdateRequest): AxiosPromise<undefined> {
    return this.patch(`${this.BASE_PATH}/update_company/${companyId}`, data)
  }

  public static getCompanyDetail(companyId: string): AxiosPromise<Company> {
    return this.get<Company>(`${this.BASE_PATH}/company_detail/${companyId}`)
  }

  public static getUserCompanies(
    userId: string,
  ): AxiosPromise<UserCompaniesResponse[]> {
    return this.get<UserCompaniesResponse[]>(
      `${this.BASE_PATH}/list_user_companies/${userId}`,
    )
  }

  public static deleteCompany({
    companyId,
  }: {
    companyId: string
  }): AxiosPromise<void> {
    return this.delete<void>(`${this.BASE_PATH}/delete_company/${companyId}`)
  }

  public static updateUserCompanies(
    data: UpdateUserCompaniesRequest,
  ): AxiosPromise<UpdateUserCompaniesResponse> {
    const { userId, companiesIds } = data
    return this.patch(`${this.BASE_PATH}/update_user_companies`, {
      user_id: userId,
      companies_ids: companiesIds,
    })
  }
}
