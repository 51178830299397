import React from 'react'
import { Text, Flex } from '@chakra-ui/react'
import { ListView } from 'shared/ui'

interface PriceZoneItemProps {
  product: string
  product_id: string
  code_id: number
}

export const PriceProductsItem: React.FC<PriceZoneItemProps> = (props) => {
  const { code_id, product } = props

  return (
    <ListView.Stacks>
      <Flex alignItems={'center'} ml={'5px'}>
        <Text w={'35px'} fontSize={'sm'}>
          {code_id}
        </Text>

        <Text fontSize={'sm'} textAlign={'left'} maxW={'4xl'}>
          {product}
        </Text>
      </Flex>
    </ListView.Stacks>
  )
}
