import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Button, Checkbox, Collapse, Flex, Text } from '@chakra-ui/react'

import { useGetTypesContracts } from 'entities/counteragentsContracts'
import {
  CreateRelatedContractFormData,
  useCreateRelatedTypeContractForm,
} from './CreateRelatedTypeContractForm'
import { useCreateRelatedContract } from '../models/service/useUpdateRelatedContractService'

// misc
import { HiMiniPlusCircle, HiMiniXMark } from 'react-icons/hi2'
import { FormInputBlock, SelectInputForm, TextTitle } from 'shared/ui'
import { accessDict, modulesDict } from 'shared/dictionary'
import { Access } from 'app/providers'

interface CreateRelatedTypeContractProps {
  type_counteragent_id: string
  excludedTypeContractIds?: string[]
}

export const CreateRelatedTypeContract: React.FC<
  CreateRelatedTypeContractProps
> = (props) => {
  const { type_counteragent_id, excludedTypeContractIds } = props

  const [open, setOpen] = useState<boolean>(false)

  const [filteredTypesContractsData, setFilteredTypesContractsData] = useState<
    {
      value: string
      label: string
    }[]
  >([])

  const {
    register,
    handleSubmit,
    errors,
    reset,
    control,
    isDirty,
    watchedFields,
    setValue,
    watch,
  } = useCreateRelatedTypeContractForm()

  const { typesContractsData } = useGetTypesContracts({
    enabled: true,
  })

  useEffect(() => {
    if (typesContractsData && excludedTypeContractIds) {
      setFilteredTypesContractsData(
        typesContractsData.filter(
          (i) => !excludedTypeContractIds.includes(i.value),
        ),
      )
    }
  }, [excludedTypeContractIds])

  const {
    mutate: createRelatedContract,
    isLoading: isCreatingRelatedContract,
  } = useCreateRelatedContract()

  const buyerValue = watch('buyer')
  const supplierValue = watch('supplier')

  const commonInputProps = useMemo(
    () => ({
      control,
      register,
      errors,
      watchedFields,
      size: 'sm' as 'sm',
      smallErrorTextInside: true,
      isRequired: true,
    }),
    [register, errors, watchedFields, control],
  )

  const onSubmit = ({
    buyer,
    supplier,
    type_contract_id,
  }: CreateRelatedContractFormData) => {
    createRelatedContract({
      successAction: () => {
        setOpen(false)
        reset()
      },
      type_counteragent_id,
      type_contract_id,
      supplier,
      buyer,
    })
  }

  const handleResetForm = useCallback(() => {
    reset()
  }, [reset])

  return (
    <Box mt={'15px'}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Collapse in={open} animateOpacity style={{ overflow: 'inherit' }}>
          <Flex
            p={'10px'}
            border={'1px solid #dfe0eb'}
            borderRadius={'5px'}
            flexDir={'column'}
          >
            <Box>
              <TextTitle size={'extraSmall'}>Добавление договора</TextTitle>
            </Box>

            <Box maxW={'500px'}>
              <Box m={'8px 0'}>
                {(errors.supplier || errors.buyer) && (
                  <Text color={'red'} fontSize={'12px'}>
                    Необходимо выбрать поставщика или покупателя
                  </Text>
                )}
              </Box>

              <Box mb={'5px'}>
                <FormInputBlock
                  titleWidth={'90PX'}
                  allowEdit={true}
                  title={'Вид договора'}
                  edit={true}
                  value={''}
                >
                  <SelectInputForm
                    placeholder="Выберите вид договора"
                    data={filteredTypesContractsData || []}
                    isCreatable={false}
                    isClearable={true}
                    getOptionValue={(option) => (option ? option.value : '')}
                    name="type_contract_id"
                    {...commonInputProps}
                  />
                </FormInputBlock>
              </Box>

              <Flex>
                <Box mr={'50px'}>
                  <FormInputBlock
                    titleWidth={'90px'}
                    allowEdit={true}
                    title={'Поставщик:'}
                    edit={true}
                    value={''}
                  >
                    <Checkbox
                      size={'lg'}
                      isChecked={supplierValue}
                      onChange={() => setValue('supplier', !supplierValue)}
                      {...register('supplier')}
                    />
                  </FormInputBlock>
                </Box>
                <Box>
                  <FormInputBlock
                    titleWidth={'90px'}
                    allowEdit={true}
                    title={'Покупатель:'}
                    edit={true}
                    value={''}
                  >
                    <Checkbox
                      size={'lg'}
                      isChecked={buyerValue}
                      onChange={() => setValue('buyer', !buyerValue)}
                      {...register('buyer')}
                    />
                  </FormInputBlock>
                </Box>
              </Flex>
            </Box>
          </Flex>
        </Collapse>
        <Access
          permissions={[accessDict.update_read]}
          module={modulesDict.reference_books}
        >
          <Box m={'15px 0'}>
            {open ? (
              <Flex>
                <Button
                  mr={'10px'}
                  position={'relative'}
                  size={'xs'}
                  display={'flex'}
                  onClick={() => {
                    setOpen(false)
                    handleResetForm()
                  }}
                >
                  <Box fontSize={'18px'}>
                    <HiMiniXMark />
                  </Box>
                  <Text position={'relative'}>Отмена</Text>
                </Button>
                {isDirty && (
                  <Button
                    size="xs"
                    type="submit"
                    isLoading={isCreatingRelatedContract}
                    isDisabled={isCreatingRelatedContract}
                    bg="teal.400"
                    color="white"
                    display="flex"
                    alignItems="center"
                    _hover={{
                      bg: 'teal.500',
                    }}
                  >
                    Сохранить
                  </Button>
                )}
              </Flex>
            ) : (
              <Button
                position={'relative'}
                size={'xs'}
                bg="blue.700"
                color="white"
                display={'flex'}
                alignItems={'center'}
                onClick={() => setOpen(true)}
                _hover={{
                  bg: 'blue.600',
                }}
              >
                <Box fontSize={'18px'} mr={'5px'}>
                  <HiMiniPlusCircle />
                </Box>
                <Text position={'relative'}>Вид договора</Text>
              </Button>
            )}
          </Box>
        </Access>
      </form>
    </Box>
  )
}
