import { Dispatch, SetStateAction } from 'react'
import { Box, Button, Flex, useDisclosure, Text } from '@chakra-ui/react'

import moment from 'moment'
import { BaseModal } from 'shared/ui'
import { useDeleteGroupDelivery } from '../models/services/deleteGroupDeliveryService'

// misc
import warningLogo from 'assets/svg/warning_logo.svg'
import { HiOutlineArchiveBoxXMark } from 'react-icons/hi2'

interface DeleteGroupDelivery {
  gs_activity_areas_id: string
  date_from: string
  date_to: string
  cancelEdit: () => void
}

export const DeleteGroupDelivery: React.FC<DeleteGroupDelivery> = (props) => {
  const { gs_activity_areas_id, date_from, date_to, cancelEdit } = props

  const { isOpen, onOpen, onClose } = useDisclosure()

  const { mutate: deleteGroupDelivery, isLoading } = useDeleteGroupDelivery()

  const handleCloseModal = () => {
    onClose()
  }

  const handleDeleteGroupDelivery = () => {
    deleteGroupDelivery({
      gs_activity_areas_id,
      date_from: moment(date_from).format('yyyy-MM-DD'),
      ...(date_to && {
        date_to: moment(date_to).format('yyyy-MM-DD'),
      }),
      successAction: () => {
        onClose()
        cancelEdit()
      },
    })
  }

  return (
    <div>
      <Button
        isDisabled={isLoading}
        isLoading={isLoading}
        colorScheme={'red'}
        size={'xs'}
        color="white"
        display={'flex'}
        alignItems={'center'}
        onClick={() => onOpen()}
      >
        Удалить набор ГТП
        <Box fontSize={'18px'} ml={'7px'}>
          <HiOutlineArchiveBoxXMark />
        </Box>
      </Button>
      <BaseModal
        header="Удаление набора ГТП"
        id="delete gpt"
        isOpen={isOpen}
        onClose={handleCloseModal}
      >
        <Box>
          <Flex justifyContent={'center'}>
            <Box w={'50px'} mb={'15px'}>
              <img src={warningLogo} alt="Warning" />
            </Box>
          </Flex>
          <Text
            fontWeight={'800'}
            mb={'5px'}
            color={'red.500'}
            textAlign={'center'}
          >
            Следующий набор ГТП будет удален
          </Text>
          <Text fontWeight={'600'} mb={'5px'} textAlign={'center'}>
            Вы действительно хотите удалить набор ГТП?
          </Text>
        </Box>
        <Flex justifyContent={'flex-end'} mt="35px">
          <Button fontWeight={'500'} mr="15px" onClick={onClose}>
            Отмена
          </Button>
          <Button
            fontWeight={'500'}
            onClick={handleDeleteGroupDelivery}
            colorScheme={'red'}
            isDisabled={isLoading}
            isLoading={isLoading}
          >
            Удалить
          </Button>
        </Flex>
      </BaseModal>
    </div>
  )
}
