import styled, { css } from 'styled-components'

export const StyledScrollToTopWrapper = styled.div<{ show?: boolean }>`
  position: fixed;
  bottom: 75px;
  right: 100px;
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 99;

  ${({ show }) =>
    show &&
    css`
      opacity: 1;
    `};

  @media (max-width: 1024px) {
    bottom: 50px;
    right: 50px;
  }
  @media (max-width: 480px) {
    bottom: 90px;
    right: 0px;
  }
`

export const StyledButton = styled.div`
  display: flex;
  width: 45px;
  height: 45px;
  border: 1px solid #80808014;
  border-radius: 8px;
  box-shadow: 0px 2px 5px rgba(0, 44, 119, 0.15);
  background: white;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  transition: 0.1s ease-in-out;
  cursor: pointer;
  opacity: 1;
  border: 1px solid #80808038;
  opacity: 0.6;
  transition: 0.2s;

  & svg {
    position: relative;
    left: 0px;
    top: -1px;
    transform: rotate(180deg);
  }

  &:hover {
    opacity: 1;
  }

  @media (max-width: 1024px) {
    width: 50px;
    height: 50px;

    svg {
      width: 9px;
    }
  }

  @media (max-width: 480px) {
    width: 35px;
    height: 35px;
    opacity: 0.6;
  }
`
