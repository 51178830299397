import React, { Dispatch } from 'react'
import { CellProps } from 'react-table'
import { Flex, Box, Checkbox, Text } from '@chakra-ui/react'

import { TypesCounteragentDetailResponse } from 'shared/models'
import { RowData } from '../models/types/types'

//misc
import { HiOutlineArchiveBoxXMark } from 'react-icons/hi2'

import { PopoverMessage } from 'shared/ui'

export enum ModalAction {
  edit,
  delete,
}

export interface NotificationState {
  isOpen: boolean
  message: string
  title: string
  id: string
  part: string
}

interface ActionCellComponentProps {
  row: CellProps<RowData, string>
  handleOpenModal: (params: {
    data: TypesCounteragentDetailResponse
    action: ModalAction
  }) => void
}

interface TableStructureProps {
  handleOpenModal: (params: {
    data: TypesCounteragentDetailResponse
    action: ModalAction
  }) => void
  handleClickCheckbox: (params: { related_tc_id: string; part: string }) => void
  isEdit?: boolean
  setOpenNotification: Dispatch<React.SetStateAction<NotificationState>>
  openNotification: NotificationState
}

export const initialNotificationState: NotificationState = {
  isOpen: false,
  title: '',
  message: '',
  id: '',
  part: '',
}

const ActionCellComponent: React.FC<ActionCellComponentProps> = React.memo(
  ({
    row,
    handleOpenModal,
  }: {
    row: CellProps<RowData, string>
    handleOpenModal: (any) => void
  }) => {
    return (
      <Flex justifyContent={'center'} gridArea={'full_name'}>
        {/* <Box
          mr={3}
          className="icon-edit"
          fontSize={'20px'}
          cursor={'pointer'}
          color={'#c2c2c2'}
          _hover={{ cursor: 'pointer', color: '#3435bc' }}
          onClick={() =>
            handleOpenModal({
              data: { ...row.original },
              action: ModalAction.edit,
            })
          }
        >
          <HiOutlinePencilSquare />
        </Box> */}
        <Box
          className="icon-delete"
          fontSize={'20px'}
          cursor={'pointer'}
          color={'#c2c2c2'}
          _hover={{ cursor: 'pointer', color: '#bc343f' }}
          onClick={() =>
            handleOpenModal({
              data: { ...row.original },
              action: ModalAction.delete,
            })
          }
        >
          <HiOutlineArchiveBoxXMark />
        </Box>
      </Flex>
    )
  },
)

export const TableStructure = ({
  handleOpenModal,
  handleClickCheckbox,
  isEdit,
  setOpenNotification,
  openNotification,
}: TableStructureProps) => {
  const action = {
    title: 'Удаление',
    disableSortBy: true,
    accessor: 'action',
    minWidth: 80,
    width: 80,
    CellComponent: ({ row }: CellProps<RowData, string>) => {
      return <ActionCellComponent row={row} handleOpenModal={handleOpenModal} />
    },
  }

  const structure = [
    {
      title: 'Вид договора',
      accessor: 'full_name',
      minWidth: 380,
      width: 380,
    },
    {
      title: 'Поставщик',
      accessor: 'supplier',
      disableSortBy: true,
      CellComponent: ({ row }: CellProps<RowData, string>) => {
        return (
          <Flex w={'100%'} justifyContent={'center'}>
            <PopoverMessage
              triggerElement={<Box position={'absolute'} w={'300px'} />}
              isOpen={
                openNotification.isOpen &&
                openNotification.id === row.original.related_tc_id &&
                openNotification.part === 'supplier'
              }
              message={openNotification.message}
              title={openNotification.title}
              onClick={() => setOpenNotification(initialNotificationState)}
            />
            <Checkbox
              isChecked={row.original?.supplier}
              isDisabled={!isEdit}
              onChange={() => {
                handleClickCheckbox({
                  related_tc_id: row.original.related_tc_id,
                  part: 'supplier',
                })
              }}
            />
          </Flex>
        )
      },
      minWidth: 50,
      width: 50,
    },
    {
      title: 'Покупатель',
      accessor: 'buyer',
      disableSortBy: true,
      CellComponent: ({ row }: CellProps<RowData, string>) => {
        return (
          <Flex w={'100%'} justifyContent={'center'}>
            <PopoverMessage
              triggerElement={<Box position={'absolute'} w={'300px'} />}
              isOpen={
                openNotification.isOpen &&
                openNotification.id === row.original.related_tc_id &&
                openNotification.part === 'buyer'
              }
              message={openNotification.message}
              title={openNotification.title}
              onClick={() => setOpenNotification(initialNotificationState)}
            />
            <Checkbox
              isChecked={row.original?.buyer}
              isDisabled={!isEdit}
              onChange={() => {
                handleClickCheckbox({
                  related_tc_id: row.original.related_tc_id,
                  part: 'buyer',
                })
              }}
            />
          </Flex>
        )
      },
      minWidth: 50,
      width: 50,
    },
  ]

  structure.push(action)

  return structure
}
