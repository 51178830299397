import { AxiosError, AxiosResponse } from 'axios'
import { UseMutateFunction, useMutation, useQueryClient } from 'react-query'
import { queryKeys } from 'shared/constants'

import {
  UpdateUserCompaniesResponse,
  UpdateUserCompaniesRequest,
} from 'shared/models'

import { CompaniesService } from 'shared/services'
import { toasts } from 'shared/utils'

interface UpdateUserCompaniesMutation {
  userId: string
  companiesIds: string[]
  successAction?: () => void
}

export function useUpdateUserCompanies(): {
  mutate: UseMutateFunction<
    AxiosResponse<UpdateUserCompaniesResponse>,
    AxiosError,
    UpdateUserCompaniesMutation,
    UpdateUserCompaniesRequest
  >
  isLoading: boolean
} {
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation(
    ({ userId, companiesIds }: UpdateUserCompaniesMutation) =>
      CompaniesService.updateUserCompanies({
        companiesIds,
        userId,
      }),
    {
      onSuccess: (
        data: AxiosResponse<UpdateUserCompaniesResponse>,
        variables: UpdateUserCompaniesMutation,
      ) => {
        toasts.success({
          title: 'Компании обновлены',
          description: `Компании пользователю успешно обновлены`,
        })
        queryClient.invalidateQueries([
          queryKeys.user.companies,
          variables.userId,
        ])
      },
      onError: () =>
        toasts.error({
          title: 'Произошла ошибка',
          description: 'Компании не были  обновлены у пользователя',
        }),
    },
  )
  return { mutate, isLoading }
}
