import React, { useCallback, useEffect, useState } from 'react'
import { Text, Button, Flex, IconButton, Box } from '@chakra-ui/react'

import { Access } from 'app/providers'
import { DeleteSubject } from 'features/referenceBooks'
import { FormInputControl, ListView } from 'shared/ui'
import { accessDict, modulesDict } from 'shared/dictionary'

import { useUpdateSubject } from '../models/services/useUpdateSubject'
import { EditSubjectFormData, useEditSubjectForm } from './UseEditSubjectForm'

//  misc
import { HiMiniXMark, HiOutlinePencilSquare } from 'react-icons/hi2'

interface SubjectItemProps {
  region_name: string
  subject_id: string
  region_code: number
}

export const SubjectItem: React.FC<SubjectItemProps> = (props) => {
  const { region_code, region_name, subject_id } = props
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const { mutate: updateSubject, isLoading: isUpdateSubjectLoading } =
    useUpdateSubject()

  const {
    register,
    handleSubmit,
    errors,
    reset,
    control,
    isDirty,
    watchedFields,
  } = useEditSubjectForm()

  useEffect(() => {
    if (subject_id)
      reset({
        region_code: String(region_code),
        region_name,
      })
  }, [region_code, region_name, subject_id, reset])

  const handleEditClick = useCallback(() => {
    if (isEdit) {
      reset()
      setIsEdit(false)
    } else setIsEdit(!isEdit)
  }, [isEdit])

  const onSubmit = ({ region_name, region_code }: EditSubjectFormData) => {
    updateSubject({
      region_name,
      region_code,
      subject_id,
      successAction: () => setIsEdit(false),
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ListView.Stacks>
        <Flex alignItems={'center'} ml={'5px'}>
          {isEdit ? (
            <Flex mr={'5px'} w={'150px'} alignItems={'center'}>
              <Text
                mr={'5px'}
                color={'#718096'}
                fontSize={'12px'}
                fontWeight={'500'}
              >
                Код:
              </Text>
              <FormInputControl
                smallErrorTextInside
                size="xs"
                name="region_code"
                register={register}
                errors={errors}
                watchedFields={watchedFields}
                isRequired
                placeholder="Код субьекта"
                type="number"
              />
            </Flex>
          ) : (
            <Text w={'35px'} fontSize={'sm'}>
              {region_code}
            </Text>
          )}

          {isEdit ? (
            <Flex mr={'5px'} w={'350px'} alignItems={'center'}>
              <Text
                mr={'5px'}
                color={'#718096'}
                fontSize={'12px'}
                fontWeight={'500'}
              >
                Имя:
              </Text>
              <FormInputControl
                smallErrorTextInside
                size="xs"
                name="region_name"
                register={register}
                errors={errors}
                watchedFields={watchedFields}
                isRequired
                placeholder="Имя субьекта"
                type="text"
              />
            </Flex>
          ) : (
            <Text fontSize={'sm'} textAlign={'left'} maxW={'4xl'}>
              {region_name}
            </Text>
          )}
        </Flex>

        <Flex alignItems={'center'}>
          {isEdit && isDirty && (
            <Box>
              <Flex justifyContent={'flex-start'}>
                {/* <Button mr="5px" onClick={handleResetForm} size={'xs'}>
                    <Flex alignItems={'center'}>Отмена</Flex>
                  </Button> */}
                <Button
                  mr="5px"
                  size={'xs'}
                  colorScheme="blue"
                  type="submit"
                  isDisabled={isUpdateSubjectLoading}
                  isLoading={isUpdateSubjectLoading}
                >
                  Сохранить
                </Button>
              </Flex>
            </Box>
          )}
          {isEdit && (
            <Box mr={'15px'}>
              <DeleteSubject
                subject_id={subject_id}
                region_name={region_name}
              />
            </Box>
          )}

          <Access
            permissions={[accessDict.update_read]}
            module={modulesDict.reference_books}
          >
            <IconButton
              size="xs"
              aria-label="Edit Activity"
              icon={isEdit ? <HiMiniXMark /> : <HiOutlinePencilSquare />}
              onClick={handleEditClick}
              fontSize="20px"
              // {...(isEdit ? { colorScheme: 'red' } : {})}
            />
          </Access>
        </Flex>
      </ListView.Stacks>
    </form>
  )
}
