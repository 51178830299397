import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useEffect } from 'react'

export interface CreateRelatedContractFormData {
  buyer?: boolean
  supplier?: boolean
  type_contract_id?: string
}

const SCHEMA_VALIDATION = yup
  .object()
  .shape({
    buyer: yup.boolean(),
    supplier: yup.boolean(),
    type_contract_id: yup.string().required('Вид договора обязательное поле'),
  })
  .test(
    'buyer_or_supplier',
    'Необходимо выбрать поставщика или покупателя',
    function (values) {
      const { buyer, supplier } = values
      if (!buyer && !supplier) {
        return this.createError({
          path: 'buyer',
          message: 'Необходимо выбрать поставщика или покупателя',
        })
      }
      return true
    },
  )
export const useCreateRelatedTypeContractForm = () => {
  const {
    setValue,
    register,
    handleSubmit,
    formState,
    resetField,
    reset,
    control,
    watch,
    setError,
    clearErrors,
  } = useForm<CreateRelatedContractFormData>({
    resolver: yupResolver(SCHEMA_VALIDATION),
    mode: 'onChange',
  })

  const watchedFields = watch()

  const buyer = watch('buyer')
  const supplier = watch('supplier')

  useEffect(() => {
    if (formState.isDirty && !buyer && !supplier) {
      setError('buyer', {
        type: 'manual',
        message: 'Необходимо выбрать поставщика или покупателя',
      })
      setError('supplier', {
        type: 'manual',
        message: 'Необходимо выбрать поставщика или покупателя',
      })
    } else {
      clearErrors('buyer')
      clearErrors('supplier')
    }
  }, [buyer, supplier, setError, clearErrors])

  return {
    reset,
    register,
    handleSubmit,
    errors: formState.errors,
    isDirty: formState.isDirty,
    isValid: formState.isValid,
    isSubmitting: formState.isSubmitting,
    control,
    resetField,
    setValue,
    watchedFields,
    watch,
    setError,
    clearErrors,
  }
}
