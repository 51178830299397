import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
} from 'react'

type UserRoleContextType = {
  role: any | null
  setRole: Dispatch<SetStateAction<any | null>>
}

const UserRoleContext = createContext<UserRoleContextType>({
  role: null,
  setRole: () => {},
})

export const useUserRole = () => useContext(UserRoleContext)

type UserRoleProviderProps = {
  children: ReactNode
}

export const UserRoleProvider: React.FC<UserRoleProviderProps> = ({
  children,
}) => {
  const [role, setRole] = useState<any | null>(null)

  return (
    <UserRoleContext.Provider value={{ role, setRole }}>
      {children}
    </UserRoleContext.Provider>
  )
}
