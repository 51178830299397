import { useQuery } from 'react-query'
import { uniqueId } from 'lodash'

import { ActivityAreasResponse } from 'shared/models'
import { queryKeys } from 'shared/constants'
import { ReferenceBookService } from 'shared/services'

interface UseGetDetailActivityAreasResult {
  data: ActivityAreasResponse | undefined
  isError: boolean
  isFetching: boolean
  isLoading: boolean
}

interface UseDetailActivityAreas {
  enabled: boolean
  gs_activity_areas_id: string
}

export function useGetDetailActivityAreas({
  enabled,
  gs_activity_areas_id,
}: UseDetailActivityAreas): UseGetDetailActivityAreasResult {
  const { data, isError, isFetching, isLoading } =
    useQuery<ActivityAreasResponse>({
      queryKey: [
        queryKeys.referenceBooks.activityAreas.detailActivityAreas,
        gs_activity_areas_id,
      ],
      queryFn: () =>
        ReferenceBookService.getDetailActivityAreas({
          gs_activity_areas_id,
        }).then((response) => {
          if (response.data && response.data.related_group_delivery_points) {
            return {
              ...response.data,
              related_group_delivery_points:
                response.data.related_group_delivery_points.map((group) => ({
                  ...group,
                  id: uniqueId('groupDelivery_'),
                })),
            }
          } else {
            return response.data
          }
        }),
      enabled,
      retry: false,
    })

  return { data, isError, isFetching, isLoading }
}
