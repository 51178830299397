import React from 'react'

import { ListView } from 'shared/ui'
import { EnergySystemResponse } from 'shared/models'
import { EnergySystemItem } from './EnergySystemItem'

interface EditEnergySystemProps {
  energySystems: EnergySystemResponse[]
}

export const EditEnergySystem: React.FC<EditEnergySystemProps> = (props) => {
  const { energySystems } = props

  return (
    <ListView.Lists>
      {energySystems.map((system) => (
        <EnergySystemItem
          key={system.ue_system_id}
          system_name={system.system_name}
          ue_system_id={system.ue_system_id}
          code_id={system.code_id}
        />
      ))}
    </ListView.Lists>
  )
}
