import { useQuery } from 'react-query'

import { HolydaysResponse } from 'shared/models'
import { queryKeys } from 'shared/constants'
import { ReferenceBookService } from 'shared/services'

interface UseGetHolydaysResult {
  data: HolydaysResponse[] | undefined
  isError: boolean
  isFetching: boolean
  isLoading: boolean
}

interface UseGetAllHolydays {
  enabled: boolean
}

export function useGetAllHolydays({
  enabled,
}: UseGetAllHolydays): UseGetHolydaysResult {
  const { data, isError, isFetching, isLoading } = useQuery<HolydaysResponse[]>(
    {
      queryKey: [queryKeys.holydays.allHolydays],
      queryFn: () =>
        ReferenceBookService.getAllHolydays().then((response) => response.data),
      enabled,
      retry: false,
    },
  )

  return { data, isError, isFetching, isLoading }
}
