import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

export interface AddNewSubjectFormData {
  region_name?: string
  region_code?: string
}

const SCHEMA_VALIDATION = yup.object().shape({
  region_name: yup.string().required('Имя обязательное поле'),
  region_code: yup
    .string()
    .required('Код обязательное поле')
    .max(3, 'Не более 3 символов'),
})

export const watchAddNewSubjectForm = () => {
  const {
    setValue,
    register,
    handleSubmit,
    formState,
    resetField,
    reset,
    control,
    watch,
  } = useForm<AddNewSubjectFormData>({
    resolver: yupResolver(SCHEMA_VALIDATION),
    mode: 'onChange',
    defaultValues: {
      region_name: '',
      region_code: '',
    },
  })

  const watchedFields = watch()

  return {
    reset,
    register,
    handleSubmit,
    errors: formState.errors,
    isDirty: formState.isDirty,
    isValid: formState.isValid,
    isSubmitting: formState.isSubmitting,
    control,
    resetField,
    setValue,
    watchedFields,
  }
}
