import { useQuery } from 'react-query'

import { TypesContractsResponse } from 'shared/models'
import { queryKeys } from 'shared/constants'
import { CounteragentsContractsService } from 'shared/services'

interface TypesContractsData {
  value: string
  label: string
}

interface UseGetTypesContractsResult {
  data: TypesContractsResponse[] | undefined
  isError: boolean
  isFetching: boolean
  isLoading: boolean
  typesContractsData: TypesContractsData[] | undefined
}

interface UseAllTypesContracts {
  enabled: boolean
}

export function useGetTypesContracts({
  enabled,
}: UseAllTypesContracts): UseGetTypesContractsResult {
  const { data, isError, isFetching, isLoading } = useQuery<
    TypesContractsResponse[]
  >({
    queryKey: [
      queryKeys.counteragentsContracts.typesContracts.allTypesContracts,
    ],
    queryFn: () =>
      CounteragentsContractsService.getTypesContracts().then(
        (response) => response.data,
      ),
    enabled,
    retry: false,
  })

  const typesContractsData = data?.map((requisite) => ({
    value: requisite.type_contract_id,
    label: requisite.full_name,
  }))

  return { data, isError, isFetching, isLoading, typesContractsData }
}
